.loan-application-list {
  
    .application-list{
       
        .list-body{
            display:flex;
            font-size:16px;
            align-items:center;
            justify-content:center;
            text-align:left;
            .list-item{
                flex: 1;
                padding:10px 18px;
                i{margin-left:10px;vertical-align:middle;}
            }
        }
        .app-progress-bar {
            background:$grey-secondary;
            border-top:0;
            padding: 20px 0;
            position: relative;
            display:flex;
            &:after {
                position: absolute;
                width: 100%;
                border-bottom: solid 1px $border-default;
                content: '';
                top: 51px;
                left: 0;
            }
            span {
                flex: 1;
                text-align: center;
                i {
                    border-radius: 50%;
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    background: #fff;
                    border: 1px solid $border-default;
                    position: relative;
                    z-index: 2;
                }
                label {
                    display: block;
                    font-size: 12px;
                    color: $text-color;
                }
                &.active {
                    label {
                        color: $text-color2;
                        font-family:$font-family-bold;
                    }
                    i {
                        background-color: $secondary-color;
                        border: 1px solid $secondary-color;
                        color: #fff;
                        text-align: center;
                        font-weight: bold;
                        font-size: 9px;
                        font-family: $font-family-icon;
                    }
                }
            }
        }
    }
}
.hr-divider{
    text-align:center;
    position:relative;
    margin:40px 0;
    color:$text-color;
    span{
        padding:10px;
        background:#fff;
        position:relative;
        z-index:1;
    }
    &:after{
        content:'';
        position:absolute;
        width:100%;
        height:1px;
        background:$default-divider;
        left: 0;
        top: 50%;
        margin-top: -.5px;
    }
}
/* My Application */

table {
  border-collapse: collapse;
  background-color: transparent;
}
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px;
}

.table th,
.table td {
  padding:18px 10px;
  vertical-align: top;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid $border-default;
text-transform:uppercase;
  font-weight:normal;
}

.application-box{
    width:100%!important;
    padding-bottom:25px !important;
   h2{
    font-size:31px !important;
   }
    .dashboard-wrapp{
        padding:45px 0 0;text-align:center;
        a {
            display:inline-block;
            font-size: 23px;
            line-height: 26px;
            text-decoration: none;
            text-align: center;
            color: $secondary-color;
            vertical-align: top;
            padding:0 33px;
            &:first-child{padding-left:0;}
            &:last-child{padding-right:0;}
            small {
                display: block;
                font-size:14px;
                line-height: 16px;
                color: $text-color2;
                text-transform:uppercase;
                text-align: center;
            }
            &.rejected{
                color:$primary-color;
            }
        }
        
    }

}
.loan-application-list {
    padding:40px 0 50px;
    background:#fff;
    .application-list{
        text-align:left;
        .table{
            thead{
                background:$grey-secondary;
                th{
                    color:$text-color;
                    font-size:12px;
                    text-align:left;
                }

            }
            tbody{
                tr{
                    border-bottom:1px solid $border-default;
                    height:101px;
                     td{
                        font-size:16px;
                        padding:30px 10px;
                     }
                }
               
            }
        }
        .acceptance{
            color:$secondary-color;
            font-size:12px!important;
            font-family:$font-family-bold;
            white-space: nowrap;
            .acceptance-box{
                display:inline-block;
                position:relative;
                &:hover{
                    .status-bar{
                        display:inline-block;
                    }
                }
                span{
                    position:relative;
                     padding-left:15px;
                     cursor:pointer;
                     &:after{
                        content:'';
                        position:absolute;
                        width:11px;
                        height:11px;
                        border:1px solid $border-default;
                        border-radius:50%;
                        left:0;
                        top: 50%;
                        margin-top: -5.5px;

                    }
                    &.completed{
                        &:after{
                            border-color:$secondary-color;
                            background-color:$secondary-color;
                        }
                    }
                    &.inprocess{
                        &:after{
                          border-color:$secondary-color;  
                        }
                    }
                }
                .status-bar{
                    position:absolute;
                    z-index:9;
                    width: 268px;
                    top: 20px;
                    display: block;
                    left: -82px;
                }
                .status-box{
                    border:1px solid $secondary-color;
                    background:#fff;
                    height:34px;
                    line-height:32px;
                    padding:0 10px;
                    position:relative;
                    display:inline-block;
                    &:after, &:before {
                        bottom: 100%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        left: 50%;
                        margin-left: -7px;
                    }

                    &:after {
                        border-color: rgba(213, 30, 79, 0);
                        border-bottom-color: #fff;
                        border-width: 7px;
                        margin-right: 1px;
                    }
                    &:before {
                        border-color: rgba(194, 225, 245, 0);
                        border-bottom-color: $secondary-color;
                        border-width: 8px;
                        margin-left: -8px;
                    }

                    ul{
                        position:relative;
                        list-style:none;
                        margin-bottom:0;
                        display:inline-block;
                        &:before{
                            content:'';
                            width:100%;
                            position:absolute;
                            height:1px;
                            background:$border-default;
                            top: 50%;
                            margin-top: -.5px;
                        }
                        li{
                            display:inline-block;
                            margin-left:6px;
                            position:relative;
                            font-size:12px;
                            color:$text-color;
                            text-transform:capitalize;
                            font-family:$font-family;
                            cursor:pointer;
                            &:before{
                                content:'';
                                width:11px;
                                height:11px;
                                border-radius:50%;
                                border:1px solid $border-default;
                                background-color:$grey-secondary;
                                display:inline-block;
                                vertical-align:middle;
                                margin-top:-2px;
                            }
                            &.inprogress{
                               &:before{
                                border-color:$secondary-color;
                                background-color:#fff;
                               } 
                            }
                            &.completed{
                                &:before{
                                    border-color:$secondary-color;
                                    background-color:$secondary-color;
                                }
                            }
                            &:first-child,&:last-child{
                                &:before{
                                    display:none;
                                }
                                background: #fff;
                            }
                            &:first-child{
                                margin-left:0;
                                margin-right: -10px;
                                padding-right: 10px;
                            }
                            &:last-child{
                                margin-left: -3px;
                                padding-left: 10px;
                            }
                            label{
                                position: absolute;
                                font-size: 12px;
                                text-transform: capitalize;
                                color: $text-color2;
                                line-height: 12px;
                                white-space: nowrap;
                                text-align: center;
                                display:none;
                                padding:2px 3px;
                                background:$grey-background;
                                border:1px solid $border-default;
                                border-radius: 2px;
                                margin-bottom:0;
                                margin-top:-3px;
                                z-index:99;
                            }
                            &:hover{
                                label{
                                    display:block;
                                }
                            }
                        }
                    }
                }
            }
            
        }
        .user-name{
            font-family:$font-family-bold;
        }
        .icon-Delete{
            font-size:20px;
            color:$text-color2;
        }
        .continue-btn{
            padding:0 20px;
            &:before{
                border-right:0;
            }
        }
        p{
            color:$text-color;
            text-align:center;
        }

        
        
    }
}
.hr-divider{
    text-align:center;
    position:relative;
    margin:40px 0;
    color:$text-color;
    span{
        padding:10px;
        background:#fff;
        position:relative;
        z-index:1;
    }
    &:after{
        content:'';
        position:absolute;
        width:100%;
        height:1px;
        background:$default-divider;
        left: 0;
        top: 50%;
        margin-top: -.5px;
    }
}

.search-filter{
    position:relative;
    margin-bottom:20px;
    clear:both;
    .createBtn{
        position:absolute;
        right:0;
    }
    .filter, .search-box{
        float:left;
    }
    &:before, &:after{
        content:'';
        clear:both;
        display:table;
    }
}
/* -- Search Box --*/
.search-box{
    position:relative;
    min-height:$form-default-height;
    width:300px;
    .default-textfield, .search-btn{
        position:absolute;
    }
    .default-textfield{
        padding:0 30px 0 10px;
        font-size:13px;
        width:100%;
    }
    .search-btn{
        right:1px;
        width:30px;
        background-color:#f7f7f7;
        height:38px;
        border:0;
        top:1px;
        color:transparent;
        font-size:0;
    }
}

/* -- Filter --*/
.filter{
    margin-left:10px;
    .default-textfield{
        padding:0 10px;
        font-size:13px;
        color:$text-color;
        font-family:$font-family;
    }
    .icon{
        font-size:7px;
        margin-left:50px;
    }
   
}













