textarea {
  resize: vertical;
}
a{
  cursor:pointer;
}
input[type="search"] {
  -webkit-appearance: none;
  -webkit-box-sizing: border-box; 
  -moz-box-sizing: border-box; 
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

button,
input,
select,
textarea {
  font-family: $font-family;
  font-size: $form-field-size;
  color: $form-field-color;
  margin: 0;
}
label {
  display: inline-block;
  margin-bottom: 5px;
  text-transform:uppercase;
}
.range-container{
  position:relative;
  display:inline-block;
  margin-bottom: 40px;
  .range-prefix{
    position:absolute;
    left:-35px;

  }
}

.form-block{
  &:before, &:after{
    content:'';
    display:table;
    clear:both;
  }
  &:last-of-type{
    .btn{margin-top:$form-fields-gap;}
  }
  +h2{margin-top:15px;}
  &.last-block{
    .form-field-row{margin-bottom:0 !important;}
  }
}
.form-field-row {
    margin-bottom: $form-fields-gap;
    color:$form-placeholder;
    font-size:$form-field-size;
    position:relative;
    &.row-full-expand {
        width: 100%;
        float: left;
    }
    md-radio-button{
      margin-right: 65px;
      &:last-of-type{margin-right:0;}
    }
    label {
      display: block;
      font-size:12px;
      font-family:$font-family-bold;
      color:$form-placeholder;
      &.label-lg{
        font-size:$form-field-size;
        font-weight:normal;
        font-family:$font-family;
        margin-bottom:10px;
        text-transform:none;
      }
    }
    .upload-box {
      label{color:$form-field-color;}
    }
    &.with-prefix{
      &.i-number{
          
          .default-textfield{
            padding-left:50px;
          }

        }
      .field-prefix{
        position:absolute;
        color: $form-field-color;
        left: 60px;
        height: $form-default-height;
        font-family:$font-family-bold;
        line-height: $form-default-lineheight;

      }
      .default-textfield{
        padding-left:40px;
      }
    }
    .error, .suggestion{
      position: absolute;
      font-size:11px;
      line-height:13px;
      color:#fff;
      text-transform:none;
      text-align:left;
      padding:3px 20px;
      min-width:330px;
      z-index:2;
    }
    &.fullWidth{
      .error, .suggestion{
        left:0;
        right:0;
      }
    }
    .suggestion{
     background:$primary-blue-bg;
     display:none;
     }
    .error{
       background:$error-color;
      
    }

    .error-las{
      position: absolute;
      font-size:11px;
      line-height:13px;
      color:#fff;
      text-transform:none;
      text-align:left;
      padding:3px 20px;
      min-width:146px;
      z-index:2;
    }
    &.fullWidth{
      .error-las{
        left:0;
        right:0;
      }
    }
  
    .error-las{
       background:$error-color;
      
    }
    @media only screen and (min-width: 1199px) {
      .error-las{
        position: absolute;
        font-size:11px;
        line-height:13px;
        color:#fff;
        text-transform:none;
        text-align:left;
        padding:3px 20px;
        min-width:248px;
        z-index:2;
      }
  }
    
  @media only screen and (min-width: 993px) and (max-width: 1198px) {
    .error-las{
      position: absolute;
      font-size:11px;
      line-height:13px;
      color:#fff;
      text-transform:none;
      text-align:left;
      padding:3px 20px;
      min-width:198px;
      z-index:2;
    }
}


    &:focus{
      .suggestion{display:block;}
    }
    .otp-timer-table {
    right: 20px;
    top: 50%;
    text-align: right;
    width: 80px;
    display: table;
    position: absolute;
    height: 26px;
    margin-top: -13px;
    font-size: 12px;
    .timer-cell {
      display: table-cell;
      vertical-align: middle;
      .timer-circle {
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 24px;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        border: 1px solid $form-field-border;
        color: $text-color;
      }
       a {
          display: inline-block;
          font-family:$font-family-bold;
          vertical-align: middle;
          margin-left:3px;
      }
    }
}

}

 .ec-personalDetail {
  .formly-field-checkbox {
    .form-field-row{
        width: auto !important;
    }
  }
}

.two-block {
    margin: 0 -40px;
    &:before,
    &:after {
        clear: both;
        content: '';
        display: table;
    }
    .form-field-row {
        width: 50%;
        padding: 0 40px;
        float: left;
        .form-field-row{
          padding:0;
          width:100%;
        }
    }
    .two-block{
      margin:0;
      .form-field-row{
          padding:0;
          width:50%;
        }
    }

}
.default-textfield {
    @include default-text-field;
    &.input-error{
        border-color: $error-color;
     }
    &:focus + .suggestion{ display:block}
    &::-webkit-input-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &::-moz-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &:-ms-input-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &::placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &[disabled]{
  background-color: $background-disable;
  font-family:$font-family-bold;
  &::-moz-placeholder {font-family:$font-family-bold;}
  &::-webkit-input-placeholder {font-family:$font-family-bold;}
   &:-ms-input-placeholder {font-family:$font-family-bold;}
    }
    &:disabled {
        background-color:$background-disable;
        opacity: 1;
        cursor: not-allowed;
    }
    &[readonly] {
        opacity: 1;
    }
    &.error-box{
      border:1px solid $error-color;
    }

}

md-autocomplete md-autocomplete-wrap {
    height: 40px;
    box-shadow: none !important;
    position: relative;
    input[type=search] {
        display: block;
    width: 100%;
    height: $form-default-height;
    font-size: $form-field-size;
    line-height: $form-default-lineheight;
    color: $form-field-color;
    padding: 0 20px;
    background-color: #fff;
    text-transform:capitalize;
    font-family:$font-family-bold;
    text-align:left;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid $form-field-border;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }
    &:focus {
        background-color: $form-field-background-on-hover;
        border-color: $form-field-border-on-hover;
        outline: none;

    }
    &::-webkit-input-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &::-moz-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &:-ms-input-placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    &::placeholder {
        color: $placaholder;
        opacity: 1;
        font-weight: normal;
        font-family:$font-family;
        text-transform:capitalize;
    }
    }
}

md-autocomplete .md-show-clear-button button {
    position: absolute;
    right: 0;
    height: 40px;
    color:$form-field-border;
    md-icon{
      height: 14px;
      width: 14px;
      min-height: 18px;
      min-width: 18px;
      path{  fill: $form-field-border;}
    }
}
md-autocomplete.md-default-theme button md-icon path, md-autocomplete button md-icon path

md-select-value {
  &.md-select-placeholder{
    color: $placaholder !important;
    opacity: 1;
    font-weight: normal;
    text-transform:capitalize;
  }
}


.upload-box {
    margin-bottom: $form-seprator-gap;
    font-size:$field-font-size;
    color:$form-field-color;
    margin: 0 0 40px;
    position:relative;
    label{
      color:$form-field-color;
      margin-bottom:0;
      font-weight: normal;
      font-size: 20px;
      text-transform: none;
    }
    .sep{
      position:absolute;
      left:50%;
      margin-left:-10.5px;
      color:$form-placeholder;
      font-size:22px;
      text-transform:lowercase;
      top: 50%;
      margin-top: -12.5px;
    }
    .form-field-row{
      margin-bottom:0;
      padding:0;
    }
    .myLabel {
      border: 1px dashed $form-placeholder;
      border-radius: 8px;
      display: inline-block;
      width: 374px;
      height: 120px;
      position:relative;
      padding:35px 40px;
      padding-left:112px;
      cursor:pointer;
      span{
        &:before{
          content:'\e909';
          font-family: $font-family-icon;
          position:absolute;
          top:50%;
          text-align:center;
          margin-top:-21.5px;
          left:40px;
          font-size:40px;
          color:$secondary-color;
          /*background:$form-field-border-on-hover;*/
        }
        small{
          font-family:$font-family;
        }
      }
      input[type="file"] {
        position: fixed;
        top: -1000px;
    }
  }
    .connect-social{
      width:374px;
      display:inline-block;
      text-align:left;
      padding-top:15px;
    }

}

/* Upload button */
.fileUpload {
  position: relative;
  overflow: hidden;
  color:$secondary-color;
  text-decoration:underline;
  cursor:pointer;
  .without-btn{
    margin-bottom:20px;
    display:inline-block;
    text-decoration:underline;
  }
  span{text-transform:uppercase;font-size:16px;}
  &.btn{
    text-decoration:none;
    span{font-size:20px;}
  }
}
.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width:100%;
  height:100%;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-name{
  padding-right:25px;
  position:relative;
  padding-left: 30px;
  margin-top:15px;
  &:first-of-type{
    margin-top:30px;
   
  }

  &.uploaded{
    padding-left:50px;
  }
  strong{
    color:$text-color2;
    font-size:$form-field-size;
  }
  .action-btn{
    position:absolute;
    right:20px;
    top: 50%;
    font-size:18px;
    margin-top: -10.5px;
    z-index: 9;
    &.left-icon{
      left:0;
      right:auto;
      margin-top:-18px;
    }
    .icon-icon-Check{
      width:36px;
      height:36px;
      line-height:34px;
      border-radius:50%;
      color:#fff;
      font-weight:bold;
      text-align:center;
      font-size: 14px;
      background:$secondary-color;
      left:0;
    }
    small{font-size:13px;}
  }
}

.form-error{
  color:$error-color;
  text-align:center;
  font-size:14px;
}

.uploaded-image{
  width:345px;
  margin:0 auto;
  padding:40px;
  border: 1px solid $form-field-border;
  box-shadow: 0 0 6px 0 $shadow-default;
  background:$grey-secondary;
  margin-bottom:30px;
  padding-left:120px;
  position:relative;
  .user-image{
    margin:0 auto;
    border-radius:50%;
    border: 1px solid $form-field-border;
    background:$light-background;
    width:80px;
    height:80px;
    position:absolute;
    left:20px;
    top:50%;
    margin-top:-40px;
    overflow:hidden;
    img{max-width:100%;}
  }
  .action-btn{
    position:absolute;
    right:20px;
    top:50%;
    margin-top:-7px;

  }
}

@media (max-width: 768px) {
  .file-name{
    br{display:block !important;}
  }
  .fileUpload{
    &.btn{
      span{
        font-size:14px;
      }
    }
  }
}

.anchor-spacing-under-btns{
  span{
    color:$primary-anchor-color;
    cursor:pointer;
    text-decoration:underline;
  }
}
.uploaded-file-container{
  margin-top:30px;
  max-height: 80px;
  overflow: auto;
  .file-name{
    &:first-of-type{margin-top:0;}
  }
  .upload-more{
    margin-top:30px;
  }
}

.no-top{top:0 !important;margin-bottom:20px!important;vertical-align: middle;}
.ver-mid{vertical-align:middle;top:0;}
/*.uploaded-file-container{
  .uploaded{
    .action-btn{right:0;}
  }
}*/
.las-form{
  .form-field-row{
    position: relative;
    input[type="text"],select{
      position: relative;
      z-index: 2;
    }
    .label{
      position: absolute;
      transition: all 0.5s ease-in-out;
      z-index: 0;
      .fas{
        margin-right: 5px;
      }
    }
    .ng-empty ~ .label{
      top: 5px;
      left: 30px;
      color: #8C8C8C;
      font-size: 0.95em;
      transition: .5s;
      pointer-events: none;
    } 
    input:focus ~ .label,
    select:focus ~ .label,
    .ng-not-empty ~ .label,
    md-select[aria-invalid="false"] ~ .label{
      top: -28px;
      left: 17px;
      color: #0062a8 !important;
      z-index: 1;
      font-size: 1.1em;
    }
    
  }
  .formly-field::after{
    content: "";
    display: block;
    clear: both;
  }

  .formly{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    .form-field-row{
      width: 100%;
      margin-top: 10px;

      .default-textfield{
        color: #495057;
        background-color: transparent !important;
        border: 0px solid #FFFFFF !important;
        border-bottom: 1px solid #CCCCCC !important;
      }
    }
  }
  .formly-field:not(.form-section-title-wrap){
    width: 50%;
    margin-bottom: 40px;
  }
  .form-section-title-wrap{
    clear: both;
    border-bottom: 2px solid rgba(26,167,227,1.00) !important;
    margin: 0 0 40px;
    padding: 0 15px;
    width: 100%;
    .form-section-title{
      margin: 10px 0 0;
      color: #0062a8;
      font-size: 17px;
      font-weight: bold;
      text-shadow: none;
      line-height: 26px;
    }
  }
  .button-radio-wrap{
    .radio-label{
      margin-bottom: 20px;
    }
    display: block;
    margin-top: 20px;
    md-radio-button{
      margin-right: 4px;
      padding: 4px 8px;
      line-height: 21px;
      border-radius: 4px;
      display: inline-block;
      color: #000;
      transition: all 0.15s ease-in-out;
      &[aria-checked="true"]{
        background: rgba(26,167,227,1.00);
        .md-label{
          color: #fff;
        }
        &:focus,
        &:active{
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
      .md-container{
        opacity: 0;
      }
      .md-label{
        margin-left: 0;
      }
    }
  }
  &.las-otp-form{
    .formly-field{
      margin: 0 auto 40px;
    }
  }
}
.form-field-row md-radio-group[disabled="true"],
.form-field-row md-radio-group[disabled="true"] md-radio-button{
  cursor: not-allowed;
}
@media all and (max-width: 767px) {
  .las-form{
    .formly-field:not(.form-section-title-wrap){
      width: 100%;
    }
  }
}



.las-form-main{
  .form-field-row{
    position: relative;
    input[type="text"],select{
      position: relative;
      z-index: 2;
    }
    .label{
      position: absolute;
      transition: all 0.5s ease-in-out;
      z-index: 0;
      .fas{
        margin-right: 5px;
      }
    }
    .ng-empty ~ .label{
      top: 5px;
      left: 30px;
      color: #8C8C8C;
      font-size: 0.95em;
      transition: .5s;
      pointer-events: none;
    } 
    input:focus ~ .label,
    select:focus ~ .label,
    .ng-not-empty ~ .label,
    md-select[aria-invalid="false"] ~ .label{
      top: -28px;
      left: 17px;
      color: #0062a8 !important;
      z-index: 1;
      font-size: 1.1em;
    }
    
  }
  .formly-field::after{
    content: "";
    display: block;
    clear: both;
  }

  .formly{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch;

    .form-field-row{
      width: 100%;
      margin-top: 10px;

      .default-textfield{
        color: #495057;
        background-color: transparent !important;
        border: 0px solid #FFFFFF !important;
        border-bottom: 1px solid #CCCCCC !important;
      }
    }
  }
  .formly-field:not(.form-section-title-wrap){
    width: 25%;
    margin-bottom: 40px;
  }
  .form-section-title-wrap{
    clear: both;
    border-bottom: 2px solid rgba(26,167,227,1.00) !important;
    margin: 0 0 40px;
    padding: 0 15px;
    width: 100%;
    .form-section-title{
      margin: 10px 0 0;
      color: #0062a8;
      font-size: 17px;
      font-weight: bold;
      text-shadow: none;
      line-height: 26px;
    }
  }
  .button-radio-wrap{
    .radio-label{
      margin-bottom: 20px;
    }
    display: block;
    margin-top: 20px;
    md-radio-button{
      margin-right: 4px;
      padding: 4px 8px;
      line-height: 21px;
      border-radius: 4px;
      display: inline-block;
      color: #000;
      transition: all 0.15s ease-in-out;
      &[aria-checked="true"]{
        background: rgba(26,167,227,1.00);
        .md-label{
          color: #fff;
        }
        &:focus,
        &:active{
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
      }
      .md-container{
        opacity: 0;
      }
      .md-label{
        margin-left: 0;
      }
    }
  }
  &.las-otp-form{
    .formly-field{
      margin: 0 auto 40px;
    }
  }
}

@media all and (max-width: 767px) {
  .las-form-main{
    .formly-field:not(.form-section-title-wrap){
      width: 100%;
    }
  }
}