.tab-vertical{
    width:100%;
    margin-top:0;
    padding:50px 0;
    h2{
        margin-top:0;
        font-size:31px;
        line-height:34px;
    }
    .tab-wrapper{
        width:30%;
        display:block;
        float:left;
        height:auto;
        li{
            margin-left:0;
            text-align:left;
            background:$tab-bg;
            height:44px;
            line-height:44px;
            margin:2px 0;
            padding:0 30px;
            position:relative;
            color:$tab-color;
            &:first-child{
                margin-top:0;
            }
            &:last-child{
                margin-bottom:0;
            }
            &:before, &:after{
                display:none;
            }
            &.active{
                background:$secondary-color;
                color:#fff;
                &:after{
                    content: '';
                    border-top: 10px solid transparent;
                    border-left: 10px solid $secondary-color;
                    border-bottom: 10px solid transparent;
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: -10px;
                    top: 11px;
                    display:block;
                    border-radius:0;
                    background:none;
                }
            }
        }
    }
    .right-tab-wrapper{
        width:70%;
        float:left;
        padding-left: 30px;
        h2{
            font-size:18px;
            text-align:left;
            margin-bottom:0;
            +p{
                text-align:left;
                margin-bottom:20px;
            }
            &:after{
                display:none;
            }
        }
         h5{
            font-size:15px;
            font-family:$font-family-bold;
        }
    }



}

.accordian-tab{
    padding:0px 30px 10px 30px;
    position:relative;
    margin-bottom:20px;
    &:before{
        content:'+';
        position:absolute;
        width:20px;
        height:20px;
        left:0;
        background:$secondary-color;
        top:0;
        color:#fff;
        text-align:center;
        font-size:20px;
        line-height:20px;
    }
    h6{
        font-size:16px;
    }

    .tab-content{
        display: none;
        padding:10px 0;
    }

    &.active{
        background:$tab-bg;
        padding:10px 30px 15px 30px;
        .tab-content{
            display: block;
        }
        &:before{
            content:'-';
            line-height:16px;
        }
    }
}
.acc-title{
    background:$tab-bg;
    color:#fff;
    margin-left: 0;
    text-decoration:none;
    font-family:$font-family-bold;
    height: 44px;
    line-height: 44px;
    margin: 2px 0;
    padding: 0 30px;
    position: relative;
    color:$secondary-color;
    font-size:15px;
    margin-bottom:20px;
}

.tab-content{
    ol,ul{
        margin-left:15px;
        margin-top:10px;
    }
    .table{
        background:#fff;
        margin-top:20px;
        margin-bottom:0;
        th,td{
            padding:10px;
        }
        thead{
            
            
            font-family:$font-family-bold;
            th{
                text-align:left;
            }
        }
        tbody{
            tr{
                border-bottom:1px solid $border-default;
            }
        }
        &.sbl{
            tbody{
                tr{
                    td{
                        &:first-child{
                            
                            font-family:$font-family-bold;
                        }
                    }
                }
            }
        }
    }
}







