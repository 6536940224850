/* Detail List */

.detail-box {
    padding: 35px 40px;
    margin-top:40px;
    box-shadow: 0 0 4px 0 $shadow-default;
    border: 1px solid $border-default;
    max-height:312px;
    height:312px;
    ol {
        margin: 18px 20px 0;
        li {
            margin-bottom: 25px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    h2 {
        margin-top: 0;
        font-size:21px;
        line-height:24px;
        margin-bottom:30px;
    }
    h6 {
        margin-bottom: 30px;
        margin-top: 0;
    }
    .box-detail{
        color:$secondary-color;
        font-family:$font-family-bold;
        label{
            display:inline-block;
            font-weight:normal;
            font-size:14px;
            width:350px;
            margin-bottom:15px;
        }
        &:last-of-type{
            label{
                margin-bottom:0;
            }
        }
    }
}
.detail-container{
    display:table;
    width:100%;
    margin-top:40px;
    .form-field-row{
        display:table-cell;
        width:50%;
        margin:0 auto;
        float:none;
        height:100%;
        .detail-box {
            height:100%;
            max-height:none;
            min-height:auto;
            margin-top:0;
            color:$text-color2;
        }
        &:last-of-type{
            .detail-box {
                max-width:550px;
                margin:0 auto;
            }
        }
    }
    
}

.for-one{
    .form-field-row{
                width:49.7%;
                margin-bottom: 20px;
    }
}

@-moz-document url-prefix() { 
  .detail-container .form-field-row .detail-box{height:none;}
}
.thankyou-box{
    margin-bottom:20px;
    .form-field-row{
        display:table-cell!important ;
    }
    .detail-box{
        /*margin-bottom:0;*/
        h2{
            text-align:left;
            margin-bottom:0;
            &:after{
                display:none;
            }
            +p{
                text-align:left;
            }
        }
        
        .box-detail{
            &:first-of-type{
                margin-top:18px;
            }
        }

        .personal-detail {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 10px;
            margin-top: 3px;
            font-family: "Cairo-Bold";
        }
    }
}
@media (min-width: 1200px) {
   .detail-box{
        padding: 35px 80px;
    } 
}




@media (max-width: 768px) {
    .detail-container.thankyou-box{
         height: auto;
     } 
 }
 @media (min-width: 769px) {
    .detail-container.thankyou-box{
         height: 0;
     } 
 }

.detailContainer2{
    .form-field-row{
        margin:0 auto 0 -4px;
    }
}
.thankyou-container{
    display: table;
    height:500px;
    width:100%;
    h2{font-size: 31px!important;margin-top:0;}
    .div-cell{
        display: table-cell;
        vertical-align: middle;
    }
}