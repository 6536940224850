
footer {
    background-color: $footer-background;
    padding: 20px 0;
    .footer-links{
        a {
            color:  $footer-link-color;
            font-size: 15px;
            position:relative;
            font-family:$font-family-bold;
            cursor:pointer;
            float: left;
            margin-right: 20px;
            margin-bottom: 10px;
            text-decoration:none;
            &:last-child{margin-right:0}
            &:after{
                content:'';
                position: absolute;
                bottom: -5px;
                left: 0;
                width: 30px;
                height: 2px;
                background-color:$primary-color;
            }
        }
    }
    .copyright {
        font-size: 10px;
        color: $footer-text-color;
        float: left;
        padding: 10px 0 0;
        margin:0;
        clear: both;
    }
    .footer-half{
        float:left;
        width:50%;
       .poweredby {
            width: 89px;
            display:inline-block;
            height: $logo-height;
            background-position: -197px -3px;
         }
       
    }
}
.fixed-footer{
    position:fixed;
    bottom:0;
    width:100%;
}
