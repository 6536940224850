@media (max-width: 1200px) {
    .detail-box-wl {
        width: 46%;
    }
    
}



@media (max-width: 992px) {
.form-field-row .error, .form-field-row .suggestion{
    left:20px;
    right:20px;
    min-width:auto;
}
.only-for-lg{
    display:block;
}
.none-for-md{
    display:none!important
}
    .md-dialog-container{
        .main-form-container {
            margin:0;
            padding:60px;
            h2{
                margin-top:0;
            }
        }
        .main-form-kfs-container {
            margin:0;
            padding:60px;
            h2{
                margin-top:0;
            }
        }
    }
    .form-field-row{margin-bottom:20px;}
    .tab-container-pass {
        min-height: 350px;
        .tab-wrapper {
            height: 60px;
            li {
                height: 60px;
                line-height:60px;
            }
            .tab-text {
                display: none;
            }
        }
    }
    .main-form-container {
        padding: 0 20px 40px;
    }
    .main-form-kfs-container {
        padding: 0 20px 40px;
    }
    .offers {
        padding: 30px 15px;
    }
    .two-block {
        margin: 0 -20px;
        .form-field-row {
            padding: 0 20px;
        }
    }
    .customer-login {
        padding: 40px;
    }
    .dt-tbl {
        .dt-tbl-cl {
            font-size: 14px
        }
    }
    .detail-box-wl {
        h2 {
            font-size: 16px;
        }
        p {
            margin-bottom: 20px;
        }
        &:nth-child(odd) {
            margin-right: 10px;
        }
        &:nth-child(even) {
            margin-left: 10px;
        }
    }
    

    /* My Application */
    .loan-application-list .application-list{
        .acceptance .acceptance-box{
           .status-box{
                &:before{
                     border-width: 8px;
                    margin-left: -14px;
                }
               &:after{
                    border-width: 6px;
                    margin-left: -12px;
                }
                    
            }
        }
    }
    /* /My Application */
    
}

/* Only For Tablet */
@media only screen and (min-width:767px) and (max-width:992px){
     .upload-box{
        margin: 0 0px 40px;
        padding:20px;
        .form-field-row{
            padding:0;
        }
        .myLabel{
            width:auto;
            height:auto;
            padding: 20px;
            padding-left: 70px;
            span{
                &:before{
                    left:20px;
                }
            }
        }
        .connect-social{
            width:auto;
            padding-top:0;
            .btn-social{
                width:auto;
                padding:0 15px;
            }
        }
    }
    .loan-application-list .application-list .app-progress-bar span label{
        font-size:11px;
    }
    .detail-box{
        padding:25px 15px;
    }
    .offers{
        .offer-container{
            margin: -30px -15px;
            padding-top: 30px;
            .div-table{
                padding:20px 0 30px;
                .table-cell{
                    padding:0 8px;
                }
                label{
                    font-size:12px;
                    line-height:15px;
                }
            }
        }
        .customize-offer-container{
            .customize-box{
                .range-slider{
                    md-slider{
                        width:440px;
                    }
                }
            }
        }   
    }

    /* My Application */
    .application-box{min-height:auto !important;}
    .loan-application-list .application-list .acceptance .acceptance-box .status-bar{left:-122px;}
     .loan-application-list .application-list{
        .table{
            thead{
                th{
                    font-size:10px;
                    padding: 18px 5px;
                }
            }
            tbody{
                tr{
                    td{
                       font-size: 12px;
                    padding: 30px 5px; 
                    }
                }
                
            }
        }
    }/* /My Application */
    
}
/* /Only For Tablet */
@media (max-width: 959px) and (min-width: 600px){
    .layout-sm-column {
        -ms-flex-direction: row;
        flex-direction: row;
    }
   
    
}
