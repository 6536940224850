.version {
    position: fixed;
    right: 0;
    bottom: 0;
    max-width: 100%;
    padding: 0.5em;

    font-family: Arial, Helvetica, sans-serif;
    // font-size: 1.5em;
    opacity: 0.9;
    z-index: 9999;

    span {
    
    }
}
