.customer-overlay {
    position: relative;
    width: 530px;
    padding: 40px;
    font-size: $field-font-size;
    color: $form-placeholder;
    h2 {
        margin-top: 0;
    }

}

.confirmation-layer {
    padding: 60px;
    text-align: center;
    &.main-form-container{
        width:auto;
        padding:60px;
        min-height:auto;
        h2{
            margin-top:0;
        }
    }
    &.main-form-kfs-container{
        width:auto;
        padding:60px;
        min-height:auto;
        h2{
            margin-top:0;
        }
    }
    h3 {
        line-height: 16px;
        color: $text-color2;
        margin: 0 0 15px;
    }
    p {
        font-size: 14px;
        line-height: 15px;
        color: $text-color;
    }
    .confirm-btns {
        margin-top: 30px;
        .btn {
            width: 105px;
            &+.btn{
                margin-left:7px;
            }
        }
    }
}

.close-overlay {
    position: absolute;
    width: 50px;
    height: 50px;
    background: $primary-btn-background-color;
    color:#fff;
    text-align: center;
    line-height: 49px;
    right: -50px;
    top: 0;
    cursor: pointer;
    i {
        vertical-align: middle;
    }
}

.md-dialog-container{
    .main-form-container{
        overflow-y:auto;
        text-align:left;
        padding:40px;
        min-height:auto;
        max-width:780px;
        h2{
            margin-top:0;
            +p{
                text-align:left;
                font-size:14px!important;
            }
        }
    }
    .main-form-kfs-container{
        overflow-y:auto;
        text-align:left;
        padding:40px;
        min-height:auto;
        max-width:1060px;
        h2{
            margin-top:0;
            +p{
                text-align:left;
                font-size:14px!important;
            }
        }
    }
}
