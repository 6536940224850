/* here we are overwrite the material css elements*/

md-content.md-default-theme,
md-content {
    background: transparent;
    color: inherit;
}

md-radio-button {
    display: inline-block;
}

md-select {
    margin: 0;
    .md-select-value {
        span {
            font-weight:normal;
            font-family:$font-family-bold;
        }
        &.md-select-placeholder {
            span {
                font-weight: normal;
            }
        }
    }
}

md-select.md-default-theme .md-select-value,
md-select .md-select-value {
    border: 0;
    padding: 0!important;
    .md-select-icon {
        margin: 0;
        transform: translate3d(0, 0, 0);
        color: $form-field-border;
    }
}

md-select-menu.md-default-theme md-content md-option[selected],
md-select-menu md-content md-option[selected] {
    font-weight:normal;
    font-family:$font-family-bold;
    color: $form-field-color;
    &:focus{
        color: $form-field-color;
    }
}

md-select.md-default-theme:not([disabled]):focus .md-select-value,
md-select:not([disabled]):focus .md-select-value, 
md-select.md-default-theme[disabled] .md-select-value, 
md-select[disabled] .md-select-value {
    border: 0!important;
    background-image:none;
    font-family:$font-family-bold;
}
md-select-placeholder{font-weight:normal;font-family:$font-family;}
md-select-menu.md-default-theme md-content md-option:not([disabled]):focus,
 md-select-menu md-content md-option:not([disabled]):focus, 
 md-select-menu.md-default-theme md-content md-option:not([disabled]):hover, 
 md-select-menu md-content md-option:not([disabled]):hover{
    background:$bg-grey;
}
md-select .md-select-value.md-select-placeholder span{
    font-weight: normal;
    font-family:$font-family;
}

md-autocomplete-wrap {
    .md-bar{
        background-color:$secondary-color!important;
    }
    .md-container{
         background-color: $slider-bar-color;
    }
}
/* Radio Button */

md-radio-button {
    margin-bottom:10px;
    .md-label {
        margin-left: 40px;
        display: block;
    }
    .md-container,
    .md-on,
    .md-off {
        width: 30px;
        height: 30px;
    }
    .md-off {
        border-color: $form-field-border;
    }
    .md-on {
        background-color: $form-field-border-on-hover;

    }
    &.md-checked {
        .md-label {
            color: $form-field-color;
        }
        .md-off {
            border-color: $form-field-border-on-hover;
        }
        
       
    }
}
md-radio-group.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before, 
md-radio-group.md-focused:not(:empty) .md-checked .md-container:before{
    background-color:$form-field-background-on-hover !important
}


/* CheckBox */

md-checkbox{
    margin-bottom:0;
    margin-top:10px;
    display:block;
    min-height:30px;
    min-width:30px;
    text-align:left;
    &+md-checkbox{margin-top:15px;}
    .md-container{
        top:0;
        transform: translateY(0);
    }
   
    .md-container, .md-icon{
        width:30px;
        height:30px;
        border-radius:0;
    }
    .md-label{
        margin-left:40px;
       font-size:14px;
    }
    &.md-default-theme:not(.md-checked) .md-icon,
    &:not(.md-checked) .md-icon {
        border-color: $form-field-border;
    }
    
    &.md-default-theme.md-checked .md-icon,
    &.md-checked .md-icon {
        background-color: $form-field-border-on-hover;
        &:after {
            left: 5.66667px;
            top: 1.22222px;
            width: 6.66667px;
            height: 13.33333px;
        }
    }
}

/* Overlay */
.md-dialog.md-default-theme, md-dialog{
    border-radius:0;
    box-shadow:none;
}
.md-scroll-mask > .md-scroll-mask-bar{
    background:rgba(0,0,0,.9);
}

/* Range Slider */
md-slider{
    display:inline-block;
    height:30px;
    margin:0;
    .md-track-container{
        height:20px;
        background-color: $slider-bar-color;
        border-top:$secondary-color 3px solid;
        border-radius:0;
        top:0;
    }
    .md-track{
        height:20px;
        background:none;
       
        &.md-track-fill{
            background-color:$secondary-color;
            border-radius:0;
        }
    }
    .md-thumb{
        left:-18px;
    }
    .md-sign{
        background-color:$slider-color;
        width:30px;
        border-radius:0;
        height:30px;
        left:-7px;
        top:-5px;
        transform:scale(1) translate3d(0, 0, 0);
        .md-thumb-text{
             display:none;
        }
        &:after{
            top: 10px;
            left: 10px;
            border-radius: 0;
            transform: scale(1) translate3d(0, 0, 0) !important;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 20px 20px;
            border-color: transparent transparent $secondary-color  transparent;
            z-index: 9;
            opacity: 1;
        }
    }
    .md-default-theme.md-min[md-discrete] .md-thumb, .md-min[md-discrete] .md-thumb, .md-thumb{
        &:after{
            background-color:$slider-color !important;
            border-color: $secondary-color !important;
            display: none;
        }
    }
    .md-track-ticks{
        canvas{
            height:0!important;
        }
    }
    
}
/*material slider overwrite*/
md-slider.md-min .md-sign{
    opacity: 1;
    top:-5px;
    left:0;
    background-color:$slider-color !important;
    cursor:pointer;
}

md-toast{
    top:0 !important;
    display:block !important;
    left:50%!important;
    margin-left:-152px;
    bottom:auto !important;
}
md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content{
    background-color: $secondary-color;
    color: #fff;
    width:304px;
}
.md-toast-animating{
    overflow:auto!important;
    overflow-x:hidden!important;
}
md-dialog{
    overflow:visible;
}

html.md-default-theme, html, body.md-default-theme, body {
    background-color: transparent !important;
}
md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after{
    display:none;
}
md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, md-select.ng-invalid.ng-touched .md-select-value{
    color:$form-placeholder!important;
}
body {
   > div {
           overflow: auto;
           max-height: 100%;
   }
   .md-select-menu-container {
       box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
   }
}
