.information-container{
     margin-top:30px;
    .added-applicant{
        padding:30px;
        color:$text-color2;
        margin:0;
        min-height:180px;
        max-height:180px;
        overflow:hidden;
        background:$grey-secondary;
        .btn{margin-top: 38px;}

        .addedList{
            max-height: 73px;
            overflow: hidden;
            overflow-y: auto;
            padding-top:10px;
            .file-name{
                &:first-of-type{margin-top:0;}
                &.uploaded{min-height:27px;}
                strong{
                    vertical-align:middle;
                }
            }
            .action-btn.left-icon {
                margin-top: -15px;
            }
           
        }
        &.new-applicant{
            background:$light-background;
            cursor:pointer;
            h6{
                padding-right:0;
            }
        }
        h6{
            padding-right:30px;
        }
        ul{
            margin-top:10px;
            list-style:none;
            li{
                margin-bottom:5px;
                &:last-child{margin-bottom:0;}
            }
        }
           
    }
    .upload-more{
        .without-btn{
            margin-bottom:0;
        }
        text-align:center;
        margin-top:20px;
    }

    .detail-container{
        margin-top:0;
        margin-bottom:20px;
        .form-field-row{
            &:last-of-type{
                .reference-box{
                    max-width: 330px;
                    margin: 0 auto;
                }
            }
        }
    } 
  
}
.upload-finacials{
    .information-container{
        margin-top:30px;
        .reference-box{
            min-height: 232px;
            height: 100%;
            max-height: 260px;
            overflow: hidden;
            margin-bottom: 20px;
        }
    }
}
.add-personal-co-applicant{
    .information-container{
       
        .reference-box{
            min-height:auto;
        }
    }
}

.detail-container{
    text-align:center;
    .form-field-row{
        display: inline-block;
        text-align:left;
    }
    &.for-one{
    text-align:left;
    }
}

.upload-finacials .information-container .reference-box {
    min-height: 232px;
    height: 100%;
    max-height: 232px;
    margin-bottom: 20px!important;
    text-align: left;
}

.refBox{min-height:160px!important;}


