.btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    font-family: $font-family;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: $form-button-fontsize;
    height: $form-button-height;
    line-height: $form-button-lineheight;
    padding: 0 40px;
    border-radius:0!important;
    &.btn-large {
        min-width: 450px;
    }
    &.btn-xlg {
        width: 300px;
    }
    &.btn-primary {
        background: $primary-btn-background-color;
        color: $primary-btn-color;
    }
    &.btn-block {
        width: 100%;
    }
    &.btn-border {
        border: solid 1px $secondary-btn-background-color;
        color: $secondary-btn-background-color;
        background: $light-background;
    }
    &.small-btn{
        height: auto;
        line-height: normal;
        padding: 9px 10px;
        font-size: 14px;
    }
    &.mid-btn{
        line-height: 50px;
        height:50px;
        padding: 0px 20px;
        font-size: 14px;
    }
    &.without-bg{
        color: $text-color;
        background:none;
         &:hover {
            background: $primary-btn-background-color;
            color: $primary-btn-color;
        }
    }
    &.link{
        border:0;
        text-decoration:underline;
        padding:0 20px;
        &:before, &:after{
            display:none;
            padding:0;
            text-align:center;
        }
    }
    
}
button{
    &.link{
        border:0;
        background:none;
    }
}
.btn-social {
    width: 150px;
    height: 50px;
    line-height: 50px;
    border-radius: 0;
    color: #fff;
    font-size: 24px;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    margin-top: 15px;
    padding: 0;
    min-width: auto;
    background-image: $icon-background;
    background-size: 489px auto;
    &.fb {
        background-position:-358px -80px;
    }
    &.linkedIn {
        background-position:-358px -128px;
    }
    &+.btn {
        margin-left: 6px;

    }
}

.anchor-primary {
    color: $primary-anchor-color;
    text-decoration: underline;
    font-family:$font-family-bold;
}

.button-conditional {
    margin-top: 40px;
    .btn {
        +.btn {
            margin-left: 35px;
        }
    }
}

.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  

.button3 {background-color: #f44336;} /* Red */ 