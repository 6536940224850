/* here we are using gill fonts as per yes bank guidelines*/
/* updated the font to Cairo as per the guidelines received on Sept-2023*/

@font-face {
    font-family: $font-family;
    src: $font-url-ttf;
}

@font-face {
    font-family: $font-family-bold;
    src: $font-url-bold-ttf;
    font-weight:normal;
}

@font-face {
    font-family: $font-family-icon;
    src: $font-url-svg-icon;
}


@font-face {
    font-family: $font-family-icon;
    src: $font-url-ttf-icon;
}

* {
    outline: none;
}

body,
html {
    background: $body-background;
    /*height:auto;*/
}

body {
    font-family: $font-family;
    margin: 0;
    padding: 0;
    font-size: $text-size;
    color: $text-color2;

}
// strong, b{
//     font-weight:normal;
//     font-family:$font-family-bold;
// }

.fullWidth{
    width:100%!important;
}
/*start of container classes*/

.container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px
}

@media(max-width: 767px) {
    .container {
        width: 100%;
    }
}

@media(min-width: 768px) {
    .container {
        width: 720px;
        max-width: 100%
    }
}

@media(min-width: 992px) {
    .container {
        width: 960px;
        max-width: 100%
    }
}

@media(min-width: 1200px) {
    .container {
        width: 1170px;
        max-width: 100%
    }
}


/*end of container classes*/

.text-center {
    text-align: center
}

.text-left {
    text-align: left
}

.text-right {
    text-align: right
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}
.font14normal{
    font-size:14px!important;
    text-transform: none !important;
    font-family:$font-family;
}
.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}
.normalcase {
    text-transform: none !important;
}

a {
    text-decoration: underline;
    color: $primary-anchor-color;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 1px;
    width: 100%;
    border: 0;
    margin: 40px 0;
    background: $default-divider;
    overflow: visible;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding: 0;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

ol {
    margin-left: 20px;
}

a,
area,
button,
[role="button"],
input,
label,
select,
textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

@media print {
    *,
    *::before,
    *::after,
    p::first-letter,
    div::first-letter,
    blockquote::first-letter,
    li::first-letter,
    p::first-line,
    div::first-line,
    blockquote::first-line,
    li::first-line {
        text-shadow: none !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
    }
    a,
    a:visited {
        text-decoration: underline;
    }
    abbr[title]::after {
        content: " (" attr(title) ")";
    }
    pre {
        white-space: pre-wrap !important;
    }
    thead {
        display: table-header-group;
    }
    tr,
    img {
        page-break-inside: avoid;
    }
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    h2,
    h3 {
        page-break-after: avoid;
    }
    .table {
        border-collapse: collapse !important;
    }
    .table td,
    .table th {
        background-color: #fff !important;
    }
    .table-bordered th,
    .table-bordered td {
        border: 1px solid #ddd !important;
    }
}

/* Background Color */

.bg-grey2 {
    background: $grey-secondary;
}


/* Device */

.only-for-mobile {
    display: none!important;
}

.only-for-xs {
    display: none!important;
}
.only-for-lg{
    display:none;
}

.container:before,
.container:after {
    content: '';
    display: table;
    clear: both;
}

md-radio-group{
    display: block;
}

.loader {
    position:fixed;
    z-index:99;
  border: 3px solid $primary-color;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  left: 50%;
  margin-left:-50px;
  top:50%;
  margin-top:-50px;
  color:#fff;
}
.red-loader{
  border: 3px solid #f3f3f3;
  border-top: 3px solid $primary-color;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader1 {
  border: 3px solid $form-field-border;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
  position:relative;
}
.red-loader1{
  border: 3px solid $form-field-border;
  border-top: 3px solid $primary-color;
  top:16px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
/* Sub Box */



/* Heading */
h1, h2, h3, h4, h5, h6{
    font-weight:normal;
}
h1 {
    font-size: 36px;
    color: $text-color2;
}

h2 {
    font-size: 21px;
}

h3 {
    font-size: 20px;
    font-family:$font-family-bold;
}

h4 {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: normal;
}

h6 {
    font-size: 20px;
    font-family:$font-family-bold;
    margin:0;
}

p {
    margin: 0;
    big {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 10px;
        display: block;
    }
    &.big {
        margin-top: 10px;
    }
}

.text-error {
    color: $primary-color;
}

.display-linline {
    display: inline-block;
}

.div-table {
    display: table;
    width: 100%;
    .table-cell {
        display: table-cell;
        padding: 0 10px;
    }
}

.link {
    color: $primary-anchor-color;
    text-decoration: underline;
}

.main-form-container {
    padding: 0 80px 80px;
    &:before,
    &:after {
        display: table;
        content: '';
    }
    h2.name-capitalize{
        text-transform: capitalize;
    }
    h2 {
        font-size: 21px;
        line-height: 24px;
        color: #404040;
        position: relative;
        text-align: center;
        margin-top: 35px;
        margin-bottom: 40px;
        padding-bottom: 5px;
        font-family:$font-family-bold;
        text-transform: uppercase;
        &:after {
            content: '';
            position: absolute;
            width: 100px;
            height: 5px;
            background-color: $primary-color;
            left: 50%;
            margin-left: -50px;
            bottom: -5px;
        }
        +p {
            margin-top: -10px;
            text-align: center;
            &.text-right{text-align:right;}
        }
    }
    h3 {
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 5px;
        margin: 10px 0 40px;
        position: relative;
        display: inline-block;
        width: 100%;
        color:$text-color2;
        &:after {
            content: '';
            position: absolute;
            width: 50px;
            height: 5px;
            background-color: $primary-color;
            left: 0;
            bottom: -5px;
        }
    }
}

.main-form-kfs-container {
    padding: 0 80px 80px;
    &:before,
    &:after {
        display: table;
        content: '';
    }
    h2.name-capitalize{
        text-transform: capitalize;
    }
    h2 {
        font-size: 21px;
        line-height: 24px;
        color: #404040;
        position: relative;
        text-align: center;
        margin-top: 35px;
        margin-bottom: 40px;
        padding-bottom: 5px;
        font-family:$font-family-bold;
        text-transform: uppercase;
        &:after {
            content: '';
            position: absolute;
            width: 100px;
            height: 5px;
            background-color: $primary-color;
            left: 50%;
            margin-left: -50px;
            bottom: -5px;
        }
        +p {
            margin-top: -10px;
            text-align: center;
            &.text-right{text-align:right;}
        }
    }
    h3 {
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 5px;
        margin: 10px 0 40px;
        position: relative;
        display: inline-block;
        width: 100%;
        color:$text-color2;
        &:after {
            content: '';
            position: absolute;
            width: 50px;
            height: 5px;
            background-color: $primary-color;
            left: 0;
            bottom: -5px;
        }
    }
}

.las-main-form-container,.las-main-form-container {
    &:before,
    &:after {
        display: table;
        content: '';
    }
    h2.name-capitalize{
        text-transform: capitalize;
    }
    h2 {
        font-size: 21px;
        line-height: 24px;
        color: #404040;
        position: relative;
        text-align: center;
        margin-top: 35px;
        margin-bottom: 40px;
        padding-bottom: 5px;
        font-family:$font-family-bold;
        text-transform: uppercase;
        &:after {
            content: '';
            position: absolute;
            width: 100px;
            height: 5px;
            background-color: $primary-color;
            left: 50%;
            margin-left: -50px;
            bottom: -5px;
        }
        +p {
            margin-top: -10px;
            text-align: center;
            &.text-right{text-align:right;}
        }
    }
    h3 {
        font-size: 18px;
        line-height: 21px;
        padding-bottom: 5px;
        margin: 10px 0 40px;
        position: relative;
        display: inline-block;
        width: 100%;
        color:$text-color2;
        &:after {
            content: '';
            position: absolute;
            width: 50px;
            height: 5px;
            background-color: $primary-color;
            left: 0;
            bottom: -5px;
        }
    }
}
.upload-box {
    border-radius: 8px;
    padding: 42px 50px;
}

.offers .thanksFacInfo{
    margin-top:30px;
    text-align:center;
    p{
        margin-top: 10px;
    }
}
.secondFPL{
    text-align:center;
    font-size: 18px;
    margin-top: 50px;
}

.checkList-wrap{
    margin-top: 20px;
    ul{
        max-width: 90%;
        //margin: 0 auto;
        margin: 0;
        li{
            margin: 10px 0;
            label{
                text-transform: initial;
                cursor: pointer;
            }
            input{
                margin-right: 5px;
            }
        }
    }
}

.yes-no-wrap{
    border-top: 1px solid #ddd;
    margin: 20px 0 50px;
    padding: 25px 0;
    & > div {
        margin: 15px 0 0;
        display: flex;
        md-radio-group{
            margin-left: 5px;
        }
    }
}

.customize-offer-container{
    /* For Firefox */
    input[type='number'] {
        -moz-appearance:textfield;
    }
    /* Webkit browsers like Safari and Chrome */
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

/*start blue header bg*/

.bank-lower-header-bg {
    background-color: $lower-bg;
    height: auto !important;
    //padding-top: 60px;
    h1 {
        margin: 0;
        font-size: 31px;
        font-family:$font-family-bold;
        text-align: center;
        font-weight:normal;
        line-height: 25px;
        color: #FFFFFF;
        text-transform: uppercase;
        small{
            color:$text-color;
            display:block;
            font-size:14px;
            font-family:$font-family;
        }
    }
}


/*end blue header bg*/

.header-wrapper{
    height: 185px !important;
    padding-top: 50px !important;
}

@media (min-width: 768px) {
    .tab-wrapper {
        width: 100%;
    }
}

.tab-container-pass {
    background-color: #FFFFFF;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 50px;
    margin-top: -80px;
    min-height: 500px;
    .tab-wrapper {
        display: -ms-flexbox;
        display: flex;
        transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
        transform: translate3d(0, 0, 0);
        height: 80px;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        li {
            float: none;
            -ms-flex: 1;
            flex: 1;
            height: 80px;
            line-height: 80px;
            font-size: 16px;
            padding: 0;
            text-transform: uppercase;
            background-color: $grey-background;
            color: $text-color;
            text-align: center;
            list-style: none;
            font-weight:normal;
            border-right: solid 1px $border-default;
            border-bottom: solid 1px $border-default;
            cursor: pointer;
            &:last-of-type {
                border-right: 0;
            }
            &.active {
                background-color: $secondary-color;
                color: #fff;
                border: solid 1px rgba(0, 0, 0, 0);
            }
            .icon-icon-Check{
                position: absolute;
                right: -4px;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
                z-index: 100;
            }
            i {
                font-size: 28px;
               
            }
            span {
                display: inline-block;
                
                &.tab-text {
                    margin-left: 4px;
                }
            }
            &:disabled,
            &[disabled] {
                cursor:not-allowed;
                pointer-events: none;
            }
            &.tab_disabled{
                cursor:not-allowed;
                pointer-events: none;
            }
        }
    }
}


/* Sub Box */

.sub-box {
    position: relative;
    width: 490px;
    padding: $default-box-padding;
    font-size: $field-font-size;
    color: $form-placeholder;
    background-color: #FFFFFF;
    border: 1px solid $border-default;
    box-shadow: 0 0 6px 0 $shadow-default;
    margin: 0 auto 0;
    text-align: center;
    &.customer-login {
        margin: -160px auto 80px;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        border: none;
        width: 570px;
        .hr-divider{
            margin:20px 0;
        }
        .singinWithPassword{
            margin-top:0;
        }
    }
    .optional-block{
        padding: 20px 20px 0;
        border: 1px solid $form-field-border;
        .optional-label{
            margin-bottom: 20px;
            font-size: 18px;
            &.error{
                color: #ff0000;
            }
        }
    }
    &.upload-fin {
        margin-top: 50px;
        color: $text-color2;
        md-radio-button {
            margin-top: 20px;
            margin-bottom: 0;
        }
        p:first-of-type {
            margin-bottom: 20px;
        }
    }
    h2 {
        margin-top: 0;
    }
    h4 {
        background: $gray-secondary;
        margin: -80px -80px 40px;
        height: 52px;
        line-height: 52px;
    }
    p {
        small {
            font-size: 13px;
            margin: 15px 0 8px;
            display: block;
        }
    }
    button {
        &+p {
            small {
                margin-bottom: 0;
            }
        }
    }
}

.anchor-spacing-under-btns {
    margin-top: 30px;
    text-transform: uppercase;
    font-size: 16px;
}

.singinWithPassword {
    text-align: center;
    padding: 20px;
    background: #f6f6f6;
    margin: 40px -40px -40px;
    text-transform: uppercase;
    font-size: 14px;
    border-top: 1px solid $border-default;
}

.structure-main {
    min-height: calc(100% - 158px);
    height: auto !important; /* This line and the next line are not necessary unless you need IE6 support */
    width:100%;

}

.secondary-block{
    background:$secondary-bg;
    padding:30px;
    color:#fff;
    margin-bottom:$form-fields-gap;
    cursor:pointer;
}

.file-hint-text{
    text-align: center;
    margin-top: 10px!important;
}

.paddingtop{
    padding-top: 10px;
}

.padd-bottom{
    padding-bottom:20px !important;
}

.link-wl{
    color: $primary-anchor-color;
}

.form-block.sub-box{
    max-width: 410px;
    margin: 0 auto;
}

/* Verification-Documents */
.list-for-verification{
    position:relative;
    padding-right:285px;
    min-height:210px;
    .table{
        margin-bottom:0;
    }
    .doc-for-upload{
        position:absolute;
        right:0;
        top:59px;
        width:265px;
        .btn-border{
            width:100%;
            margin-bottom:10px;
            padding:0 15px!important;
            &:before, &:after{
                display:none;
            }
        }
    }
}
.application-bredcrumb{
    position:relative;
    text-align:center;
    margin-bottom:30px;
    a{
        font-size:14px;
        line-height:15px;
        color: $default-anchor-color;
        text-decoration:none;
        &.prev, &.next{
            position:absolute;
        }
        &.prev{left:0;}
        &.current{
            cursor:default;
            h2{display:inline-block;margin-bottom:0;}
        }
        &.next{right:0;}
    }
}
.inline-div{
    display:inline-block;
    .btn{
        padding:0 20px!important;
        margin-left: -6px;
        height:34px;
        line-height:34px;
        top: -3px;
        &:before{border-right:0;}
    }
}
.verification-table{
    padding-bottom:20px;
    border: 1px solid $form-field-border;
    .default-textfield{
        width:150px;
        margin-left:10px;
        height:34px;
        line-height:32px;
        display:inline-block;
        padding: 0 10px;
        &+.default-textfield{
          position:relative;
          top:-1px;
          margin-left:6px;
        }
    }
    .md-select-value .md-select-icon:after{top:1px;}
    table{
      width:100%;
      &:last-of-type{
        margin-bottom:20px;
      }
      thead{
        background:$grey-secondary;
        border-bottom:#eaeaea solid 1px;
        color:$text-color2;
        font-size:15px;
        font-family:$font-family-bold;
        text-align:left;
        height:52px;
        line-height:52px;
        th{
            padding:0 20px;
            text-transform:capitalize;
           /* &:last-child{padding-left:0;}*/
            span{
                text-align:right;font-family:$font-family;font-size:13px;
            }

        }
        
      }
      tbody{
        tr{
            border-bottom:#eaeaea solid 1px;
            height:50px;
            line-height:50px;
            td{
                padding:0 20px;
                font-size:15px;
                label{
                    min-width:240px;
                    text-transform:none;
                    margin-bottom:0;
                    max-width: 240px;
                    line-height:14px;
                    vertical-align:middle;
                }
                md-radio-group{
                    font-size:14px;
                    md-radio-button+md-radio-button{margin-left:28px;}
                    .md-label{
                        color:$text-color;
                    }
                    .md-checked{
                        .md-label{
                        color:$text-color2;
                        }
                    }
                }
                &:last-child{
                    padding-left:0;
                    text-align:right;
                    font-size:11px;
                }
            }
        }
      }
    }
}

.ec-personalDetail{
    md-checkbox{
        .md-label{
            font-weight: bold;
        }
    }
}

/*loader css*/
// .calculateLoading{
//     width:100%!important;
//     height:100%!important;
// }
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid $primary-btn-background-color;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  color:transparent;
  left: 50%;
  margin-left:-50px;
  top:50%;
  margin-top:-50px;
  position:fixed;
  z-index:99;
}
.getdataLoader{
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid $primary-btn-background-color;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  color:transparent;
  left: 50%;
  margin-left:-50px;
  top:50%;
  margin-top:-50px;
  position:fixed;
  z-index:99;
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.red-loader{
  border-top: 5px solid $primary-color;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader-sm {
  border: 3px solid $form-field-border;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}
.red-loader-sm{
 border-top: 3px solid $primary-color;
  
}
.form-block{
    .red-loader-sm{top:10px;position: relative;}
}

.dsa-loader{
        position:absolute !important;
        right: 0;
}
.material-icons{
    color: #999;
    font-size: 21px !important;
    vertical-align: middle;
    text-decoration: none;
}

.otp-password-type{
    -webkit-text-security: disc;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;
}   


.otp-password-type::-webkit-outer-spin-button,
.otp-password-type::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* Firefox */
.otp-password-type {
    -moz-appearance: textfield;
  }
.las-basic-accordion-heading-parent{
    width:100%;
    cursor: pointer;
}

.las-basic-accordion-heading-child-1{
	width:80%;
    float:left;
}
.las-basic-accordion-heading-child-2{
	width:20%;
    float:right;
    text-align:right
}


/* Firefox */
.otp-password-type {
    -moz-appearance: textfield;
  }

  .right-diamond-active:before {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid #d71920;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

.las-vertical-line{
    border-left: 3px solid #d71920;
    height: 60px;
}

.las-main-heading{
    padding-bottom: 50px;
    padding-top: 20px;
}

.las-main-heading-left{
    width: 50%;
    float:left;
    border-right: 2.5px solid #d71920;
    text-align: right;
    padding-right: 2%;
}

.las-main-heading-right{
    width:50%;
    float:right; 
    padding-left: 2%; 
    padding-top: 14px
}


@media only screen and (max-width: 767px) {
    .responsive-las-main-heading {
      display: none;
    }
}

@media only screen and (min-width: 768px) {
    .las-info-wrapper {
      display: none;
    }
}

.labelWithParagraph{
    color: #0062a8 !important;
    margin-top:8px !important;
}

.personal-detail-confirmation-same-line{
    display: flex;
    width: 100% !important;
}

.customer-id-number::-webkit-outer-spin-button,
.customer-id-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
.customer-id-number {
  -moz-appearance: textfield;
}

.other-charges-tooltip {
    position: relative;
    display: inline-block;
  }
  
  .other-charges-tooltip .other-charges-tooltip-text {
    visibility: hidden;
    width: 250px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -152%;
    margin-left: -195px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .other-charges-tooltip .other-charges-tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 90px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .other-charges-tooltip:hover .other-charges-tooltip-text {
    visibility: visible;
    opacity: 1;
  }

.etb-left-half-red-border {
    position: relative;
    padding-left: 13px;
}

.etb-left-half-red-border:after {
content : "";
content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 4px;
    margin-top: 8px;
    height: 70%;
    background: #d71920;
}

.etb-accordion-heading-parent{
    width:100%;
    cursor: pointer;
    padding-bottom: 60px;
}

.etb-accordion-heading-child-1{
    width:90%;
    float:left;
}
.etb-accordion-heading-child-2{
    width:10%;
    float:right;
    text-align:right
}
.etb-accordion-icon{
    cursor: pointer;
}

.etb-accordion-content{
    margin-top: 19px;
}     

.etb-accordion-form-content{
    padding: 18px 113px 0 113px
}

@media only screen and (max-width: 711px) {
    .responsive-additional-address {
      height:64px;
      margin-bottom: 14px;
    }
}

@media only screen and (min-width:309px) and (max-width: 389px) {
    .responsive-additional-address {
      height:85px
    }
}

@media (max-width: 992px){
    .head-offer-etb {
        width: auto;
        min-height: 0px !important;
    }
}

@media(max-width: 768px) {
    .continue-loan-application-great-mobile{
        margin-top: 120px !important;
    }
}
@media(min-width: 769px) and (max-width: 992px)  {
    .continue-loan-application-great-mobile{
        margin-top: 20px !important;
    }
}
@media(min-width: 769px) {
    .sub-box-home{
        padding-top:53px !important;
    }
}

@media (max-width: 992px){
    .assisted-yes {
        margin-right: 0px !important; 
    }
    .kyc-upload-mobile{
        margin-top: -280px !important;
    }
}

@media(max-width: 768px){
    .passcode-thank-you{
        margin-top: -60px !important;
    }
    .disbursement-initiation-heading{
        margin-top: -60px !important;
    }
    .no-offer-box-mobile{
        margin-top: -75px !important;
    }    
    .great-non-preapproved{
        margin-top: 210px !important;
    }
}

@media(max-width: 767px){
    .bl-loan-detail-box{
        margin-top: 55px !important;
    }
}
.passcode-suggesstion{
    left: 0px !important;
    right: 0px !important;
}

.al-tnc-download-link{
    display:inline-block;width: 75%;
    text-align: left;
}

@media(min-width: 1000px) and (max-width: 1199px){
    .al-tnc-download-link{
        margin-left: -8px;
    }
}

@media(min-width: 769px) and (max-width: 992px){
    .great-non-preapproved{
        margin-top: 20px !important;
    }
}
@media(min-width: 1200px) {
    .al-tnc-download-link{
        margin-left: 52px;
    }
}

@media(min-width: 769px) {
    .complete-loan-application-heading{
        margin-top: 30px !important;
    }
}


.lis-logo-background .lis-logo{
    width: 32rem !important;
    margin-top: 5px;
    height: 11rem !important;
    top: 40px;
    bottom: 105px;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    margin: 11rem 0 0 0 !important;
}


.lis-logo-background{
    line-height: 200px;
    height: 100%;
    /* border: 3px solid green; */
    text-align: center;
    width: 100% !important;
    position: absolute;
    z-index: 20;
    top: 0px;
    /* bottom: 105px; */
    margin: 0 0% 0 0%;
    background: rgba(255, 255, 255, 1);

}


.popupCloseButton {
    background-color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: arial;
    font-weight: bold;
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 45px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
}
.popupCloseButton:hover {
    background-color: #ccc;
}

@media(max-width: 320px) {
    .lis-logo-background .lis-logo{
        width: 15rem !important;
        height: 5.5rem !important;
    }
}

@media(min-width:321px) and (max-width: 375px) {
    .lis-logo-background .lis-logo{
        width: 17rem !important;
        height: 5.75rem !important;
    }
}

@media(min-width: 376px) and (max-width: 730px){
    .lis-logo-background .lis-logo{
        width: 22.25rem !important;
        height: 7.75rem !important;
    }
}

@media(min-width: 731px) and (max-width: 1160px){
    .lis-logo-background .lis-logo{
        width: 23.25rem !important;
        height: 8.75rem !important;
    }
}


.left-half-red-border {
    position: relative;
}

.left-half-red-border:after {
content : "";
position: absolute;
left    : 0;
top  : 0;
width  : 3px;
margin-top: 12px;
height   : 28%;  /* or 100px */
background: #d71920;
}


.las-accordion-icon{
    cursor: pointer;
}

.las-accordion-content{
    margin-top: 12px;
}

.right-diamond-active {
    width: 100px ;
    height: 40px;
    position: relative;
    background: #d71920;
    color: white;
}

thead .table-portofolio-sticky{
    position: sticky;
    top: 0;
    background-color: #E6F0F5 !important;
}

.table-height{
    max-height: 52vh;
}

.table-responsive{
    overflow: auto;
}

.portfolio .enablePort thead td:first-child, .portfolio .enablePort thead td:nth-of-type(2) {
    top: 0;
    z-index: 10;
}

.right-diamond-active:before {
    content: "";
    position: absolute;
    right: -10px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 10px solid #d71920;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }

  .right-diamond-active {
    width: 100px;
    height: 40px;
    position: relative;
    background: #d71920;
    color: white;
}

.left-half-red-border {
        position: relative;
    }
    
    .left-half-red-border:after {
    content : "";
    position: absolute;
    left    : 0;
    top  : 0;
    width  : 3px;
    margin-top: 12px;
    height   : 28%;  /* or 100px */
    background: #d71920;
    }
    
    
    
    .las-accordion-icon{
        cursor: pointer;
    }
    
    .las-accordion-content{
        margin-top: 12px;
    }
    
    
    .right-diamond-active {
        width: 100px ;
        height: 40px;
        position: relative;
        background: #d71920;
        color: white;
    }

    thead .table-portofolio-sticky{
        position: sticky;
        top: 0;
        background-color: #E6F0F5 !important;
    }

    .table-height{
        max-height: 52vh;
    }

    .table-responsive{
        overflow: auto;
    }

    .portfolio .enablePort thead td:first-child, .portfolio .enablePort thead td:nth-of-type(2) {
        top: 0;
        z-index: 10;
    }
    
    .amc-wrapper{
        position:relative;
        width: 300px !important;
        bottom:46px;
    }.amc-tooltip {
        transform: none;
    }
    
    .amc-tooltip:hover > .amc-tooltip-text, .amc-tooltip:hover > .amc-wrapper {
        pointer-events: auto;
        opacity: 0.8;
    }
    
    .amc-tooltip > .amc-tooltip-text, .amc-tooltip >.amc-wrapper {
        left: 0;

        display: block;
        position: absolute;
        z-index: 6000;
        overflow: visible;
        padding: 5px 8px;
        margin-top: 10px;
        border-radius: 4px;
        text-align: center;
        color: #fff;
        background: #000;
        pointer-events: none;
        opacity: 0.0;
        -o-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    span.amc-tooltip.amc-tooltip-scroll {
        // position: relative;
        display: inline-block;
    }

    /* Arrow */
    .amc-tooltip > .amc-tooltip-text:before, .amc-tooltip > .amc-wrapper:before  {
        display: inline;
        bottom: -10px;
        content: "";
        position: absolute;
        border: solid;
        border-color: black transparent;
        border-width: 10px 0.5em 0em 0.5em;
        z-index: 6000;
        left: 221px;
    }
    
    /* Invisible area so you can hover over tooltip */
    .amc-tooltip > .amc-tooltip-text:after, .amc-tooltip > .amc-wrapper:after  {
        bottom: -20px;
        content: " ";
        display: block;
        height: 20px;
        position: absolute;
        width: 60px;
        left: 20px;
    }
    
    .amc-wrapper > .amc-tooltip-text {
        overflow-y: auto;
        max-height: 266px;
        max-width: 300px;
        display: block;
    }

    @media(min-width: 768px) and (max-width: 1199px){
        .amc-tooltip > .amc-tooltip-text, .amc-tooltip >.amc-wrapper {
            left: -136px !important;
        }
    }

.second-factor-main:after {
    content: "";
    display: table;
    clear: both;
}
.second-factor-column {
    float: left;
    width: 50%;
    padding: 10px;
    padding: 90px 50px 0px 0px;
}
.second-factor-column-right {
    float: left;
    width: 50%;
    // padding: 60px 50px 0px 0px;
 }
.second-factor-heading{
    color: #2967a1;
    font-size: 23px;
}
.second-factor-radio-btn{
    padding: 20px 10px 20px 10px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    width: 124px;
    margin-right: 22px;
    margin-top: 20px;
}
.second-factor-input{
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    width: 340px !important;
    display: initial !important;
    padding: 0 7px !important;
}
.factor-form{
    width: 340px !important;
}
.second-factor-mobile{
    display: none;
}

.error-netbanking{
    left: -4px !important;
    width: 365px;
    top: 39px;
}

@media (max-width: 1199px) {
    .second-factor-radio-btn {
        padding: 8px 8px 8px 10px;
        border: 2px solid lightgrey;
        border-radius: 10px;
        display: inline-block;
        width: 124px;
        margin-right: 16px;
        margin-top: 10px;
    }
}
@media (max-width: 991px) {
    .second-factor-radio-btn {
        padding: 8px 8px 8px 10px;
        border: 2px solid lightgrey;
        border-radius: 10px;
        display: inline-block;
        width: 124px;
        margin-right: 16px;
        margin-top: 10px;
    }
    .second-factor-input {
        border-left: 0px !important;
        border-right: 0px !important;
        border-top: 0px !important;
        width: calc(100% - 20px) !important;
        display: initial !important;
        padding: 0 7px !important;
    }
    .second-factor-image{
        width: 129%;
    }
    .second-factor-column {
        float: left;
        width: 50%;
        padding: 10px;
        padding: 50px 50px 0px 0px;
    }
    .error-netbanking{
        width: calc(100% + 5px) !important;
    }
    .factor-form{
        width: calc(100% - 0px) !important;
    }
}

@media(min-width: 769px) and (max-width: 829px) {
    .second-factor-radio-btn {
        padding: 8px 8px 8px 10px;
        border: 2px solid lightgrey;
        border-radius: 10px;
        display: inline-block;
        width: 124px;
        margin-right: 16px;
        margin-top: 10px;
    }
    .factor-form{
        width: calc(100% - 0px) !important;
    }
    .error-netbanking{
        width: calc(100% + 5px) !important;
    }
}

@media(min-width: 0px) and (max-width: 769px) {
   .second-factor-column-right{
       display: none;
   }
   .second-factor-column{
        width: auto;
        padding: 0px;
    }
    .second-factor-mobile{
        display: block;
    }
    .bank-lower-header-bg{
        display: none;
    }
    .second-factor-radio-btn {
        padding: 2px 8px 12px 10px;
    
    }
    .second-factor-input {
        width: calc(100% - 20px) !important;
    }
    .factor-form {
        width: calc(100% - 20px) !important;
    }
    .error-netbanking{
        width: calc(100% + 5px) !important;
    }
}

.noselect-secondfactor {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
  }

.pass-like-text{
    -webkit-text-security: disc;
    -moz-text-security: disc;
}
.las-upload-container {
  padding: 0px 220px 0px !important;
}

.lasDotfUploadList{
    max-height: none !important;
}

.las-dotf-added-doc{
    min-height: fit-content !important;
    max-height: 800px !important;
}

.red-loader2 {
    border: 3px solid #cccccc;
    border-top: 3px solid #d71920;
    top: 0px;
}



@media(min-width: 0px) and (max-width: 767px) {
    .las-upload-container { 
        padding: 0px 0px 0px !important;
        padding-left: 40px !important;
        padding-right: 40px !important;
        min-height: 0px !important;
    }
    .las-dotf-added-doc {
        min-height: fit-content !important;
        max-height: 800px !important;
    }  
}

.two-block-las{
    margin: 0 0px !important;
}


@media(min-width: 0px) and (max-width: 650px) {
    .track-status {
        width: 100% !important;
    } 
    .track-status .status-wrap {
        width: 100% !important;
    } 
}
.pass-like-text{
    -webkit-text-security: disc;
        -moz-text-security: disc;
 }
 .branch-las-dotf{
  background: #d71920 !important;
}



@media(min-width: 1200px) and (max-width: 1289px) {
    .breadcrumb-title-appNo{
        margin-left: -38px !important;
    }
}

@media(min-width: 992px) and (max-width: 1077px) {
    .breadcrumb-title-appNo{
        margin-left: -48px !important;
    }
}

@media(min-width: 827) and (max-width: 991px) {
    .breadcrumb-title-appNo{
        margin-left: -82px !important;
    }
}

@media(min-width: 768px) and (max-width: 826px) {
    .breadcrumb-title-appNo{
        margin-left: -48px !important;
    }
}

@media(min-width: 0px) and (max-width: 767px) {
    .breadcrumb-title-appNo{
        margin-left: -20px !important;
    }
}


.on-road-price-table{
    background: white;
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid #e9ece7;
        text-align: left;
        padding: 8px;
    }

    .serial-no{
        width: 100px;
        text-align: center;
    }

    .component{
        width: 360px;
    }

    .value-rupee{
        width: 198px;
        text-align: center;
    }

    .rupee-input{
        border: 0px;
        text-align: center;
        width: 100%;
    }

    .discount-strip{
        background: #fef2f1;
    }

    .total-strip{
        background: #ccffc4;
    }
}

.onRoadPricePopUp{
    border-style: solid;
    border-width: 4px 0px 0px 0px;
    border-color: #ce3225;
    background: #fafafa;
    cursor: pointer;
}

.on-road-price-input{
    width: 60%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: #404040;
    padding: 0 20px;
    background-color: #fff;
    text-transform: capitalize;
    font-family: "Cairo-Bold";
    text-align: left;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid #cccccc;
}

.on-road-price-button{
    display: inline-block;
    float: left;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 40%;
    text-align: center;
    // text-transform: uppercase;
    text-decoration: none;
    /* white-space: nowrap; */
    vertical-align: middle;
    font-family: "Cairo-Medium";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 11px;
    height: 40px;
    line-height: 40px;
    /* padding: 0 40px; */
    border-radius: 0 !important;
    background: #1962a8;
    color: white;
    font-family: "Cairo-Bold";
    font-size: 12px !important
}
.uanNumberBox{
    @media (max-width: 992px){
        .form-field-row .error, .form-field-row .suggestion {
            left: 0px !important;
            right: 20px !important;
            min-width: auto;
        }
    }
}
