header {
    height: 70px;
    background-color: #FFFFFF;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
    display: block;
    position: relative;
    z-index: 1;
    .header-logo {
        width: 322px;
        float: left;
        padding: 6px 0;
        .logo,
        .poweredby {
            height: $logo-height;
        }
        .logo {
            float: left;
            width: $logo-width;
            background-position: $logo-background-position;
        }
        .poweredby {
            width: 89px;
            float: left;
            background-position: -201px -3px;
            position: relative;
            margin-left: 71px;
            &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 36px;
                background: $form-placeholder;
                left: -36px;
                top: 50%;
                margin-top: -18px;
            }
        }
    }
    .header-right{
        float:right;
        text-transform:uppercase;
        font-size:13px;
        font-family:$font-family-bold;
        a{
            text-decoration:none;
            &.menu-link{
               float:left;
                color:$text-color;
                border-bottom: 2px solid transparent;
                padding-bottom: 3px;
                margin:27px 15px 23px;
                &:hover, &.active{
                    color:$secondary-color;
                    border-bottom: 2px solid $primary-color;
                } 
            }
        }
        .user-login {
            height: 70px;
            margin-left:15px;
            float: left;
            background-color:$primary-color;
            
            display: flex;
            padding: 0 15px;
            align-items: center;
            color: #fff;
            position: relative;
            cursor: pointer;
            
            span {
                em{font-style:normal;}
                margin-left: 6px;
                display: inline-block;
                
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 24px;
                line-height: 24px;
                padding-right: 20px;
                position: relative;
                &:after {
                    position: absolute;
                    right: 0;
                    content: "\e902";
                    font-family: $font-family-icon;
                    font-size: 8px;
                }
            }
            .loggedIn-item {
                position: absolute;
                top: 70px;
                font-size:14px;
                background-color: $primary-color;
                width: 240px;
                right: 0px;
                border-radius: 20px 0 20px 20px;
                padding:20px;
                a {
                    display: block;
                    padding: 10px 0px;
                    color: #fff;
                    font-weight:normal;
                    font-family:$font-family;
                    text-transform:capitalize;
                    border-bottom: 1px solid rgba(255, 255, 255, .5);
                    &:last-child {
                        border: none;
                    }
                }
            }   
        }
    }
    
}
