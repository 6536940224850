.calculateLoading {
    height: 300px;
    .loader1.red-loader1{
    position: fixed;
    margin: 15% 48%;
    width: 50px;
    height: 50px;
    border-top: 5px solid #d71920 !important;
    border: 5px solid #cccccc;
    }
}
.offers {
    padding-top: $default-box-Toppadding;
    margin-top: -140px;
    .tenure-amount{
        max-width: 430px;
        margin: 50px auto 0;
        display: flex;
        justify-content: space-between;
        text-align: left;
        & > *{
            width: 200px;
        }
    }
    label{
        color:$text-color2;
    }
    .offer-container{
        background:$grey-secondary;
        margin:-80px -80px 0;
        padding:80px 40px 0;
        border-bottom:1px solid $border-default;
        .div-table {
            padding: 40px 0;
            label {
                text-transform: uppercase;
                display: block;
                font-size: 14px;
                margin-bottom:3px;
                line-height: 16px;
                font-weight:normal;
            }
            .loan-amount {
                font-size: 23px;
                line-height: 28px;
                font-family:$font-family-bold;
                color:$secondary-color;
                span{
                    text-decoration: line-through;
                    color:$text-color;
                    font-weight:normal;
                    font-family:$font-family;
                }
            }
        }
    }
    .customize-offer-container{
        padding:40px 0 80px;
        color:$text-color;
        //border-bottom:1px solid $border-default;
        h6{
            font-size:18px;
            margin:0;
            font-weight:normal;
        }
        .customize-box{
            margin-top:40px;
            .box-label{
                width:430px;
                margin:0 auto;
                label{
                    display:block;
                    text-align:left;
                }
            }
            label{
                font-size:14px;
                font-family:$font-family-bold;
                margin-bottom:15px;
                position:relative;
                .selected-value{
                    position:absolute;
                    padding-left:10px;
                    font-family:$font-family;
                    color:$secondary-color;
                }
            }
            .range-slider {
                width:430px;
                margin:0 auto;
                height:30px;
                position:relative;
                label{
                     font-size:16px;
                     margin-bottom:0;
                     font-weight:normal;
                     position: absolute;
                     bottom: -17px;
                     left:0;
                     color:$secondary-color;
                     font-family: $font-family;
                     &:last-of-type{
                        right:0;
                        left:auto;
                     }
                }
                md-slider{
                    display:inline-block;
                    width:100%;
                }
            }
            +.customize-box{
                margin-top:60px;
            }
        }
        
    }
    h2 {
        font-size: 31px;
        line-height: 36px;
        margin-top:0;
        font-family:$font-family-bold;
        +p {
            line-height: 30px;
        }
    }
    .icon-img{
        margin-bottom:20px;
    }
    h3 {
        font-size: 21px;
        line-height: 24px;
        text-transform: uppercase;
    }
    p{
        &+p{
            margin-top:30px;
        }
    }
   .declaration{
        color:$text-color;
        padding:35px 0 0;
        p{
            font-size:16px;
            margin-bottom:25px;
        }
        .form-field-row{
            margin-bottom:10px;
        }
    }
    .loan-tenure {
        span {
            width: 63px;
            border: 1px solid $form-field-border;
            color: $text-color;
            font-size: 18px;
            margin: 0 5px;
            border-radius: 20px;
            display: inline-block;
            height: 40px;
            line-height: 38px;
            cursor: pointer;
            &:hover {
                background: $secondary-btn-background-color;
                border-color: $secondary-btn-background-color;
                color: #fff;
            }
            &.active {
                background: $secondary-btn-background-color;
                border-color: $secondary-btn-background-color;
                color: #fff;
            }
        }
    }
    .cv-offer-box{
        border: 1px solid rgba(224, 224, 224, 0.7);
        margin-bottom: 10px;
        width: 854px;
        margin: 30px auto 10px;
        font-family: "Cairo-Bold";
        text-align: left;
        ul{
            margin-bottom:0;
           li{
                list-style:none;
                background:$grey-secondary;
                border-bottom: 1px solid #ccc;
                label{
                    background:$light-background;
                    width:410px;
                    display:inline-block;
                    margin-bottom:0;
                    color:$text-color;
                    font-weight:normal;
                    padding:20px 20px 20px;
                    margin-right:30px;
                    border-right:1px solid $border-default;
                }
                div{
                    width: 330px;
                    display: inline-block;
                }
                &:first-child{
                    label{padding-top:20px;}
                }
                &:last-child{
                    label{padding-bottom:20px;}
                }

            } 
        }
    }

    .no-offer-box{
        border:1px solid $border-default;
        margin-bottom:10px;
        width:500px;
        margin:30px auto 10px;
        font-family:$font-family-bold;
        text-align:left;
        ul{
            margin-bottom:0;
           li{
                list-style:none;
                background:$grey-secondary;
                display: table;
                width: 100%;
                label{
                    background:$light-background;
                    width:175px;
                    display:inline-block;
                    margin-bottom:0;
                    color:$text-color;
                    font-weight:normal;
                    padding:0 30px 10px;
                    margin-right:30px;
                    border-right:1px solid $border-default;
                    display: table-cell;
                }
                div{
                    display: table-cell; 
                    padding:0 30px 10px;
                               }
                &:first-child{
                    label{padding-top:20px;}
                }
                &:last-child{
                    label{padding-bottom:20px;}
                }

            } 
        }
        
    }
   
}

      