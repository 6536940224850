/* Modification */

.bg-grey{
    background:$bg-grey;
}
body,
html {
    background: $body-background;
    margin:0;
    padding:0;
}
.container .no-margin-top{
    margin-top: 0px;
}
.note-inr{
    color: #000;
}
.margin-top-20 {
    margin-top: 20px;display: block;
}
.no-text-decoration {
    text-decoration: none;
}
.no-offer-form
{
    margin: 20px auto 0;
    width:400px;
    .proceedHeading{
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 20px;
        color: #0062a8;
    }
    .form-field-row{
        width: 80%;
        margin: 0 auto 15px;
        .error{
            width: 100%;
            min-width: auto;
        }
        .suggestion{
            min-width: auto;
            width: 100%;
        }
    }
}
.normal{
    font-size: 15px;
}
.padd-20{
    padding: 0 20px;
}
.name-capitalize{
	text-transform: capitalize;
}

.add-bt-amt{
    margin-bottom: 10px;
}
header{
    box-shadow:none;
    border-bottom:$form-field-border solid 1px;
    .header-logo{
        width: 162px;
    }
    .header-right{
        font-size:11px;
        .user-login{
            padding:0 10px;
            span{
                padding-right:0;

                &:after{
                    display:none;
                }
            }
        }
        .header-logo{
            width:91px;
            
            .poweredby{
                margin-left:0;
                &:before{
                    left:auto;
                    right:-36px;
                    display:none;
                }
            }
        }
    }
}

md-radio-button{
    margin-bottom:0;
    &.md-checked{
        .md-off{
            border-color: $form-field-border;
        }
        .md-ink-ripple{
            color:transparent;
        }
    }
    .md-on{
        background-color: $text-color2;
    }
    .md-off{
        border-width: 1px;
    }
    
    .md-label{
        margin-left:26px;
    }
    .md-container, .md-on, .md-off{
        width:16px;
        height:16px;
    }
}

.form-error{
    margin-bottom:10px;
}
.bank-lower-header-bg{
   background-image:none;
   height:185px;
   //padding-top:50px;

   h1{
    color:$text-color2;
    position:relative;
    padding-bottom:5px;
    &:after {
        content: '';
        position: absolute;
        width: 100px;
        height: 5px;
        background-color: $primary-color;
        left: 50%;
        margin-left: -50px;
        bottom:-5px;
    }
   }
   p{
        text-align:center;
        margin-top:8px;
        color:$text-color2;
        text-transform:uppercase;
        small{font-size:14px;}
    }
}
.main-form-container{
    width: 860px;
    margin:0 auto;
    padding: 0px 80px 50px;
    h3{
        margin:10px 0;
        padding-bottom:0;
        &:after {
           display:none;
        }
    }
    h2{
        font-size:21px;
        margin-bottom:30px;
        +p{
            font-size:16px;
        }
    }
}

.main-form-kfs-container{
    width: 1060px;
    margin:0 auto;
    padding: 0px 80px 50px;
    h3{
        margin:10px 0;
        padding-bottom:0;
        &:after {
           display:none;
        }
    }
    h2{
        margin:0 auto !important;
        font-size:21px;
        text-align: center;
        margin-bottom:30px;
        +p{
            font-size:16px;
        }
    }
}
.etb-main-form-container{
    margin:0 auto;
    h3{
        margin:10px 0;
        padding-bottom:0;
        &:after {
           display:none;
        }
    }
    h2{
        font-size:21px;
        margin-bottom:30px;
        +p{
            font-size:16px;
        }
    }
}
.two-block {
    margin: 0 -20px;
    .form-field-row{
        padding:0 20px;
    }
}
.form-field-row.with-prefix .field-prefix{left:40px;}

md-checkbox{
    min-height:22px;
    color:$text-color2;
    .md-icon{
        border-width:1px;
        &:after{
            color:$primary-color;
        }
    }
    .md-container, .md-icon{
        width: 22px;
        height: 22px;
    }
    .md-label{
        margin-left:32px;
    }
    &.md-checked{
        .md-icon{
            background-color:transparent;
            border-color:#ccc;
            &:after{
                 border-color:$primary-color;
                left: 6.66667px;
                top: 2.22222px;
            }
           
        }
        .md-ink-ripple{
            color:transparent;
        }
    }
}

.fileUpload.btn span {
    font-size: 11px;
}


.btn{
    position:relative;
    padding: 0 20px 0 60px;
    font-family:$font-family-bold;
    &.btn-social{
        padding:0;
        &:before, &:after{
            display:none;
        }
    }
    &:before{
        content:'';
        position:absolute;
        width: 40px;
        border-right: 1px solid #eb8b8f;
        height: 100%;
        left: 0;
        top: 0;
    }
    &:after{
        content:'';
        position: absolute;
        left: 12px;
        top: 50%;
        width: 10px;
        height: 10px;
        margin-top: -6px;
        border-right: 2.5px solid #eb8b8f;
        border-top: 2.5px solid #eb8b8f;
        transform: rotate(45deg);
    }
    &.btn-large{
        min-width:auto;
        min-width: initial!important;
    }
    &.btn-block{
        width:auto;
    }
    &.small-btn{
        padding:9px 10px 9px 50px;
        font-size:11px;
    }
    &.btn.mid-btn{
        height:40px;
        line-height:40px;
        padding: 0px 20px 0 60px;
        font-size:11px;
    }
    &.without-bg{
        color: $text-color;
        background:none;
        border:solid 1px $form-field-border;
        &:before{
            background:$primary-btn-background-color;
        }
         &:hover {
            background: $primary-btn-background-color;
            color: $primary-btn-color;
        }
    }
}
.btn-social{
    width: 120px;
    height: 40px;
    line-height: 40px;
    font-size:18px;
}
.tab-container-pass{
    padding: 0 18px 0 0;
    box-shadow:none;
    margin-bottom:0;
    // margin-top: -60px;
    margin-top: 0;
    .tab-wrapper{
        height:36px;
        li{
            height:36px;
            line-height:36px;
            font-size:15px;
            font-family:$font-family-bold;
            color:#fff;
            text-transform:capitalize;
            border-bottom:0;
            background-color:$yes-bank-gray;
            position:relative;
            border-right:0;
            margin-left:18px;
            i{
                font-size:18px;
            }
            &:before{
                content:'';
                position:absolute;
                width:36px;
                height:36px;
                border-radius:50%;
                background:#fff;
                left:-18px;
                top:0;
            }
             &:after{
                content:'';
                position:absolute;
                width:36px;
                height:36px;
                border-radius:50%;
                background:$yes-bank-gray;
                right:-18px;
                top:0;
                z-index:99;
            }
            &:first-child{
                margin-left:0;
                &:before{
                    display:none;
                }
            }
            &.active{
                border:0;
                &:after{
                    background:$secondary-bg;
                }
            }
            span{
                &.tab-text{
                    position:relative;
                    top:-3px;
                }
            }
        }
    } 
} 
.sub-box {
    font-size:$text-size;
    box-shadow:none;
    border:0;
    padding:0 30px 0;
    &.customer-login {
       width:450px;
       box-shadow:none;
       margin: 0 auto 0;
       padding-bottom:50px;
      
        .error, .suggestion{
          width:100%;
          left:auto;
          right:auto;
        }
       h2{
            font-size:25px;
            &+p{margin-top:0;}
         }
        .form-field-row.with-prefix{
            .field-prefix{
                left:20px;
                
            }
            .default-textfield{
                padding-left:50px;
            }
        } 
    }
    &.upload-fin{
        margin-top:30px;
        p{
            &:first-of-type{
                margin-top:0;
            }
        }
    }
    
    &.form-block{
        .form-field-row.with-prefix .field-prefix{
            left: 20px;
        }
    }
}
.singinWithPassword {
    margin:0;
    margin-top:40px;
    background:$grey-secondary;
    border-top:0;
    a{
        text-decoration:none;
    }
    
}
.offers{
    margin-top: -36px;
    width: 100%;
    padding: 50px 0;
    margin-right:0!important;
    .offer-container{
        margin:-50px 0 0;
        padding:50px 0 0;
    }
    h2{
        font-size:31px;
        margin-top:0;
        &+p{
            margin-top:0;
            line-height:20px;
            
            br{
                display:block;
            }
        }
    }
    .icon-img{
        display:none;
    }
    .declaration{
        //padding:40px 0 0;
        padding: 0;
        p{
            margin-bottom:0;
        }
        md-checkbox{
            margin-top:40px;
            margin-bottom:25px;
        }
    }
}
.anchor-spacing-under-btns{
    font-size:$text-size;
}
p{
  big{
    margin-bottom: 40px;
  }  
    
}
.upload-box{
    margin:0 0 40px;
    padding:20px 0;
    label{
        font-size:18px;
    }
    .connect-social{
        width:250px;
    }
    .myLabel{
        width:100%;
        padding: 38px 40px;
        padding-left:72px;
        span{
            &:before{
                left:20px;
            }
        }
    }
    .sep{
        left:54%;
    }
}
.file-name{
    .action-btn{
       .icon-icon-Check{
            height:26px;
            width:26px;
            line-height:26px;
            font-size:9px;
        } 
        .icon-Delete{
            color:$text-color;
            font-size:15px;
        }
        &.left-icon{
            margin-top:-13px;
        }
    }
    &.uploaded{
        padding-left:35px;
    }
} 

.fileUpload span{
    font-size:$text-size;
} 
.agreement{
    margin-top:-166px;
    width:100%;
    padding-bottom:50px;
    padding-right:0;
    h2{
        font-size:31px;
    }
    .agreemet-preview{
        margin-top:0;
    }
}
.detail-box{
    margin-bottom:20px;
    max-height: 290px;
    min-height: 290px;
    padding:40px;
    
}
.detail-box-wl, .detail-box{
    h2{
        font-size:21px;
    }
    .icon-IconUpload, .icon-IconKyc{
        display:none;
    }
}
.dt-tbl .dt-tbl-cl{
    font-size:$text-size;
    p{
        font-family:$font-family-bold;
    }
    
}
.welcome-upload{
    .fileUpload{
            margin-bottom:20px;
        }
}
.reference-box{
    margin-top:40px;
}
.default-container{
    padding:50px 0;
    width:100%;
    margin:0!important;
    h2{
        margin-top:0;
        +p{
            text-align:left;
            font-size:14px;
        }
    }
    p{
        padding-bottom:15px;
        line-height:18px;

        &:last-of-type{
            padding-bottom:0;
        }
    }
    ol{
        li{
            margin-bottom:30px;
            &:last-child{
                margin-bottom:0;
            }
            p{
                padding-bottom:0;
                margin-bottom:10px;
            }
        }
    }
    h6{
        font-size:16px;
    }
}

i{
    font-style:normal;
}
@media (max-width: 992px){
    .tab-container-pass .tab-wrapper li {
        height: 36px;
        line-height: 36px;
        &:after, &:before{
            width:36px;
            height:36px;
        }
        span{
            &.tab-text{
                display:block;
                margin-left:0;
                position:relative;
                z-index:100;
                padding-left:25px;
            }
        }
        
    }
    .main-form-container{
        width: auto;
        min-height: 300px;
        padding: 0;
        margin-right: -18px;
    }
    .main-form-kfs-container{
        width: auto;
        min-height: 300px;
        padding: 0;
        margin-right: -18px;
    }
    body, html {
    font-size:14px !important;
    }

}
@media only screen and (min-width:767px) and (max-width:992px){
    .main-form-container{
        padding: 30px 0;
    }
    .main-form-kfs-container{
        padding: 30px 0;
    }
    .sub-box.customer-login{
        padding:0 0 30px;
    }
    .tab-container-pass{
        .main-form-container{
            h2{
                margin-top:0;
            }
        }
        .main-form-kfs-container{
            h2{
                margin-top:0;
            }
        }
    }
    .welcome-upload .detail-box{
        padding:60px 20px;
        min-height:277px;
    }
    .upload-box .connect-social .btn-social{
        width:120px;
    }
    .loan-application-list{
        .list-header{
            .head{
                font-size:11px;
                padding:10px 4px;
            }  
        }
        .application-list{
            .list-body{
                .list-item{
                    padding:10px 4px;
                    font-size:13px;
                }
                .btn.small-btn{
                    padding:9px 10px 9px 34px;
                    &:before{
                        width:24px;
                    }
                    &:after{
                        left:4px;
                    }
                }
            }
        } 
    }

    .reference-box{
        margin-top:0;
    }
    .agreement{
        margin-top:-184px!important;
    }

}


@media (max-width: 768px){
    
    header{
        .header-logo{
          width: 121px;  
        }
        .header-right{
            .user-login{
                width:auto;
            }
            .header-logo{
               width:83px;
                .poweredby{
                    &:before{
                        right:-16px;
                    }
                }
            } 
        }
    }
    .upload-box .myLabel{
        width:288px;
    }
    .bank-lower-header-bg{
        height: 170px;
        padding-top: 30px;
    }
    .singinWithPassword{
        margin-top:30px;
    }
    .tab-container-pass{
        margin-top: -55px;
        min-height:auto;
        
         .tab-wrapper {
            margin: -55px 0 0 0;
            li{
                padding-left:27px;
                &:first-child{
                    padding-left:15px;
                }
                span{
                    &.tab-text{
                        display:none;
                        font-size:11px;
                        white-space: nowrap;
                        padding-left:11px;
                    }
                }
                i{
                    position:relative;
                    z-index:999;
                }
            }
        }
    }
    .upload-box{
        .connect-social{
            width:100%;
        }
    } 
    .main-form-container{
        padding-bottom:30px;
        h2{
            font-size:18px;
            margin-top:20px;
            margin-bottom:25px;
        }
    }
    .main-form-kfs-container{
        padding-bottom:30px;
        h2{
            font-size:18px;
            margin-top:20px;
            margin-bottom:25px;
        }
    }
    .sub-box{
        padding-bottom:30px;
        &.customer-login{
            width:100%;
            margin-bottom:0;
            padding-bottom:30px;
            h2{
               font-size: 20px; 
               margin-top:0;
            }
        }
    }
    .two-block .form-field-row.with-prefix .field-prefix{left:40px;}
    .offers{
        border:0;
        box-shadow:none;
        padding:30px 0;
        margin-top: -185px;
        margin-right:0;
       
        .declaration{
            padding:15px 0 0;
            md-checkbox{
                margin:30px 0;
            }
        }
    }
    .detail-box{
        max-height:none;
        min-height:auto;
        padding: 20px 15px;
        h2{margin-top:0;font-size:16px;}
        .box-detail label{font-size:12px;}
    }
    .welcome-upload .detail-box{
        min-height:auto;
        height:auto;
        max-height:none;
        padding:20px 15px;

        .btn{
            margin-top:20px;
        }
        .welcome-uploadBox{
            .form-field-row{
                margin-bottom:20px;
            }
            btn{
                margin-top:0;
            }
           
        }
    }
    .reference-box{
        padding:15px 60px 15px 15px;
        h6{
            font-size:16px;
        }
        p{
            font-size:14px;
        }
    }
    .agreement{
        margin-top:-150px;
        margin-right:0;
    }
    p big {
        margin-bottom: 20px;
    }
    .hr-divider{
        margin:20px 0;
    }
    .offers .offer-container .div-table .loan-amount{
        font-size:18px;
    }


    .detailContainer2 .form-field-row {
    margin: 0 auto;
}

.tab-container-pass 
.tab-wrapper li{
        font-size: 15px !important;
}

 .icon-icon-Check{
    right: -8px !important;
    font-size: 10px !important;
}



}

@media (max-width: 576px){
    .secondary-block{
        padding:20px;
        h6{
            font-size: 18px;
            margin: 0 0 10px 0;
            line-height: 20px;
        }
    }
    .display-inline{
        display:inline-block !important;
    }
    .detail-box .box-detail label{
        display:inline-block;
        width:150px;
    }
    .btn {
        height: 40px;
        line-height: 40px;
        font-size:11px;
        width:auto;
    }
    .btn.btn-social{
        width:120px;
    }
    .offers .offer-container .offer-details .div-table .table-cell{
        border-bottom:0;
    }
    .upload-box label{
        font-size:18px;
    }
     .upload-box .myLabel{
        width:100%;
        font-size:14px;
            padding-top: 23px;
            padding-bottom:23px
    }
    .offers .customize-offer-container{
        .customize-box{
            text-align:center;
            label {
                display: block;
                text-align: center;
            }
        } 
        .only-for-xs{
            p{
                text-align:left;
            }
        }
    } 
    .offers{
        br{
            display:block;
        }
    }
    .declaration{
        br{
            display:none;
        }
    }
    .confirmation-layer .confirm-btns .btn + .btn{
        margin-left:0;
    }
    .md-dialog-container .main-form-container {
        margin: 0;
        padding: 30px 20px 20px 20px;
    }
    .md-dialog-container .main-form-kfs-container {
        margin: 0;
        padding: 30px 20px 20px 20px;
    }
 .close-overlay {
        top: 0;
        right: 0;
        width: 30px;
         height: 30px;
         line-height:29px;
         font-size:12px;
         z-index:99;
    }
    /*.bank-lower-header-bg h1 {
        font-size: 17px;
        line-height: 21px;
    }*/

    .sub-heading{
    p{
        margin-left: -15px;
    }
}

}
.reference-container{
    margin-top:0!important;
    .detail-container{
        margin-bottom:0!important;
        .reference-box{
            margin-top:30px!important;
        }
    }

}

.sub-heading{
    p{
    text-align:left!important;
    margin-top:18px;

    }
}

.fix-height{
    min-height: calc(100vh - 171px);
}
.cv-no-offer{
    min-height: 400px;
    padding: 100px 0;
}
.cv-offer{
    min-height: 400px;
    padding: 50px 0;
    ul li{
        list-style: none;
    }
}

.popup-box{
    p{
        &.text-center{text-align: center!important;}
    }
    .terms-list{
        margin-top:30px;
        p{margin-bottom:5px;}
        li{
            margin-bottom:10px;
             &:last-child{margin-bottom:0;}
              ul{
                margin-left:20px;
                list-style-type:upper-alpha;
                li{
                    
                   
                    ul{
                        list-style-type:lower-alpha;
                    }
                }
            }
        }
       
    }
}
.line-checkbox {
    margin-right: 15%;
}
.single-line-checkbox {
    display: inline-block;
    width: 50%;
}
.half-width-field {
    .form-field-row {
        float: none;
    }
}
.md-select-menu-container {
    //left: 657px; 
    width: 320px;
}
.on-road-price-icon {
    left: 308px;
    top: -142px;
}
@media(min-width: 0px) and (max-width: 769px) {
    .on-road-price-icon {
    left: 94%;
    top: -262px;
    }
}  




.downloadbtn{
    &:after{
        content:'';
        position: absolute;
        left: 12px;
        top: 40%;
        width: 23px;
        height: 23px;
        margin-top: -6px;
        background: url(/assets/images/download.svg) no-repeat top left;
        transform: none;
        border: none;
    }
}

.password-block{
    position:relative;
}
.eye-icon{
    position: absolute;
    top:15px;
    right:178px;
    @media screen and (max-width: 768px){
            position: absolute;
            top:15px;
            right:3px;
    }
    @media screen and (min-width: 768px) and (max-width:980px) {
        position: absolute;
        top:15px;
        right:128px;
}
}