.new-doc-white-container {
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 0 4px 0 #DBD9E7;
    padding: 30px 15px;
    margin: 0 auto 20px;
    &:after {
        clear: both;
        content: '';
        display: table;
    }
     md-checkbox {display:inline-block;}
    h4 {
        font-size: 17px;
        margin: 0;
        margin-bottom: 30px;
        padding:0 15px;
        font-family:$font-family-bold;
        span {
            display: block;
            font-size: 13px;
            font-weight: normal;
            font-family:$font-family;
            color:$text-color;
        }
        &.comment-space {
            margin-bottom: 5px;
        }
    }
    .document-table{
        display: table;
        width: 100%;
        table-layout: fixed;
       
    .document-list {
        display: table-cell;
        padding: 0 15px;
        position: relative;
        padding-bottom: 85px;
        
        label {
            margin-bottom: 25px;
            display: block;
            width: 100%;
            font-weight: normal;
            color: #93929D;
            padding-bottom: 10px;
            border-bottom:#eaeaea solid 1px;;
            font-size: 12px;
            text-transform: uppercase;
        }
        ul {
            list-style:none;
            li {
                padding-right: 0;
                margin-bottom: 5px;
               /* md-checkbox .md-label {
                    margin-top: 5px;
                }*/
                .small-checkbox {
                    md-checkbox .md-label {
                        margin-top: -5px!important;
                        margin-left: 18px;
                    }
                }
                md-checkbox+.default-textfield{margin-top:15px;}
            }
        }
        &.download-list {
            border-right: solid 1px #eaeaea;
            padding: 0 30px 15px;
            margin-top: -30px;
            padding-top: 30px;
            ul {
                li{
                    padding-right: 0;
                    position: relative;
                }
            }
            &:first-child {
                padding-left: 0;
            }
            &.noborder {
                border-right: none;
                padding-right: 0;
                margin-right: 0;
            }
        }
    }
}
}

.after-upload-doc {
    display: table;
    width: calc(100% + 60px);
    padding: 0 30px;
    border-bottom: 1px solid #EAEAEF;
    margin: 0 -30px 30px;
    .doc-row {
        display: table-row;
        &:last-child{
            .doc-cell{
                padding-bottom:30px;
            }
        }
        &:first-child{
            .doc-cell{
                padding-bottom:20px;
            }
        }
        .doc-cell {
            display: table-cell;
            padding: 5px 10px;
            color: #666;
            &:nth-child(1) {
                width: 60px;
                padding-left: 30px;
            }
            &:nth-child(2) {
                &.doc-title {
                    color: #333;
                    font-size: 18px;
                    
                    font-family:$font-family-bold;
                }
            }
            &:nth-child(3) {
                text-align: right;
                font-size: 12px;
                color: $text-color;
            }
            &:nth-child(4) {
                width: 195px;
                text-align: right;
                padding-right: 30px;
            }
        }
    }
}
.all-doc{
    padding:10px !important;
    background-color:$gray-secondary;
    position: absolute;
    bottom: 0;
   left: 15px;
    right: 15px;
}
.list-container{
   /* border-top: solid 1px #EBEAEF;*/
   /* padding:30px 0;*/
    padding-bottom: 30px;
   
    &.no-style{
        padding-top: 0;
        border:none;
    }
    &.no-padding-bottom{
        padding-bottom: 0;
    }
    &:after{
        content:'';
        display: table;
        clear: both;
    }
}
.padding-bottom0{
    padding-bottom: 0 !important;
}
.multi-list{
    margin-bottom: 10px;
    &:last-child{
        margin-bottom:0;
    }
}
.default-textfield.textarea{
    height:90px;
    margin-bottom:20px;
}

.textarea-box{padding:0 15px;h4{padding:0;}}
.small-checkbox {
   float: right;
    position: relative;
    margin-top: 0;
    md-checkbox .md-icon {
        width: 14px;
        height: 14px;
    }
    md-checkbox {
        min-width: 14px;
        min-height: 14px;
        margin-bottom: 0;
        margin-right: 10px;
        display: inline-block;
        .md-label {
            font-size: 12px;
            margin-left: 23px;
        }
        .md-container {
            width: 14px;
            height: 14px;
        }
    }
    md-checkbox.md-checked .md-icon:after {
        width: 5px;
        height: 8px;
        left: 4px;
    }
    md-checkbox.md-default-theme.md-checked .md-icon:after, md-checkbox.md-checked .md-icon:after{
        border-color: #fff;
    }
}

