@media only screen and (min-width:767px) and (max-width:992px){ 
    .list-for-verification{
        padding-right: 235px;
        .doc-for-upload{
            width: 215px;
            .btn-border{
                font-size:11px;
            }
        } 
    }
    .verification-table{
        
        .default-textfield{width:140px;}
        table{
           tbody{
                tr{
                    
                    td{
                        padding:10px 15px;
                        label{line-height:normal;display:block;margin-bottom:10px;}
                    }
                }
            }
        }
       
    }  
}
@media (max-width:992px){ 
    .verification-table{
        md-radio-button{margin-top:0;}
        table{
            thead{
                th{
                    overflow:hidden;
                    &:last-child{padding-left:15px;padding-right:15px;}
                }
            }
            tbody{
                tr{
                    height:auto;
                    line-height:normal;
                    td{
                        padding:10px 15px;
                        label{line-height:normal;}
                        &:last-child{width:100px;padding-left:10px;}
                    }
                }
            }
        }
        .inline-div{display:block;margin-top:10px;}
        .default-textfield{
            margin-left:0;
            margin-right:10px;
        }
    } 


}
@media (max-width: 768px) {
     .application-bredcrumb{
             margin-top:30px;
            a{
                &.current{
                    h2{margin-top:0;}
                }
            }
        }
    .list-for-verification{
        padding-right:0;
        .doc-for-upload{
            position: relative;
            top:auto;
            width:100%;
            margin-top:30px;
        }
       
    }
   .new-doc-white-container{
        padding: 20px 5px;
        .document-table{
            .document-list{
                display:block;
                md-checkbox .md-label{margin-top:5px;}
                margin-bottom: 30px;
                padding-bottom: 60px;
            }
        }
        .small-checkbox {
            md-checkbox{margin-top:0!important;.md-label{margin-top:5px!important;}}
        }
        .all-doc{margin-bottom:0!important;}
    } 
   
}


@media (max-width: 576px) {
    .application-bredcrumb{
       a{
            color:transparent;
            font-size:0;
        }
    }
    .list-for-verification{
        
        table{
            thead{
                height:auto;
                line-height:normal;
                tr{
                    th{
                        padding-top:12px;
                        padding-bottom:12px;
                        span{display:block;text-align:left;}
                    }
                }
            }
            tbody{
                tr{
                    display:block;
                    td{
                        display: block;
                        width:100%!important;
                        label{
                            display:block;
                            max-width:100%;
                            margin-bottom:10px;
                        }
                        &:last-child{
                            padding: 0 15px 10px !important;
                            text-align: left;
                        }
                    }
                }
            }
        }
    }
    .verification-table{
        .default-textfield{
            width:100%;
            margin:10px 0 0!important;
        }
    } 
    


}

@media (max-width: 500px) {
    .list-container{
        padding: 0 0 30px;
    }
    .new-doc-white-container{
        padding: 0;
        box-shadow:none;
        .document-table{
            .document-list{padding:0 0 60px 0;}
        }
        h4{
            margin-bottom: 20px;
            padding: 0 ;
        }
        .all-doc{
            left:0;
            right:0;
        }
    } 
}
