html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

.incompatible-browser {
  display: none;
  position: relative;
  width: 100%;
  text-align: center; }

.no-js .incompatible-browser {
  display: block; }

.incompatible .incompatible-browser {
  display: block; }

.loader {
  display: none;
  text-align: center; }

.version {
  position: fixed;
  right: 0;
  bottom: 0;
  max-width: 100%;
  padding: 0.5em;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0.9;
  z-index: 9999; }

html, body {
  height: 100%;
  position: relative; }

body {
  margin: 0;
  padding: 0; }

[tabindex='-1']:focus {
  outline: none; }

.inset {
  padding: 10px; }

a.md-no-style,
button.md-no-style {
  font-weight: normal;
  background-color: inherit;
  text-align: left;
  border: none;
  padding: 0;
  margin: 0; }

select,
button,
textarea,
input {
  vertical-align: baseline; }

input[type="reset"],
input[type="submit"],
html input[type="button"],
button {
  cursor: pointer;
  -webkit-appearance: button; }
  input[type="reset"][disabled],
  input[type="submit"][disabled],
  html input[type="button"][disabled],
  button[disabled] {
    cursor: default; }

textarea {
  vertical-align: top;
  overflow: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
  -webkit-box-sizing: content-box; }
  input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none; }

input:-webkit-autofill {
  text-shadow: none; }

.md-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  text-transform: none;
  width: 1px; }

.md-shadow {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: inherit;
  pointer-events: none; }

.md-shadow-bottom-z-1 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.md-shadow-bottom-z-2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }

.md-shadow-animated.md-shadow {
  transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1); }

/*
 * A container inside of a rippling element (eg a button),
 * which contains all of the individual ripples
 */
.md-ripple-container {
  pointer-events: none;
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1); }

.md-ripple {
  position: absolute;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: 50% 50%;
  opacity: 0;
  border-radius: 50%; }
  .md-ripple.md-ripple-placed {
    transition: margin 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), border 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), height 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-ripple.md-ripple-scaled {
    transform: translate(-50%, -50%) scale(1); }
  .md-ripple.md-ripple-active, .md-ripple.md-ripple-full, .md-ripple.md-ripple-visible {
    opacity: 0.20; }
  .md-ripple.md-ripple-remove {
    animation: md-remove-ripple 0.9s cubic-bezier(0.25, 0.8, 0.25, 1); }

@keyframes md-remove-ripple {
  0% {
    opacity: .15; }
  100% {
    opacity: 0; } }

.md-padding {
  padding: 8px; }

.md-margin {
  margin: 8px; }

.md-scroll-mask {
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50; }
  .md-scroll-mask > .md-scroll-mask-bar {
    display: block;
    position: absolute;
    background-color: #fafafa;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 65;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3); }

.md-no-momentum {
  -webkit-overflow-scrolling: auto; }

.md-no-flicker {
  -webkit-filter: blur(0px); }

@media (min-width: 960px) {
  .md-padding {
    padding: 16px; } }

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed; }

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override; }

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override; }

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/************
 * Headings
 ************/
.md-display-4 {
  font-size: 112px;
  font-weight: 300;
  letter-spacing: -0.010em;
  line-height: 112px; }

.md-display-3 {
  font-size: 56px;
  font-weight: 400;
  letter-spacing: -0.005em;
  line-height: 56px; }

.md-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px; }

.md-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 40px; }

.md-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px; }

.md-title {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.005em; }

.md-subhead {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 24px; }

/************
 * Body Copy
 ************/
.md-body-1 {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 20px; }

.md-body-2 {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.010em;
  line-height: 24px; }

.md-caption {
  font-size: 12px;
  letter-spacing: 0.020em; }

.md-button {
  letter-spacing: 0.010em; }

/************
 * Defaults
 ************/
button,
select,
html,
textarea,
input {
  font-family: Roboto, "Helvetica Neue", sans-serif; }

select,
button,
textarea,
input {
  font-size: 100%; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }

._md-panel-hidden {
  display: none; }

._md-panel-offscreen {
  left: -9999px; }

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0; }

._md-panel-shown .md-panel {
  opacity: 1;
  transition: none; }

.md-panel {
  opacity: 0;
  position: fixed; }
  .md-panel._md-panel-shown {
    opacity: 1;
    transition: none; }
  .md-panel._md-panel-animate-enter {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-panel-animate-leave {
    opacity: 1;
    transition: all 0.3s cubic-bezier(0.4, 0, 1, 1); }
  .md-panel._md-panel-animate-scale-out, .md-panel._md-panel-animate-fade-out {
    opacity: 0; }
  .md-panel._md-panel-backdrop {
    height: 100%;
    position: absolute;
    width: 100%; }
  .md-panel._md-opaque-enter {
    opacity: .48;
    transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1); }
  .md-panel._md-opaque-leave {
    transition: opacity 0.3s cubic-bezier(0.4, 0, 1, 1); }

md-autocomplete {
  border-radius: 2px;
  display: block;
  height: 40px;
  position: relative;
  overflow: visible;
  min-width: 190px; }
  md-autocomplete[disabled] input {
    cursor: default; }
  md-autocomplete[md-floating-label] {
    border-radius: 0;
    background: transparent;
    height: auto; }
    md-autocomplete[md-floating-label] md-input-container {
      padding-bottom: 0; }
    md-autocomplete[md-floating-label] md-autocomplete-wrap {
      height: auto; }
    md-autocomplete[md-floating-label] .md-show-clear-button button {
      display: block;
      position: absolute;
      right: 0;
      top: 20px;
      width: 30px;
      height: 30px; }
    md-autocomplete[md-floating-label] .md-show-clear-button input {
      padding-right: 30px; }
      [dir=rtl] md-autocomplete[md-floating-label] .md-show-clear-button input {
        padding-right: 0;
        padding-left: 30px; }
  md-autocomplete md-autocomplete-wrap {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;
    overflow: visible;
    height: 40px; }
    md-autocomplete md-autocomplete-wrap.md-menu-showing {
      z-index: 51; }
    md-autocomplete md-autocomplete-wrap md-input-container, md-autocomplete md-autocomplete-wrap input {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0; }
    md-autocomplete md-autocomplete-wrap md-progress-linear {
      position: absolute;
      bottom: -2px;
      left: 0; }
      md-autocomplete md-autocomplete-wrap md-progress-linear.md-inline {
        bottom: 40px;
        right: 2px;
        left: 2px;
        width: auto; }
      md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px;
        transition: none; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate .md-container {
          transition: none;
          height: 3px; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-enter.ng-enter-active {
            opacity: 1; }
        md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave {
          transition: opacity 0.15s linear; }
          md-autocomplete md-autocomplete-wrap md-progress-linear .md-mode-indeterminate.ng-leave.ng-leave-active {
            opacity: 0; }
  md-autocomplete input:not(.md-input) {
    font-size: 14px;
    box-sizing: border-box;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    width: 100%;
    padding: 0 15px;
    line-height: 40px;
    height: 40px; }
    md-autocomplete input:not(.md-input)::-ms-clear {
      display: none; }
  md-autocomplete .md-show-clear-button button {
    position: relative;
    line-height: 20px;
    text-align: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: none;
    border-radius: 50%;
    padding: 0;
    font-size: 12px;
    background: transparent;
    margin: auto 5px; }
    md-autocomplete .md-show-clear-button button:after {
      content: '';
      position: absolute;
      top: -6px;
      right: -6px;
      bottom: -6px;
      left: -6px;
      border-radius: 50%;
      transform: scale(0);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-autocomplete .md-show-clear-button button:focus {
      outline: none; }
      md-autocomplete .md-show-clear-button button:focus:after {
        transform: scale(1);
        opacity: 1; }
    md-autocomplete .md-show-clear-button button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0) scale(0.9); }
      md-autocomplete .md-show-clear-button button md-icon path {
        stroke-width: 0; }
    md-autocomplete .md-show-clear-button button.ng-enter {
      transform: scale(0);
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-enter.ng-enter-active {
        transform: scale(1); }
    md-autocomplete .md-show-clear-button button.ng-leave {
      transition: transform 0.15s ease-out; }
      md-autocomplete .md-show-clear-button button.ng-leave.ng-leave-active {
        transform: scale(0); }
  @media screen and (-ms-high-contrast: active) {
    md-autocomplete input {
      border: 1px solid #fff; }
    md-autocomplete li:focus {
      color: #fff; } }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  position: absolute;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 100;
  height: 100%; }
  .md-virtual-repeat-container.md-autocomplete-suggestions-container .highlight {
    font-weight: bold; }

.md-virtual-repeat-container.md-not-found {
  height: 48px; }

.md-autocomplete-suggestions {
  margin: 0;
  list-style: none;
  padding: 0; }
  .md-autocomplete-suggestions li {
    font-size: 14px;
    overflow: hidden;
    padding: 0 15px;
    line-height: 48px;
    height: 48px;
    transition: background 0.15s linear;
    margin: 0;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .md-autocomplete-suggestions li:focus {
      outline: none; }
    .md-autocomplete-suggestions li:not(.md-not-found-wrapper) {
      cursor: pointer; }

@media screen and (-ms-high-contrast: active) {
  md-autocomplete,
  .md-autocomplete-suggestions {
    border: 1px solid #fff; } }

md-backdrop {
  transition: opacity 450ms;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50; }
  md-backdrop.md-menu-backdrop {
    position: fixed !important;
    z-index: 99; }
  md-backdrop.md-select-backdrop {
    z-index: 81;
    transition-duration: 0; }
  md-backdrop.md-dialog-backdrop {
    z-index: 79; }
  md-backdrop.md-bottom-sheet-backdrop {
    z-index: 69; }
  md-backdrop.md-sidenav-backdrop {
    z-index: 59; }
  md-backdrop.md-click-catcher {
    position: absolute; }
  md-backdrop.md-opaque {
    opacity: .48; }
    md-backdrop.md-opaque.ng-enter {
      opacity: 0; }
    md-backdrop.md-opaque.ng-enter.md-opaque.ng-enter-active {
      opacity: .48; }
    md-backdrop.md-opaque.ng-leave {
      opacity: .48;
      transition: opacity 400ms; }
    md-backdrop.md-opaque.ng-leave.md-opaque.ng-leave-active {
      opacity: 0; }

md-bottom-sheet {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px 16px 88px 16px;
  z-index: 70;
  border-top-width: 1px;
  border-top-style: solid;
  transform: translate3d(0, 80px, 0);
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform; }
  md-bottom-sheet.md-has-header {
    padding-top: 0; }
  md-bottom-sheet.ng-enter {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-bottom-sheet.ng-enter-active {
    opacity: 1;
    display: block;
    transform: translate3d(0, 80px, 0) !important; }
  md-bottom-sheet.ng-leave-active {
    transform: translate3d(0, 100%, 0) !important;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-bottom-sheet .md-subheader {
    background-color: transparent;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    line-height: 56px;
    padding: 0;
    white-space: nowrap; }
  md-bottom-sheet md-inline-icon {
    display: inline-block;
    height: 24px;
    width: 24px;
    fill: #444; }
  md-bottom-sheet md-list-item {
    display: flex;
    outline: none; }
    md-bottom-sheet md-list-item:hover {
      cursor: pointer; }
  md-bottom-sheet.md-list md-list-item {
    padding: 0;
    align-items: center;
    height: 48px; }
  md-bottom-sheet.md-grid {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 0; }
    md-bottom-sheet.md-grid md-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      transition: all 0.5s;
      align-items: center; }
    md-bottom-sheet.md-grid md-list-item {
      flex-direction: column;
      align-items: center;
      transition: all 0.5s;
      height: 96px;
      margin-top: 8px;
      margin-bottom: 8px;
      /* Mixin for how many grid items to show per row */ }
      @media (max-width: 960px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 33.33333%;
          max-width: 33.33333%; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n + 1) {
            align-items: flex-start; }
          md-bottom-sheet.md-grid md-list-item:nth-of-type(3n) {
            align-items: flex-end; } }
      @media (min-width: 960px) and (max-width: 1279px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 25%;
          max-width: 25%; } }
      @media (min-width: 1280px) and (max-width: 1919px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 16.66667%;
          max-width: 16.66667%; } }
      @media (min-width: 1920px) {
        md-bottom-sheet.md-grid md-list-item {
          flex: 1 1 14.28571%;
          max-width: 14.28571%; } }
      md-bottom-sheet.md-grid md-list-item::before {
        display: none; }
      md-bottom-sheet.md-grid md-list-item .md-list-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48px;
        padding-bottom: 16px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-item-content {
        border: 1px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80px; }
      md-bottom-sheet.md-grid md-list-item .md-grid-text {
        font-weight: 400;
        line-height: 16px;
        font-size: 13px;
        margin: 0;
        white-space: nowrap;
        width: 64px;
        text-align: center;
        text-transform: none;
        padding-top: 8px; }

@media screen and (-ms-high-contrast: active) {
  md-bottom-sheet {
    border: 1px solid #fff; } }

button.md-button::-moz-focus-inner {
  border: 0; }

.md-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  /** Alignment adjustments */
  min-height: 36px;
  min-width: 88px;
  line-height: 36px;
  vertical-align: middle;
  align-items: center;
  text-align: center;
  border-radius: 2px;
  box-sizing: border-box;
  /* Reset default button appearance */
  user-select: none;
  outline: none;
  border: 0;
  /** Custom styling for button */
  padding: 0 6px;
  margin: 6px 8px;
  background: transparent;
  color: currentColor;
  white-space: nowrap;
  /* Uppercase text content */
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  font-variant: inherit;
  font-family: inherit;
  text-decoration: none;
  overflow: hidden;
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    min-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    line-height: 32px; }
  .md-dense > .md-button:not(.md-dense-disabled),
  .md-dense :not(.md-dense-disabled) .md-button:not(.md-dense-disabled) {
    font-size: 13px; }
  .md-button:focus {
    outline: none; }
  .md-button:hover, .md-button:focus {
    text-decoration: none; }
  .md-button.ng-hide, .md-button.ng-leave {
    transition: none; }
  .md-button.md-cornered {
    border-radius: 0; }
  .md-button.md-icon {
    padding: 0;
    background: none; }
  .md-button.md-raised:not([disabled]) {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button.md-icon-button {
    margin: 0 6px;
    height: 40px;
    min-width: 0;
    line-height: 24px;
    padding: 8px;
    width: 40px;
    border-radius: 50%; }
  .md-button.md-fab {
    z-index: 20;
    line-height: 56px;
    min-width: 0;
    width: 56px;
    height: 56px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform; }
    .md-button.md-fab.md-fab-bottom-right {
      top: auto;
      right: 20px;
      bottom: 20px;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-bottom-left {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-fab-top-right {
      top: 20px;
      right: 20px;
      bottom: auto;
      left: auto;
      position: absolute; }
    .md-button.md-fab.md-fab-top-left {
      top: 20px;
      right: auto;
      bottom: auto;
      left: 20px;
      position: absolute; }
    .md-button.md-fab.md-mini {
      line-height: 40px;
      width: 40px;
      height: 40px; }
    .md-button.md-fab.ng-hide, .md-button.md-fab.ng-leave {
      transition: none; }
  .md-button:not([disabled]).md-raised.md-focused, .md-button:not([disabled]).md-fab.md-focused {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .md-button:not([disabled]).md-raised:active, .md-button:not([disabled]).md-fab:active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4); }
  .md-button .md-ripple-container {
    border-radius: inherit;
    background-clip: padding-box;
    overflow: hidden;
    -webkit-transform: translateZ(0); }

.md-button.md-icon-button md-icon,
button.md-button.md-fab md-icon {
  display: block; }

.md-toast-open-top .md-button.md-fab-top-left,
.md-toast-open-top .md-button.md-fab-top-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, 42px, 0); }
  .md-toast-open-top .md-button.md-fab-top-left:not([disabled]).md-focused, .md-toast-open-top .md-button.md-fab-top-left:not([disabled]):hover,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]).md-focused,
  .md-toast-open-top .md-button.md-fab-top-right:not([disabled]):hover {
    transform: translate3d(0, 41px, 0); }

.md-toast-open-bottom .md-button.md-fab-bottom-left,
.md-toast-open-bottom .md-button.md-fab-bottom-right {
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate3d(0, -42px, 0); }
  .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]).md-focused, .md-toast-open-bottom .md-button.md-fab-bottom-left:not([disabled]):hover,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]).md-focused,
  .md-toast-open-bottom .md-button.md-fab-bottom-right:not([disabled]):hover {
    transform: translate3d(0, -43px, 0); }

.md-button-group {
  display: flex;
  flex: 1;
  width: 100%; }
  .md-button-group > .md-button {
    flex: 1;
    display: block;
    overflow: hidden;
    width: 0;
    border-width: 1px 0px 1px 1px;
    border-radius: 0;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap; }
    .md-button-group > .md-button:first-child {
      border-radius: 2px 0px 0px 2px; }
    .md-button-group > .md-button:last-child {
      border-right-width: 1px;
      border-radius: 0px 2px 2px 0px; }

@media screen and (-ms-high-contrast: active) {
  .md-button.md-raised,
  .md-button.md-fab {
    border: 1px solid #fff; } }

md-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 8px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  md-card md-card-header {
    padding: 16px;
    display: flex;
    flex-direction: row; }
    md-card md-card-header:first-child md-card-avatar {
      margin-right: 12px; }
      [dir=rtl] md-card md-card-header:first-child md-card-avatar {
        margin-right: auto;
        margin-left: 12px; }
    md-card md-card-header:last-child md-card-avatar {
      margin-left: 12px; }
      [dir=rtl] md-card md-card-header:last-child md-card-avatar {
        margin-left: auto;
        margin-right: 12px; }
    md-card md-card-header md-card-avatar {
      width: 40px;
      height: 40px; }
      md-card md-card-header md-card-avatar .md-user-avatar,
      md-card md-card-header md-card-avatar md-icon {
        border-radius: 50%; }
      md-card md-card-header md-card-avatar md-icon {
        padding: 8px; }
        md-card md-card-header md-card-avatar md-icon > svg {
          height: inherit;
          width: inherit; }
      md-card md-card-header md-card-avatar + md-card-header-text {
        max-height: 40px; }
        md-card md-card-header md-card-avatar + md-card-header-text .md-title {
          font-size: 14px; }
    md-card md-card-header md-card-header-text {
      display: flex;
      flex: 1;
      flex-direction: column; }
      md-card md-card-header md-card-header-text .md-subhead {
        font-size: 14px; }
  md-card > img,
  md-card > md-card-header img,
  md-card md-card-title-media img {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    height: auto; }
  md-card md-card-title {
    padding: 24px 16px 16px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row; }
    md-card md-card-title + md-card-content {
      padding-top: 0; }
    md-card md-card-title md-card-title-text {
      flex: 1;
      flex-direction: column;
      display: flex; }
      md-card md-card-title md-card-title-text .md-subhead {
        padding-top: 0;
        font-size: 14px; }
      md-card md-card-title md-card-title-text:only-child .md-subhead {
        padding-top: 12px; }
    md-card md-card-title md-card-title-media {
      margin-top: -8px; }
      md-card md-card-title md-card-title-media .md-media-sm {
        height: 80px;
        width: 80px; }
      md-card md-card-title md-card-title-media .md-media-md {
        height: 112px;
        width: 112px; }
      md-card md-card-title md-card-title-media .md-media-lg {
        height: 152px;
        width: 152px; }
  md-card md-card-content {
    display: block;
    padding: 16px; }
    md-card md-card-content > p:first-child {
      margin-top: 0; }
    md-card md-card-content > p:last-child {
      margin-bottom: 0; }
    md-card md-card-content .md-media-xl {
      height: 240px;
      width: 240px; }
  md-card .md-actions, md-card md-card-actions {
    margin: 8px; }
    md-card .md-actions.layout-column .md-button:not(.md-icon-button), md-card md-card-actions.layout-column .md-button:not(.md-icon-button) {
      margin: 2px 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):first-of-type {
        margin-top: 0; }
      md-card .md-actions.layout-column .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions.layout-column .md-button:not(.md-icon-button):last-of-type {
        margin-bottom: 0; }
    md-card .md-actions.layout-column .md-button.md-icon-button, md-card md-card-actions.layout-column .md-button.md-icon-button {
      margin-top: 6px;
      margin-bottom: 6px; }
    md-card .md-actions md-card-icon-actions, md-card md-card-actions md-card-icon-actions {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-direction: row; }
    md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button), md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button) {
      margin: 0 4px; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
        margin-left: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):first-of-type {
          margin-left: auto;
          margin-right: 0; }
      md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
        margin-right: 0; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button:not(.md-icon-button):last-of-type {
          margin-right: auto;
          margin-left: 0; }
    md-card .md-actions:not(.layout-column) .md-button.md-icon-button, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button {
      margin-left: 6px;
      margin-right: 6px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
        margin-left: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:first-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:first-of-type {
          margin-left: auto;
          margin-right: 12px; }
      md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
        margin-right: 12px; }
        [dir=rtl] md-card .md-actions:not(.layout-column) .md-button.md-icon-button:last-of-type, [dir=rtl] md-card md-card-actions:not(.layout-column) .md-button.md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 12px; }
    md-card .md-actions:not(.layout-column) .md-button + md-card-icon-actions, md-card md-card-actions:not(.layout-column) .md-button + md-card-icon-actions {
      flex: 1;
      justify-content: flex-end;
      display: flex;
      flex-direction: row; }
  md-card md-card-footer {
    margin-top: auto;
    padding: 16px; }

@media screen and (-ms-high-contrast: active) {
  md-card {
    border: 1px solid #fff; } }

.md-image-no-fill > img {
  width: auto;
  height: auto; }

.md-inline-form md-checkbox {
  margin: 19px 0 18px; }

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: 20px;
  min-height: 20px;
  margin-left: 0;
  margin-right: 16px; }
  [dir=rtl] md-checkbox {
    margin-left: 16px; }
  [dir=rtl] md-checkbox {
    margin-right: 0; }
  md-checkbox:last-of-type {
    margin-left: 0;
    margin-right: 0; }
  md-checkbox.md-focused:not([disabled]) .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-checkbox.md-focused:not([disabled]):not(.md-checked) .md-container:before {
    background-color: rgba(0, 0, 0, 0.12); }
  md-checkbox.md-align-top-left > div.md-container {
    top: 12px; }
  md-checkbox .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-checkbox .md-container {
      left: auto; }
    [dir=rtl] md-checkbox .md-container {
      right: 0; }
    md-checkbox .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-checkbox .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-checkbox .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-checkbox .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-checkbox.md-checked .md-icon {
    border-color: transparent; }
    md-checkbox.md-checked .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-checkbox[disabled] {
    cursor: default; }
  md-checkbox.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-checkbox .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    user-select: text;
    margin-left: 30px;
    margin-right: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-left: 0; }
    [dir=rtl] md-checkbox .md-label {
      margin-right: 30px; }

.md-contact-chips .md-chips md-chip {
  padding: 0 25px 0 0; }
  [dir=rtl] .md-contact-chips .md-chips md-chip {
    padding: 0 0 0 25px; }
  .md-contact-chips .md-chips md-chip .md-contact-avatar {
    float: left; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-avatar {
      float: right; }
    .md-contact-chips .md-chips md-chip .md-contact-avatar img {
      height: 32px;
      border-radius: 16px; }
  .md-contact-chips .md-chips md-chip .md-contact-name {
    display: inline-block;
    height: 32px;
    margin-left: 8px; }
    [dir=rtl] .md-contact-chips .md-chips md-chip .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }

.md-contact-suggestion {
  height: 56px; }
  .md-contact-suggestion img {
    height: 40px;
    border-radius: 20px;
    margin-top: 8px; }
  .md-contact-suggestion .md-contact-name {
    margin-left: 8px;
    width: 120px; }
    [dir=rtl] .md-contact-suggestion .md-contact-name {
      margin-left: auto;
      margin-right: 8px; }
  .md-contact-suggestion .md-contact-name, .md-contact-suggestion .md-contact-email {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis; }

.md-contact-chips-suggestions li {
  height: 100%; }

.md-chips {
  display: block;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  padding: 0 0 8px 3px;
  vertical-align: middle; }
  .md-chips:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] .md-chips {
    padding: 0 3px 8px 0; }
  .md-chips.md-readonly .md-chip-input-container {
    min-height: 32px; }
  .md-chips:not(.md-readonly) {
    cursor: text; }
  .md-chips.md-removable md-chip {
    padding-right: 22px; }
    [dir=rtl] .md-chips.md-removable md-chip {
      padding-right: 0;
      padding-left: 22px; }
    .md-chips.md-removable md-chip .md-chip-content {
      padding-right: 4px; }
      [dir=rtl] .md-chips.md-removable md-chip .md-chip-content {
        padding-right: 0;
        padding-left: 4px; }
  .md-chips md-chip {
    cursor: default;
    border-radius: 16px;
    display: block;
    height: 32px;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0 12px 0 12px;
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    position: relative; }
    [dir=rtl] .md-chips md-chip {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips md-chip {
      float: right; }
    .md-chips md-chip .md-chip-content {
      display: block;
      float: left;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
      [dir=rtl] .md-chips md-chip .md-chip-content {
        float: right; }
      .md-chips md-chip .md-chip-content:focus {
        outline: none; }
    .md-chips md-chip._md-chip-content-edit-is-enabled {
      -webkit-user-select: none;
      /* webkit (safari, chrome) browsers */
      -moz-user-select: none;
      /* mozilla browsers */
      -khtml-user-select: none;
      /* webkit (konqueror) browsers */
      -ms-user-select: none;
      /* IE10+ */ }
    .md-chips md-chip .md-chip-remove-container {
      position: absolute;
      right: 0;
      line-height: 22px; }
      [dir=rtl] .md-chips md-chip .md-chip-remove-container {
        right: auto;
        left: 0; }
    .md-chips md-chip .md-chip-remove {
      text-align: center;
      width: 32px;
      height: 32px;
      min-width: 0;
      padding: 0;
      background: transparent;
      border: none;
      box-shadow: none;
      margin: 0;
      position: relative; }
      .md-chips md-chip .md-chip-remove md-icon {
        height: 18px;
        width: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0); }
  .md-chips .md-chip-input-container {
    display: block;
    line-height: 32px;
    margin: 8px 8px 0 0;
    padding: 0;
    float: left; }
    [dir=rtl] .md-chips .md-chip-input-container {
      margin: 8px 0 0 8px; }
    [dir=rtl] .md-chips .md-chip-input-container {
      float: right; }
    .md-chips .md-chip-input-container input:not([type]), .md-chips .md-chip-input-container input[type="email"], .md-chips .md-chip-input-container input[type="number"], .md-chips .md-chip-input-container input[type="tel"], .md-chips .md-chip-input-container input[type="url"], .md-chips .md-chip-input-container input[type="text"] {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:not([type]):focus, .md-chips .md-chip-input-container input[type="email"]:focus, .md-chips .md-chip-input-container input[type="number"]:focus, .md-chips .md-chip-input-container input[type="tel"]:focus, .md-chips .md-chip-input-container input[type="url"]:focus, .md-chips .md-chip-input-container input[type="text"]:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      background: transparent;
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete md-autocomplete-wrap {
      box-shadow: none; }
    .md-chips .md-chip-input-container md-autocomplete input {
      position: relative; }
    .md-chips .md-chip-input-container input {
      border: 0;
      height: 32px;
      line-height: 32px;
      padding: 0; }
      .md-chips .md-chip-input-container input:focus {
        outline: none; }
    .md-chips .md-chip-input-container md-autocomplete, .md-chips .md-chip-input-container md-autocomplete-wrap {
      height: 32px; }
    .md-chips .md-chip-input-container md-autocomplete {
      box-shadow: none; }
      .md-chips .md-chip-input-container md-autocomplete input {
        position: relative; }
    .md-chips .md-chip-input-container:not(:first-child) {
      margin: 8px 8px 0 0; }
      [dir=rtl] .md-chips .md-chip-input-container:not(:first-child) {
        margin: 8px 0 0 8px; }
    .md-chips .md-chip-input-container input {
      background: transparent;
      border-width: 0; }
  .md-chips md-autocomplete button {
    display: none; }

@media screen and (-ms-high-contrast: active) {
  .md-chip-input-container,
  md-chip {
    border: 1px solid #fff; }
  .md-chip-input-container md-autocomplete {
    border: none; } }

md-content {
  display: block;
  position: relative;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-content[md-scroll-y] {
    overflow-y: auto;
    overflow-x: hidden; }
  md-content[md-scroll-x] {
    overflow-x: auto;
    overflow-y: hidden; }
  @media print {
    md-content {
      overflow: visible !important; } }

/** Styles for mdCalendar. */
md-calendar {
  font-size: 13px;
  user-select: none; }

.md-calendar-scroll-mask {
  display: inline-block;
  overflow: hidden;
  height: 308px; }
  .md-calendar-scroll-mask .md-virtual-repeat-scroller {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; }
    .md-calendar-scroll-mask .md-virtual-repeat-scroller::-webkit-scrollbar {
      display: none; }
  .md-calendar-scroll-mask .md-virtual-repeat-offsetter {
    width: 100%; }

.md-calendar-scroll-container {
  box-shadow: inset -3px 3px 6px rgba(0, 0, 0, 0.2);
  display: inline-block;
  height: 308px;
  width: 346px; }

.md-calendar-date {
  height: 44px;
  width: 44px;
  text-align: center;
  padding: 0;
  border: none;
  box-sizing: content-box; }
  .md-calendar-date:first-child {
    padding-left: 16px; }
    [dir=rtl] .md-calendar-date:first-child {
      padding-left: 0;
      padding-right: 16px; }
  .md-calendar-date:last-child {
    padding-right: 16px; }
    [dir=rtl] .md-calendar-date:last-child {
      padding-right: 0;
      padding-left: 16px; }
  .md-calendar-date.md-calendar-date-disabled {
    cursor: default; }

.md-calendar-date-selection-indicator {
  transition: background-color, color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 50%;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px; }
  .md-calendar-date:not(.md-disabled) .md-calendar-date-selection-indicator {
    cursor: pointer; }

.md-calendar-month-label {
  height: 44px;
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 24px; }
  [dir=rtl] .md-calendar-month-label {
    padding: 0 24px 0 0; }
  .md-calendar-month-label.md-calendar-label-clickable {
    cursor: pointer; }
  .md-calendar-month-label md-icon {
    transform: rotate(180deg); }
    [dir=rtl] .md-calendar-month-label md-icon {
      transform: none; }
  .md-calendar-month-label span {
    vertical-align: middle; }

.md-calendar-day-header {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar-day-header th {
    height: 40px;
    width: 44px;
    text-align: center;
    padding: 0;
    border: none;
    box-sizing: content-box;
    font-weight: normal; }
    .md-calendar-day-header th:first-child {
      padding-left: 16px; }
      [dir=rtl] .md-calendar-day-header th:first-child {
        padding-left: 0;
        padding-right: 16px; }
    .md-calendar-day-header th:last-child {
      padding-right: 16px; }
      [dir=rtl] .md-calendar-day-header th:last-child {
        padding-right: 0;
        padding-left: 16px; }

.md-calendar {
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: collapse; }
  .md-calendar tr:last-child td {
    border-bottom-width: 1px;
    border-bottom-style: solid; }
  .md-calendar:first-child {
    border-top: 1px solid transparent; }
  .md-calendar tbody, .md-calendar td, .md-calendar tr {
    vertical-align: middle;
    box-sizing: content-box; }

/** Styles for mdDatepicker. */
md-datepicker {
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; }

.md-inline-form md-datepicker {
  margin-top: 12px; }

.md-datepicker-button {
  display: inline-block;
  box-sizing: border-box;
  background: none;
  vertical-align: middle;
  position: relative; }
  .md-datepicker-button:before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    content: '';
    speak: none; }

.md-datepicker-input {
  font-size: 14px;
  box-sizing: border-box;
  border: none;
  box-shadow: none;
  outline: none;
  background: transparent;
  min-width: 120px;
  max-width: 328px;
  padding: 0 0 5px; }
  .md-datepicker-input::-ms-clear {
    display: none; }

._md-datepicker-floating-label > md-datepicker {
  overflow: visible; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-input-container {
    border: none; }
  ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
    float: left;
    margin-top: -12px;
    top: 9.5px; }
    [dir=rtl] ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
      float: right; }

._md-datepicker-floating-label .md-input {
  float: none; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
  right: 18px;
  left: auto;
  width: calc(100% - 84px); }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    right: auto; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon > label:not(.md-no-float):not(.md-container-ignore) {
    left: 18px; }

._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
  margin-left: 64px; }
  [dir=rtl] ._md-datepicker-floating-label._md-datepicker-has-calendar-icon .md-input-message-animation {
    margin-left: auto;
    margin-right: 64px; }

._md-datepicker-has-triangle-icon {
  padding-right: 18px;
  margin-right: -18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    padding-right: 0;
    padding-left: 18px; }
  [dir=rtl] ._md-datepicker-has-triangle-icon {
    margin-right: auto;
    margin-left: -18px; }

.md-datepicker-input-container {
  position: relative;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  display: inline-block;
  width: auto; }
  .md-icon-button + .md-datepicker-input-container {
    margin-left: 12px; }
    [dir=rtl] .md-icon-button + .md-datepicker-input-container {
      margin-left: auto;
      margin-right: 12px; }
  .md-datepicker-input-container.md-datepicker-focused {
    border-bottom-width: 2px; }

.md-datepicker-is-showing .md-scroll-mask {
  z-index: 99; }

.md-datepicker-calendar-pane {
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 100;
  border-width: 1px;
  border-style: solid;
  background: transparent;
  transform: scale(0);
  transform-origin: 0 0;
  transition: transform 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .md-datepicker-calendar-pane.md-pane-open {
    transform: scale(1); }

.md-datepicker-input-mask {
  height: 40px;
  width: 340px;
  position: relative;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
  cursor: text; }

.md-datepicker-calendar {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.5, 0, 0.25, 1); }
  .md-pane-open .md-datepicker-calendar {
    opacity: 1; }
  .md-datepicker-calendar md-calendar:focus {
    outline: none; }

.md-datepicker-expand-triangle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid; }

.md-datepicker-triangle-button {
  position: absolute;
  right: 0;
  bottom: -2.5px;
  transform: translateX(45%); }
  [dir=rtl] .md-datepicker-triangle-button {
    right: auto;
    left: 0; }
  [dir=rtl] .md-datepicker-triangle-button {
    transform: translateX(-45%); }

.md-datepicker-triangle-button.md-button.md-icon-button {
  height: 36px;
  width: 36px;
  position: absolute;
  padding: 8px; }

md-datepicker[disabled] .md-datepicker-input-container {
  border-bottom-color: transparent; }

md-datepicker[disabled] .md-datepicker-triangle-button {
  display: none; }

.md-datepicker-open {
  overflow: hidden; }
  .md-datepicker-open .md-datepicker-input-container,
  .md-datepicker-open input.md-input {
    border-bottom-color: transparent; }
  .md-datepicker-open .md-datepicker-triangle-button,
  .md-datepicker-open.md-input-has-value > label,
  .md-datepicker-open.md-input-has-placeholder > label {
    display: none; }

.md-datepicker-pos-adjusted .md-datepicker-input-mask {
  display: none; }

.md-datepicker-calendar-pane .md-calendar {
  transform: translateY(-85px);
  transition: transform 0.65s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-delay: 0.125s; }

.md-datepicker-calendar-pane.md-pane-open .md-calendar {
  transform: translateY(0); }

.md-dialog-is-showing {
  max-height: 100%; }

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 80;
  overflow: hidden; }

md-dialog {
  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto;
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column; }
  md-dialog.md-transition-in {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 0) scale(1); }
  md-dialog.md-transition-out {
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transform: translate(0, 100%) scale(0.2); }
  md-dialog > form {
    display: flex;
    flex-direction: column;
    overflow: auto; }
  md-dialog .md-dialog-content {
    padding: 24px; }
  md-dialog md-dialog-content {
    order: 1;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
    md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
      margin-top: 0; }
    md-dialog md-dialog-content:focus {
      outline: none; }
    md-dialog md-dialog-content .md-subheader {
      margin: 0; }
    md-dialog md-dialog-content .md-dialog-content-body {
      width: 100%; }
    md-dialog md-dialog-content .md-prompt-input-container {
      width: 100%;
      box-sizing: border-box; }
  md-dialog .md-actions, md-dialog md-dialog-actions {
    display: flex;
    order: 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding-right: 8px;
    padding-left: 16px;
    min-height: 52px;
    overflow: hidden; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-right: 16px; }
    [dir=rtl] md-dialog .md-actions, [dir=rtl] md-dialog md-dialog-actions {
      padding-left: 8px; }
    md-dialog .md-actions .md-button, md-dialog md-dialog-actions .md-button {
      margin-bottom: 8px;
      margin-left: 8px;
      margin-right: 0;
      margin-top: 8px; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-left: 0; }
      [dir=rtl] md-dialog .md-actions .md-button, [dir=rtl] md-dialog md-dialog-actions .md-button {
        margin-right: 8px; }
  md-dialog.md-content-overflow .md-actions, md-dialog.md-content-overflow md-dialog-actions {
    border-top-width: 1px;
    border-top-style: solid; }

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff; } }

@media (max-width: 959px) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0; } }

md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0; }
  md-divider[md-inset] {
    margin-left: 80px; }
    [dir=rtl] md-divider[md-inset] {
      margin-left: auto;
      margin-right: 80px; }

.layout-row > md-divider,
.layout-xs-row > md-divider, .layout-gt-xs-row > md-divider,
.layout-sm-row > md-divider, .layout-gt-sm-row > md-divider,
.layout-md-row > md-divider, .layout-gt-md-row > md-divider,
.layout-lg-row > md-divider, .layout-gt-lg-row > md-divider,
.layout-xl-row > md-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid; }

md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 20;
  /*
   * Hide some graphics glitches if switching animation types
   */
  /*
   * Handle the animations
   */ }
  md-fab-speed-dial.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-speed-dial.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-speed-dial:not(.md-hover-full) {
    pointer-events: none; }
    md-fab-speed-dial:not(.md-hover-full) md-fab-trigger, md-fab-speed-dial:not(.md-hover-full) .md-fab-action-item {
      pointer-events: auto; }
    md-fab-speed-dial:not(.md-hover-full).md-is-open {
      pointer-events: auto; }
  md-fab-speed-dial ._md-css-variables {
    z-index: 20; }
  md-fab-speed-dial.md-is-open .md-fab-action-item {
    align-items: center; }
  md-fab-speed-dial md-fab-actions {
    display: flex;
    height: auto; }
    md-fab-speed-dial md-fab-actions .md-fab-action-item {
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-down {
    flex-direction: column; }
    md-fab-speed-dial.md-down md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-down md-fab-actions {
      flex-direction: column;
      order: 2; }
  md-fab-speed-dial.md-up {
    flex-direction: column; }
    md-fab-speed-dial.md-up md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-up md-fab-actions {
      flex-direction: column-reverse;
      order: 1; }
  md-fab-speed-dial.md-left {
    flex-direction: row; }
    md-fab-speed-dial.md-left md-fab-trigger {
      order: 2; }
    md-fab-speed-dial.md-left md-fab-actions {
      flex-direction: row-reverse;
      order: 1; }
      md-fab-speed-dial.md-left md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-right {
    flex-direction: row; }
    md-fab-speed-dial.md-right md-fab-trigger {
      order: 1; }
    md-fab-speed-dial.md-right md-fab-actions {
      flex-direction: row;
      order: 2; }
      md-fab-speed-dial.md-right md-fab-actions .md-fab-action-item {
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-fab-speed-dial.md-fling-remove .md-fab-action-item > *, md-fab-speed-dial.md-scale-remove .md-fab-action-item > * {
    visibility: hidden; }
  md-fab-speed-dial.md-fling .md-fab-action-item {
    opacity: 1; }
  md-fab-speed-dial.md-fling.md-animations-waiting .md-fab-action-item {
    opacity: 0;
    transition-duration: 0s; }
  md-fab-speed-dial.md-scale .md-fab-action-item {
    transform: scale(0);
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 0.14286s; }

md-fab-toolbar {
  display: block;
  /*
   * Closed styling
   */
  /*
   * Hover styling
   */ }
  md-fab-toolbar.md-fab-bottom-right {
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-bottom-left {
    top: auto;
    right: auto;
    bottom: 20px;
    left: 20px;
    position: absolute; }
  md-fab-toolbar.md-fab-top-right {
    top: 20px;
    right: 20px;
    bottom: auto;
    left: auto;
    position: absolute; }
  md-fab-toolbar.md-fab-top-left {
    top: 20px;
    right: auto;
    bottom: auto;
    left: 20px;
    position: absolute; }
  md-fab-toolbar .md-fab-toolbar-wrapper {
    display: block;
    position: relative;
    overflow: hidden;
    height: 68px; }
  md-fab-toolbar md-fab-trigger {
    position: absolute;
    z-index: 20; }
    md-fab-toolbar md-fab-trigger button {
      overflow: visible !important; }
    md-fab-toolbar md-fab-trigger .md-fab-toolbar-background {
      display: block;
      position: absolute;
      z-index: 21;
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
    md-fab-toolbar md-fab-trigger md-icon {
      position: relative;
      z-index: 22;
      opacity: 1;
      transition: all 200ms ease-in; }
  md-fab-toolbar.md-left md-fab-trigger {
    right: 0; }
    [dir=rtl] md-fab-toolbar.md-left md-fab-trigger {
      right: auto;
      left: 0; }
  md-fab-toolbar.md-left .md-toolbar-tools {
    flex-direction: row-reverse; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-right: 0.6rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-right: auto;
        margin-left: 0.6rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
      margin-left: -0.8rem; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:first-child {
        margin-left: auto;
        margin-right: -0.8rem; }
    md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
      margin-right: 8px; }
      [dir=rtl] md-fab-toolbar.md-left .md-toolbar-tools > .md-button:last-child {
        margin-right: auto;
        margin-left: 8px; }
  md-fab-toolbar.md-right md-fab-trigger {
    left: 0; }
    [dir=rtl] md-fab-toolbar.md-right md-fab-trigger {
      left: auto;
      right: 0; }
  md-fab-toolbar.md-right .md-toolbar-tools {
    flex-direction: row; }
  md-fab-toolbar md-toolbar {
    background-color: transparent !important;
    pointer-events: none;
    z-index: 23; }
    md-fab-toolbar md-toolbar .md-toolbar-tools {
      padding: 0 20px;
      margin-top: 3px; }
    md-fab-toolbar md-toolbar .md-fab-action-item {
      opacity: 0;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 0.15s; }
  md-fab-toolbar.md-is-open md-fab-trigger > button {
    box-shadow: none; }
    md-fab-toolbar.md-is-open md-fab-trigger > button md-icon {
      opacity: 0; }
  md-fab-toolbar.md-is-open .md-fab-action-item {
    opacity: 1;
    transform: scale(1); }

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative; }
  md-grid-list md-grid-tile,
  md-grid-list md-grid-tile > figure,
  md-grid-list md-grid-tile-header,
  md-grid-list md-grid-tile-footer {
    box-sizing: border-box; }
  md-grid-list md-grid-tile {
    display: block;
    position: absolute; }
    md-grid-list md-grid-tile figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0; }
    md-grid-list md-grid-tile md-grid-tile-header,
    md-grid-list md-grid-tile md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;
      position: absolute;
      left: 0;
      right: 0; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h3,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-weight: 400;
        margin: 0 0 0 16px; }
      md-grid-list md-grid-tile md-grid-tile-header h3,
      md-grid-list md-grid-tile md-grid-tile-footer h3 {
        font-size: 14px; }
      md-grid-list md-grid-tile md-grid-tile-header h4,
      md-grid-list md-grid-tile md-grid-tile-footer h4 {
        font-size: 12px; }
    md-grid-list md-grid-tile md-grid-tile-header {
      top: 0; }
    md-grid-list md-grid-tile md-grid-tile-footer {
      bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff; }
  md-grid-tile-footer {
    border-top: 1px solid #fff; } }

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px; }
  md-icon svg {
    pointer-events: none;
    display: block; }
  md-icon[md-font-icon] {
    line-height: 24px;
    width: auto; }

md-input-container {
  display: inline-block;
  position: relative;
  padding: 2px;
  margin: 18px 0;
  vertical-align: middle;
  /*
   * The .md-input class is added to the input/textarea
   */ }
  md-input-container:after {
    content: '';
    display: table;
    clear: both; }
  md-input-container.md-block {
    display: block; }
  md-input-container .md-errors-spacer {
    float: right;
    min-height: 24px;
    min-width: 1px; }
    [dir=rtl] md-input-container .md-errors-spacer {
      float: left; }
  md-input-container > md-icon {
    position: absolute;
    top: 8px;
    left: 2px;
    right: auto; }
    [dir=rtl] md-input-container > md-icon {
      left: auto; }
    [dir=rtl] md-input-container > md-icon {
      right: 2px; }
  md-input-container textarea,
  md-input-container input[type="text"],
  md-input-container input[type="password"],
  md-input-container input[type="datetime"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="date"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"],
  md-input-container input[type="number"],
  md-input-container input[type="email"],
  md-input-container input[type="url"],
  md-input-container input[type="search"],
  md-input-container input[type="tel"],
  md-input-container input[type="color"] {
    /* remove default appearance from all input/textarea */
    -moz-appearance: none;
    -webkit-appearance: none; }
  md-input-container input[type="date"],
  md-input-container input[type="datetime-local"],
  md-input-container input[type="month"],
  md-input-container input[type="time"],
  md-input-container input[type="week"] {
    min-height: 26px; }
  md-input-container textarea {
    resize: none;
    overflow: hidden; }
    md-input-container textarea.md-input {
      min-height: 26px;
      -ms-flex-preferred-size: auto; }
    md-input-container textarea[md-no-autogrow] {
      height: auto;
      overflow: auto; }
  md-input-container label:not(.md-container-ignore) {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      left: auto; }
    [dir=rtl] md-input-container label:not(.md-container-ignore) {
      right: 0; }
    md-input-container label:not(.md-container-ignore).md-required:after {
      content: ' *';
      font-size: 13px;
      vertical-align: top; }
  md-input-container label:not(.md-no-float):not(.md-container-ignore),
  md-input-container .md-placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 3px;
    padding-right: 0;
    z-index: 1;
    transform: translate3d(0, 28px, 0) scale(1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    max-width: 100%;
    transform-origin: left top; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-left: 0; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      padding-right: 3px; }
    [dir=rtl] md-input-container label:not(.md-no-float):not(.md-container-ignore), [dir=rtl]
    md-input-container .md-placeholder {
      transform-origin: right top; }
  md-input-container .md-placeholder {
    position: absolute;
    top: 0;
    opacity: 0;
    transition-property: opacity, transform;
    transform: translate3d(0, 30px, 0); }
  md-input-container.md-input-focused .md-placeholder {
    opacity: 1;
    transform: translate3d(0, 24px, 0); }
  md-input-container.md-input-has-value .md-placeholder {
    transition: none;
    opacity: 0; }
  md-input-container:not(.md-input-has-value) input:not(:focus) {
    color: transparent; }
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-ampm-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-day-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-hour-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-millisecond-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-minute-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-month-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-second-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-week-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-year-field,
  md-input-container:not(.md-input-has-value) input:not(:focus)::-webkit-datetime-edit-text {
    color: transparent; }
  md-input-container .md-input {
    order: 2;
    display: block;
    margin-top: 0;
    background: none;
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 2px;
    padding-right: 2px;
    border-width: 0 0 1px 0;
    line-height: 26px;
    height: 30px;
    -ms-flex-preferred-size: 26px;
    border-radius: 0;
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    float: left; }
    [dir=rtl] md-input-container .md-input {
      float: right; }
    md-input-container .md-input:focus {
      outline: none; }
    md-input-container .md-input:invalid {
      outline: none;
      box-shadow: none; }
    md-input-container .md-input.md-no-flex {
      flex: none !important; }
  md-input-container .md-char-counter {
    text-align: right;
    padding-right: 2px;
    padding-left: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      text-align: left; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-right: 0; }
    [dir=rtl] md-input-container .md-char-counter {
      padding-left: 2px; }
  md-input-container .md-input-messages-animation {
    position: relative;
    order: 4;
    overflow: hidden;
    clear: left; }
    [dir=rtl] md-input-container .md-input-messages-animation {
      clear: right; }
  md-input-container .md-input-message-animation, md-input-container .md-char-counter {
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    opacity: 1;
    margin-top: 0;
    padding-top: 5px; }
    md-input-container .md-input-message-animation:not(.md-char-counter), md-input-container .md-char-counter:not(.md-char-counter) {
      padding-right: 5px;
      padding-left: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-right: 0; }
      [dir=rtl] md-input-container .md-input-message-animation:not(.md-char-counter), [dir=rtl] md-input-container .md-char-counter:not(.md-char-counter) {
        padding-left: 5px; }
  md-input-container:not(.md-input-invalid) .md-auto-hide .md-input-message-animation {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter-prepare {
    opacity: 0;
    margin-top: -100px; }
  md-input-container .md-input-message-animation.ng-enter:not(.ng-enter-active) {
    opacity: 0;
    margin-top: -100px; }
  md-input-container.md-input-focused label:not(.md-no-float), md-input-container.md-input-has-placeholder label:not(.md-no-float), md-input-container.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 6px, 0) scale(0.75);
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s; }
  md-input-container.md-input-has-value label {
    transition: none; }
  md-input-container.md-input-focused .md-input,
  md-input-container .md-input.ng-invalid.ng-dirty,
  md-input-container.md-input-resized .md-input {
    padding-bottom: 0;
    border-width: 0 0 2px 0; }
  md-input-container .md-input[disabled],
  [disabled] md-input-container .md-input {
    background-position: bottom -1px left 0;
    background-size: 4px 1px;
    background-repeat: repeat-x; }
  md-input-container.md-icon-float {
    transition: margin-top 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    md-input-container.md-icon-float > label {
      pointer-events: none;
      position: absolute; }
    md-input-container.md-icon-float > md-icon {
      top: 8px;
      left: 2px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-float > md-icon {
        right: 2px; }
  md-input-container.md-icon-left > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-left > label .md-placeholder, md-input-container.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
  md-input-container.md-icon-right > label .md-placeholder {
    width: calc(100% - 36px - 18px); }
  md-input-container.md-icon-left {
    padding-left: 36px;
    padding-right: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-left: 0; }
    [dir=rtl] md-input-container.md-icon-left {
      padding-right: 36px; }
    md-input-container.md-icon-left > label {
      left: 36px;
      right: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        left: auto; }
      [dir=rtl] md-input-container.md-icon-left > label {
        right: 36px; }
  md-input-container.md-icon-right {
    padding-left: 0;
    padding-right: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-left: 36px; }
    [dir=rtl] md-input-container.md-icon-right {
      padding-right: 0; }
    md-input-container.md-icon-right > md-icon:last-of-type {
      margin: 0;
      right: 2px;
      left: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        right: auto; }
      [dir=rtl] md-input-container.md-icon-right > md-icon:last-of-type {
        left: 2px; }
  md-input-container.md-icon-left.md-icon-right {
    padding-left: 36px;
    padding-right: 36px; }
    md-input-container.md-icon-left.md-icon-right > label:not(.md-no-float):not(.md-container-ignore),
    md-input-container.md-icon-left.md-icon-right > label .md-placeholder {
      width: calc(100% - (36px * 2)); }

.md-resize-wrapper {
  position: relative; }
  .md-resize-wrapper:after {
    content: '';
    display: table;
    clear: both; }

.md-resize-handle {
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 10px;
  background: transparent;
  width: 100%;
  cursor: ns-resize; }

@media screen and (-ms-high-contrast: active) {
  md-input-container.md-default-theme > md-icon {
    fill: #fff; } }

md-list {
  display: block;
  padding: 8px 0px 8px 0px; }
  md-list .md-subheader {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.010em;
    line-height: 1.2em; }
  md-list.md-dense md-list-item,
  md-list.md-dense md-list-item .md-list-item-inner {
    min-height: 48px; }
    md-list.md-dense md-list-item::before,
    md-list.md-dense md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item md-icon:first-child,
    md-list.md-dense md-list-item .md-list-item-inner md-icon:first-child {
      width: 20px;
      height: 20px; }
    md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 36px; }
      [dir=rtl] md-list.md-dense md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 36px; }
    md-list.md-dense md-list-item .md-avatar, md-list.md-dense md-list-item .md-avatar-icon,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
      margin-right: 20px; }
      [dir=rtl] md-list.md-dense md-list-item .md-avatar, [dir=rtl] md-list.md-dense md-list-item .md-avatar-icon, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list.md-dense md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 20px; }
    md-list.md-dense md-list-item .md-avatar,
    md-list.md-dense md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 36px;
      height: 36px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
    margin-left: 56px; }
    [dir=rtl] md-list.md-dense md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
      margin-left: auto;
      margin-right: 56px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line .md-list-item-text p, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line .md-list-item-text p, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h4,
  md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text p {
    line-height: 1.05;
    font-size: 12px; }
  md-list.md-dense md-list-item.md-2-line .md-list-item-text h3, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line .md-list-item-text h3, md-list.md-dense md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
    font-size: 13px; }
  md-list.md-dense md-list-item.md-2-line, md-list.md-dense md-list-item.md-2-line > .md-no-style {
    min-height: 60px; }
    md-list.md-dense md-list-item.md-2-line::before, md-list.md-dense md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 60px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-2-line > .md-avatar, md-list.md-dense md-list-item.md-2-line .md-avatar-icon, md-list.md-dense md-list-item.md-2-line > .md-no-style > .md-avatar, md-list.md-dense md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
  md-list.md-dense md-list-item.md-3-line, md-list.md-dense md-list-item.md-3-line > .md-no-style {
    min-height: 76px; }
    md-list.md-dense md-list-item.md-3-line::before, md-list.md-dense md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 76px;
      visibility: hidden;
      display: inline-block; }
    md-list.md-dense md-list-item.md-3-line > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-avatar, md-list.md-dense md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list.md-dense md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

md-list-item {
  position: relative; }
  md-list-item.md-proxy-focus.md-focused .md-no-style {
    transition: background-color 0.15s linear; }
  md-list-item._md-button-wrap {
    position: relative; }
    md-list-item._md-button-wrap > div.md-button:first-child {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 16px;
      margin: 0;
      font-weight: 400;
      text-align: left;
      border: medium none; }
      [dir=rtl] md-list-item._md-button-wrap > div.md-button:first-child {
        text-align: right; }
      md-list-item._md-button-wrap > div.md-button:first-child > .md-button:first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        margin: 0;
        padding: 0; }
      md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner {
        width: 100%; }
        md-list-item._md-button-wrap > div.md-button:first-child .md-list-item-inner::before {
          content: '';
          min-height: inherit;
          visibility: hidden;
          display: inline-block; }
  md-list-item.md-no-proxy,
  md-list-item .md-no-style {
    position: relative;
    padding: 0px 16px;
    flex: 1 1 auto; }
    md-list-item.md-no-proxy.md-button,
    md-list-item .md-no-style.md-button {
      font-size: inherit;
      height: inherit;
      text-align: left;
      text-transform: none;
      width: 100%;
      white-space: normal;
      flex-direction: inherit;
      align-items: inherit;
      border-radius: 0;
      margin: 0; }
      [dir=rtl] md-list-item.md-no-proxy.md-button, [dir=rtl]
      md-list-item .md-no-style.md-button {
        text-align: right; }
      md-list-item.md-no-proxy.md-button > .md-ripple-container,
      md-list-item .md-no-style.md-button > .md-ripple-container {
        border-radius: 0; }
    md-list-item.md-no-proxy:focus,
    md-list-item .md-no-style:focus {
      outline: none; }
  md-list-item.md-clickable:hover {
    cursor: pointer; }
  md-list-item md-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%; }
    [dir=rtl] md-list-item md-divider {
      left: auto;
      right: 0; }
    md-list-item md-divider[md-inset] {
      left: 72px;
      width: calc(100% - 72px);
      margin: 0 !important; }
      [dir=rtl] md-list-item md-divider[md-inset] {
        left: auto;
        right: 72px; }
  md-list-item,
  md-list-item .md-list-item-inner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 48px;
    height: auto; }
    md-list-item::before,
    md-list-item .md-list-item-inner::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    md-list-item > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item > md-icon.md-secondary:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-primary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > div.md-secondary > md-icon:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon.md-secondary:not(.md-avatar-icon) {
      width: 24px;
      margin-top: 16px;
      margin-bottom: 12px;
      box-sizing: content-box; }
    md-list-item > div.md-primary > md-checkbox,
    md-list-item > div.md-secondary > md-checkbox,
    md-list-item > md-checkbox,
    md-list-item md-checkbox.md-secondary,
    md-list-item .md-list-item-inner > div.md-primary > md-checkbox,
    md-list-item .md-list-item-inner > div.md-secondary > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox,
    md-list-item .md-list-item-inner md-checkbox.md-secondary {
      align-self: center; }
      md-list-item > div.md-primary > md-checkbox .md-label,
      md-list-item > div.md-secondary > md-checkbox .md-label,
      md-list-item > md-checkbox .md-label,
      md-list-item md-checkbox.md-secondary .md-label,
      md-list-item .md-list-item-inner > div.md-primary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > div.md-secondary > md-checkbox .md-label,
      md-list-item .md-list-item-inner > md-checkbox .md-label,
      md-list-item .md-list-item-inner md-checkbox.md-secondary .md-label {
        display: none; }
    md-list-item > md-icon:first-child:not(.md-avatar-icon),
    md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
      margin-right: 32px; }
      [dir=rtl] md-list-item > md-icon:first-child:not(.md-avatar-icon), [dir=rtl]
      md-list-item .md-list-item-inner > md-icon:first-child:not(.md-avatar-icon) {
        margin-right: auto;
        margin-left: 32px; }
    md-list-item .md-avatar, md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar,
    md-list-item .md-list-item-inner .md-avatar-icon {
      margin-top: 8px;
      margin-bottom: 8px;
      margin-right: 16px;
      border-radius: 50%;
      box-sizing: content-box; }
      [dir=rtl] md-list-item .md-avatar, [dir=rtl] md-list-item .md-avatar-icon, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar, [dir=rtl]
      md-list-item .md-list-item-inner .md-avatar-icon {
        margin-right: auto;
        margin-left: 16px; }
    md-list-item .md-avatar,
    md-list-item .md-list-item-inner .md-avatar {
      flex: none;
      width: 40px;
      height: 40px; }
    md-list-item .md-avatar-icon,
    md-list-item .md-list-item-inner .md-avatar-icon {
      padding: 8px; }
      md-list-item .md-avatar-icon svg,
      md-list-item .md-list-item-inner .md-avatar-icon svg {
        width: 24px;
        height: 24px; }
    md-list-item > md-checkbox,
    md-list-item .md-list-item-inner > md-checkbox {
      width: 24px;
      margin-left: 3px;
      margin-right: 29px;
      margin-top: 16px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-left: 29px; }
      [dir=rtl] md-list-item > md-checkbox, [dir=rtl]
      md-list-item .md-list-item-inner > md-checkbox {
        margin-right: 3px; }
    md-list-item .md-secondary-container,
    md-list-item .md-list-item-inner .md-secondary-container {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      margin: auto;
      margin-right: 0;
      margin-left: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-right: auto; }
      [dir=rtl] md-list-item .md-secondary-container, [dir=rtl]
      md-list-item .md-list-item-inner .md-secondary-container {
        margin-left: 0; }
      md-list-item .md-secondary-container .md-button:last-of-type, md-list-item .md-secondary-container .md-icon-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type,
      md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
        margin-right: 0; }
        [dir=rtl] md-list-item .md-secondary-container .md-button:last-of-type, [dir=rtl] md-list-item .md-secondary-container .md-icon-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-button:last-of-type, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container .md-icon-button:last-of-type {
          margin-right: auto;
          margin-left: 0; }
      md-list-item .md-secondary-container md-checkbox,
      md-list-item .md-list-item-inner .md-secondary-container md-checkbox {
        margin-top: 0;
        margin-bottom: 0; }
        md-list-item .md-secondary-container md-checkbox:last-child,
        md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
          width: 24px;
          margin-right: 0; }
          [dir=rtl] md-list-item .md-secondary-container md-checkbox:last-child, [dir=rtl]
          md-list-item .md-list-item-inner .md-secondary-container md-checkbox:last-child {
            margin-right: auto;
            margin-left: 0; }
      md-list-item .md-secondary-container md-switch,
      md-list-item .md-list-item-inner .md-secondary-container md-switch {
        margin-top: 0;
        margin-bottom: 0;
        margin-right: -6px; }
        [dir=rtl] md-list-item .md-secondary-container md-switch, [dir=rtl]
        md-list-item .md-list-item-inner .md-secondary-container md-switch {
          margin-right: auto;
          margin-left: -6px; }
    md-list-item > p, md-list-item > .md-list-item-inner > p,
    md-list-item .md-list-item-inner > p,
    md-list-item .md-list-item-inner > .md-list-item-inner > p {
      flex: 1 1 auto;
      margin: 0; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style, md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    align-items: flex-start;
    justify-content: center; }
    md-list-item.md-2-line.md-long-text, md-list-item.md-2-line > .md-no-style.md-long-text, md-list-item.md-3-line.md-long-text, md-list-item.md-3-line > .md-no-style.md-long-text {
      margin-top: 8px;
      margin-bottom: 8px; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text, md-list-item.md-3-line .md-list-item-text, md-list-item.md-3-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto;
      margin: auto;
      text-overflow: ellipsis;
      overflow: hidden; }
      md-list-item.md-2-line .md-list-item-text.md-offset, md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, md-list-item.md-3-line .md-list-item-text.md-offset, md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
        margin-left: 56px; }
        [dir=rtl] md-list-item.md-2-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-2-line > .md-no-style .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line .md-list-item-text.md-offset, [dir=rtl] md-list-item.md-3-line > .md-no-style .md-list-item-text.md-offset {
          margin-left: auto;
          margin-right: 56px; }
      md-list-item.md-2-line .md-list-item-text h3, md-list-item.md-2-line > .md-no-style .md-list-item-text h3, md-list-item.md-3-line .md-list-item-text h3, md-list-item.md-3-line > .md-no-style .md-list-item-text h3 {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.010em;
        margin: 0 0 0px 0;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text h4, md-list-item.md-2-line > .md-no-style .md-list-item-text h4, md-list-item.md-3-line .md-list-item-text h4, md-list-item.md-3-line > .md-no-style .md-list-item-text h4 {
        font-size: 14px;
        letter-spacing: 0.010em;
        margin: 3px 0 1px 0;
        font-weight: 400;
        line-height: 1.2em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis; }
      md-list-item.md-2-line .md-list-item-text p, md-list-item.md-2-line > .md-no-style .md-list-item-text p, md-list-item.md-3-line .md-list-item-text p, md-list-item.md-3-line > .md-no-style .md-list-item-text p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.010em;
        margin: 0 0 0 0;
        line-height: 1.6em; }
  md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style {
    height: auto;
    min-height: 72px; }
    md-list-item.md-2-line::before, md-list-item.md-2-line > .md-no-style::before {
      content: '';
      min-height: 72px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-2-line > .md-avatar, md-list-item.md-2-line .md-avatar-icon, md-list-item.md-2-line > .md-no-style > .md-avatar, md-list-item.md-2-line > .md-no-style .md-avatar-icon {
      margin-top: 12px; }
    md-list-item.md-2-line > md-icon:first-child, md-list-item.md-2-line > .md-no-style > md-icon:first-child {
      align-self: flex-start; }
    md-list-item.md-2-line .md-list-item-text, md-list-item.md-2-line > .md-no-style .md-list-item-text {
      flex: 1 1 auto; }
  md-list-item.md-3-line, md-list-item.md-3-line > .md-no-style {
    height: auto;
    min-height: 88px; }
    md-list-item.md-3-line::before, md-list-item.md-3-line > .md-no-style::before {
      content: '';
      min-height: 88px;
      visibility: hidden;
      display: inline-block; }
    md-list-item.md-3-line > md-icon:first-child,
    md-list-item.md-3-line > .md-avatar, md-list-item.md-3-line > .md-no-style > md-icon:first-child,
    md-list-item.md-3-line > .md-no-style > .md-avatar {
      margin-top: 16px; }

md-toolbar.md-menu-toolbar h2.md-toolbar-tools {
  line-height: 1rem;
  height: auto;
  padding: 28px;
  padding-bottom: 12px; }

md-toolbar.md-has-open-menu {
  position: relative;
  z-index: 100; }

md-menu-bar {
  padding: 0 20px;
  display: block;
  position: relative;
  z-index: 2; }
  md-menu-bar .md-menu {
    display: inline-block;
    padding: 0;
    position: relative; }
  md-menu-bar button {
    font-size: 14px;
    padding: 0 10px;
    margin: 0;
    border: 0;
    background-color: transparent;
    height: 40px; }
  md-menu-bar md-backdrop.md-menu-backdrop {
    z-index: -2; }

md-menu-content.md-menu-bar-menu.md-dense {
  max-height: none;
  padding: 16px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent {
    position: relative; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
      position: absolute;
      padding: 0;
      width: 24px;
      top: 6px;
      left: 24px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > md-icon {
        left: auto;
        right: 24px; }
    md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
      padding: 0 32px 0 64px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item.md-indent .md-menu > .md-button {
        padding: 0 64px 0 32px; }
  md-menu-content.md-menu-bar-menu.md-dense .md-button {
    min-height: 0;
    height: 32px; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span {
      float: left; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span {
        float: right; }
    md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
      float: right;
      margin: 0 8px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-button span.md-alt-text {
        float: left; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-divider {
    margin: 8px 0; }
  md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
    text-align: left; }
    [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense md-menu-item > .md-button, [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      text-align: right; }
  md-menu-content.md-menu-bar-menu.md-dense .md-menu {
    padding: 0; }
    md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
      position: relative;
      margin: 0;
      width: 100%;
      text-transform: none;
      font-weight: normal;
      border-radius: 0px;
      padding-left: 16px; }
      [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button {
        padding-left: 0;
        padding-right: 16px; }
      md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
        display: block;
        content: '\25BC';
        position: absolute;
        top: 0px;
        speak: none;
        transform: rotate(270deg) scaleY(0.45) scaleX(0.9);
        right: 28px; }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          transform: rotate(90deg) scaleY(0.45) scaleX(0.9); }
        [dir=rtl] md-menu-content.md-menu-bar-menu.md-dense .md-menu > .md-button:after {
          right: auto;
          left: 28px; }

.md-open-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  opacity: 0;
  border-radius: 2px;
  max-height: calc(100vh - 10px);
  overflow: auto; }
  .md-open-menu-container md-menu-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    height: 1px;
    min-height: 1px;
    max-height: 1px;
    width: 100%; }
  .md-open-menu-container md-menu-content > * {
    opacity: 0; }
  .md-open-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-open-menu-container.md-active {
    opacity: 1;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 200ms; }
    .md-open-menu-container.md-active > md-menu-content > * {
      opacity: 1;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-duration: 200ms;
      transition-delay: 100ms; }
  .md-open-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-menu-content {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  max-height: 304px;
  overflow-y: auto; }
  md-menu-content.md-dense {
    max-height: 208px; }
    md-menu-content.md-dense md-menu-item {
      height: 32px;
      min-height: 0px; }

md-menu-item {
  display: flex;
  flex-direction: row;
  min-height: 48px;
  height: 48px;
  align-content: center;
  justify-content: flex-start;
  /*
   * We cannot use flex on <button> elements due to a bug in Firefox, so we also can't use it on
   * <a> elements. Add some top padding to fix alignment since buttons automatically align their
   * text vertically.
   */ }
  md-menu-item > * {
    width: 100%;
    margin: auto 0;
    padding-left: 16px;
    padding-right: 16px; }
  md-menu-item > a.md-button {
    padding-top: 5px; }
  md-menu-item > .md-button {
    text-align: left;
    display: inline-block;
    border-radius: 0;
    margin: auto 0;
    font-size: 15px;
    text-transform: none;
    font-weight: 400;
    height: 100%;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%; }
    md-menu-item > .md-button::-moz-focus-inner {
      padding: 0;
      border: 0; }
    [dir=rtl] md-menu-item > .md-button {
      text-align: right; }
    md-menu-item > .md-button md-icon {
      margin: auto 16px auto 0; }
      [dir=rtl] md-menu-item > .md-button md-icon {
        margin: auto 0 auto 16px; }
    md-menu-item > .md-button p {
      display: inline-block;
      margin: auto; }
    md-menu-item > .md-button span {
      margin-top: auto;
      margin-bottom: auto; }
    md-menu-item > .md-button .md-ripple-container {
      border-radius: inherit; }

md-toolbar .md-menu {
  height: auto;
  margin: auto;
  padding: 0; }

@media (max-width: 959px) {
  md-menu-content {
    min-width: 112px; }
  md-menu-content[width="3"] {
    min-width: 168px; }
  md-menu-content[width="4"] {
    min-width: 224px; }
  md-menu-content[width="5"] {
    min-width: 280px; }
  md-menu-content[width="6"] {
    min-width: 336px; }
  md-menu-content[width="7"] {
    min-width: 392px; } }

@media (min-width: 960px) {
  md-menu-content {
    min-width: 96px; }
  md-menu-content[width="3"] {
    min-width: 192px; }
  md-menu-content[width="4"] {
    min-width: 256px; }
  md-menu-content[width="5"] {
    min-width: 320px; }
  md-menu-content[width="6"] {
    min-width: 384px; }
  md-menu-content[width="7"] {
    min-width: 448px; } }

/** Matches "md-tabs md-tabs-wrapper" style. */
.md-nav-bar {
  border-style: solid;
  border-width: 0 0 1px;
  height: 48px;
  position: relative; }

._md-nav-bar-list {
  outline: none;
  list-style: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row; }

.md-nav-item:first-of-type {
  margin-left: 8px; }

.md-button._md-nav-button {
  line-height: 24px;
  margin: 0 4px;
  padding: 12px 16px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
  .md-button._md-nav-button:focus {
    outline: none; }
  .md-button._md-nav-button:hover {
    background-color: inherit; }

md-nav-ink-bar {
  bottom: 0;
  height: 2px;
  left: auto;
  position: absolute;
  right: auto;
  background-color: black; }
  md-nav-ink-bar._md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar._md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }
  md-nav-ink-bar.ng-animate {
    transition: none; }

md-nav-extra-content {
  min-height: 48px;
  padding-right: 12px; }

@keyframes indeterminate-rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

md-progress-circular {
  position: relative;
  display: block; }
  md-progress-circular._md-progress-circular-disabled {
    visibility: hidden; }
  md-progress-circular.md-mode-indeterminate svg {
    animation: indeterminate-rotate 1568.63ms linear infinite; }
  md-progress-circular svg {
    position: absolute;
    overflow: visible;
    top: 0;
    left: 0; }

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: 16px;
  white-space: nowrap;
  cursor: pointer;
  position: relative; }
  md-radio-button[disabled] {
    cursor: default; }
    md-radio-button[disabled] .md-container {
      cursor: default; }
  md-radio-button .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;
    left: 0;
    right: auto; }
    [dir=rtl] md-radio-button .md-container {
      left: auto; }
    [dir=rtl] md-radio-button .md-container {
      right: 0; }
    md-radio-button .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
    md-radio-button .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
  md-radio-button.md-align-top-left > div.md-container {
    top: 12px; }
  md-radio-button .md-off {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: border-color ease 0.28s; }
  md-radio-button .md-on {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: transform ease 0.28s;
    transform: scale(0); }
  md-radio-button.md-checked .md-on {
    transform: scale(0.5); }
  md-radio-button .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    margin-left: 30px;
    margin-right: 0;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    width: auto; }
    [dir=rtl] md-radio-button .md-label {
      margin-left: 0; }
    [dir=rtl] md-radio-button .md-label {
      margin-right: 30px; }

md-radio-group {
  /** Layout adjustments for the radio group. */ }
  md-radio-group.layout-column md-radio-button, md-radio-group.layout-xs-column md-radio-button, md-radio-group.layout-gt-xs-column md-radio-button, md-radio-group.layout-sm-column md-radio-button, md-radio-group.layout-gt-sm-column md-radio-button, md-radio-group.layout-md-column md-radio-button, md-radio-group.layout-gt-md-column md-radio-button, md-radio-group.layout-lg-column md-radio-button, md-radio-group.layout-gt-lg-column md-radio-button, md-radio-group.layout-xl-column md-radio-button {
    margin-bottom: 16px; }
  md-radio-group.layout-row md-radio-button, md-radio-group.layout-xs-row md-radio-button, md-radio-group.layout-gt-xs-row md-radio-button, md-radio-group.layout-sm-row md-radio-button, md-radio-group.layout-gt-sm-row md-radio-button, md-radio-group.layout-md-row md-radio-button, md-radio-group.layout-gt-md-row md-radio-button, md-radio-group.layout-lg-row md-radio-button, md-radio-group.layout-gt-lg-row md-radio-button, md-radio-group.layout-xl-row md-radio-button {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-left: 16px; }
    [dir=rtl] md-radio-group.layout-row md-radio-button, [dir=rtl] md-radio-group.layout-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-xs-row md-radio-button, [dir=rtl] md-radio-group.layout-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-sm-row md-radio-button, [dir=rtl] md-radio-group.layout-md-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-md-row md-radio-button, [dir=rtl] md-radio-group.layout-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-gt-lg-row md-radio-button, [dir=rtl] md-radio-group.layout-xl-row md-radio-button {
      margin-right: 0; }
    md-radio-group.layout-row md-radio-button:last-of-type, md-radio-group.layout-xs-row md-radio-button:last-of-type, md-radio-group.layout-gt-xs-row md-radio-button:last-of-type, md-radio-group.layout-sm-row md-radio-button:last-of-type, md-radio-group.layout-gt-sm-row md-radio-button:last-of-type, md-radio-group.layout-md-row md-radio-button:last-of-type, md-radio-group.layout-gt-md-row md-radio-button:last-of-type, md-radio-group.layout-lg-row md-radio-button:last-of-type, md-radio-group.layout-gt-lg-row md-radio-button:last-of-type, md-radio-group.layout-xl-row md-radio-button:last-of-type {
      margin-left: 0;
      margin-right: 0; }
  md-radio-group:focus {
    outline: none; }
  md-radio-group.md-focused .md-checked .md-container:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-radio-group[disabled] md-radio-button {
    cursor: default; }
    md-radio-group[disabled] md-radio-button .md-container {
      cursor: default; }

.md-inline-form md-radio-group {
  margin: 18px 0 19px; }
  .md-inline-form md-radio-group md-radio-button {
    display: inline-block;
    height: 30px;
    padding: 2px;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0; }

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff; } }

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important; }
  md-progress-linear._md-progress-linear-disabled {
    visibility: hidden; }
  md-progress-linear .md-container {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 5px;
    transform: translate(0, 0) scale(1, 1); }
    md-progress-linear .md-container .md-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 5px; }
    md-progress-linear .md-container .md-dashed:before {
      content: "";
      display: none;
      position: absolute;
      margin-top: 0;
      height: 5px;
      width: 100%;
      background-color: transparent;
      background-size: 10px 10px !important;
      background-position: 0px -23px; }
    md-progress-linear .md-container .md-bar1, md-progress-linear .md-container .md-bar2 {
      transition: transform 0.2s linear; }
    md-progress-linear .md-container.md-mode-query .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-query .md-bar2 {
      transition: all 0.2s linear;
      animation: query 0.8s infinite cubic-bezier(0.39, 0.575, 0.565, 1); }
    md-progress-linear .md-container.md-mode-determinate .md-bar1 {
      display: none; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar1 {
      animation: md-progress-linear-indeterminate-scale-1 4s infinite, md-progress-linear-indeterminate-1 4s infinite; }
    md-progress-linear .md-container.md-mode-indeterminate .md-bar2 {
      animation: md-progress-linear-indeterminate-scale-2 4s infinite, md-progress-linear-indeterminate-2 4s infinite; }
    md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container {
      animation: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar1 {
        animation-name: none; }
      md-progress-linear .md-container.ng-hide ._md-progress-linear-disabled md-progress-linear .md-container .md-bar2 {
        animation-name: none; }
  md-progress-linear .md-container.md-mode-buffer {
    background-color: transparent !important;
    transition: all 0.2s linear; }
    md-progress-linear .md-container.md-mode-buffer .md-dashed:before {
      display: block;
      animation: buffer 3s infinite linear; }

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(0.3, 1); }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1); } }

@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1;
    background-position: -200px -23px; } }

@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear; }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1); }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -105.16667%;
    animation-timing-function: linear; }
  20% {
    left: -105.16667%;
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582); }
  69.15% {
    left: 21.5%;
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635); }
  100% {
    left: 95.44444%; } }

@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397); }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432); }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179); }
  100% {
    transform: scaleX(0.1); } }

@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -54.88889%;
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968); }
  25% {
    left: -17.25%;
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372); }
  48.35% {
    left: 29.5%;
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203); }
  100% {
    left: 117.38889%; } }

md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform-origin: left top; }
  [dir=rtl] md-input-container:not([md-no-float]) .md-select-placeholder span:first-child {
    transform-origin: right top; }

md-input-container.md-input-focused:not([md-no-float]) .md-select-placeholder span:first-child {
  transform: translateY(-22px) translateX(-2px) scale(0.75); }

.md-select-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  opacity: 0;
  display: none;
  transform: translateY(-1px); }
  .md-select-menu-container:not(.md-clickable) {
    pointer-events: none; }
  .md-select-menu-container md-progress-circular {
    display: table;
    margin: 24px auto !important; }
  .md-select-menu-container.md-active {
    display: block;
    opacity: 1; }
    .md-select-menu-container.md-active md-select-menu {
      transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-duration: 150ms; }
      .md-select-menu-container.md-active md-select-menu > * {
        opacity: 1;
        transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
        transition-duration: 150ms;
        transition-delay: 100ms; }
  .md-select-menu-container.md-leave {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 250ms; }

md-input-container > md-select {
  margin: 0;
  order: 2; }

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after, md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: ' *';
  font-size: 13px;
  vertical-align: top; }

md-input-container.md-input-invalid md-select .md-select-value {
  border-bottom-style: solid;
  padding-bottom: 1px; }

md-select {
  display: flex;
  margin: 20px 0 26px 0; }
  md-select[required].ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-empty.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
    content: ' *';
    font-size: 13px;
    vertical-align: top; }
  md-select[disabled] .md-select-value {
    background-position: 0 bottom;
    background-size: 4px 1px;
    background-repeat: repeat-x;
    margin-bottom: -1px; }
  md-select:focus {
    outline: none; }
  md-select[disabled]:hover {
    cursor: default; }
  md-select:not([disabled]):hover {
    cursor: pointer; }
  md-select:not([disabled]).ng-invalid.ng-touched .md-select-value {
    border-bottom-style: solid;
    padding-bottom: 1px; }
  md-select:not([disabled]):focus .md-select-value {
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-bottom: 0; }
  md-select:not([disabled]):focus.ng-invalid.ng-touched .md-select-value {
    padding-bottom: 0; }

md-input-container.md-input-has-value .md-select-value > span:not(.md-select-icon) {
  transform: translate3d(0, 1px, 0); }

.md-select-value {
  display: flex;
  align-items: center;
  padding: 2px 2px 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: rgba(0, 0, 0, 0);
  position: relative;
  box-sizing: content-box;
  min-width: 64px;
  min-height: 26px;
  flex-grow: 1; }
  .md-select-value > span:not(.md-select-icon) {
    max-width: 100%;
    flex: 1 1 auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    .md-select-value > span:not(.md-select-icon) .md-text {
      display: inline; }
  .md-select-value .md-select-icon {
    display: block;
    align-items: flex-end;
    text-align: end;
    width: 24px;
    margin: 0 4px;
    transform: translate3d(0, -2px, 0);
    font-size: 1.2rem; }
  .md-select-value .md-select-icon:after {
    display: block;
    content: '\25BC';
    position: relative;
    top: 2px;
    speak: none;
    font-size: 13px;
    transform: scaleY(0.5) scaleX(1); }
  .md-select-value.md-select-placeholder {
    display: flex;
    order: 1;
    pointer-events: none;
    -webkit-font-smoothing: antialiased;
    padding-left: 2px;
    z-index: 1; }

md-select-menu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  max-height: 256px;
  min-height: 48px;
  overflow-y: hidden;
  transform-origin: left top;
  transform: scale(1); }
  md-select-menu.md-reverse {
    flex-direction: column-reverse; }
  md-select-menu:not(.md-overflow) md-content {
    padding-top: 8px;
    padding-bottom: 8px; }
  [dir=rtl] md-select-menu {
    transform-origin: right top; }
  md-select-menu md-content {
    min-width: 136px;
    min-height: 48px;
    max-height: 256px;
    overflow-y: auto; }
  md-select-menu > * {
    opacity: 0; }

md-option {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
  transition: background 0.15s linear;
  padding: 0 16px 0 16px;
  height: 48px; }
  md-option[disabled] {
    cursor: default; }
  md-option:focus {
    outline: none; }
  md-option .md-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

md-optgroup {
  display: block; }
  md-optgroup label {
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    padding: 16px;
    font-weight: 500; }
  md-optgroup md-option {
    padding-left: 32px;
    padding-right: 32px; }

@media screen and (-ms-high-contrast: active) {
  .md-select-backdrop {
    background-color: transparent; }
  md-select-menu {
    border: 1px solid #fff; } }

md-select-menu[multiple] md-option.md-checkbox-enabled {
  padding-left: 40px;
  padding-right: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-left: 16px; }
  [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled {
    padding-right: 40px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: 20px;
    height: 20px;
    left: 0;
    right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      right: 0; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container:after {
      box-sizing: border-box;
      content: '';
      position: absolute;
      top: -10px;
      right: -10px;
      bottom: -10px;
      left: -10px; }
    md-select-menu[multiple] md-option.md-checkbox-enabled .md-container .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-icon {
    box-sizing: border-box;
    transition: 240ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 2px; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon {
    border-color: transparent; }
    md-select-menu[multiple] md-option.md-checkbox-enabled[selected] .md-icon:after {
      box-sizing: border-box;
      transform: rotate(45deg);
      position: absolute;
      left: 4.66667px;
      top: 0.22222px;
      display: table;
      width: 6.66667px;
      height: 13.33333px;
      border-width: 2px;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled[disabled] {
    cursor: default; }
  md-select-menu[multiple] md-option.md-checkbox-enabled.md-indeterminate .md-icon:after {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: table;
    width: 12px;
    height: 2px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    content: ''; }
  md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
    margin-left: 10.66667px;
    margin-right: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-left: auto; }
    [dir=rtl] md-select-menu[multiple] md-option.md-checkbox-enabled .md-container {
      margin-right: 10.66667px; }

md-sidenav {
  box-sizing: border-box;
  position: absolute;
  flex-direction: column;
  z-index: 60;
  width: 320px;
  max-width: 320px;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }
  md-sidenav ul {
    list-style: none; }
  md-sidenav.md-closed {
    display: none; }
  md-sidenav.md-closed-add, md-sidenav.md-closed-remove {
    display: flex;
    transition: 0.2s ease-in all; }
  md-sidenav.md-closed-add.md-closed-add-active, md-sidenav.md-closed-remove.md-closed-remove-active {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-sidenav.md-locked-open-add, md-sidenav.md-locked-open-remove {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-left, md-sidenav.md-locked-open.md-closed, md-sidenav.md-locked-open.md-closed.md-sidenav-right {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-locked-open-remove.md-closed {
    position: static;
    display: flex;
    transform: translate3d(0, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add {
    position: static;
    display: flex;
    transform: translate3d(0%, 0, 0); }
  md-sidenav.md-closed.md-locked-open-add:not(.md-locked-open-add-active) {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }
  md-sidenav.md-closed.md-locked-open-add-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-sidenav.md-locked-open-remove-active {
    transition: width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), min-width 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    width: 0 !important;
    min-width: 0 !important; }

.md-sidenav-backdrop.md-locked-open {
  display: none; }

.md-sidenav-left, md-sidenav {
  left: 0;
  top: 0;
  transform: translate3d(0%, 0, 0); }
  .md-sidenav-left.md-closed, md-sidenav.md-closed {
    transform: translate3d(-100%, 0, 0); }

.md-sidenav-right {
  left: 100%;
  top: 0;
  transform: translate(-100%, 0); }
  .md-sidenav-right.md-closed {
    transform: translate(0%, 0); }

@media (min-width: 600px) {
  md-sidenav {
    max-width: 400px; } }

@media (max-width: 456px) {
  md-sidenav {
    width: calc(100% - 56px);
    min-width: calc(100% - 56px);
    max-width: calc(100% - 56px); } }

@media screen and (-ms-high-contrast: active) {
  .md-sidenav-left, md-sidenav {
    border-right: 1px solid #fff; }
  .md-sidenav-right {
    border-left: 1px solid #fff; } }

@keyframes sliderFocusThumb {
  0% {
    transform: scale(0.7); }
  30% {
    transform: scale(1); }
  100% {
    transform: scale(0.7); } }

@keyframes sliderDiscreteFocusThumb {
  0% {
    transform: scale(0.7); }
  50% {
    transform: scale(0.8); }
  100% {
    transform: scale(0); } }

@keyframes sliderDiscreteFocusRing {
  0% {
    transform: scale(0.7);
    opacity: 0; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0); } }

md-slider {
  height: 48px;
  min-width: 128px;
  position: relative;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  display: block;
  flex-direction: row;
  /**
   * Track
   */
  /**
   * Slider thumb
   */
  /* The sign that's focused in discrete mode */
  /**
   * The border/background that comes in when focused in non-discrete mode
   */
  /* Don't animate left/right while panning */ }
  md-slider *, md-slider *:after {
    box-sizing: border-box; }
  md-slider .md-slider-wrapper {
    outline: none;
    width: 100%;
    height: 100%; }
  md-slider .md-slider-content {
    position: relative; }
  md-slider .md-track-container {
    width: 100%;
    position: absolute;
    top: 23px;
    height: 2px; }
  md-slider .md-track {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-fill {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: width, height; }
  md-slider .md-track-ticks {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%; }
  md-slider .md-track-ticks canvas {
    width: 100%;
    height: 100%; }
  md-slider .md-thumb-container {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: left, right, bottom; }
    [dir=rtl] md-slider .md-thumb-container {
      left: auto;
      right: 0; }
  md-slider .md-thumb {
    z-index: 1;
    position: absolute;
    left: -10px;
    top: 14px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    transform: scale(0.7);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
    [dir=rtl] md-slider .md-thumb {
      left: auto;
      right: -10px; }
    md-slider .md-thumb:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      border-width: 3px;
      border-style: solid;
      transition: inherit; }
  md-slider .md-sign {
    /* Center the children (slider-thumb-text) */
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: -14px;
    top: -17px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.4) translate3d(0, 67.5px, 0);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    /* The arrow pointing down under the sign */ }
    md-slider .md-sign:after {
      position: absolute;
      content: '';
      left: 0px;
      border-radius: 16px;
      top: 19px;
      border-left: 14px solid transparent;
      border-right: 14px solid transparent;
      border-top-width: 16px;
      border-top-style: solid;
      opacity: 0;
      transform: translate3d(0, -8px, 0);
      transition: all 0.2s cubic-bezier(0.35, 0, 0.25, 1); }
      [dir=rtl] md-slider .md-sign:after {
        left: auto;
        right: 0px; }
    md-slider .md-sign .md-thumb-text {
      z-index: 1;
      font-size: 12px;
      font-weight: bold; }
  md-slider .md-focus-ring {
    position: absolute;
    left: -17px;
    top: 7px;
    width: 34px;
    height: 34px;
    border-radius: 34px;
    transform: scale(0.7);
    opacity: 0;
    transition: all 0.35s cubic-bezier(0.35, 0, 0.25, 1); }
    [dir=rtl] md-slider .md-focus-ring {
      left: auto;
      right: -17px; }
  md-slider .md-disabled-thumb {
    position: absolute;
    left: -14px;
    top: 10px;
    width: 28px;
    height: 28px;
    border-radius: 28px;
    transform: scale(0.5);
    border-width: 4px;
    border-style: solid;
    display: none; }
    [dir=rtl] md-slider .md-disabled-thumb {
      left: auto;
      right: -14px; }
  md-slider.md-min .md-sign {
    opacity: 0; }
  md-slider:focus {
    outline: none; }
  md-slider.md-dragging .md-thumb-container,
  md-slider.md-dragging .md-track-fill {
    transition: none; }
  md-slider:not([md-discrete]) {
    /* Hide the sign and ticks in non-discrete mode */ }
    md-slider:not([md-discrete]) .md-track-ticks,
    md-slider:not([md-discrete]) .md-sign {
      display: none; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper .md-thumb:hover {
      transform: scale(0.8); }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
      transform: scale(1);
      opacity: 1; }
    md-slider:not([md-discrete]):not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
      animation: sliderFocusThumb 0.7s cubic-bezier(0.35, 0, 0.25, 1); }
    md-slider:not([md-discrete]):not([disabled]).md-active .md-slider-wrapper .md-thumb {
      transform: scale(1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-focus-ring {
    transform: scale(0);
    animation: sliderDiscreteFocusRing 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb {
    animation: sliderDiscreteFocusThumb 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-thumb, md-slider[md-discrete]:not([disabled]).md-active .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign,
  md-slider[md-discrete]:not([disabled]) .md-slider-wrapper.md-focused .md-sign:after, md-slider[md-discrete]:not([disabled]).md-active .md-sign,
  md-slider[md-discrete]:not([disabled]).md-active .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-discrete][disabled][readonly] .md-thumb {
    transform: scale(0); }
  md-slider[md-discrete][disabled][readonly] .md-sign,
  md-slider[md-discrete][disabled][readonly] .md-sign:after {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1); }
  md-slider[disabled] .md-track-fill {
    display: none; }
  md-slider[disabled] .md-track-ticks {
    opacity: 0; }
  md-slider[disabled]:not([readonly]) .md-sign {
    opacity: 0; }
  md-slider[disabled] .md-thumb {
    transform: scale(0.5); }
  md-slider[disabled] .md-disabled-thumb {
    display: block; }
  md-slider[md-vertical] {
    flex-direction: column;
    min-height: 128px;
    min-width: 0; }
    md-slider[md-vertical] .md-slider-wrapper {
      flex: 1;
      padding-top: 12px;
      padding-bottom: 12px;
      width: 48px;
      align-self: center;
      display: flex;
      justify-content: center; }
    md-slider[md-vertical] .md-track-container {
      height: 100%;
      width: 2px;
      top: 0;
      left: calc(50% - (2px / 2)); }
    md-slider[md-vertical] .md-thumb-container {
      top: auto;
      margin-bottom: 23px;
      left: calc(50% - 1px);
      bottom: 0; }
      md-slider[md-vertical] .md-thumb-container .md-thumb:after {
        left: 1px; }
      md-slider[md-vertical] .md-thumb-container .md-focus-ring {
        left: -16px; }
    md-slider[md-vertical] .md-track-fill {
      bottom: 0; }
    md-slider[md-vertical][md-discrete] .md-sign {
      left: -40px;
      top: 9.5px;
      transform: scale(0.4) translate3d(67.5px, 0, 0);
      /* The arrow pointing left next the sign */ }
      md-slider[md-vertical][md-discrete] .md-sign:after {
        top: 9.5px;
        left: 19px;
        border-top: 14px solid transparent;
        border-right: 0;
        border-bottom: 14px solid transparent;
        border-left-width: 16px;
        border-left-style: solid;
        opacity: 0;
        transform: translate3d(0, -8px, 0);
        transition: all 0.2s ease-in-out; }
      md-slider[md-vertical][md-discrete] .md-sign .md-thumb-text {
        z-index: 1;
        font-size: 12px;
        font-weight: bold; }
    md-slider[md-vertical][md-discrete].md-active .md-sign:after,
    md-slider[md-vertical][md-discrete] .md-focused .md-sign:after, md-slider[md-vertical][md-discrete][disabled][readonly] .md-sign:after {
      top: 0; }
    md-slider[md-vertical][disabled][readonly] .md-thumb {
      transform: scale(0); }
    md-slider[md-vertical][disabled][readonly] .md-sign,
    md-slider[md-vertical][disabled][readonly] .md-sign:after {
      opacity: 1;
      transform: translate3d(0, 0, 0) scale(1); }
  md-slider[md-invert]:not([md-vertical]) .md-track-fill {
    left: auto;
    right: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      left: 0; }
    [dir=rtl] md-slider[md-invert]:not([md-vertical]) .md-track-fill {
      right: auto; }
  md-slider[md-invert][md-vertical] .md-track-fill {
    bottom: auto;
    top: 0; }

md-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row; }
  md-slider-container > *:first-child:not(md-slider),
  md-slider-container > *:last-child:not(md-slider) {
    min-width: 25px;
    max-width: 42px;
    height: 25px;
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: color, max-width; }
  md-slider-container > *:first-child:not(md-slider) {
    margin-right: 16px; }
    [dir=rtl] md-slider-container > *:first-child:not(md-slider) {
      margin-right: auto;
      margin-left: 16px; }
  md-slider-container > *:last-child:not(md-slider) {
    margin-left: 16px; }
    [dir=rtl] md-slider-container > *:last-child:not(md-slider) {
      margin-left: auto;
      margin-right: 16px; }
  md-slider-container[md-vertical] {
    flex-direction: column; }
    md-slider-container[md-vertical] > *:first-child:not(md-slider),
    md-slider-container[md-vertical] > *:last-child:not(md-slider) {
      margin-right: 0;
      margin-left: 0;
      text-align: center; }
  md-slider-container md-input-container input[type="number"] {
    text-align: center;
    padding-left: 15px;
    height: 50px;
    margin-top: -25px; }
    [dir=rtl] md-slider-container md-input-container input[type="number"] {
      padding-left: 0;
      padding-right: 15px; }

@media screen and (-ms-high-contrast: active) {
  md-slider.md-default-theme .md-track {
    border-bottom: 1px solid #fff; } }

.md-sticky-clone {
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  position: absolute !important;
  transform: translate3d(-9999px, -9999px, 0); }
  .md-sticky-clone[sticky-state="active"] {
    transform: translate3d(0, 0, 0); }
    .md-sticky-clone[sticky-state="active"]:not(.md-sticky-no-effect) .md-subheader-inner {
      animation: subheaderStickyHoverIn 0.3s ease-out both; }

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent; }
  100% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); } }

@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.16); }
  100% {
    box-shadow: 0 0 0 0 transparent; } }

.md-subheader-wrapper:not(.md-sticky-no-effect) {
  transition: 0.2s ease-out margin; }
  .md-subheader-wrapper:not(.md-sticky-no-effect) .md-subheader {
    margin: 0; }
  .md-subheader-wrapper:not(.md-sticky-no-effect).md-sticky-clone {
    z-index: 2; }
  .md-subheader-wrapper:not(.md-sticky-no-effect)[sticky-state="active"] {
    margin-top: -2px; }
  .md-subheader-wrapper:not(.md-sticky-no-effect):not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
    animation: subheaderStickyHoverOut 0.3s ease-out both; }

.md-subheader {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  margin: 0 0 0 0;
  position: relative; }
  .md-subheader .md-subheader-inner {
    display: block;
    padding: 16px; }
  .md-subheader .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative; }

[md-swipe-left], [md-swipe-right] {
  touch-action: pan-y; }

[md-swipe-up], [md-swipe-down] {
  touch-action: pan-x; }

.md-inline-form md-switch {
  margin-top: 18px;
  margin-bottom: 19px; }

md-switch {
  margin: 16px 0;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  height: 30px;
  line-height: 28px;
  align-items: center;
  display: flex;
  margin-left: inherit;
  margin-right: 16px; }
  [dir=rtl] md-switch {
    margin-left: 16px; }
  [dir=rtl] md-switch {
    margin-right: inherit; }
  md-switch:last-of-type {
    margin-left: inherit;
    margin-right: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-left: 0; }
    [dir=rtl] md-switch:last-of-type {
      margin-right: inherit; }
  md-switch[disabled] {
    cursor: default; }
    md-switch[disabled] .md-container {
      cursor: default; }
  md-switch .md-container {
    cursor: grab;
    width: 36px;
    height: 24px;
    position: relative;
    user-select: none;
    margin-right: 8px;
    float: left; }
    [dir=rtl] md-switch .md-container {
      margin-right: 0px;
      margin-left: 8px; }
  md-switch.md-inverted .md-container {
    margin-right: initial;
    margin-left: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-right: 8px; }
    [dir=rtl] md-switch.md-inverted .md-container {
      margin-left: initial; }
  md-switch:not([disabled]) .md-dragging,
  md-switch:not([disabled]).md-dragging .md-container {
    cursor: grabbing; }
  md-switch.md-focused .md-thumb:before {
    left: -8px;
    top: -8px;
    right: -8px;
    bottom: -8px; }
  md-switch .md-label {
    border-color: transparent;
    border-width: 0;
    float: left; }
  md-switch .md-bar {
    left: 1px;
    width: 34px;
    top: 5px;
    height: 14px;
    border-radius: 8px;
    position: absolute; }
  md-switch .md-thumb-container {
    top: 2px;
    left: 0;
    width: 16px;
    position: absolute;
    transform: translate3d(0, 0, 0);
    z-index: 1; }
  md-switch.md-checked .md-thumb-container {
    transform: translate3d(100%, 0, 0); }
  md-switch .md-thumb {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    outline: none;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
    md-switch .md-thumb:before {
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto; }
    md-switch .md-thumb .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -20px;
      top: -20px;
      right: -20px;
      bottom: -20px; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb-container,
  md-switch:not(.md-dragging) .md-thumb {
    transition: all 0.08s linear;
    transition-property: transform, background-color; }
  md-switch:not(.md-dragging) .md-bar,
  md-switch:not(.md-dragging) .md-thumb {
    transition-delay: 0.05s; }

@media screen and (-ms-high-contrast: active) {
  md-switch.md-default-theme .md-bar {
    background-color: #666; }
  md-switch.md-default-theme.md-checked .md-bar {
    background-color: #9E9E9E; }
  md-switch.md-default-theme .md-thumb {
    background-color: #fff; } }

@keyframes md-tab-content-hide {
  0% {
    opacity: 1; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0; } }

md-tab-data {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0; }

md-tabs {
  display: block;
  margin: 0;
  border-radius: 2px;
  overflow: hidden;
  position: relative;
  flex-shrink: 0; }
  md-tabs:not(.md-no-tab-content):not(.md-dynamic-height) {
    min-height: 248px; }
  md-tabs[md-align-tabs="bottom"] {
    padding-bottom: 48px; }
    md-tabs[md-align-tabs="bottom"] md-tabs-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 2; }
    md-tabs[md-align-tabs="bottom"] md-tabs-content-wrapper {
      top: 0;
      bottom: 48px; }
  md-tabs.md-dynamic-height md-tabs-content-wrapper {
    min-height: 0;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    overflow: visible; }
  md-tabs.md-dynamic-height md-tab-content.md-active {
    position: relative; }
  md-tabs[md-border-bottom] md-tabs-wrapper {
    border-width: 0 0 1px;
    border-style: solid; }
  md-tabs[md-border-bottom]:not(.md-dynamic-height) md-tabs-content-wrapper {
    top: 49px; }

md-tabs-wrapper {
  display: block;
  position: relative;
  transform: translate(0, 0); }
  md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
    height: 100%;
    width: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1em;
    z-index: 2;
    cursor: pointer;
    font-size: 16px;
    background: transparent no-repeat center center;
    transition: all 0.5s cubic-bezier(0.35, 0, 0.25, 1); }
    md-tabs-wrapper md-prev-button:focus, md-tabs-wrapper md-next-button:focus {
      outline: none; }
    md-tabs-wrapper md-prev-button.md-disabled, md-tabs-wrapper md-next-button.md-disabled {
      opacity: 0.25;
      cursor: default; }
    md-tabs-wrapper md-prev-button.ng-leave, md-tabs-wrapper md-next-button.ng-leave {
      transition: none; }
    md-tabs-wrapper md-prev-button md-icon, md-tabs-wrapper md-next-button md-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    [dir="rtl"] md-tabs-wrapper md-prev-button, [dir="rtl"] md-tabs-wrapper md-next-button {
      transform: rotateY(180deg) translateY(-50%); }
  md-tabs-wrapper md-prev-button {
    left: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMjA4IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTUuNCw3LjQgMTQsNiA4LDEyIDE0LDE4IDE1LjQsMTYuNiAxMC44LDEyIAkJIiBzdHlsZT0iZmlsbDp3aGl0ZTsiLz4gPHJlY3QgZmlsbD0ibm9uZSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ii8+IDwvZz4gPC9nPiA8ZyBpZD0iR3JpZCIgZGlzcGxheT0ibm9uZSI+IDxnIGRpc3BsYXk9ImlubGluZSI+IDwvZz4gPC9nPiA8L3N2Zz4NCg=="); }
    [dir=rtl] md-tabs-wrapper md-prev-button {
      left: auto;
      right: 0; }
  md-tabs-wrapper md-next-button {
    right: 0;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE3LjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPiA8IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPiA8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjQgMjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPiA8ZyBpZD0iSGVhZGVyIj4gPGc+IDxyZWN0IHg9Ii02MTgiIHk9Ii0xMzM2IiBmaWxsPSJub25lIiB3aWR0aD0iMTQwMCIgaGVpZ2h0PSIzNjAwIi8+IDwvZz4gPC9nPiA8ZyBpZD0iTGFiZWwiPiA8L2c+IDxnIGlkPSJJY29uIj4gPGc+IDxwb2x5Z29uIHBvaW50cz0iMTAsNiA4LjYsNy40IDEzLjIsMTIgOC42LDE2LjYgMTAsMTggMTYsMTIgCQkiIHN0eWxlPSJmaWxsOndoaXRlOyIvPiA8cmVjdCBmaWxsPSJub25lIiB3aWR0aD0iMjQiIGhlaWdodD0iMjQiLz4gPC9nPiA8L2c+IDxnIGlkPSJHcmlkIiBkaXNwbGF5PSJub25lIj4gPGcgZGlzcGxheT0iaW5saW5lIj4gPC9nPiA8L2c+IDwvc3ZnPg0K"); }
    [dir=rtl] md-tabs-wrapper md-next-button {
      right: auto;
      left: 0; }
    md-tabs-wrapper md-next-button md-icon {
      transform: translate(-50%, -50%) rotate(180deg); }
  md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper {
    width: 100%;
    flex-direction: row; }
    md-tabs-wrapper.md-stretch-tabs md-pagination-wrapper md-tab-item {
      flex-grow: 1; }

md-tabs-canvas {
  position: relative;
  overflow: hidden;
  display: block;
  height: 48px; }
  md-tabs-canvas:after {
    content: '';
    display: table;
    clear: both; }
  md-tabs-canvas .md-dummy-wrapper {
    position: absolute;
    top: 0;
    left: 0; }
    [dir=rtl] md-tabs-canvas .md-dummy-wrapper {
      left: auto;
      right: 0; }
  md-tabs-canvas.md-paginated {
    margin: 0 32px; }
  md-tabs-canvas.md-center-tabs {
    display: flex;
    flex-direction: column;
    text-align: center; }
    md-tabs-canvas.md-center-tabs .md-tab {
      float: none;
      display: inline-block; }

md-pagination-wrapper {
  height: 48px;
  display: flex;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  position: absolute;
  left: 0;
  transform: translate(0, 0); }
  md-pagination-wrapper:after {
    content: '';
    display: table;
    clear: both; }
  [dir=rtl] md-pagination-wrapper {
    left: auto;
    right: 0; }
  md-pagination-wrapper.md-center-tabs {
    position: relative;
    justify-content: center; }

md-tabs-content-wrapper {
  display: block;
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

md-tab-content {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
  overflow: auto;
  transform: translate(0, 0); }
  md-tab-content.md-no-scroll {
    bottom: auto;
    overflow: hidden; }
  md-tab-content.ng-leave, md-tab-content.md-no-transition {
    transition: none; }
  md-tab-content.md-left:not(.md-active) {
    transform: translateX(-100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-left:not(.md-active) {
      transform: translateX(100%); }
    md-tab-content.md-left:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content.md-right:not(.md-active) {
    transform: translateX(100%);
    animation: 1s md-tab-content-hide;
    visibility: hidden; }
    [dir=rtl] md-tab-content.md-right:not(.md-active) {
      transform: translateX(-100%); }
    md-tab-content.md-right:not(.md-active) * {
      transition: visibility 0s linear;
      transition-delay: 0.5s;
      visibility: hidden; }
  md-tab-content > div {
    flex: 1 0 100%;
    min-width: 0; }
    md-tab-content > div.ng-leave {
      animation: 1s md-tab-content-hide; }

md-ink-bar {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 0;
  height: 2px; }
  md-ink-bar.md-left {
    transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1); }
  md-ink-bar.md-right {
    transition: left 0.25s cubic-bezier(0.35, 0, 0.25, 1), right 0.125s cubic-bezier(0.35, 0, 0.25, 1); }

md-tab {
  position: absolute;
  z-index: -1;
  left: -9999px; }

.md-tab {
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  padding: 12px 24px;
  transition: background-color 0.35s cubic-bezier(0.35, 0, 0.25, 1);
  cursor: pointer;
  white-space: nowrap;
  position: relative;
  text-transform: uppercase;
  float: left;
  font-weight: 500;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis; }
  [dir=rtl] .md-tab {
    float: right; }
  .md-tab.md-focused, .md-tab:focus {
    box-shadow: none;
    outline: none; }
  .md-tab.md-active {
    cursor: default; }
  .md-tab.md-disabled {
    pointer-events: none;
    touch-action: pan-y;
    user-select: none;
    -webkit-user-drag: none;
    opacity: 0.5;
    cursor: default; }
  .md-tab.ng-leave {
    transition: none; }

md-toolbar + md-tabs, md-toolbar + md-dialog-content md-tabs {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.md-toast-text {
  padding: 0 6px; }

md-toast {
  position: absolute;
  z-index: 105;
  box-sizing: border-box;
  cursor: default;
  overflow: hidden;
  padding: 8px;
  opacity: 1;
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  /* Transition differently when swiping */
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
  md-toast .md-toast-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-height: 168px;
    max-width: 100%;
    min-height: 48px;
    padding: 0 18px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    font-size: 14px;
    overflow: hidden;
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    justify-content: flex-start; }
    md-toast .md-toast-content::before {
      content: '';
      min-height: 48px;
      visibility: hidden;
      display: inline-block; }
    [dir=rtl] md-toast .md-toast-content {
      justify-content: flex-end; }
    md-toast .md-toast-content span {
      flex: 1 1 0%;
      box-sizing: border-box;
      min-width: 0; }
  md-toast.md-capsule {
    border-radius: 24px; }
    md-toast.md-capsule .md-toast-content {
      border-radius: 24px; }
  md-toast.ng-leave-active .md-toast-content {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2); }
  md-toast.md-swipeleft .md-toast-content, md-toast.md-swiperight .md-toast-content, md-toast.md-swipeup .md-toast-content, md-toast.md-swipedown .md-toast-content {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  md-toast.ng-enter {
    opacity: 0; }
    md-toast.ng-enter .md-toast-content {
      transform: translate3d(0, 100%, 0); }
    md-toast.ng-enter.md-top .md-toast-content {
      transform: translate3d(0, -100%, 0); }
    md-toast.ng-enter.ng-enter-active {
      opacity: 1; }
      md-toast.ng-enter.ng-enter-active .md-toast-content {
        transform: translate3d(0, 0, 0); }
  md-toast.ng-leave.ng-leave-active .md-toast-content {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
    transform: translate3d(0, -50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
    transform: translate3d(0, 50%, 0); }
  md-toast.ng-leave.ng-leave-active.md-top .md-toast-content {
    transform: translate3d(0, -100%, 0); }
  md-toast .md-action {
    line-height: 19px;
    margin-left: 24px;
    margin-right: 0;
    cursor: pointer;
    text-transform: uppercase;
    float: right; }
  md-toast .md-button {
    min-width: 0;
    margin-right: 0;
    margin-left: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-right: 12px; }
    [dir=rtl] md-toast .md-button {
      margin-left: 0; }

@media (max-width: 959px) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeup .md-toast-content {
      transform: translate3d(0, -50%, 0); }
    md-toast.ng-leave.ng-leave-active.md-swipedown .md-toast-content {
      transform: translate3d(0, 50%, 0); } }

@media (min-width: 960px) {
  md-toast {
    min-width: 304px;
    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */ }
    md-toast.md-bottom {
      bottom: 0; }
    md-toast.md-left {
      left: 0; }
    md-toast.md-right {
      right: 0; }
    md-toast.md-top {
      top: 0; }
    md-toast._md-start {
      left: 0; }
      [dir=rtl] md-toast._md-start {
        left: auto;
        right: 0; }
    md-toast._md-end {
      right: 0; }
      [dir=rtl] md-toast._md-end {
        right: auto;
        left: 0; }
    md-toast.ng-leave.ng-leave-active.md-swipeleft .md-toast-content {
      transform: translate3d(-50%, 0, 0); }
    md-toast.ng-leave.ng-leave-active.md-swiperight .md-toast-content {
      transform: translate3d(50%, 0, 0); } }

@media (min-width: 1920px) {
  md-toast .md-toast-content {
    max-width: 568px; } }

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff; } }

.md-toast-animating {
  overflow: hidden !important; }

md-toolbar {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  font-size: 20px;
  min-height: 64px;
  width: 100%; }
  md-toolbar._md-toolbar-transitions {
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    transition-property: background-color, fill, color; }
  md-toolbar.md-whiteframe-z1-add, md-toolbar.md-whiteframe-z1-remove {
    transition: box-shadow 0.5s linear; }
  md-toolbar md-toolbar-filler {
    width: 72px; }
  md-toolbar *,
  md-toolbar *:before,
  md-toolbar *:after {
    box-sizing: border-box; }
  md-toolbar.ng-animate {
    transition: none; }
  md-toolbar.md-tall {
    height: 128px;
    min-height: 128px;
    max-height: 128px; }
  md-toolbar.md-medium-tall {
    height: 88px;
    min-height: 88px;
    max-height: 88px; }
    md-toolbar.md-medium-tall .md-toolbar-tools {
      height: 48px;
      min-height: 48px;
      max-height: 48px; }
  md-toolbar > .md-indent {
    margin-left: 64px; }
    [dir=rtl] md-toolbar > .md-indent {
      margin-left: auto;
      margin-right: 64px; }
  md-toolbar ~ md-content > md-list {
    padding: 0; }
    md-toolbar ~ md-content > md-list md-list-item:last-child md-divider {
      display: none; }

.md-toolbar-tools {
  font-size: 20px;
  letter-spacing: 0.005em;
  box-sizing: border-box;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 64px;
  max-height: 64px;
  padding: 0 16px;
  margin: 0; }
  .md-toolbar-tools h1, .md-toolbar-tools h2, .md-toolbar-tools h3 {
    font-size: inherit;
    font-weight: inherit;
    margin: inherit; }
  .md-toolbar-tools a {
    color: inherit;
    text-decoration: none; }
  .md-toolbar-tools .fill-height {
    display: flex;
    align-items: center; }
  .md-toolbar-tools md-checkbox {
    margin: inherit; }
  .md-toolbar-tools .md-button {
    margin-top: 0;
    margin-bottom: 0; }
    .md-toolbar-tools .md-button, .md-toolbar-tools .md-button.md-icon-button md-icon {
      transition-duration: 0.5s;
      transition-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
      transition-property: background-color, fill, color; }
      .md-toolbar-tools .md-button.ng-animate, .md-toolbar-tools .md-button.md-icon-button md-icon.ng-animate {
        transition: none; }
  .md-toolbar-tools > .md-button:first-child {
    margin-left: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:first-child {
      margin-left: auto;
      margin-right: -8px; }
  .md-toolbar-tools > .md-button:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > .md-button:last-child {
      margin-right: auto;
      margin-left: -8px; }
  .md-toolbar-tools > md-menu:last-child {
    margin-right: -8px; }
    [dir=rtl] .md-toolbar-tools > md-menu:last-child {
      margin-right: auto;
      margin-left: -8px; }
    .md-toolbar-tools > md-menu:last-child > .md-button {
      margin-right: 0; }
      [dir=rtl] .md-toolbar-tools > md-menu:last-child > .md-button {
        margin-right: auto;
        margin-left: 0; }
  @media screen and (-ms-high-contrast: active) {
    .md-toolbar-tools {
      border-bottom: 1px solid #fff; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: portrait) {
  md-toolbar {
    min-height: 56px; }
  .md-toolbar-tools {
    height: 56px;
    max-height: 56px; } }

@media (min-width: 0) and (max-width: 959px) and (orientation: landscape) {
  md-toolbar {
    min-height: 48px; }
  .md-toolbar-tools {
    height: 48px;
    max-height: 48px; } }

.md-tooltip {
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 32px;
  line-height: 32px;
  padding-right: 16px;
  padding-left: 16px; }
  .md-tooltip.md-origin-top {
    transform-origin: center bottom;
    margin-top: -24px; }
  .md-tooltip.md-origin-right {
    transform-origin: left center;
    margin-left: 24px; }
  .md-tooltip.md-origin-bottom {
    transform-origin: center top;
    margin-top: 24px; }
  .md-tooltip.md-origin-left {
    transform-origin: right center;
    margin-left: -24px; }
  @media (min-width: 960px) {
    .md-tooltip {
      font-size: 10px;
      height: 22px;
      line-height: 22px;
      padding-right: 8px;
      padding-left: 8px; }
      .md-tooltip.md-origin-top {
        margin-top: -14px; }
      .md-tooltip.md-origin-right {
        margin-left: 14px; }
      .md-tooltip.md-origin-bottom {
        margin-top: 14px; }
      .md-tooltip.md-origin-left {
        margin-left: -14px; } }
  .md-tooltip.md-show-add {
    transform: scale(0); }
  .md-tooltip.md-show {
    transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 150ms;
    transform: scale(1);
    opacity: 0.9; }
  .md-tooltip.md-hide {
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-duration: 150ms;
    transform: scale(0);
    opacity: 0; }

.md-truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .md-truncate.md-clip {
    text-overflow: clip; }
  .md-truncate.flex {
    width: 0; }

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative; }
  .md-virtual-repeat-container .md-virtual-repeat-scroller {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-overflow-scrolling: touch; }
  .md-virtual-repeat-container .md-virtual-repeat-sizer {
    box-sizing: border-box;
    height: 1px;
    display: block;
    margin: 0;
    padding: 0;
    width: 1px; }
  .md-virtual-repeat-container .md-virtual-repeat-offsetter {
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-scroller {
  overflow-x: auto;
  overflow-y: hidden; }

.md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
  bottom: 16px;
  right: auto;
  white-space: nowrap; }
  [dir=rtl] .md-virtual-repeat-container.md-orient-horizontal .md-virtual-repeat-offsetter {
    right: auto;
    left: auto; }

.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-2dp {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-3dp {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-4dp, .md-whiteframe-z2 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-5dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-6dp {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.md-whiteframe-8dp {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-9dp {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-11dp {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.md-whiteframe-12dp {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-13dp, .md-whiteframe-z5 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-14dp {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.md-whiteframe-15dp {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-16dp {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-17dp {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.md-whiteframe-18dp {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-19dp {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.md-whiteframe-20dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-21dp {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-22dp {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.md-whiteframe-23dp {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.md-whiteframe-24dp {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff; } }

@media print {
  md-whiteframe, [md-whiteframe] {
    background-color: #ffffff; } }

/*
* Since Layout API uses ng-cloak to hide the dom elements while layouts are adjusted
*/
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/*
*  Responsive attributes
*
*  References:
*  1) https://scotch.io/tutorials/a-visual-guide-to-css3-flexbox-properties#flex
*  2) https://css-tricks.com/almanac/properties/f/flex/
*  3) https://css-tricks.com/snippets/css/a-guide-to-flexbox/
*  4) https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
*  5) http://godban.com.ua/projects/flexgrid
*/
@-moz-document url-prefix() {
  .layout-fill {
    margin: 0;
    width: 100%;
    min-height: 100%;
    height: 100%; } }

/*
 *  Apply Mixins to create Layout/Flexbox styles
 */
.flex-order {
  order: 0; }

.flex-order--20 {
  order: -20; }

.flex-order--19 {
  order: -19; }

.flex-order--18 {
  order: -18; }

.flex-order--17 {
  order: -17; }

.flex-order--16 {
  order: -16; }

.flex-order--15 {
  order: -15; }

.flex-order--14 {
  order: -14; }

.flex-order--13 {
  order: -13; }

.flex-order--12 {
  order: -12; }

.flex-order--11 {
  order: -11; }

.flex-order--10 {
  order: -10; }

.flex-order--9 {
  order: -9; }

.flex-order--8 {
  order: -8; }

.flex-order--7 {
  order: -7; }

.flex-order--6 {
  order: -6; }

.flex-order--5 {
  order: -5; }

.flex-order--4 {
  order: -4; }

.flex-order--3 {
  order: -3; }

.flex-order--2 {
  order: -2; }

.flex-order--1 {
  order: -1; }

.flex-order-0 {
  order: 0; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

.flex-order-4 {
  order: 4; }

.flex-order-5 {
  order: 5; }

.flex-order-6 {
  order: 6; }

.flex-order-7 {
  order: 7; }

.flex-order-8 {
  order: 8; }

.flex-order-9 {
  order: 9; }

.flex-order-10 {
  order: 10; }

.flex-order-11 {
  order: 11; }

.flex-order-12 {
  order: 12; }

.flex-order-13 {
  order: 13; }

.flex-order-14 {
  order: 14; }

.flex-order-15 {
  order: 15; }

.flex-order-16 {
  order: 16; }

.flex-order-17 {
  order: 17; }

.flex-order-18 {
  order: 18; }

.flex-order-19 {
  order: 19; }

.flex-order-20 {
  order: 20; }

.offset-0, .flex-offset-0, .layout-margin .flex-offset-0, .layout-margin .offset-0 {
  margin-left: 0; }
  [dir=rtl] .offset-0, [dir=rtl] .flex-offset-0, [dir=rtl] .layout-margin .flex-offset-0, [dir=rtl] .layout-margin .offset-0 {
    margin-left: auto;
    margin-right: 0; }

.offset-5, .flex-offset-5, .layout-margin .flex-offset-5, .layout-margin .offset-5 {
  margin-left: 5%; }
  [dir=rtl] .offset-5, [dir=rtl] .flex-offset-5, [dir=rtl] .layout-margin .flex-offset-5, [dir=rtl] .layout-margin .offset-5 {
    margin-left: auto;
    margin-right: 5%; }

.offset-10, .flex-offset-10, .layout-margin .flex-offset-10, .layout-margin .offset-10 {
  margin-left: 10%; }
  [dir=rtl] .offset-10, [dir=rtl] .flex-offset-10, [dir=rtl] .layout-margin .flex-offset-10, [dir=rtl] .layout-margin .offset-10 {
    margin-left: auto;
    margin-right: 10%; }

.offset-15, .flex-offset-15, .layout-margin .flex-offset-15, .layout-margin .offset-15 {
  margin-left: 15%; }
  [dir=rtl] .offset-15, [dir=rtl] .flex-offset-15, [dir=rtl] .layout-margin .flex-offset-15, [dir=rtl] .layout-margin .offset-15 {
    margin-left: auto;
    margin-right: 15%; }

.offset-20, .flex-offset-20, .layout-margin .flex-offset-20, .layout-margin .offset-20 {
  margin-left: 20%; }
  [dir=rtl] .offset-20, [dir=rtl] .flex-offset-20, [dir=rtl] .layout-margin .flex-offset-20, [dir=rtl] .layout-margin .offset-20 {
    margin-left: auto;
    margin-right: 20%; }

.offset-25, .flex-offset-25, .layout-margin .flex-offset-25, .layout-margin .offset-25 {
  margin-left: 25%; }
  [dir=rtl] .offset-25, [dir=rtl] .flex-offset-25, [dir=rtl] .layout-margin .flex-offset-25, [dir=rtl] .layout-margin .offset-25 {
    margin-left: auto;
    margin-right: 25%; }

.offset-30, .flex-offset-30, .layout-margin .flex-offset-30, .layout-margin .offset-30 {
  margin-left: 30%; }
  [dir=rtl] .offset-30, [dir=rtl] .flex-offset-30, [dir=rtl] .layout-margin .flex-offset-30, [dir=rtl] .layout-margin .offset-30 {
    margin-left: auto;
    margin-right: 30%; }

.offset-35, .flex-offset-35, .layout-margin .flex-offset-35, .layout-margin .offset-35 {
  margin-left: 35%; }
  [dir=rtl] .offset-35, [dir=rtl] .flex-offset-35, [dir=rtl] .layout-margin .flex-offset-35, [dir=rtl] .layout-margin .offset-35 {
    margin-left: auto;
    margin-right: 35%; }

.offset-40, .flex-offset-40, .layout-margin .flex-offset-40, .layout-margin .offset-40 {
  margin-left: 40%; }
  [dir=rtl] .offset-40, [dir=rtl] .flex-offset-40, [dir=rtl] .layout-margin .flex-offset-40, [dir=rtl] .layout-margin .offset-40 {
    margin-left: auto;
    margin-right: 40%; }

.offset-45, .flex-offset-45, .layout-margin .flex-offset-45, .layout-margin .offset-45 {
  margin-left: 45%; }
  [dir=rtl] .offset-45, [dir=rtl] .flex-offset-45, [dir=rtl] .layout-margin .flex-offset-45, [dir=rtl] .layout-margin .offset-45 {
    margin-left: auto;
    margin-right: 45%; }

.offset-50, .flex-offset-50, .layout-margin .flex-offset-50, .layout-margin .offset-50 {
  margin-left: 50%; }
  [dir=rtl] .offset-50, [dir=rtl] .flex-offset-50, [dir=rtl] .layout-margin .flex-offset-50, [dir=rtl] .layout-margin .offset-50 {
    margin-left: auto;
    margin-right: 50%; }

.offset-55, .flex-offset-55, .layout-margin .flex-offset-55, .layout-margin .offset-55 {
  margin-left: 55%; }
  [dir=rtl] .offset-55, [dir=rtl] .flex-offset-55, [dir=rtl] .layout-margin .flex-offset-55, [dir=rtl] .layout-margin .offset-55 {
    margin-left: auto;
    margin-right: 55%; }

.offset-60, .flex-offset-60, .layout-margin .flex-offset-60, .layout-margin .offset-60 {
  margin-left: 60%; }
  [dir=rtl] .offset-60, [dir=rtl] .flex-offset-60, [dir=rtl] .layout-margin .flex-offset-60, [dir=rtl] .layout-margin .offset-60 {
    margin-left: auto;
    margin-right: 60%; }

.offset-65, .flex-offset-65, .layout-margin .flex-offset-65, .layout-margin .offset-65 {
  margin-left: 65%; }
  [dir=rtl] .offset-65, [dir=rtl] .flex-offset-65, [dir=rtl] .layout-margin .flex-offset-65, [dir=rtl] .layout-margin .offset-65 {
    margin-left: auto;
    margin-right: 65%; }

.offset-70, .flex-offset-70, .layout-margin .flex-offset-70, .layout-margin .offset-70 {
  margin-left: 70%; }
  [dir=rtl] .offset-70, [dir=rtl] .flex-offset-70, [dir=rtl] .layout-margin .flex-offset-70, [dir=rtl] .layout-margin .offset-70 {
    margin-left: auto;
    margin-right: 70%; }

.offset-75, .flex-offset-75, .layout-margin .flex-offset-75, .layout-margin .offset-75 {
  margin-left: 75%; }
  [dir=rtl] .offset-75, [dir=rtl] .flex-offset-75, [dir=rtl] .layout-margin .flex-offset-75, [dir=rtl] .layout-margin .offset-75 {
    margin-left: auto;
    margin-right: 75%; }

.offset-80, .flex-offset-80, .layout-margin .flex-offset-80, .layout-margin .offset-80 {
  margin-left: 80%; }
  [dir=rtl] .offset-80, [dir=rtl] .flex-offset-80, [dir=rtl] .layout-margin .flex-offset-80, [dir=rtl] .layout-margin .offset-80 {
    margin-left: auto;
    margin-right: 80%; }

.offset-85, .flex-offset-85, .layout-margin .flex-offset-85, .layout-margin .offset-85 {
  margin-left: 85%; }
  [dir=rtl] .offset-85, [dir=rtl] .flex-offset-85, [dir=rtl] .layout-margin .flex-offset-85, [dir=rtl] .layout-margin .offset-85 {
    margin-left: auto;
    margin-right: 85%; }

.offset-90, .flex-offset-90, .layout-margin .flex-offset-90, .layout-margin .offset-90 {
  margin-left: 90%; }
  [dir=rtl] .offset-90, [dir=rtl] .flex-offset-90, [dir=rtl] .layout-margin .flex-offset-90, [dir=rtl] .layout-margin .offset-90 {
    margin-left: auto;
    margin-right: 90%; }

.offset-95, .flex-offset-95, .layout-margin .flex-offset-95, .layout-margin .offset-95 {
  margin-left: 95%; }
  [dir=rtl] .offset-95, [dir=rtl] .flex-offset-95, [dir=rtl] .layout-margin .flex-offset-95, [dir=rtl] .layout-margin .offset-95 {
    margin-left: auto;
    margin-right: 95%; }

.offset-33, .flex-offset-33, .layout-margin .flex-offset-33, .layout-margin .offset-33 {
  margin-left: calc(100% / 3); }

.offset-66, .flex-offset-66, .layout-margin .flex-offset-66, .layout-margin .offset-66 {
  margin-left: calc(200% / 3); }
  [dir=rtl] .offset-66, [dir=rtl] .flex-offset-66, [dir=rtl] .layout-margin .flex-offset-66, [dir=rtl] .layout-margin .offset-66 {
    margin-left: auto;
    margin-right: calc(200% / 3); }

.layout-align,
.layout-align-start-stretch {
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch; }

.layout-align-start,
.layout-align-start-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-stretch {
  justify-content: flex-start; }

.layout-align-center,
.layout-align-center-start,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-stretch {
  justify-content: center; }

.layout-align-end,
.layout-align-end-start,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-stretch {
  justify-content: flex-end; }

.layout-align-space-around,
.layout-align-space-around-center,
.layout-align-space-around-start,
.layout-align-space-around-end,
.layout-align-space-around-stretch {
  justify-content: space-around; }

.layout-align-space-between,
.layout-align-space-between-center,
.layout-align-space-between-start,
.layout-align-space-between-end,
.layout-align-space-between-stretch {
  justify-content: space-between; }

.layout-align-start-start,
.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-between-start,
.layout-align-space-around-start {
  align-items: flex-start;
  align-content: flex-start; }

.layout-align-start-center,
.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-between-center,
.layout-align-space-around-center {
  align-items: center;
  align-content: center;
  max-width: 100%; }

.layout-align-start-center > *,
.layout-align-center-center > *,
.layout-align-end-center > *,
.layout-align-space-between-center > *,
.layout-align-space-around-center > * {
  max-width: 100%;
  box-sizing: border-box; }

.layout-align-start-end,
.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-between-end,
.layout-align-space-around-end {
  align-items: flex-end;
  align-content: flex-end; }

.layout-align-start-stretch,
.layout-align-center-stretch,
.layout-align-end-stretch,
.layout-align-space-between-stretch,
.layout-align-space-around-stretch {
  align-items: stretch;
  align-content: stretch; }

.flex {
  flex: 1;
  box-sizing: border-box; }

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box; }

.flex-initial {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-auto {
  flex: 1 1 auto;
  box-sizing: border-box; }

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box; }

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box; }

.flex-nogrow {
  flex: 0 1 auto;
  box-sizing: border-box; }

.flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box; }

.layout-row > .flex-0 {
  flex: 1 1 100%;
  max-width: 0%;
  max-height: 100%;
  box-sizing: border-box;
  min-width: 0; }

.layout-column > .flex-0 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 0%;
  box-sizing: border-box;
  min-height: 0; }

.flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.layout-row > .flex-5 {
  flex: 1 1 100%;
  max-width: 5%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-5 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 5%;
  box-sizing: border-box; }

.flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.layout-row > .flex-10 {
  flex: 1 1 100%;
  max-width: 10%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-10 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 10%;
  box-sizing: border-box; }

.flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.layout-row > .flex-15 {
  flex: 1 1 100%;
  max-width: 15%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-15 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 15%;
  box-sizing: border-box; }

.flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.layout-row > .flex-20 {
  flex: 1 1 100%;
  max-width: 20%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-20 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 20%;
  box-sizing: border-box; }

.flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.layout-row > .flex-25 {
  flex: 1 1 100%;
  max-width: 25%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-25 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 25%;
  box-sizing: border-box; }

.flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.layout-row > .flex-30 {
  flex: 1 1 100%;
  max-width: 30%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-30 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 30%;
  box-sizing: border-box; }

.flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.layout-row > .flex-35 {
  flex: 1 1 100%;
  max-width: 35%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-35 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 35%;
  box-sizing: border-box; }

.flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.layout-row > .flex-40 {
  flex: 1 1 100%;
  max-width: 40%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-40 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 40%;
  box-sizing: border-box; }

.flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.layout-row > .flex-45 {
  flex: 1 1 100%;
  max-width: 45%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-45 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 45%;
  box-sizing: border-box; }

.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.layout-row > .flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-50 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 50%;
  box-sizing: border-box; }

.flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.layout-row > .flex-55 {
  flex: 1 1 100%;
  max-width: 55%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-55 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 55%;
  box-sizing: border-box; }

.flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.layout-row > .flex-60 {
  flex: 1 1 100%;
  max-width: 60%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-60 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 60%;
  box-sizing: border-box; }

.flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.layout-row > .flex-65 {
  flex: 1 1 100%;
  max-width: 65%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-65 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 65%;
  box-sizing: border-box; }

.flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.layout-row > .flex-70 {
  flex: 1 1 100%;
  max-width: 70%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-70 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 70%;
  box-sizing: border-box; }

.flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.layout-row > .flex-75 {
  flex: 1 1 100%;
  max-width: 75%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-75 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 75%;
  box-sizing: border-box; }

.flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.layout-row > .flex-80 {
  flex: 1 1 100%;
  max-width: 80%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-80 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 80%;
  box-sizing: border-box; }

.flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.layout-row > .flex-85 {
  flex: 1 1 100%;
  max-width: 85%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-85 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 85%;
  box-sizing: border-box; }

.flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.layout-row > .flex-90 {
  flex: 1 1 100%;
  max-width: 90%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-90 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 90%;
  box-sizing: border-box; }

.flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.layout-row > .flex-95 {
  flex: 1 1 100%;
  max-width: 95%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-95 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 95%;
  box-sizing: border-box; }

.flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-100 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-column > .flex-33 {
  flex: 1 1 33.33%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 66.66%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-row > .flex-33 {
  flex: 1 1 100%;
  max-width: 33.33%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex-66 {
  flex: 1 1 100%;
  max-width: 66.66%;
  max-height: 100%;
  box-sizing: border-box; }

.layout-row > .flex {
  min-width: 0; }

.layout-column > .flex-33 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 33.33%;
  box-sizing: border-box; }

.layout-column > .flex-66 {
  flex: 1 1 100%;
  max-width: 100%;
  max-height: 66.66%;
  box-sizing: border-box; }

.layout-column > .flex {
  min-height: 0; }

.layout, .layout-column, .layout-row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }

.layout-column {
  flex-direction: column; }

.layout-row {
  flex-direction: row; }

.layout-padding-sm > *,
.layout-padding > .flex-sm {
  padding: 4px; }

.layout-padding,
.layout-padding-gt-sm,
.layout-padding-md,
.layout-padding > *,
.layout-padding-gt-sm > *,
.layout-padding-md > *,
.layout-padding > .flex,
.layout-padding > .flex-gt-sm,
.layout-padding > .flex-md {
  padding: 8px; }

.layout-padding-gt-md > *,
.layout-padding-lg > *,
.layout-padding-gt-lg > *,
.layout-padding > .flex-gt-md,
.layout-padding > .flex-lg,
.layout-padding > .flex-lg,
.layout-padding > .flex-gt-lg {
  padding: 16px; }

.layout-margin-sm > *,
.layout-margin > .flex-sm {
  margin: 4px; }

.layout-margin,
.layout-margin-gt-sm,
.layout-margin-md,
.layout-margin > *,
.layout-margin-gt-sm > *,
.layout-margin-md > *,
.layout-margin > .flex,
.layout-margin > .flex-gt-sm,
.layout-margin > .flex-md {
  margin: 8px; }

.layout-margin-gt-md > *,
.layout-margin-lg > *,
.layout-margin-gt-lg > *,
.layout-margin > .flex-gt-md,
.layout-margin > .flex-lg,
.layout-margin > .flex-gt-lg {
  margin: 16px; }

.layout-wrap {
  flex-wrap: wrap; }

.layout-nowrap {
  flex-wrap: nowrap; }

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%; }

/**
 * `hide-gt-sm show-gt-lg` should hide from 600px to 1200px
 * `show-md hide-gt-sm` should show from 0px to 960px and hide at >960px
 * `hide-gt-md show-gt-sm` should show everywhere (show overrides hide)`
 *
 *  hide means hide everywhere
 *  Sizes:
 *         $layout-breakpoint-xs:     600px !default;
 *         $layout-breakpoint-sm:     960px !default;
 *         $layout-breakpoint-md:     1280px !default;
 *         $layout-breakpoint-lg:     1920px !default;
 */
@media (max-width: 599px) {
  .hide-xs:not(.show-xs):not(.show), .hide:not(.show-xs):not(.show) {
    display: none; }
  .flex-order-xs--20 {
    order: -20; }
  .flex-order-xs--19 {
    order: -19; }
  .flex-order-xs--18 {
    order: -18; }
  .flex-order-xs--17 {
    order: -17; }
  .flex-order-xs--16 {
    order: -16; }
  .flex-order-xs--15 {
    order: -15; }
  .flex-order-xs--14 {
    order: -14; }
  .flex-order-xs--13 {
    order: -13; }
  .flex-order-xs--12 {
    order: -12; }
  .flex-order-xs--11 {
    order: -11; }
  .flex-order-xs--10 {
    order: -10; }
  .flex-order-xs--9 {
    order: -9; }
  .flex-order-xs--8 {
    order: -8; }
  .flex-order-xs--7 {
    order: -7; }
  .flex-order-xs--6 {
    order: -6; }
  .flex-order-xs--5 {
    order: -5; }
  .flex-order-xs--4 {
    order: -4; }
  .flex-order-xs--3 {
    order: -3; }
  .flex-order-xs--2 {
    order: -2; }
  .flex-order-xs--1 {
    order: -1; }
  .flex-order-xs-0 {
    order: 0; }
  .flex-order-xs-1 {
    order: 1; }
  .flex-order-xs-2 {
    order: 2; }
  .flex-order-xs-3 {
    order: 3; }
  .flex-order-xs-4 {
    order: 4; }
  .flex-order-xs-5 {
    order: 5; }
  .flex-order-xs-6 {
    order: 6; }
  .flex-order-xs-7 {
    order: 7; }
  .flex-order-xs-8 {
    order: 8; }
  .flex-order-xs-9 {
    order: 9; }
  .flex-order-xs-10 {
    order: 10; }
  .flex-order-xs-11 {
    order: 11; }
  .flex-order-xs-12 {
    order: 12; }
  .flex-order-xs-13 {
    order: 13; }
  .flex-order-xs-14 {
    order: 14; }
  .flex-order-xs-15 {
    order: 15; }
  .flex-order-xs-16 {
    order: 16; }
  .flex-order-xs-17 {
    order: 17; }
  .flex-order-xs-18 {
    order: 18; }
  .flex-order-xs-19 {
    order: 19; }
  .flex-order-xs-20 {
    order: 20; }
  .offset-xs-0, .flex-offset-xs-0, .layout-margin .flex-offset-xs-0, .layout-margin .offset-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xs-0, [dir=rtl] .flex-offset-xs-0, [dir=rtl] .layout-margin .flex-offset-xs-0, [dir=rtl] .layout-margin .offset-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xs-5, .flex-offset-xs-5, .layout-margin .flex-offset-xs-5, .layout-margin .offset-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xs-5, [dir=rtl] .flex-offset-xs-5, [dir=rtl] .layout-margin .flex-offset-xs-5, [dir=rtl] .layout-margin .offset-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xs-10, .flex-offset-xs-10, .layout-margin .flex-offset-xs-10, .layout-margin .offset-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xs-10, [dir=rtl] .flex-offset-xs-10, [dir=rtl] .layout-margin .flex-offset-xs-10, [dir=rtl] .layout-margin .offset-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xs-15, .flex-offset-xs-15, .layout-margin .flex-offset-xs-15, .layout-margin .offset-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xs-15, [dir=rtl] .flex-offset-xs-15, [dir=rtl] .layout-margin .flex-offset-xs-15, [dir=rtl] .layout-margin .offset-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xs-20, .flex-offset-xs-20, .layout-margin .flex-offset-xs-20, .layout-margin .offset-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xs-20, [dir=rtl] .flex-offset-xs-20, [dir=rtl] .layout-margin .flex-offset-xs-20, [dir=rtl] .layout-margin .offset-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xs-25, .flex-offset-xs-25, .layout-margin .flex-offset-xs-25, .layout-margin .offset-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xs-25, [dir=rtl] .flex-offset-xs-25, [dir=rtl] .layout-margin .flex-offset-xs-25, [dir=rtl] .layout-margin .offset-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xs-30, .flex-offset-xs-30, .layout-margin .flex-offset-xs-30, .layout-margin .offset-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xs-30, [dir=rtl] .flex-offset-xs-30, [dir=rtl] .layout-margin .flex-offset-xs-30, [dir=rtl] .layout-margin .offset-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xs-35, .flex-offset-xs-35, .layout-margin .flex-offset-xs-35, .layout-margin .offset-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xs-35, [dir=rtl] .flex-offset-xs-35, [dir=rtl] .layout-margin .flex-offset-xs-35, [dir=rtl] .layout-margin .offset-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xs-40, .flex-offset-xs-40, .layout-margin .flex-offset-xs-40, .layout-margin .offset-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xs-40, [dir=rtl] .flex-offset-xs-40, [dir=rtl] .layout-margin .flex-offset-xs-40, [dir=rtl] .layout-margin .offset-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xs-45, .flex-offset-xs-45, .layout-margin .flex-offset-xs-45, .layout-margin .offset-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xs-45, [dir=rtl] .flex-offset-xs-45, [dir=rtl] .layout-margin .flex-offset-xs-45, [dir=rtl] .layout-margin .offset-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xs-50, .flex-offset-xs-50, .layout-margin .flex-offset-xs-50, .layout-margin .offset-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xs-50, [dir=rtl] .flex-offset-xs-50, [dir=rtl] .layout-margin .flex-offset-xs-50, [dir=rtl] .layout-margin .offset-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xs-55, .flex-offset-xs-55, .layout-margin .flex-offset-xs-55, .layout-margin .offset-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xs-55, [dir=rtl] .flex-offset-xs-55, [dir=rtl] .layout-margin .flex-offset-xs-55, [dir=rtl] .layout-margin .offset-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xs-60, .flex-offset-xs-60, .layout-margin .flex-offset-xs-60, .layout-margin .offset-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xs-60, [dir=rtl] .flex-offset-xs-60, [dir=rtl] .layout-margin .flex-offset-xs-60, [dir=rtl] .layout-margin .offset-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xs-65, .flex-offset-xs-65, .layout-margin .flex-offset-xs-65, .layout-margin .offset-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xs-65, [dir=rtl] .flex-offset-xs-65, [dir=rtl] .layout-margin .flex-offset-xs-65, [dir=rtl] .layout-margin .offset-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xs-70, .flex-offset-xs-70, .layout-margin .flex-offset-xs-70, .layout-margin .offset-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xs-70, [dir=rtl] .flex-offset-xs-70, [dir=rtl] .layout-margin .flex-offset-xs-70, [dir=rtl] .layout-margin .offset-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xs-75, .flex-offset-xs-75, .layout-margin .flex-offset-xs-75, .layout-margin .offset-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xs-75, [dir=rtl] .flex-offset-xs-75, [dir=rtl] .layout-margin .flex-offset-xs-75, [dir=rtl] .layout-margin .offset-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xs-80, .flex-offset-xs-80, .layout-margin .flex-offset-xs-80, .layout-margin .offset-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xs-80, [dir=rtl] .flex-offset-xs-80, [dir=rtl] .layout-margin .flex-offset-xs-80, [dir=rtl] .layout-margin .offset-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xs-85, .flex-offset-xs-85, .layout-margin .flex-offset-xs-85, .layout-margin .offset-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xs-85, [dir=rtl] .flex-offset-xs-85, [dir=rtl] .layout-margin .flex-offset-xs-85, [dir=rtl] .layout-margin .offset-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xs-90, .flex-offset-xs-90, .layout-margin .flex-offset-xs-90, .layout-margin .offset-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xs-90, [dir=rtl] .flex-offset-xs-90, [dir=rtl] .layout-margin .flex-offset-xs-90, [dir=rtl] .layout-margin .offset-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xs-95, .flex-offset-xs-95, .layout-margin .flex-offset-xs-95, .layout-margin .offset-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xs-95, [dir=rtl] .flex-offset-xs-95, [dir=rtl] .layout-margin .flex-offset-xs-95, [dir=rtl] .layout-margin .offset-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xs-33, .flex-offset-xs-33, .layout-margin .flex-offset-xs-33, .layout-margin .offset-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-xs-66, .flex-offset-xs-66, .layout-margin .flex-offset-xs-66, .layout-margin .offset-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xs-66, [dir=rtl] .flex-offset-xs-66, [dir=rtl] .layout-margin .flex-offset-xs-66, [dir=rtl] .layout-margin .offset-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xs,
  .layout-align-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xs-start,
  .layout-align-xs-start-start,
  .layout-align-xs-start-center,
  .layout-align-xs-start-end,
  .layout-align-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-xs-center,
  .layout-align-xs-center-start,
  .layout-align-xs-center-center,
  .layout-align-xs-center-end,
  .layout-align-xs-center-stretch {
    justify-content: center; }
  .layout-align-xs-end,
  .layout-align-xs-end-start,
  .layout-align-xs-end-center,
  .layout-align-xs-end-end,
  .layout-align-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-xs-space-around,
  .layout-align-xs-space-around-center,
  .layout-align-xs-space-around-start,
  .layout-align-xs-space-around-end,
  .layout-align-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xs-space-between,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xs-start-start,
  .layout-align-xs-center-start,
  .layout-align-xs-end-start,
  .layout-align-xs-space-between-start,
  .layout-align-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xs-start-center,
  .layout-align-xs-center-center,
  .layout-align-xs-end-center,
  .layout-align-xs-space-between-center,
  .layout-align-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xs-start-center > *,
  .layout-align-xs-center-center > *,
  .layout-align-xs-end-center > *,
  .layout-align-xs-space-between-center > *,
  .layout-align-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xs-start-end,
  .layout-align-xs-center-end,
  .layout-align-xs-end-end,
  .layout-align-xs-space-between-end,
  .layout-align-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xs-start-stretch,
  .layout-align-xs-center-stretch,
  .layout-align-xs-end-stretch,
  .layout-align-xs-space-between-stretch,
  .layout-align-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xs-column > .flex-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xs-row > .flex {
    min-width: 0; }
  .layout-xs-column > .flex-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xs-column > .flex-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xs-column > .flex {
    min-height: 0; }
  .layout-xs, .layout-xs-column, .layout-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xs-column {
    flex-direction: column; }
  .layout-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) {
  .flex-order-gt-xs--20 {
    order: -20; }
  .flex-order-gt-xs--19 {
    order: -19; }
  .flex-order-gt-xs--18 {
    order: -18; }
  .flex-order-gt-xs--17 {
    order: -17; }
  .flex-order-gt-xs--16 {
    order: -16; }
  .flex-order-gt-xs--15 {
    order: -15; }
  .flex-order-gt-xs--14 {
    order: -14; }
  .flex-order-gt-xs--13 {
    order: -13; }
  .flex-order-gt-xs--12 {
    order: -12; }
  .flex-order-gt-xs--11 {
    order: -11; }
  .flex-order-gt-xs--10 {
    order: -10; }
  .flex-order-gt-xs--9 {
    order: -9; }
  .flex-order-gt-xs--8 {
    order: -8; }
  .flex-order-gt-xs--7 {
    order: -7; }
  .flex-order-gt-xs--6 {
    order: -6; }
  .flex-order-gt-xs--5 {
    order: -5; }
  .flex-order-gt-xs--4 {
    order: -4; }
  .flex-order-gt-xs--3 {
    order: -3; }
  .flex-order-gt-xs--2 {
    order: -2; }
  .flex-order-gt-xs--1 {
    order: -1; }
  .flex-order-gt-xs-0 {
    order: 0; }
  .flex-order-gt-xs-1 {
    order: 1; }
  .flex-order-gt-xs-2 {
    order: 2; }
  .flex-order-gt-xs-3 {
    order: 3; }
  .flex-order-gt-xs-4 {
    order: 4; }
  .flex-order-gt-xs-5 {
    order: 5; }
  .flex-order-gt-xs-6 {
    order: 6; }
  .flex-order-gt-xs-7 {
    order: 7; }
  .flex-order-gt-xs-8 {
    order: 8; }
  .flex-order-gt-xs-9 {
    order: 9; }
  .flex-order-gt-xs-10 {
    order: 10; }
  .flex-order-gt-xs-11 {
    order: 11; }
  .flex-order-gt-xs-12 {
    order: 12; }
  .flex-order-gt-xs-13 {
    order: 13; }
  .flex-order-gt-xs-14 {
    order: 14; }
  .flex-order-gt-xs-15 {
    order: 15; }
  .flex-order-gt-xs-16 {
    order: 16; }
  .flex-order-gt-xs-17 {
    order: 17; }
  .flex-order-gt-xs-18 {
    order: 18; }
  .flex-order-gt-xs-19 {
    order: 19; }
  .flex-order-gt-xs-20 {
    order: 20; }
  .offset-gt-xs-0, .flex-offset-gt-xs-0, .layout-margin .flex-offset-gt-xs-0, .layout-margin .offset-gt-xs-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-xs-0, [dir=rtl] .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .flex-offset-gt-xs-0, [dir=rtl] .layout-margin .offset-gt-xs-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-xs-5, .flex-offset-gt-xs-5, .layout-margin .flex-offset-gt-xs-5, .layout-margin .offset-gt-xs-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-xs-5, [dir=rtl] .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .flex-offset-gt-xs-5, [dir=rtl] .layout-margin .offset-gt-xs-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-xs-10, .flex-offset-gt-xs-10, .layout-margin .flex-offset-gt-xs-10, .layout-margin .offset-gt-xs-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-xs-10, [dir=rtl] .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .flex-offset-gt-xs-10, [dir=rtl] .layout-margin .offset-gt-xs-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-xs-15, .flex-offset-gt-xs-15, .layout-margin .flex-offset-gt-xs-15, .layout-margin .offset-gt-xs-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-xs-15, [dir=rtl] .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .flex-offset-gt-xs-15, [dir=rtl] .layout-margin .offset-gt-xs-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-xs-20, .flex-offset-gt-xs-20, .layout-margin .flex-offset-gt-xs-20, .layout-margin .offset-gt-xs-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-xs-20, [dir=rtl] .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .flex-offset-gt-xs-20, [dir=rtl] .layout-margin .offset-gt-xs-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-xs-25, .flex-offset-gt-xs-25, .layout-margin .flex-offset-gt-xs-25, .layout-margin .offset-gt-xs-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-xs-25, [dir=rtl] .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .flex-offset-gt-xs-25, [dir=rtl] .layout-margin .offset-gt-xs-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-xs-30, .flex-offset-gt-xs-30, .layout-margin .flex-offset-gt-xs-30, .layout-margin .offset-gt-xs-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-xs-30, [dir=rtl] .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .flex-offset-gt-xs-30, [dir=rtl] .layout-margin .offset-gt-xs-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-xs-35, .flex-offset-gt-xs-35, .layout-margin .flex-offset-gt-xs-35, .layout-margin .offset-gt-xs-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-xs-35, [dir=rtl] .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .flex-offset-gt-xs-35, [dir=rtl] .layout-margin .offset-gt-xs-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-xs-40, .flex-offset-gt-xs-40, .layout-margin .flex-offset-gt-xs-40, .layout-margin .offset-gt-xs-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-xs-40, [dir=rtl] .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .flex-offset-gt-xs-40, [dir=rtl] .layout-margin .offset-gt-xs-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-xs-45, .flex-offset-gt-xs-45, .layout-margin .flex-offset-gt-xs-45, .layout-margin .offset-gt-xs-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-xs-45, [dir=rtl] .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .flex-offset-gt-xs-45, [dir=rtl] .layout-margin .offset-gt-xs-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-xs-50, .flex-offset-gt-xs-50, .layout-margin .flex-offset-gt-xs-50, .layout-margin .offset-gt-xs-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-xs-50, [dir=rtl] .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .flex-offset-gt-xs-50, [dir=rtl] .layout-margin .offset-gt-xs-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-xs-55, .flex-offset-gt-xs-55, .layout-margin .flex-offset-gt-xs-55, .layout-margin .offset-gt-xs-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-xs-55, [dir=rtl] .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .flex-offset-gt-xs-55, [dir=rtl] .layout-margin .offset-gt-xs-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-xs-60, .flex-offset-gt-xs-60, .layout-margin .flex-offset-gt-xs-60, .layout-margin .offset-gt-xs-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-xs-60, [dir=rtl] .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .flex-offset-gt-xs-60, [dir=rtl] .layout-margin .offset-gt-xs-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-xs-65, .flex-offset-gt-xs-65, .layout-margin .flex-offset-gt-xs-65, .layout-margin .offset-gt-xs-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-xs-65, [dir=rtl] .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .flex-offset-gt-xs-65, [dir=rtl] .layout-margin .offset-gt-xs-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-xs-70, .flex-offset-gt-xs-70, .layout-margin .flex-offset-gt-xs-70, .layout-margin .offset-gt-xs-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-xs-70, [dir=rtl] .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .flex-offset-gt-xs-70, [dir=rtl] .layout-margin .offset-gt-xs-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-xs-75, .flex-offset-gt-xs-75, .layout-margin .flex-offset-gt-xs-75, .layout-margin .offset-gt-xs-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-xs-75, [dir=rtl] .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .flex-offset-gt-xs-75, [dir=rtl] .layout-margin .offset-gt-xs-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-xs-80, .flex-offset-gt-xs-80, .layout-margin .flex-offset-gt-xs-80, .layout-margin .offset-gt-xs-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-xs-80, [dir=rtl] .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .flex-offset-gt-xs-80, [dir=rtl] .layout-margin .offset-gt-xs-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-xs-85, .flex-offset-gt-xs-85, .layout-margin .flex-offset-gt-xs-85, .layout-margin .offset-gt-xs-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-xs-85, [dir=rtl] .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .flex-offset-gt-xs-85, [dir=rtl] .layout-margin .offset-gt-xs-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-xs-90, .flex-offset-gt-xs-90, .layout-margin .flex-offset-gt-xs-90, .layout-margin .offset-gt-xs-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-xs-90, [dir=rtl] .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .flex-offset-gt-xs-90, [dir=rtl] .layout-margin .offset-gt-xs-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-xs-95, .flex-offset-gt-xs-95, .layout-margin .flex-offset-gt-xs-95, .layout-margin .offset-gt-xs-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-xs-95, [dir=rtl] .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .flex-offset-gt-xs-95, [dir=rtl] .layout-margin .offset-gt-xs-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-xs-33, .flex-offset-gt-xs-33, .layout-margin .flex-offset-gt-xs-33, .layout-margin .offset-gt-xs-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-xs-66, .flex-offset-gt-xs-66, .layout-margin .flex-offset-gt-xs-66, .layout-margin .offset-gt-xs-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-xs-66, [dir=rtl] .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .flex-offset-gt-xs-66, [dir=rtl] .layout-margin .offset-gt-xs-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-xs,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-xs-start,
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-xs-center,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-center-stretch {
    justify-content: center; }
  .layout-align-gt-xs-end,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-xs-space-around,
  .layout-align-gt-xs-space-around-center,
  .layout-align-gt-xs-space-around-start,
  .layout-align-gt-xs-space-around-end,
  .layout-align-gt-xs-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-xs-space-between,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-xs-start-start,
  .layout-align-gt-xs-center-start,
  .layout-align-gt-xs-end-start,
  .layout-align-gt-xs-space-between-start,
  .layout-align-gt-xs-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-xs-start-center,
  .layout-align-gt-xs-center-center,
  .layout-align-gt-xs-end-center,
  .layout-align-gt-xs-space-between-center,
  .layout-align-gt-xs-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-xs-start-center > *,
  .layout-align-gt-xs-center-center > *,
  .layout-align-gt-xs-end-center > *,
  .layout-align-gt-xs-space-between-center > *,
  .layout-align-gt-xs-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-xs-start-end,
  .layout-align-gt-xs-center-end,
  .layout-align-gt-xs-end-end,
  .layout-align-gt-xs-space-between-end,
  .layout-align-gt-xs-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-xs-start-stretch,
  .layout-align-gt-xs-center-stretch,
  .layout-align-gt-xs-end-stretch,
  .layout-align-gt-xs-space-between-stretch,
  .layout-align-gt-xs-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-xs {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-xs-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-xs-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-xs-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-xs-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-xs-row > .flex {
    min-width: 0; }
  .layout-gt-xs-column > .flex-gt-xs-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex-gt-xs-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-xs-column > .flex {
    min-height: 0; }
  .layout-gt-xs, .layout-gt-xs-column, .layout-gt-xs-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-xs-column {
    flex-direction: column; }
  .layout-gt-xs-row {
    flex-direction: row; } }

@media (min-width: 600px) and (max-width: 959px) {
  .hide:not(.show-gt-xs):not(.show-sm):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .hide-sm:not(.show-gt-xs):not(.show-sm):not(.show) {
    display: none; }
  .flex-order-sm--20 {
    order: -20; }
  .flex-order-sm--19 {
    order: -19; }
  .flex-order-sm--18 {
    order: -18; }
  .flex-order-sm--17 {
    order: -17; }
  .flex-order-sm--16 {
    order: -16; }
  .flex-order-sm--15 {
    order: -15; }
  .flex-order-sm--14 {
    order: -14; }
  .flex-order-sm--13 {
    order: -13; }
  .flex-order-sm--12 {
    order: -12; }
  .flex-order-sm--11 {
    order: -11; }
  .flex-order-sm--10 {
    order: -10; }
  .flex-order-sm--9 {
    order: -9; }
  .flex-order-sm--8 {
    order: -8; }
  .flex-order-sm--7 {
    order: -7; }
  .flex-order-sm--6 {
    order: -6; }
  .flex-order-sm--5 {
    order: -5; }
  .flex-order-sm--4 {
    order: -4; }
  .flex-order-sm--3 {
    order: -3; }
  .flex-order-sm--2 {
    order: -2; }
  .flex-order-sm--1 {
    order: -1; }
  .flex-order-sm-0 {
    order: 0; }
  .flex-order-sm-1 {
    order: 1; }
  .flex-order-sm-2 {
    order: 2; }
  .flex-order-sm-3 {
    order: 3; }
  .flex-order-sm-4 {
    order: 4; }
  .flex-order-sm-5 {
    order: 5; }
  .flex-order-sm-6 {
    order: 6; }
  .flex-order-sm-7 {
    order: 7; }
  .flex-order-sm-8 {
    order: 8; }
  .flex-order-sm-9 {
    order: 9; }
  .flex-order-sm-10 {
    order: 10; }
  .flex-order-sm-11 {
    order: 11; }
  .flex-order-sm-12 {
    order: 12; }
  .flex-order-sm-13 {
    order: 13; }
  .flex-order-sm-14 {
    order: 14; }
  .flex-order-sm-15 {
    order: 15; }
  .flex-order-sm-16 {
    order: 16; }
  .flex-order-sm-17 {
    order: 17; }
  .flex-order-sm-18 {
    order: 18; }
  .flex-order-sm-19 {
    order: 19; }
  .flex-order-sm-20 {
    order: 20; }
  .offset-sm-0, .flex-offset-sm-0, .layout-margin .flex-offset-sm-0, .layout-margin .offset-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-sm-0, [dir=rtl] .flex-offset-sm-0, [dir=rtl] .layout-margin .flex-offset-sm-0, [dir=rtl] .layout-margin .offset-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-sm-5, .flex-offset-sm-5, .layout-margin .flex-offset-sm-5, .layout-margin .offset-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-sm-5, [dir=rtl] .flex-offset-sm-5, [dir=rtl] .layout-margin .flex-offset-sm-5, [dir=rtl] .layout-margin .offset-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-sm-10, .flex-offset-sm-10, .layout-margin .flex-offset-sm-10, .layout-margin .offset-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-sm-10, [dir=rtl] .flex-offset-sm-10, [dir=rtl] .layout-margin .flex-offset-sm-10, [dir=rtl] .layout-margin .offset-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-sm-15, .flex-offset-sm-15, .layout-margin .flex-offset-sm-15, .layout-margin .offset-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-sm-15, [dir=rtl] .flex-offset-sm-15, [dir=rtl] .layout-margin .flex-offset-sm-15, [dir=rtl] .layout-margin .offset-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-sm-20, .flex-offset-sm-20, .layout-margin .flex-offset-sm-20, .layout-margin .offset-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-sm-20, [dir=rtl] .flex-offset-sm-20, [dir=rtl] .layout-margin .flex-offset-sm-20, [dir=rtl] .layout-margin .offset-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-sm-25, .flex-offset-sm-25, .layout-margin .flex-offset-sm-25, .layout-margin .offset-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-sm-25, [dir=rtl] .flex-offset-sm-25, [dir=rtl] .layout-margin .flex-offset-sm-25, [dir=rtl] .layout-margin .offset-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-sm-30, .flex-offset-sm-30, .layout-margin .flex-offset-sm-30, .layout-margin .offset-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-sm-30, [dir=rtl] .flex-offset-sm-30, [dir=rtl] .layout-margin .flex-offset-sm-30, [dir=rtl] .layout-margin .offset-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-sm-35, .flex-offset-sm-35, .layout-margin .flex-offset-sm-35, .layout-margin .offset-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-sm-35, [dir=rtl] .flex-offset-sm-35, [dir=rtl] .layout-margin .flex-offset-sm-35, [dir=rtl] .layout-margin .offset-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-sm-40, .flex-offset-sm-40, .layout-margin .flex-offset-sm-40, .layout-margin .offset-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-sm-40, [dir=rtl] .flex-offset-sm-40, [dir=rtl] .layout-margin .flex-offset-sm-40, [dir=rtl] .layout-margin .offset-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-sm-45, .flex-offset-sm-45, .layout-margin .flex-offset-sm-45, .layout-margin .offset-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-sm-45, [dir=rtl] .flex-offset-sm-45, [dir=rtl] .layout-margin .flex-offset-sm-45, [dir=rtl] .layout-margin .offset-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-sm-50, .flex-offset-sm-50, .layout-margin .flex-offset-sm-50, .layout-margin .offset-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-sm-50, [dir=rtl] .flex-offset-sm-50, [dir=rtl] .layout-margin .flex-offset-sm-50, [dir=rtl] .layout-margin .offset-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-sm-55, .flex-offset-sm-55, .layout-margin .flex-offset-sm-55, .layout-margin .offset-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-sm-55, [dir=rtl] .flex-offset-sm-55, [dir=rtl] .layout-margin .flex-offset-sm-55, [dir=rtl] .layout-margin .offset-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-sm-60, .flex-offset-sm-60, .layout-margin .flex-offset-sm-60, .layout-margin .offset-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-sm-60, [dir=rtl] .flex-offset-sm-60, [dir=rtl] .layout-margin .flex-offset-sm-60, [dir=rtl] .layout-margin .offset-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-sm-65, .flex-offset-sm-65, .layout-margin .flex-offset-sm-65, .layout-margin .offset-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-sm-65, [dir=rtl] .flex-offset-sm-65, [dir=rtl] .layout-margin .flex-offset-sm-65, [dir=rtl] .layout-margin .offset-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-sm-70, .flex-offset-sm-70, .layout-margin .flex-offset-sm-70, .layout-margin .offset-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-sm-70, [dir=rtl] .flex-offset-sm-70, [dir=rtl] .layout-margin .flex-offset-sm-70, [dir=rtl] .layout-margin .offset-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-sm-75, .flex-offset-sm-75, .layout-margin .flex-offset-sm-75, .layout-margin .offset-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-sm-75, [dir=rtl] .flex-offset-sm-75, [dir=rtl] .layout-margin .flex-offset-sm-75, [dir=rtl] .layout-margin .offset-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-sm-80, .flex-offset-sm-80, .layout-margin .flex-offset-sm-80, .layout-margin .offset-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-sm-80, [dir=rtl] .flex-offset-sm-80, [dir=rtl] .layout-margin .flex-offset-sm-80, [dir=rtl] .layout-margin .offset-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-sm-85, .flex-offset-sm-85, .layout-margin .flex-offset-sm-85, .layout-margin .offset-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-sm-85, [dir=rtl] .flex-offset-sm-85, [dir=rtl] .layout-margin .flex-offset-sm-85, [dir=rtl] .layout-margin .offset-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-sm-90, .flex-offset-sm-90, .layout-margin .flex-offset-sm-90, .layout-margin .offset-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-sm-90, [dir=rtl] .flex-offset-sm-90, [dir=rtl] .layout-margin .flex-offset-sm-90, [dir=rtl] .layout-margin .offset-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-sm-95, .flex-offset-sm-95, .layout-margin .flex-offset-sm-95, .layout-margin .offset-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-sm-95, [dir=rtl] .flex-offset-sm-95, [dir=rtl] .layout-margin .flex-offset-sm-95, [dir=rtl] .layout-margin .offset-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-sm-33, .flex-offset-sm-33, .layout-margin .flex-offset-sm-33, .layout-margin .offset-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-sm-66, .flex-offset-sm-66, .layout-margin .flex-offset-sm-66, .layout-margin .offset-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-sm-66, [dir=rtl] .flex-offset-sm-66, [dir=rtl] .layout-margin .flex-offset-sm-66, [dir=rtl] .layout-margin .offset-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-sm,
  .layout-align-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-sm-start,
  .layout-align-sm-start-start,
  .layout-align-sm-start-center,
  .layout-align-sm-start-end,
  .layout-align-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-sm-center,
  .layout-align-sm-center-start,
  .layout-align-sm-center-center,
  .layout-align-sm-center-end,
  .layout-align-sm-center-stretch {
    justify-content: center; }
  .layout-align-sm-end,
  .layout-align-sm-end-start,
  .layout-align-sm-end-center,
  .layout-align-sm-end-end,
  .layout-align-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-sm-space-around,
  .layout-align-sm-space-around-center,
  .layout-align-sm-space-around-start,
  .layout-align-sm-space-around-end,
  .layout-align-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-sm-space-between,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-sm-start-start,
  .layout-align-sm-center-start,
  .layout-align-sm-end-start,
  .layout-align-sm-space-between-start,
  .layout-align-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-sm-start-center,
  .layout-align-sm-center-center,
  .layout-align-sm-end-center,
  .layout-align-sm-space-between-center,
  .layout-align-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-sm-start-center > *,
  .layout-align-sm-center-center > *,
  .layout-align-sm-end-center > *,
  .layout-align-sm-space-between-center > *,
  .layout-align-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-sm-start-end,
  .layout-align-sm-center-end,
  .layout-align-sm-end-end,
  .layout-align-sm-space-between-end,
  .layout-align-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-sm-start-stretch,
  .layout-align-sm-center-stretch,
  .layout-align-sm-end-stretch,
  .layout-align-sm-space-between-stretch,
  .layout-align-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-sm-column > .flex-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-sm-row > .flex {
    min-width: 0; }
  .layout-sm-column > .flex-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-sm-column > .flex-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-sm-column > .flex {
    min-height: 0; }
  .layout-sm, .layout-sm-column, .layout-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-sm-column {
    flex-direction: column; }
  .layout-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) {
  .flex-order-gt-sm--20 {
    order: -20; }
  .flex-order-gt-sm--19 {
    order: -19; }
  .flex-order-gt-sm--18 {
    order: -18; }
  .flex-order-gt-sm--17 {
    order: -17; }
  .flex-order-gt-sm--16 {
    order: -16; }
  .flex-order-gt-sm--15 {
    order: -15; }
  .flex-order-gt-sm--14 {
    order: -14; }
  .flex-order-gt-sm--13 {
    order: -13; }
  .flex-order-gt-sm--12 {
    order: -12; }
  .flex-order-gt-sm--11 {
    order: -11; }
  .flex-order-gt-sm--10 {
    order: -10; }
  .flex-order-gt-sm--9 {
    order: -9; }
  .flex-order-gt-sm--8 {
    order: -8; }
  .flex-order-gt-sm--7 {
    order: -7; }
  .flex-order-gt-sm--6 {
    order: -6; }
  .flex-order-gt-sm--5 {
    order: -5; }
  .flex-order-gt-sm--4 {
    order: -4; }
  .flex-order-gt-sm--3 {
    order: -3; }
  .flex-order-gt-sm--2 {
    order: -2; }
  .flex-order-gt-sm--1 {
    order: -1; }
  .flex-order-gt-sm-0 {
    order: 0; }
  .flex-order-gt-sm-1 {
    order: 1; }
  .flex-order-gt-sm-2 {
    order: 2; }
  .flex-order-gt-sm-3 {
    order: 3; }
  .flex-order-gt-sm-4 {
    order: 4; }
  .flex-order-gt-sm-5 {
    order: 5; }
  .flex-order-gt-sm-6 {
    order: 6; }
  .flex-order-gt-sm-7 {
    order: 7; }
  .flex-order-gt-sm-8 {
    order: 8; }
  .flex-order-gt-sm-9 {
    order: 9; }
  .flex-order-gt-sm-10 {
    order: 10; }
  .flex-order-gt-sm-11 {
    order: 11; }
  .flex-order-gt-sm-12 {
    order: 12; }
  .flex-order-gt-sm-13 {
    order: 13; }
  .flex-order-gt-sm-14 {
    order: 14; }
  .flex-order-gt-sm-15 {
    order: 15; }
  .flex-order-gt-sm-16 {
    order: 16; }
  .flex-order-gt-sm-17 {
    order: 17; }
  .flex-order-gt-sm-18 {
    order: 18; }
  .flex-order-gt-sm-19 {
    order: 19; }
  .flex-order-gt-sm-20 {
    order: 20; }
  .offset-gt-sm-0, .flex-offset-gt-sm-0, .layout-margin .flex-offset-gt-sm-0, .layout-margin .offset-gt-sm-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-sm-0, [dir=rtl] .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .flex-offset-gt-sm-0, [dir=rtl] .layout-margin .offset-gt-sm-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-sm-5, .flex-offset-gt-sm-5, .layout-margin .flex-offset-gt-sm-5, .layout-margin .offset-gt-sm-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-sm-5, [dir=rtl] .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .flex-offset-gt-sm-5, [dir=rtl] .layout-margin .offset-gt-sm-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-sm-10, .flex-offset-gt-sm-10, .layout-margin .flex-offset-gt-sm-10, .layout-margin .offset-gt-sm-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-sm-10, [dir=rtl] .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .flex-offset-gt-sm-10, [dir=rtl] .layout-margin .offset-gt-sm-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-sm-15, .flex-offset-gt-sm-15, .layout-margin .flex-offset-gt-sm-15, .layout-margin .offset-gt-sm-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-sm-15, [dir=rtl] .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .flex-offset-gt-sm-15, [dir=rtl] .layout-margin .offset-gt-sm-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-sm-20, .flex-offset-gt-sm-20, .layout-margin .flex-offset-gt-sm-20, .layout-margin .offset-gt-sm-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-sm-20, [dir=rtl] .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .flex-offset-gt-sm-20, [dir=rtl] .layout-margin .offset-gt-sm-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-sm-25, .flex-offset-gt-sm-25, .layout-margin .flex-offset-gt-sm-25, .layout-margin .offset-gt-sm-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-sm-25, [dir=rtl] .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .flex-offset-gt-sm-25, [dir=rtl] .layout-margin .offset-gt-sm-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-sm-30, .flex-offset-gt-sm-30, .layout-margin .flex-offset-gt-sm-30, .layout-margin .offset-gt-sm-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-sm-30, [dir=rtl] .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .flex-offset-gt-sm-30, [dir=rtl] .layout-margin .offset-gt-sm-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-sm-35, .flex-offset-gt-sm-35, .layout-margin .flex-offset-gt-sm-35, .layout-margin .offset-gt-sm-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-sm-35, [dir=rtl] .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .flex-offset-gt-sm-35, [dir=rtl] .layout-margin .offset-gt-sm-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-sm-40, .flex-offset-gt-sm-40, .layout-margin .flex-offset-gt-sm-40, .layout-margin .offset-gt-sm-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-sm-40, [dir=rtl] .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .flex-offset-gt-sm-40, [dir=rtl] .layout-margin .offset-gt-sm-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-sm-45, .flex-offset-gt-sm-45, .layout-margin .flex-offset-gt-sm-45, .layout-margin .offset-gt-sm-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-sm-45, [dir=rtl] .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .flex-offset-gt-sm-45, [dir=rtl] .layout-margin .offset-gt-sm-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-sm-50, .flex-offset-gt-sm-50, .layout-margin .flex-offset-gt-sm-50, .layout-margin .offset-gt-sm-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-sm-50, [dir=rtl] .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .flex-offset-gt-sm-50, [dir=rtl] .layout-margin .offset-gt-sm-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-sm-55, .flex-offset-gt-sm-55, .layout-margin .flex-offset-gt-sm-55, .layout-margin .offset-gt-sm-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-sm-55, [dir=rtl] .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .flex-offset-gt-sm-55, [dir=rtl] .layout-margin .offset-gt-sm-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-sm-60, .flex-offset-gt-sm-60, .layout-margin .flex-offset-gt-sm-60, .layout-margin .offset-gt-sm-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-sm-60, [dir=rtl] .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .flex-offset-gt-sm-60, [dir=rtl] .layout-margin .offset-gt-sm-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-sm-65, .flex-offset-gt-sm-65, .layout-margin .flex-offset-gt-sm-65, .layout-margin .offset-gt-sm-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-sm-65, [dir=rtl] .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .flex-offset-gt-sm-65, [dir=rtl] .layout-margin .offset-gt-sm-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-sm-70, .flex-offset-gt-sm-70, .layout-margin .flex-offset-gt-sm-70, .layout-margin .offset-gt-sm-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-sm-70, [dir=rtl] .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .flex-offset-gt-sm-70, [dir=rtl] .layout-margin .offset-gt-sm-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-sm-75, .flex-offset-gt-sm-75, .layout-margin .flex-offset-gt-sm-75, .layout-margin .offset-gt-sm-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-sm-75, [dir=rtl] .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .flex-offset-gt-sm-75, [dir=rtl] .layout-margin .offset-gt-sm-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-sm-80, .flex-offset-gt-sm-80, .layout-margin .flex-offset-gt-sm-80, .layout-margin .offset-gt-sm-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-sm-80, [dir=rtl] .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .flex-offset-gt-sm-80, [dir=rtl] .layout-margin .offset-gt-sm-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-sm-85, .flex-offset-gt-sm-85, .layout-margin .flex-offset-gt-sm-85, .layout-margin .offset-gt-sm-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-sm-85, [dir=rtl] .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .flex-offset-gt-sm-85, [dir=rtl] .layout-margin .offset-gt-sm-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-sm-90, .flex-offset-gt-sm-90, .layout-margin .flex-offset-gt-sm-90, .layout-margin .offset-gt-sm-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-sm-90, [dir=rtl] .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .flex-offset-gt-sm-90, [dir=rtl] .layout-margin .offset-gt-sm-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-sm-95, .flex-offset-gt-sm-95, .layout-margin .flex-offset-gt-sm-95, .layout-margin .offset-gt-sm-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-sm-95, [dir=rtl] .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .flex-offset-gt-sm-95, [dir=rtl] .layout-margin .offset-gt-sm-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-sm-33, .flex-offset-gt-sm-33, .layout-margin .flex-offset-gt-sm-33, .layout-margin .offset-gt-sm-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-sm-66, .flex-offset-gt-sm-66, .layout-margin .flex-offset-gt-sm-66, .layout-margin .offset-gt-sm-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-sm-66, [dir=rtl] .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .flex-offset-gt-sm-66, [dir=rtl] .layout-margin .offset-gt-sm-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-sm,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-sm-start,
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-sm-center,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-center-stretch {
    justify-content: center; }
  .layout-align-gt-sm-end,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-sm-space-around,
  .layout-align-gt-sm-space-around-center,
  .layout-align-gt-sm-space-around-start,
  .layout-align-gt-sm-space-around-end,
  .layout-align-gt-sm-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-sm-space-between,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-sm-start-start,
  .layout-align-gt-sm-center-start,
  .layout-align-gt-sm-end-start,
  .layout-align-gt-sm-space-between-start,
  .layout-align-gt-sm-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-sm-start-center,
  .layout-align-gt-sm-center-center,
  .layout-align-gt-sm-end-center,
  .layout-align-gt-sm-space-between-center,
  .layout-align-gt-sm-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-sm-start-center > *,
  .layout-align-gt-sm-center-center > *,
  .layout-align-gt-sm-end-center > *,
  .layout-align-gt-sm-space-between-center > *,
  .layout-align-gt-sm-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-sm-start-end,
  .layout-align-gt-sm-center-end,
  .layout-align-gt-sm-end-end,
  .layout-align-gt-sm-space-between-end,
  .layout-align-gt-sm-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-sm-start-stretch,
  .layout-align-gt-sm-center-stretch,
  .layout-align-gt-sm-end-stretch,
  .layout-align-gt-sm-space-between-stretch,
  .layout-align-gt-sm-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-sm {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-sm-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-sm-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-sm-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-sm-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-sm-row > .flex {
    min-width: 0; }
  .layout-gt-sm-column > .flex-gt-sm-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex-gt-sm-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-sm-column > .flex {
    min-height: 0; }
  .layout-gt-sm, .layout-gt-sm-column, .layout-gt-sm-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-sm-column {
    flex-direction: column; }
  .layout-gt-sm-row {
    flex-direction: row; } }

@media (min-width: 960px) and (max-width: 1279px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-md):not(.show) {
    display: none; }
  .hide-md:not(.show-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-md--20 {
    order: -20; }
  .flex-order-md--19 {
    order: -19; }
  .flex-order-md--18 {
    order: -18; }
  .flex-order-md--17 {
    order: -17; }
  .flex-order-md--16 {
    order: -16; }
  .flex-order-md--15 {
    order: -15; }
  .flex-order-md--14 {
    order: -14; }
  .flex-order-md--13 {
    order: -13; }
  .flex-order-md--12 {
    order: -12; }
  .flex-order-md--11 {
    order: -11; }
  .flex-order-md--10 {
    order: -10; }
  .flex-order-md--9 {
    order: -9; }
  .flex-order-md--8 {
    order: -8; }
  .flex-order-md--7 {
    order: -7; }
  .flex-order-md--6 {
    order: -6; }
  .flex-order-md--5 {
    order: -5; }
  .flex-order-md--4 {
    order: -4; }
  .flex-order-md--3 {
    order: -3; }
  .flex-order-md--2 {
    order: -2; }
  .flex-order-md--1 {
    order: -1; }
  .flex-order-md-0 {
    order: 0; }
  .flex-order-md-1 {
    order: 1; }
  .flex-order-md-2 {
    order: 2; }
  .flex-order-md-3 {
    order: 3; }
  .flex-order-md-4 {
    order: 4; }
  .flex-order-md-5 {
    order: 5; }
  .flex-order-md-6 {
    order: 6; }
  .flex-order-md-7 {
    order: 7; }
  .flex-order-md-8 {
    order: 8; }
  .flex-order-md-9 {
    order: 9; }
  .flex-order-md-10 {
    order: 10; }
  .flex-order-md-11 {
    order: 11; }
  .flex-order-md-12 {
    order: 12; }
  .flex-order-md-13 {
    order: 13; }
  .flex-order-md-14 {
    order: 14; }
  .flex-order-md-15 {
    order: 15; }
  .flex-order-md-16 {
    order: 16; }
  .flex-order-md-17 {
    order: 17; }
  .flex-order-md-18 {
    order: 18; }
  .flex-order-md-19 {
    order: 19; }
  .flex-order-md-20 {
    order: 20; }
  .offset-md-0, .flex-offset-md-0, .layout-margin .flex-offset-md-0, .layout-margin .offset-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-md-0, [dir=rtl] .flex-offset-md-0, [dir=rtl] .layout-margin .flex-offset-md-0, [dir=rtl] .layout-margin .offset-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-md-5, .flex-offset-md-5, .layout-margin .flex-offset-md-5, .layout-margin .offset-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-md-5, [dir=rtl] .flex-offset-md-5, [dir=rtl] .layout-margin .flex-offset-md-5, [dir=rtl] .layout-margin .offset-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-md-10, .flex-offset-md-10, .layout-margin .flex-offset-md-10, .layout-margin .offset-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-md-10, [dir=rtl] .flex-offset-md-10, [dir=rtl] .layout-margin .flex-offset-md-10, [dir=rtl] .layout-margin .offset-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-md-15, .flex-offset-md-15, .layout-margin .flex-offset-md-15, .layout-margin .offset-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-md-15, [dir=rtl] .flex-offset-md-15, [dir=rtl] .layout-margin .flex-offset-md-15, [dir=rtl] .layout-margin .offset-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-md-20, .flex-offset-md-20, .layout-margin .flex-offset-md-20, .layout-margin .offset-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-md-20, [dir=rtl] .flex-offset-md-20, [dir=rtl] .layout-margin .flex-offset-md-20, [dir=rtl] .layout-margin .offset-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-md-25, .flex-offset-md-25, .layout-margin .flex-offset-md-25, .layout-margin .offset-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-md-25, [dir=rtl] .flex-offset-md-25, [dir=rtl] .layout-margin .flex-offset-md-25, [dir=rtl] .layout-margin .offset-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-md-30, .flex-offset-md-30, .layout-margin .flex-offset-md-30, .layout-margin .offset-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-md-30, [dir=rtl] .flex-offset-md-30, [dir=rtl] .layout-margin .flex-offset-md-30, [dir=rtl] .layout-margin .offset-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-md-35, .flex-offset-md-35, .layout-margin .flex-offset-md-35, .layout-margin .offset-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-md-35, [dir=rtl] .flex-offset-md-35, [dir=rtl] .layout-margin .flex-offset-md-35, [dir=rtl] .layout-margin .offset-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-md-40, .flex-offset-md-40, .layout-margin .flex-offset-md-40, .layout-margin .offset-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-md-40, [dir=rtl] .flex-offset-md-40, [dir=rtl] .layout-margin .flex-offset-md-40, [dir=rtl] .layout-margin .offset-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-md-45, .flex-offset-md-45, .layout-margin .flex-offset-md-45, .layout-margin .offset-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-md-45, [dir=rtl] .flex-offset-md-45, [dir=rtl] .layout-margin .flex-offset-md-45, [dir=rtl] .layout-margin .offset-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-md-50, .flex-offset-md-50, .layout-margin .flex-offset-md-50, .layout-margin .offset-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-md-50, [dir=rtl] .flex-offset-md-50, [dir=rtl] .layout-margin .flex-offset-md-50, [dir=rtl] .layout-margin .offset-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-md-55, .flex-offset-md-55, .layout-margin .flex-offset-md-55, .layout-margin .offset-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-md-55, [dir=rtl] .flex-offset-md-55, [dir=rtl] .layout-margin .flex-offset-md-55, [dir=rtl] .layout-margin .offset-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-md-60, .flex-offset-md-60, .layout-margin .flex-offset-md-60, .layout-margin .offset-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-md-60, [dir=rtl] .flex-offset-md-60, [dir=rtl] .layout-margin .flex-offset-md-60, [dir=rtl] .layout-margin .offset-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-md-65, .flex-offset-md-65, .layout-margin .flex-offset-md-65, .layout-margin .offset-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-md-65, [dir=rtl] .flex-offset-md-65, [dir=rtl] .layout-margin .flex-offset-md-65, [dir=rtl] .layout-margin .offset-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-md-70, .flex-offset-md-70, .layout-margin .flex-offset-md-70, .layout-margin .offset-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-md-70, [dir=rtl] .flex-offset-md-70, [dir=rtl] .layout-margin .flex-offset-md-70, [dir=rtl] .layout-margin .offset-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-md-75, .flex-offset-md-75, .layout-margin .flex-offset-md-75, .layout-margin .offset-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-md-75, [dir=rtl] .flex-offset-md-75, [dir=rtl] .layout-margin .flex-offset-md-75, [dir=rtl] .layout-margin .offset-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-md-80, .flex-offset-md-80, .layout-margin .flex-offset-md-80, .layout-margin .offset-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-md-80, [dir=rtl] .flex-offset-md-80, [dir=rtl] .layout-margin .flex-offset-md-80, [dir=rtl] .layout-margin .offset-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-md-85, .flex-offset-md-85, .layout-margin .flex-offset-md-85, .layout-margin .offset-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-md-85, [dir=rtl] .flex-offset-md-85, [dir=rtl] .layout-margin .flex-offset-md-85, [dir=rtl] .layout-margin .offset-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-md-90, .flex-offset-md-90, .layout-margin .flex-offset-md-90, .layout-margin .offset-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-md-90, [dir=rtl] .flex-offset-md-90, [dir=rtl] .layout-margin .flex-offset-md-90, [dir=rtl] .layout-margin .offset-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-md-95, .flex-offset-md-95, .layout-margin .flex-offset-md-95, .layout-margin .offset-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-md-95, [dir=rtl] .flex-offset-md-95, [dir=rtl] .layout-margin .flex-offset-md-95, [dir=rtl] .layout-margin .offset-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-md-33, .flex-offset-md-33, .layout-margin .flex-offset-md-33, .layout-margin .offset-md-33 {
    margin-left: calc(100% / 3); }
  .offset-md-66, .flex-offset-md-66, .layout-margin .flex-offset-md-66, .layout-margin .offset-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-md-66, [dir=rtl] .flex-offset-md-66, [dir=rtl] .layout-margin .flex-offset-md-66, [dir=rtl] .layout-margin .offset-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-md,
  .layout-align-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-md-start,
  .layout-align-md-start-start,
  .layout-align-md-start-center,
  .layout-align-md-start-end,
  .layout-align-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-md-center,
  .layout-align-md-center-start,
  .layout-align-md-center-center,
  .layout-align-md-center-end,
  .layout-align-md-center-stretch {
    justify-content: center; }
  .layout-align-md-end,
  .layout-align-md-end-start,
  .layout-align-md-end-center,
  .layout-align-md-end-end,
  .layout-align-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-md-space-around,
  .layout-align-md-space-around-center,
  .layout-align-md-space-around-start,
  .layout-align-md-space-around-end,
  .layout-align-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-md-space-between,
  .layout-align-md-space-between-center,
  .layout-align-md-space-between-start,
  .layout-align-md-space-between-end,
  .layout-align-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-md-start-start,
  .layout-align-md-center-start,
  .layout-align-md-end-start,
  .layout-align-md-space-between-start,
  .layout-align-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-md-start-center,
  .layout-align-md-center-center,
  .layout-align-md-end-center,
  .layout-align-md-space-between-center,
  .layout-align-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-md-start-center > *,
  .layout-align-md-center-center > *,
  .layout-align-md-end-center > *,
  .layout-align-md-space-between-center > *,
  .layout-align-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-md-start-end,
  .layout-align-md-center-end,
  .layout-align-md-end-end,
  .layout-align-md-space-between-end,
  .layout-align-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-md-start-stretch,
  .layout-align-md-center-stretch,
  .layout-align-md-end-stretch,
  .layout-align-md-space-between-stretch,
  .layout-align-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-md-column > .flex-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-md-row > .flex {
    min-width: 0; }
  .layout-md-column > .flex-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-md-column > .flex-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-md-column > .flex {
    min-height: 0; }
  .layout-md, .layout-md-column, .layout-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-md-column {
    flex-direction: column; }
  .layout-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) {
  .flex-order-gt-md--20 {
    order: -20; }
  .flex-order-gt-md--19 {
    order: -19; }
  .flex-order-gt-md--18 {
    order: -18; }
  .flex-order-gt-md--17 {
    order: -17; }
  .flex-order-gt-md--16 {
    order: -16; }
  .flex-order-gt-md--15 {
    order: -15; }
  .flex-order-gt-md--14 {
    order: -14; }
  .flex-order-gt-md--13 {
    order: -13; }
  .flex-order-gt-md--12 {
    order: -12; }
  .flex-order-gt-md--11 {
    order: -11; }
  .flex-order-gt-md--10 {
    order: -10; }
  .flex-order-gt-md--9 {
    order: -9; }
  .flex-order-gt-md--8 {
    order: -8; }
  .flex-order-gt-md--7 {
    order: -7; }
  .flex-order-gt-md--6 {
    order: -6; }
  .flex-order-gt-md--5 {
    order: -5; }
  .flex-order-gt-md--4 {
    order: -4; }
  .flex-order-gt-md--3 {
    order: -3; }
  .flex-order-gt-md--2 {
    order: -2; }
  .flex-order-gt-md--1 {
    order: -1; }
  .flex-order-gt-md-0 {
    order: 0; }
  .flex-order-gt-md-1 {
    order: 1; }
  .flex-order-gt-md-2 {
    order: 2; }
  .flex-order-gt-md-3 {
    order: 3; }
  .flex-order-gt-md-4 {
    order: 4; }
  .flex-order-gt-md-5 {
    order: 5; }
  .flex-order-gt-md-6 {
    order: 6; }
  .flex-order-gt-md-7 {
    order: 7; }
  .flex-order-gt-md-8 {
    order: 8; }
  .flex-order-gt-md-9 {
    order: 9; }
  .flex-order-gt-md-10 {
    order: 10; }
  .flex-order-gt-md-11 {
    order: 11; }
  .flex-order-gt-md-12 {
    order: 12; }
  .flex-order-gt-md-13 {
    order: 13; }
  .flex-order-gt-md-14 {
    order: 14; }
  .flex-order-gt-md-15 {
    order: 15; }
  .flex-order-gt-md-16 {
    order: 16; }
  .flex-order-gt-md-17 {
    order: 17; }
  .flex-order-gt-md-18 {
    order: 18; }
  .flex-order-gt-md-19 {
    order: 19; }
  .flex-order-gt-md-20 {
    order: 20; }
  .offset-gt-md-0, .flex-offset-gt-md-0, .layout-margin .flex-offset-gt-md-0, .layout-margin .offset-gt-md-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-md-0, [dir=rtl] .flex-offset-gt-md-0, [dir=rtl] .layout-margin .flex-offset-gt-md-0, [dir=rtl] .layout-margin .offset-gt-md-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-md-5, .flex-offset-gt-md-5, .layout-margin .flex-offset-gt-md-5, .layout-margin .offset-gt-md-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-md-5, [dir=rtl] .flex-offset-gt-md-5, [dir=rtl] .layout-margin .flex-offset-gt-md-5, [dir=rtl] .layout-margin .offset-gt-md-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-md-10, .flex-offset-gt-md-10, .layout-margin .flex-offset-gt-md-10, .layout-margin .offset-gt-md-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-md-10, [dir=rtl] .flex-offset-gt-md-10, [dir=rtl] .layout-margin .flex-offset-gt-md-10, [dir=rtl] .layout-margin .offset-gt-md-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-md-15, .flex-offset-gt-md-15, .layout-margin .flex-offset-gt-md-15, .layout-margin .offset-gt-md-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-md-15, [dir=rtl] .flex-offset-gt-md-15, [dir=rtl] .layout-margin .flex-offset-gt-md-15, [dir=rtl] .layout-margin .offset-gt-md-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-md-20, .flex-offset-gt-md-20, .layout-margin .flex-offset-gt-md-20, .layout-margin .offset-gt-md-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-md-20, [dir=rtl] .flex-offset-gt-md-20, [dir=rtl] .layout-margin .flex-offset-gt-md-20, [dir=rtl] .layout-margin .offset-gt-md-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-md-25, .flex-offset-gt-md-25, .layout-margin .flex-offset-gt-md-25, .layout-margin .offset-gt-md-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-md-25, [dir=rtl] .flex-offset-gt-md-25, [dir=rtl] .layout-margin .flex-offset-gt-md-25, [dir=rtl] .layout-margin .offset-gt-md-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-md-30, .flex-offset-gt-md-30, .layout-margin .flex-offset-gt-md-30, .layout-margin .offset-gt-md-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-md-30, [dir=rtl] .flex-offset-gt-md-30, [dir=rtl] .layout-margin .flex-offset-gt-md-30, [dir=rtl] .layout-margin .offset-gt-md-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-md-35, .flex-offset-gt-md-35, .layout-margin .flex-offset-gt-md-35, .layout-margin .offset-gt-md-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-md-35, [dir=rtl] .flex-offset-gt-md-35, [dir=rtl] .layout-margin .flex-offset-gt-md-35, [dir=rtl] .layout-margin .offset-gt-md-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-md-40, .flex-offset-gt-md-40, .layout-margin .flex-offset-gt-md-40, .layout-margin .offset-gt-md-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-md-40, [dir=rtl] .flex-offset-gt-md-40, [dir=rtl] .layout-margin .flex-offset-gt-md-40, [dir=rtl] .layout-margin .offset-gt-md-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-md-45, .flex-offset-gt-md-45, .layout-margin .flex-offset-gt-md-45, .layout-margin .offset-gt-md-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-md-45, [dir=rtl] .flex-offset-gt-md-45, [dir=rtl] .layout-margin .flex-offset-gt-md-45, [dir=rtl] .layout-margin .offset-gt-md-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-md-50, .flex-offset-gt-md-50, .layout-margin .flex-offset-gt-md-50, .layout-margin .offset-gt-md-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-md-50, [dir=rtl] .flex-offset-gt-md-50, [dir=rtl] .layout-margin .flex-offset-gt-md-50, [dir=rtl] .layout-margin .offset-gt-md-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-md-55, .flex-offset-gt-md-55, .layout-margin .flex-offset-gt-md-55, .layout-margin .offset-gt-md-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-md-55, [dir=rtl] .flex-offset-gt-md-55, [dir=rtl] .layout-margin .flex-offset-gt-md-55, [dir=rtl] .layout-margin .offset-gt-md-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-md-60, .flex-offset-gt-md-60, .layout-margin .flex-offset-gt-md-60, .layout-margin .offset-gt-md-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-md-60, [dir=rtl] .flex-offset-gt-md-60, [dir=rtl] .layout-margin .flex-offset-gt-md-60, [dir=rtl] .layout-margin .offset-gt-md-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-md-65, .flex-offset-gt-md-65, .layout-margin .flex-offset-gt-md-65, .layout-margin .offset-gt-md-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-md-65, [dir=rtl] .flex-offset-gt-md-65, [dir=rtl] .layout-margin .flex-offset-gt-md-65, [dir=rtl] .layout-margin .offset-gt-md-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-md-70, .flex-offset-gt-md-70, .layout-margin .flex-offset-gt-md-70, .layout-margin .offset-gt-md-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-md-70, [dir=rtl] .flex-offset-gt-md-70, [dir=rtl] .layout-margin .flex-offset-gt-md-70, [dir=rtl] .layout-margin .offset-gt-md-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-md-75, .flex-offset-gt-md-75, .layout-margin .flex-offset-gt-md-75, .layout-margin .offset-gt-md-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-md-75, [dir=rtl] .flex-offset-gt-md-75, [dir=rtl] .layout-margin .flex-offset-gt-md-75, [dir=rtl] .layout-margin .offset-gt-md-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-md-80, .flex-offset-gt-md-80, .layout-margin .flex-offset-gt-md-80, .layout-margin .offset-gt-md-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-md-80, [dir=rtl] .flex-offset-gt-md-80, [dir=rtl] .layout-margin .flex-offset-gt-md-80, [dir=rtl] .layout-margin .offset-gt-md-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-md-85, .flex-offset-gt-md-85, .layout-margin .flex-offset-gt-md-85, .layout-margin .offset-gt-md-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-md-85, [dir=rtl] .flex-offset-gt-md-85, [dir=rtl] .layout-margin .flex-offset-gt-md-85, [dir=rtl] .layout-margin .offset-gt-md-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-md-90, .flex-offset-gt-md-90, .layout-margin .flex-offset-gt-md-90, .layout-margin .offset-gt-md-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-md-90, [dir=rtl] .flex-offset-gt-md-90, [dir=rtl] .layout-margin .flex-offset-gt-md-90, [dir=rtl] .layout-margin .offset-gt-md-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-md-95, .flex-offset-gt-md-95, .layout-margin .flex-offset-gt-md-95, .layout-margin .offset-gt-md-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-md-95, [dir=rtl] .flex-offset-gt-md-95, [dir=rtl] .layout-margin .flex-offset-gt-md-95, [dir=rtl] .layout-margin .offset-gt-md-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-md-33, .flex-offset-gt-md-33, .layout-margin .flex-offset-gt-md-33, .layout-margin .offset-gt-md-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-md-66, .flex-offset-gt-md-66, .layout-margin .flex-offset-gt-md-66, .layout-margin .offset-gt-md-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-md-66, [dir=rtl] .flex-offset-gt-md-66, [dir=rtl] .layout-margin .flex-offset-gt-md-66, [dir=rtl] .layout-margin .offset-gt-md-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-md,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-md-start,
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-md-center,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-center-stretch {
    justify-content: center; }
  .layout-align-gt-md-end,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-md-space-around,
  .layout-align-gt-md-space-around-center,
  .layout-align-gt-md-space-around-start,
  .layout-align-gt-md-space-around-end,
  .layout-align-gt-md-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-md-space-between,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-md-start-start,
  .layout-align-gt-md-center-start,
  .layout-align-gt-md-end-start,
  .layout-align-gt-md-space-between-start,
  .layout-align-gt-md-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-md-start-center,
  .layout-align-gt-md-center-center,
  .layout-align-gt-md-end-center,
  .layout-align-gt-md-space-between-center,
  .layout-align-gt-md-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-md-start-center > *,
  .layout-align-gt-md-center-center > *,
  .layout-align-gt-md-end-center > *,
  .layout-align-gt-md-space-between-center > *,
  .layout-align-gt-md-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-md-start-end,
  .layout-align-gt-md-center-end,
  .layout-align-gt-md-end-end,
  .layout-align-gt-md-space-between-end,
  .layout-align-gt-md-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-md-start-stretch,
  .layout-align-gt-md-center-stretch,
  .layout-align-gt-md-end-stretch,
  .layout-align-gt-md-space-between-stretch,
  .layout-align-gt-md-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-md {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-md-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-md-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-md-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-md-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-md-row > .flex {
    min-width: 0; }
  .layout-gt-md-column > .flex-gt-md-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex-gt-md-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-md-column > .flex {
    min-height: 0; }
  .layout-gt-md, .layout-gt-md-column, .layout-gt-md-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-md-column {
    flex-direction: column; }
  .layout-gt-md-row {
    flex-direction: row; } }

@media (min-width: 1280px) and (max-width: 1919px) {
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-lg):not(.show) {
    display: none; }
  .hide-lg:not(.show-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; }
  .flex-order-lg--20 {
    order: -20; }
  .flex-order-lg--19 {
    order: -19; }
  .flex-order-lg--18 {
    order: -18; }
  .flex-order-lg--17 {
    order: -17; }
  .flex-order-lg--16 {
    order: -16; }
  .flex-order-lg--15 {
    order: -15; }
  .flex-order-lg--14 {
    order: -14; }
  .flex-order-lg--13 {
    order: -13; }
  .flex-order-lg--12 {
    order: -12; }
  .flex-order-lg--11 {
    order: -11; }
  .flex-order-lg--10 {
    order: -10; }
  .flex-order-lg--9 {
    order: -9; }
  .flex-order-lg--8 {
    order: -8; }
  .flex-order-lg--7 {
    order: -7; }
  .flex-order-lg--6 {
    order: -6; }
  .flex-order-lg--5 {
    order: -5; }
  .flex-order-lg--4 {
    order: -4; }
  .flex-order-lg--3 {
    order: -3; }
  .flex-order-lg--2 {
    order: -2; }
  .flex-order-lg--1 {
    order: -1; }
  .flex-order-lg-0 {
    order: 0; }
  .flex-order-lg-1 {
    order: 1; }
  .flex-order-lg-2 {
    order: 2; }
  .flex-order-lg-3 {
    order: 3; }
  .flex-order-lg-4 {
    order: 4; }
  .flex-order-lg-5 {
    order: 5; }
  .flex-order-lg-6 {
    order: 6; }
  .flex-order-lg-7 {
    order: 7; }
  .flex-order-lg-8 {
    order: 8; }
  .flex-order-lg-9 {
    order: 9; }
  .flex-order-lg-10 {
    order: 10; }
  .flex-order-lg-11 {
    order: 11; }
  .flex-order-lg-12 {
    order: 12; }
  .flex-order-lg-13 {
    order: 13; }
  .flex-order-lg-14 {
    order: 14; }
  .flex-order-lg-15 {
    order: 15; }
  .flex-order-lg-16 {
    order: 16; }
  .flex-order-lg-17 {
    order: 17; }
  .flex-order-lg-18 {
    order: 18; }
  .flex-order-lg-19 {
    order: 19; }
  .flex-order-lg-20 {
    order: 20; }
  .offset-lg-0, .flex-offset-lg-0, .layout-margin .flex-offset-lg-0, .layout-margin .offset-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-lg-0, [dir=rtl] .flex-offset-lg-0, [dir=rtl] .layout-margin .flex-offset-lg-0, [dir=rtl] .layout-margin .offset-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-lg-5, .flex-offset-lg-5, .layout-margin .flex-offset-lg-5, .layout-margin .offset-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-lg-5, [dir=rtl] .flex-offset-lg-5, [dir=rtl] .layout-margin .flex-offset-lg-5, [dir=rtl] .layout-margin .offset-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-lg-10, .flex-offset-lg-10, .layout-margin .flex-offset-lg-10, .layout-margin .offset-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-lg-10, [dir=rtl] .flex-offset-lg-10, [dir=rtl] .layout-margin .flex-offset-lg-10, [dir=rtl] .layout-margin .offset-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-lg-15, .flex-offset-lg-15, .layout-margin .flex-offset-lg-15, .layout-margin .offset-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-lg-15, [dir=rtl] .flex-offset-lg-15, [dir=rtl] .layout-margin .flex-offset-lg-15, [dir=rtl] .layout-margin .offset-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-lg-20, .flex-offset-lg-20, .layout-margin .flex-offset-lg-20, .layout-margin .offset-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-lg-20, [dir=rtl] .flex-offset-lg-20, [dir=rtl] .layout-margin .flex-offset-lg-20, [dir=rtl] .layout-margin .offset-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-lg-25, .flex-offset-lg-25, .layout-margin .flex-offset-lg-25, .layout-margin .offset-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-lg-25, [dir=rtl] .flex-offset-lg-25, [dir=rtl] .layout-margin .flex-offset-lg-25, [dir=rtl] .layout-margin .offset-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-lg-30, .flex-offset-lg-30, .layout-margin .flex-offset-lg-30, .layout-margin .offset-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-lg-30, [dir=rtl] .flex-offset-lg-30, [dir=rtl] .layout-margin .flex-offset-lg-30, [dir=rtl] .layout-margin .offset-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-lg-35, .flex-offset-lg-35, .layout-margin .flex-offset-lg-35, .layout-margin .offset-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-lg-35, [dir=rtl] .flex-offset-lg-35, [dir=rtl] .layout-margin .flex-offset-lg-35, [dir=rtl] .layout-margin .offset-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-lg-40, .flex-offset-lg-40, .layout-margin .flex-offset-lg-40, .layout-margin .offset-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-lg-40, [dir=rtl] .flex-offset-lg-40, [dir=rtl] .layout-margin .flex-offset-lg-40, [dir=rtl] .layout-margin .offset-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-lg-45, .flex-offset-lg-45, .layout-margin .flex-offset-lg-45, .layout-margin .offset-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-lg-45, [dir=rtl] .flex-offset-lg-45, [dir=rtl] .layout-margin .flex-offset-lg-45, [dir=rtl] .layout-margin .offset-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-lg-50, .flex-offset-lg-50, .layout-margin .flex-offset-lg-50, .layout-margin .offset-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-lg-50, [dir=rtl] .flex-offset-lg-50, [dir=rtl] .layout-margin .flex-offset-lg-50, [dir=rtl] .layout-margin .offset-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-lg-55, .flex-offset-lg-55, .layout-margin .flex-offset-lg-55, .layout-margin .offset-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-lg-55, [dir=rtl] .flex-offset-lg-55, [dir=rtl] .layout-margin .flex-offset-lg-55, [dir=rtl] .layout-margin .offset-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-lg-60, .flex-offset-lg-60, .layout-margin .flex-offset-lg-60, .layout-margin .offset-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-lg-60, [dir=rtl] .flex-offset-lg-60, [dir=rtl] .layout-margin .flex-offset-lg-60, [dir=rtl] .layout-margin .offset-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-lg-65, .flex-offset-lg-65, .layout-margin .flex-offset-lg-65, .layout-margin .offset-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-lg-65, [dir=rtl] .flex-offset-lg-65, [dir=rtl] .layout-margin .flex-offset-lg-65, [dir=rtl] .layout-margin .offset-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-lg-70, .flex-offset-lg-70, .layout-margin .flex-offset-lg-70, .layout-margin .offset-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-lg-70, [dir=rtl] .flex-offset-lg-70, [dir=rtl] .layout-margin .flex-offset-lg-70, [dir=rtl] .layout-margin .offset-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-lg-75, .flex-offset-lg-75, .layout-margin .flex-offset-lg-75, .layout-margin .offset-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-lg-75, [dir=rtl] .flex-offset-lg-75, [dir=rtl] .layout-margin .flex-offset-lg-75, [dir=rtl] .layout-margin .offset-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-lg-80, .flex-offset-lg-80, .layout-margin .flex-offset-lg-80, .layout-margin .offset-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-lg-80, [dir=rtl] .flex-offset-lg-80, [dir=rtl] .layout-margin .flex-offset-lg-80, [dir=rtl] .layout-margin .offset-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-lg-85, .flex-offset-lg-85, .layout-margin .flex-offset-lg-85, .layout-margin .offset-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-lg-85, [dir=rtl] .flex-offset-lg-85, [dir=rtl] .layout-margin .flex-offset-lg-85, [dir=rtl] .layout-margin .offset-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-lg-90, .flex-offset-lg-90, .layout-margin .flex-offset-lg-90, .layout-margin .offset-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-lg-90, [dir=rtl] .flex-offset-lg-90, [dir=rtl] .layout-margin .flex-offset-lg-90, [dir=rtl] .layout-margin .offset-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-lg-95, .flex-offset-lg-95, .layout-margin .flex-offset-lg-95, .layout-margin .offset-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-lg-95, [dir=rtl] .flex-offset-lg-95, [dir=rtl] .layout-margin .flex-offset-lg-95, [dir=rtl] .layout-margin .offset-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-lg-33, .flex-offset-lg-33, .layout-margin .flex-offset-lg-33, .layout-margin .offset-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-lg-66, .flex-offset-lg-66, .layout-margin .flex-offset-lg-66, .layout-margin .offset-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-lg-66, [dir=rtl] .flex-offset-lg-66, [dir=rtl] .layout-margin .flex-offset-lg-66, [dir=rtl] .layout-margin .offset-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-lg,
  .layout-align-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-lg-start,
  .layout-align-lg-start-start,
  .layout-align-lg-start-center,
  .layout-align-lg-start-end,
  .layout-align-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-lg-center,
  .layout-align-lg-center-start,
  .layout-align-lg-center-center,
  .layout-align-lg-center-end,
  .layout-align-lg-center-stretch {
    justify-content: center; }
  .layout-align-lg-end,
  .layout-align-lg-end-start,
  .layout-align-lg-end-center,
  .layout-align-lg-end-end,
  .layout-align-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-lg-space-around,
  .layout-align-lg-space-around-center,
  .layout-align-lg-space-around-start,
  .layout-align-lg-space-around-end,
  .layout-align-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-lg-space-between,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-lg-start-start,
  .layout-align-lg-center-start,
  .layout-align-lg-end-start,
  .layout-align-lg-space-between-start,
  .layout-align-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-lg-start-center,
  .layout-align-lg-center-center,
  .layout-align-lg-end-center,
  .layout-align-lg-space-between-center,
  .layout-align-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-lg-start-center > *,
  .layout-align-lg-center-center > *,
  .layout-align-lg-end-center > *,
  .layout-align-lg-space-between-center > *,
  .layout-align-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-lg-start-end,
  .layout-align-lg-center-end,
  .layout-align-lg-end-end,
  .layout-align-lg-space-between-end,
  .layout-align-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-lg-start-stretch,
  .layout-align-lg-center-stretch,
  .layout-align-lg-end-stretch,
  .layout-align-lg-space-between-stretch,
  .layout-align-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-lg-column > .flex-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-lg-row > .flex {
    min-width: 0; }
  .layout-lg-column > .flex-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-lg-column > .flex-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-lg-column > .flex {
    min-height: 0; }
  .layout-lg, .layout-lg-column, .layout-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-lg-column {
    flex-direction: column; }
  .layout-lg-row {
    flex-direction: row; } }

@media (min-width: 1920px) {
  .flex-order-gt-lg--20 {
    order: -20; }
  .flex-order-gt-lg--19 {
    order: -19; }
  .flex-order-gt-lg--18 {
    order: -18; }
  .flex-order-gt-lg--17 {
    order: -17; }
  .flex-order-gt-lg--16 {
    order: -16; }
  .flex-order-gt-lg--15 {
    order: -15; }
  .flex-order-gt-lg--14 {
    order: -14; }
  .flex-order-gt-lg--13 {
    order: -13; }
  .flex-order-gt-lg--12 {
    order: -12; }
  .flex-order-gt-lg--11 {
    order: -11; }
  .flex-order-gt-lg--10 {
    order: -10; }
  .flex-order-gt-lg--9 {
    order: -9; }
  .flex-order-gt-lg--8 {
    order: -8; }
  .flex-order-gt-lg--7 {
    order: -7; }
  .flex-order-gt-lg--6 {
    order: -6; }
  .flex-order-gt-lg--5 {
    order: -5; }
  .flex-order-gt-lg--4 {
    order: -4; }
  .flex-order-gt-lg--3 {
    order: -3; }
  .flex-order-gt-lg--2 {
    order: -2; }
  .flex-order-gt-lg--1 {
    order: -1; }
  .flex-order-gt-lg-0 {
    order: 0; }
  .flex-order-gt-lg-1 {
    order: 1; }
  .flex-order-gt-lg-2 {
    order: 2; }
  .flex-order-gt-lg-3 {
    order: 3; }
  .flex-order-gt-lg-4 {
    order: 4; }
  .flex-order-gt-lg-5 {
    order: 5; }
  .flex-order-gt-lg-6 {
    order: 6; }
  .flex-order-gt-lg-7 {
    order: 7; }
  .flex-order-gt-lg-8 {
    order: 8; }
  .flex-order-gt-lg-9 {
    order: 9; }
  .flex-order-gt-lg-10 {
    order: 10; }
  .flex-order-gt-lg-11 {
    order: 11; }
  .flex-order-gt-lg-12 {
    order: 12; }
  .flex-order-gt-lg-13 {
    order: 13; }
  .flex-order-gt-lg-14 {
    order: 14; }
  .flex-order-gt-lg-15 {
    order: 15; }
  .flex-order-gt-lg-16 {
    order: 16; }
  .flex-order-gt-lg-17 {
    order: 17; }
  .flex-order-gt-lg-18 {
    order: 18; }
  .flex-order-gt-lg-19 {
    order: 19; }
  .flex-order-gt-lg-20 {
    order: 20; }
  .offset-gt-lg-0, .flex-offset-gt-lg-0, .layout-margin .flex-offset-gt-lg-0, .layout-margin .offset-gt-lg-0 {
    margin-left: 0; }
    [dir=rtl] .offset-gt-lg-0, [dir=rtl] .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .flex-offset-gt-lg-0, [dir=rtl] .layout-margin .offset-gt-lg-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-gt-lg-5, .flex-offset-gt-lg-5, .layout-margin .flex-offset-gt-lg-5, .layout-margin .offset-gt-lg-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-gt-lg-5, [dir=rtl] .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .flex-offset-gt-lg-5, [dir=rtl] .layout-margin .offset-gt-lg-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-gt-lg-10, .flex-offset-gt-lg-10, .layout-margin .flex-offset-gt-lg-10, .layout-margin .offset-gt-lg-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-gt-lg-10, [dir=rtl] .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .flex-offset-gt-lg-10, [dir=rtl] .layout-margin .offset-gt-lg-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-gt-lg-15, .flex-offset-gt-lg-15, .layout-margin .flex-offset-gt-lg-15, .layout-margin .offset-gt-lg-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-gt-lg-15, [dir=rtl] .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .flex-offset-gt-lg-15, [dir=rtl] .layout-margin .offset-gt-lg-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-gt-lg-20, .flex-offset-gt-lg-20, .layout-margin .flex-offset-gt-lg-20, .layout-margin .offset-gt-lg-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-gt-lg-20, [dir=rtl] .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .flex-offset-gt-lg-20, [dir=rtl] .layout-margin .offset-gt-lg-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-gt-lg-25, .flex-offset-gt-lg-25, .layout-margin .flex-offset-gt-lg-25, .layout-margin .offset-gt-lg-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-gt-lg-25, [dir=rtl] .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .flex-offset-gt-lg-25, [dir=rtl] .layout-margin .offset-gt-lg-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-gt-lg-30, .flex-offset-gt-lg-30, .layout-margin .flex-offset-gt-lg-30, .layout-margin .offset-gt-lg-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-gt-lg-30, [dir=rtl] .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .flex-offset-gt-lg-30, [dir=rtl] .layout-margin .offset-gt-lg-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-gt-lg-35, .flex-offset-gt-lg-35, .layout-margin .flex-offset-gt-lg-35, .layout-margin .offset-gt-lg-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-gt-lg-35, [dir=rtl] .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .flex-offset-gt-lg-35, [dir=rtl] .layout-margin .offset-gt-lg-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-gt-lg-40, .flex-offset-gt-lg-40, .layout-margin .flex-offset-gt-lg-40, .layout-margin .offset-gt-lg-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-gt-lg-40, [dir=rtl] .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .flex-offset-gt-lg-40, [dir=rtl] .layout-margin .offset-gt-lg-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-gt-lg-45, .flex-offset-gt-lg-45, .layout-margin .flex-offset-gt-lg-45, .layout-margin .offset-gt-lg-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-gt-lg-45, [dir=rtl] .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .flex-offset-gt-lg-45, [dir=rtl] .layout-margin .offset-gt-lg-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-gt-lg-50, .flex-offset-gt-lg-50, .layout-margin .flex-offset-gt-lg-50, .layout-margin .offset-gt-lg-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-gt-lg-50, [dir=rtl] .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .flex-offset-gt-lg-50, [dir=rtl] .layout-margin .offset-gt-lg-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-gt-lg-55, .flex-offset-gt-lg-55, .layout-margin .flex-offset-gt-lg-55, .layout-margin .offset-gt-lg-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-gt-lg-55, [dir=rtl] .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .flex-offset-gt-lg-55, [dir=rtl] .layout-margin .offset-gt-lg-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-gt-lg-60, .flex-offset-gt-lg-60, .layout-margin .flex-offset-gt-lg-60, .layout-margin .offset-gt-lg-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-gt-lg-60, [dir=rtl] .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .flex-offset-gt-lg-60, [dir=rtl] .layout-margin .offset-gt-lg-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-gt-lg-65, .flex-offset-gt-lg-65, .layout-margin .flex-offset-gt-lg-65, .layout-margin .offset-gt-lg-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-gt-lg-65, [dir=rtl] .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .flex-offset-gt-lg-65, [dir=rtl] .layout-margin .offset-gt-lg-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-gt-lg-70, .flex-offset-gt-lg-70, .layout-margin .flex-offset-gt-lg-70, .layout-margin .offset-gt-lg-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-gt-lg-70, [dir=rtl] .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .flex-offset-gt-lg-70, [dir=rtl] .layout-margin .offset-gt-lg-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-gt-lg-75, .flex-offset-gt-lg-75, .layout-margin .flex-offset-gt-lg-75, .layout-margin .offset-gt-lg-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-gt-lg-75, [dir=rtl] .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .flex-offset-gt-lg-75, [dir=rtl] .layout-margin .offset-gt-lg-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-gt-lg-80, .flex-offset-gt-lg-80, .layout-margin .flex-offset-gt-lg-80, .layout-margin .offset-gt-lg-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-gt-lg-80, [dir=rtl] .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .flex-offset-gt-lg-80, [dir=rtl] .layout-margin .offset-gt-lg-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-gt-lg-85, .flex-offset-gt-lg-85, .layout-margin .flex-offset-gt-lg-85, .layout-margin .offset-gt-lg-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-gt-lg-85, [dir=rtl] .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .flex-offset-gt-lg-85, [dir=rtl] .layout-margin .offset-gt-lg-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-gt-lg-90, .flex-offset-gt-lg-90, .layout-margin .flex-offset-gt-lg-90, .layout-margin .offset-gt-lg-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-gt-lg-90, [dir=rtl] .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .flex-offset-gt-lg-90, [dir=rtl] .layout-margin .offset-gt-lg-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-gt-lg-95, .flex-offset-gt-lg-95, .layout-margin .flex-offset-gt-lg-95, .layout-margin .offset-gt-lg-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-gt-lg-95, [dir=rtl] .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .flex-offset-gt-lg-95, [dir=rtl] .layout-margin .offset-gt-lg-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-gt-lg-33, .flex-offset-gt-lg-33, .layout-margin .flex-offset-gt-lg-33, .layout-margin .offset-gt-lg-33 {
    margin-left: calc(100% / 3); }
  .offset-gt-lg-66, .flex-offset-gt-lg-66, .layout-margin .flex-offset-gt-lg-66, .layout-margin .offset-gt-lg-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-gt-lg-66, [dir=rtl] .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .flex-offset-gt-lg-66, [dir=rtl] .layout-margin .offset-gt-lg-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-gt-lg,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-gt-lg-start,
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-start-stretch {
    justify-content: flex-start; }
  .layout-align-gt-lg-center,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-center-stretch {
    justify-content: center; }
  .layout-align-gt-lg-end,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-end-stretch {
    justify-content: flex-end; }
  .layout-align-gt-lg-space-around,
  .layout-align-gt-lg-space-around-center,
  .layout-align-gt-lg-space-around-start,
  .layout-align-gt-lg-space-around-end,
  .layout-align-gt-lg-space-around-stretch {
    justify-content: space-around; }
  .layout-align-gt-lg-space-between,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-between-stretch {
    justify-content: space-between; }
  .layout-align-gt-lg-start-start,
  .layout-align-gt-lg-center-start,
  .layout-align-gt-lg-end-start,
  .layout-align-gt-lg-space-between-start,
  .layout-align-gt-lg-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-gt-lg-start-center,
  .layout-align-gt-lg-center-center,
  .layout-align-gt-lg-end-center,
  .layout-align-gt-lg-space-between-center,
  .layout-align-gt-lg-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-gt-lg-start-center > *,
  .layout-align-gt-lg-center-center > *,
  .layout-align-gt-lg-end-center > *,
  .layout-align-gt-lg-space-between-center > *,
  .layout-align-gt-lg-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-gt-lg-start-end,
  .layout-align-gt-lg-center-end,
  .layout-align-gt-lg-end-end,
  .layout-align-gt-lg-space-between-end,
  .layout-align-gt-lg-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-gt-lg-start-stretch,
  .layout-align-gt-lg-center-stretch,
  .layout-align-gt-lg-end-stretch,
  .layout-align-gt-lg-space-between-stretch,
  .layout-align-gt-lg-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-gt-lg {
    flex: 1;
    box-sizing: border-box; }
  .flex-gt-lg-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-gt-lg-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-gt-lg-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-gt-lg-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-gt-lg-row > .flex {
    min-width: 0; }
  .layout-gt-lg-column > .flex-gt-lg-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex-gt-lg-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-gt-lg-column > .flex {
    min-height: 0; }
  .layout-gt-lg, .layout-gt-lg-column, .layout-gt-lg-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-gt-lg-column {
    flex-direction: column; }
  .layout-gt-lg-row {
    flex-direction: row; }
  .flex-order-xl--20 {
    order: -20; }
  .flex-order-xl--19 {
    order: -19; }
  .flex-order-xl--18 {
    order: -18; }
  .flex-order-xl--17 {
    order: -17; }
  .flex-order-xl--16 {
    order: -16; }
  .flex-order-xl--15 {
    order: -15; }
  .flex-order-xl--14 {
    order: -14; }
  .flex-order-xl--13 {
    order: -13; }
  .flex-order-xl--12 {
    order: -12; }
  .flex-order-xl--11 {
    order: -11; }
  .flex-order-xl--10 {
    order: -10; }
  .flex-order-xl--9 {
    order: -9; }
  .flex-order-xl--8 {
    order: -8; }
  .flex-order-xl--7 {
    order: -7; }
  .flex-order-xl--6 {
    order: -6; }
  .flex-order-xl--5 {
    order: -5; }
  .flex-order-xl--4 {
    order: -4; }
  .flex-order-xl--3 {
    order: -3; }
  .flex-order-xl--2 {
    order: -2; }
  .flex-order-xl--1 {
    order: -1; }
  .flex-order-xl-0 {
    order: 0; }
  .flex-order-xl-1 {
    order: 1; }
  .flex-order-xl-2 {
    order: 2; }
  .flex-order-xl-3 {
    order: 3; }
  .flex-order-xl-4 {
    order: 4; }
  .flex-order-xl-5 {
    order: 5; }
  .flex-order-xl-6 {
    order: 6; }
  .flex-order-xl-7 {
    order: 7; }
  .flex-order-xl-8 {
    order: 8; }
  .flex-order-xl-9 {
    order: 9; }
  .flex-order-xl-10 {
    order: 10; }
  .flex-order-xl-11 {
    order: 11; }
  .flex-order-xl-12 {
    order: 12; }
  .flex-order-xl-13 {
    order: 13; }
  .flex-order-xl-14 {
    order: 14; }
  .flex-order-xl-15 {
    order: 15; }
  .flex-order-xl-16 {
    order: 16; }
  .flex-order-xl-17 {
    order: 17; }
  .flex-order-xl-18 {
    order: 18; }
  .flex-order-xl-19 {
    order: 19; }
  .flex-order-xl-20 {
    order: 20; }
  .offset-xl-0, .flex-offset-xl-0, .layout-margin .flex-offset-xl-0, .layout-margin .offset-xl-0 {
    margin-left: 0; }
    [dir=rtl] .offset-xl-0, [dir=rtl] .flex-offset-xl-0, [dir=rtl] .layout-margin .flex-offset-xl-0, [dir=rtl] .layout-margin .offset-xl-0 {
      margin-left: auto;
      margin-right: 0; }
  .offset-xl-5, .flex-offset-xl-5, .layout-margin .flex-offset-xl-5, .layout-margin .offset-xl-5 {
    margin-left: 5%; }
    [dir=rtl] .offset-xl-5, [dir=rtl] .flex-offset-xl-5, [dir=rtl] .layout-margin .flex-offset-xl-5, [dir=rtl] .layout-margin .offset-xl-5 {
      margin-left: auto;
      margin-right: 5%; }
  .offset-xl-10, .flex-offset-xl-10, .layout-margin .flex-offset-xl-10, .layout-margin .offset-xl-10 {
    margin-left: 10%; }
    [dir=rtl] .offset-xl-10, [dir=rtl] .flex-offset-xl-10, [dir=rtl] .layout-margin .flex-offset-xl-10, [dir=rtl] .layout-margin .offset-xl-10 {
      margin-left: auto;
      margin-right: 10%; }
  .offset-xl-15, .flex-offset-xl-15, .layout-margin .flex-offset-xl-15, .layout-margin .offset-xl-15 {
    margin-left: 15%; }
    [dir=rtl] .offset-xl-15, [dir=rtl] .flex-offset-xl-15, [dir=rtl] .layout-margin .flex-offset-xl-15, [dir=rtl] .layout-margin .offset-xl-15 {
      margin-left: auto;
      margin-right: 15%; }
  .offset-xl-20, .flex-offset-xl-20, .layout-margin .flex-offset-xl-20, .layout-margin .offset-xl-20 {
    margin-left: 20%; }
    [dir=rtl] .offset-xl-20, [dir=rtl] .flex-offset-xl-20, [dir=rtl] .layout-margin .flex-offset-xl-20, [dir=rtl] .layout-margin .offset-xl-20 {
      margin-left: auto;
      margin-right: 20%; }
  .offset-xl-25, .flex-offset-xl-25, .layout-margin .flex-offset-xl-25, .layout-margin .offset-xl-25 {
    margin-left: 25%; }
    [dir=rtl] .offset-xl-25, [dir=rtl] .flex-offset-xl-25, [dir=rtl] .layout-margin .flex-offset-xl-25, [dir=rtl] .layout-margin .offset-xl-25 {
      margin-left: auto;
      margin-right: 25%; }
  .offset-xl-30, .flex-offset-xl-30, .layout-margin .flex-offset-xl-30, .layout-margin .offset-xl-30 {
    margin-left: 30%; }
    [dir=rtl] .offset-xl-30, [dir=rtl] .flex-offset-xl-30, [dir=rtl] .layout-margin .flex-offset-xl-30, [dir=rtl] .layout-margin .offset-xl-30 {
      margin-left: auto;
      margin-right: 30%; }
  .offset-xl-35, .flex-offset-xl-35, .layout-margin .flex-offset-xl-35, .layout-margin .offset-xl-35 {
    margin-left: 35%; }
    [dir=rtl] .offset-xl-35, [dir=rtl] .flex-offset-xl-35, [dir=rtl] .layout-margin .flex-offset-xl-35, [dir=rtl] .layout-margin .offset-xl-35 {
      margin-left: auto;
      margin-right: 35%; }
  .offset-xl-40, .flex-offset-xl-40, .layout-margin .flex-offset-xl-40, .layout-margin .offset-xl-40 {
    margin-left: 40%; }
    [dir=rtl] .offset-xl-40, [dir=rtl] .flex-offset-xl-40, [dir=rtl] .layout-margin .flex-offset-xl-40, [dir=rtl] .layout-margin .offset-xl-40 {
      margin-left: auto;
      margin-right: 40%; }
  .offset-xl-45, .flex-offset-xl-45, .layout-margin .flex-offset-xl-45, .layout-margin .offset-xl-45 {
    margin-left: 45%; }
    [dir=rtl] .offset-xl-45, [dir=rtl] .flex-offset-xl-45, [dir=rtl] .layout-margin .flex-offset-xl-45, [dir=rtl] .layout-margin .offset-xl-45 {
      margin-left: auto;
      margin-right: 45%; }
  .offset-xl-50, .flex-offset-xl-50, .layout-margin .flex-offset-xl-50, .layout-margin .offset-xl-50 {
    margin-left: 50%; }
    [dir=rtl] .offset-xl-50, [dir=rtl] .flex-offset-xl-50, [dir=rtl] .layout-margin .flex-offset-xl-50, [dir=rtl] .layout-margin .offset-xl-50 {
      margin-left: auto;
      margin-right: 50%; }
  .offset-xl-55, .flex-offset-xl-55, .layout-margin .flex-offset-xl-55, .layout-margin .offset-xl-55 {
    margin-left: 55%; }
    [dir=rtl] .offset-xl-55, [dir=rtl] .flex-offset-xl-55, [dir=rtl] .layout-margin .flex-offset-xl-55, [dir=rtl] .layout-margin .offset-xl-55 {
      margin-left: auto;
      margin-right: 55%; }
  .offset-xl-60, .flex-offset-xl-60, .layout-margin .flex-offset-xl-60, .layout-margin .offset-xl-60 {
    margin-left: 60%; }
    [dir=rtl] .offset-xl-60, [dir=rtl] .flex-offset-xl-60, [dir=rtl] .layout-margin .flex-offset-xl-60, [dir=rtl] .layout-margin .offset-xl-60 {
      margin-left: auto;
      margin-right: 60%; }
  .offset-xl-65, .flex-offset-xl-65, .layout-margin .flex-offset-xl-65, .layout-margin .offset-xl-65 {
    margin-left: 65%; }
    [dir=rtl] .offset-xl-65, [dir=rtl] .flex-offset-xl-65, [dir=rtl] .layout-margin .flex-offset-xl-65, [dir=rtl] .layout-margin .offset-xl-65 {
      margin-left: auto;
      margin-right: 65%; }
  .offset-xl-70, .flex-offset-xl-70, .layout-margin .flex-offset-xl-70, .layout-margin .offset-xl-70 {
    margin-left: 70%; }
    [dir=rtl] .offset-xl-70, [dir=rtl] .flex-offset-xl-70, [dir=rtl] .layout-margin .flex-offset-xl-70, [dir=rtl] .layout-margin .offset-xl-70 {
      margin-left: auto;
      margin-right: 70%; }
  .offset-xl-75, .flex-offset-xl-75, .layout-margin .flex-offset-xl-75, .layout-margin .offset-xl-75 {
    margin-left: 75%; }
    [dir=rtl] .offset-xl-75, [dir=rtl] .flex-offset-xl-75, [dir=rtl] .layout-margin .flex-offset-xl-75, [dir=rtl] .layout-margin .offset-xl-75 {
      margin-left: auto;
      margin-right: 75%; }
  .offset-xl-80, .flex-offset-xl-80, .layout-margin .flex-offset-xl-80, .layout-margin .offset-xl-80 {
    margin-left: 80%; }
    [dir=rtl] .offset-xl-80, [dir=rtl] .flex-offset-xl-80, [dir=rtl] .layout-margin .flex-offset-xl-80, [dir=rtl] .layout-margin .offset-xl-80 {
      margin-left: auto;
      margin-right: 80%; }
  .offset-xl-85, .flex-offset-xl-85, .layout-margin .flex-offset-xl-85, .layout-margin .offset-xl-85 {
    margin-left: 85%; }
    [dir=rtl] .offset-xl-85, [dir=rtl] .flex-offset-xl-85, [dir=rtl] .layout-margin .flex-offset-xl-85, [dir=rtl] .layout-margin .offset-xl-85 {
      margin-left: auto;
      margin-right: 85%; }
  .offset-xl-90, .flex-offset-xl-90, .layout-margin .flex-offset-xl-90, .layout-margin .offset-xl-90 {
    margin-left: 90%; }
    [dir=rtl] .offset-xl-90, [dir=rtl] .flex-offset-xl-90, [dir=rtl] .layout-margin .flex-offset-xl-90, [dir=rtl] .layout-margin .offset-xl-90 {
      margin-left: auto;
      margin-right: 90%; }
  .offset-xl-95, .flex-offset-xl-95, .layout-margin .flex-offset-xl-95, .layout-margin .offset-xl-95 {
    margin-left: 95%; }
    [dir=rtl] .offset-xl-95, [dir=rtl] .flex-offset-xl-95, [dir=rtl] .layout-margin .flex-offset-xl-95, [dir=rtl] .layout-margin .offset-xl-95 {
      margin-left: auto;
      margin-right: 95%; }
  .offset-xl-33, .flex-offset-xl-33, .layout-margin .flex-offset-xl-33, .layout-margin .offset-xl-33 {
    margin-left: calc(100% / 3); }
  .offset-xl-66, .flex-offset-xl-66, .layout-margin .flex-offset-xl-66, .layout-margin .offset-xl-66 {
    margin-left: calc(200% / 3); }
    [dir=rtl] .offset-xl-66, [dir=rtl] .flex-offset-xl-66, [dir=rtl] .layout-margin .flex-offset-xl-66, [dir=rtl] .layout-margin .offset-xl-66 {
      margin-left: auto;
      margin-right: calc(200% / 3); }
  .layout-align-xl,
  .layout-align-xl-start-stretch {
    justify-content: flex-start;
    align-content: stretch;
    align-items: stretch; }
  .layout-align-xl-start,
  .layout-align-xl-start-start,
  .layout-align-xl-start-center,
  .layout-align-xl-start-end,
  .layout-align-xl-start-stretch {
    justify-content: flex-start; }
  .layout-align-xl-center,
  .layout-align-xl-center-start,
  .layout-align-xl-center-center,
  .layout-align-xl-center-end,
  .layout-align-xl-center-stretch {
    justify-content: center; }
  .layout-align-xl-end,
  .layout-align-xl-end-start,
  .layout-align-xl-end-center,
  .layout-align-xl-end-end,
  .layout-align-xl-end-stretch {
    justify-content: flex-end; }
  .layout-align-xl-space-around,
  .layout-align-xl-space-around-center,
  .layout-align-xl-space-around-start,
  .layout-align-xl-space-around-end,
  .layout-align-xl-space-around-stretch {
    justify-content: space-around; }
  .layout-align-xl-space-between,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-between-stretch {
    justify-content: space-between; }
  .layout-align-xl-start-start,
  .layout-align-xl-center-start,
  .layout-align-xl-end-start,
  .layout-align-xl-space-between-start,
  .layout-align-xl-space-around-start {
    align-items: flex-start;
    align-content: flex-start; }
  .layout-align-xl-start-center,
  .layout-align-xl-center-center,
  .layout-align-xl-end-center,
  .layout-align-xl-space-between-center,
  .layout-align-xl-space-around-center {
    align-items: center;
    align-content: center;
    max-width: 100%; }
  .layout-align-xl-start-center > *,
  .layout-align-xl-center-center > *,
  .layout-align-xl-end-center > *,
  .layout-align-xl-space-between-center > *,
  .layout-align-xl-space-around-center > * {
    max-width: 100%;
    box-sizing: border-box; }
  .layout-align-xl-start-end,
  .layout-align-xl-center-end,
  .layout-align-xl-end-end,
  .layout-align-xl-space-between-end,
  .layout-align-xl-space-around-end {
    align-items: flex-end;
    align-content: flex-end; }
  .layout-align-xl-start-stretch,
  .layout-align-xl-center-stretch,
  .layout-align-xl-end-stretch,
  .layout-align-xl-space-between-stretch,
  .layout-align-xl-space-around-stretch {
    align-items: stretch;
    align-content: stretch; }
  .flex-xl {
    flex: 1;
    box-sizing: border-box; }
  .flex-xl-grow {
    flex: 1 1 100%;
    box-sizing: border-box; }
  .flex-xl-initial {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-auto {
    flex: 1 1 auto;
    box-sizing: border-box; }
  .flex-xl-none {
    flex: 0 0 auto;
    box-sizing: border-box; }
  .flex-xl-noshrink {
    flex: 1 0 auto;
    box-sizing: border-box; }
  .flex-xl-nogrow {
    flex: 0 1 auto;
    box-sizing: border-box; }
  .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 0%;
    max-height: 100%;
    box-sizing: border-box;
    min-width: 0; }
  .layout-xl-column > .flex-xl-0 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 0%;
    box-sizing: border-box;
    min-height: 0; }
  .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 5%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-5 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 5%;
    box-sizing: border-box; }
  .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 10%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-10 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 10%;
    box-sizing: border-box; }
  .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 15%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-15 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 15%;
    box-sizing: border-box; }
  .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-20 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 20%;
    box-sizing: border-box; }
  .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 25%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-25 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 25%;
    box-sizing: border-box; }
  .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 30%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-30 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 30%;
    box-sizing: border-box; }
  .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 35%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-35 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 35%;
    box-sizing: border-box; }
  .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 40%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-40 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 40%;
    box-sizing: border-box; }
  .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 45%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-45 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 45%;
    box-sizing: border-box; }
  .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 50%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box; }
  .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 55%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-55 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 55%;
    box-sizing: border-box; }
  .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 60%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-60 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 60%;
    box-sizing: border-box; }
  .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 65%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-65 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 65%;
    box-sizing: border-box; }
  .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 70%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-70 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 70%;
    box-sizing: border-box; }
  .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 75%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-75 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 75%;
    box-sizing: border-box; }
  .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 80%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-80 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 80%;
    box-sizing: border-box; }
  .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 85%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-85 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 85%;
    box-sizing: border-box; }
  .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 90%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-90 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 90%;
    box-sizing: border-box; }
  .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 95%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-95 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 95%;
    box-sizing: border-box; }
  .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-100 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-row > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-33 {
    flex: 1 1 33.33%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-column > .flex-xl-66 {
    flex: 1 1 66.66%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 33.33%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 66.66%;
    max-height: 100%;
    box-sizing: border-box; }
  .layout-xl-row > .flex {
    min-width: 0; }
  .layout-xl-column > .flex-xl-33 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 33.33%;
    box-sizing: border-box; }
  .layout-xl-column > .flex-xl-66 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 66.66%;
    box-sizing: border-box; }
  .layout-xl-column > .flex {
    min-height: 0; }
  .layout-xl, .layout-xl-column, .layout-xl-row {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
  .layout-xl-column {
    flex-direction: column; }
  .layout-xl-row {
    flex-direction: row; }
  .hide:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-xs:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-sm:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-md:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show), .hide-gt-lg:not(.show-gt-xs):not(.show-gt-sm):not(.show-gt-md):not(.show-gt-lg):not(.show-xl):not(.show) {
    display: none; }
  .hide-xl:not(.show-xl):not(.show-gt-lg):not(.show-gt-md):not(.show-gt-sm):not(.show-gt-xs):not(.show) {
    display: none; } }

@media print {
  .hide-print:not(.show-print):not(.show) {
    display: none !important; } }

/*for website helpers structure*/
/*@import 'helpers/variable.scss';*/
/*font family used as pr bank guideline*/
/*yes bank font icons*/
/* button styles */
/* anchor styles */
/* dropdown styles */
/* form feild variables */
/* gradient lower header bg */
/* Logo Style */
/* Slider */
/* Tab */
/*for website layout structure*/
/* here we are using gill fonts as per yes bank guidelines*/
/* updated the font to Cairo as per the guidelines received on Sept-2023*/
@font-face {
  font-family: "Cairo-Medium";
  src: url("../assets/fonts/cairo/Cairo-Medium.ttf") format("ttf"); }

@font-face {
  font-family: "Cairo-Bold";
  src: url("../assets/fonts/cairo/Cairo-Bold.ttf") format("ttf");
  font-weight: normal; }

@font-face {
  font-family: "yesicon";
  src: url("../assets/fonts/yesicon.eot") format("svg"); }

@font-face {
  font-family: "yesicon";
  src: url("../assets/fonts/yesicon.ttf") format("ttf"); }

* {
  outline: none; }

body,
html {
  background: #ffffff;
  /*height:auto;*/ }

body {
  font-family: "Cairo-Medium";
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #404040; }

.fullWidth {
  width: 100% !important; }

/*start of container classes*/
.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px; }

@media (max-width: 767px) {
  .container {
    width: 100%; } }

@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%; } }

@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .container {
    width: 1170px;
    max-width: 100%; } }

/*end of container classes*/
.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.font14normal {
  font-size: 14px !important;
  text-transform: none !important;
  font-family: "Cairo-Medium"; }

.uppercase {
  text-transform: uppercase !important; }

.lowercase {
  text-transform: lowercase !important; }

.normalcase {
  text-transform: none !important; }

a {
  text-decoration: underline;
  color: #0062a8; }

hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 1px;
  width: 100%;
  border: 0;
  margin: 40px 0;
  background: #f4f4f4;
  overflow: visible; }

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding: 0; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

ol {
  margin-left: 20px; }

a,
area,
button,
[role="button"],
input,
label,
select,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

@media print {
  *,
  *::before,
  *::after,
  p::first-letter,
  div::first-letter,
  blockquote::first-letter,
  li::first-letter,
  p::first-line,
  div::first-line,
  blockquote::first-line,
  li::first-line {
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .table {
    border-collapse: collapse !important; }
  .table td,
  .table th {
    background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/* Background Color */
.bg-grey2 {
  background: #fafafa; }

/* Device */
.only-for-mobile {
  display: none !important; }

.only-for-xs {
  display: none !important; }

.only-for-lg {
  display: none; }

.container:before,
.container:after {
  content: '';
  display: table;
  clear: both; }

md-radio-group {
  display: block; }

.loader {
  position: fixed;
  z-index: 99;
  border: 3px solid #d71920;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  color: #fff; }

.red-loader {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #d71920; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader1 {
  border: 3px solid #cccccc;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  display: inline-block;
  position: relative; }

.red-loader1 {
  border: 3px solid #cccccc;
  border-top: 3px solid #d71920;
  top: 16px; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

/* Sub Box */
/* Heading */
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

h1 {
  font-size: 36px;
  color: #404040; }

h2 {
  font-size: 21px; }

h3 {
  font-size: 20px;
  font-family: "Cairo-Bold"; }

h4 {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: normal; }

h6 {
  font-size: 20px;
  font-family: "Cairo-Bold";
  margin: 0; }

p {
  margin: 0; }
  p big {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
    display: block; }
  p.big {
    margin-top: 10px; }

.text-error {
  color: #d71920; }

.display-linline {
  display: inline-block; }

.div-table {
  display: table;
  width: 100%; }
  .div-table .table-cell {
    display: table-cell;
    padding: 0 10px; }

.link {
  color: #0062a8;
  text-decoration: underline; }

.main-form-container {
  padding: 0 80px 80px; }
  .main-form-container:before, .main-form-container:after {
    display: table;
    content: ''; }
  .main-form-container h2.name-capitalize {
    text-transform: capitalize; }
  .main-form-container h2 {
    font-size: 21px;
    line-height: 24px;
    color: #404040;
    position: relative;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 40px;
    padding-bottom: 5px;
    font-family: "Cairo-Bold";
    text-transform: uppercase; }
    .main-form-container h2:after {
      content: '';
      position: absolute;
      width: 100px;
      height: 5px;
      background-color: #d71920;
      left: 50%;
      margin-left: -50px;
      bottom: -5px; }
    .main-form-container h2 + p {
      margin-top: -10px;
      text-align: center; }
      .main-form-container h2 + p.text-right {
        text-align: right; }
  .main-form-container h3 {
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 5px;
    margin: 10px 0 40px;
    position: relative;
    display: inline-block;
    width: 100%;
    color: #404040; }
    .main-form-container h3:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 5px;
      background-color: #d71920;
      left: 0;
      bottom: -5px; }

.main-form-kfs-container {
  padding: 0 80px 80px; }
  .main-form-kfs-container:before, .main-form-kfs-container:after {
    display: table;
    content: ''; }
  .main-form-kfs-container h2.name-capitalize {
    text-transform: capitalize; }
  .main-form-kfs-container h2 {
    font-size: 21px;
    line-height: 24px;
    color: #404040;
    position: relative;
    text-align: center;
    margin-top: 35px;
    margin-bottom: 40px;
    padding-bottom: 5px;
    font-family: "Cairo-Bold";
    text-transform: uppercase; }
    .main-form-kfs-container h2:after {
      content: '';
      position: absolute;
      width: 100px;
      height: 5px;
      background-color: #d71920;
      left: 50%;
      margin-left: -50px;
      bottom: -5px; }
    .main-form-kfs-container h2 + p {
      margin-top: -10px;
      text-align: center; }
      .main-form-kfs-container h2 + p.text-right {
        text-align: right; }
  .main-form-kfs-container h3 {
    font-size: 18px;
    line-height: 21px;
    padding-bottom: 5px;
    margin: 10px 0 40px;
    position: relative;
    display: inline-block;
    width: 100%;
    color: #404040; }
    .main-form-kfs-container h3:after {
      content: '';
      position: absolute;
      width: 50px;
      height: 5px;
      background-color: #d71920;
      left: 0;
      bottom: -5px; }

.las-main-form-container:before, .las-main-form-container:after, .las-main-form-container:before, .las-main-form-container:after {
  display: table;
  content: ''; }

.las-main-form-container h2.name-capitalize, .las-main-form-container h2.name-capitalize {
  text-transform: capitalize; }

.las-main-form-container h2, .las-main-form-container h2 {
  font-size: 21px;
  line-height: 24px;
  color: #404040;
  position: relative;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 40px;
  padding-bottom: 5px;
  font-family: "Cairo-Bold";
  text-transform: uppercase; }
  .las-main-form-container h2:after, .las-main-form-container h2:after {
    content: '';
    position: absolute;
    width: 100px;
    height: 5px;
    background-color: #d71920;
    left: 50%;
    margin-left: -50px;
    bottom: -5px; }
  .las-main-form-container h2 + p, .las-main-form-container h2 + p {
    margin-top: -10px;
    text-align: center; }
    .las-main-form-container h2 + p.text-right, .las-main-form-container h2 + p.text-right {
      text-align: right; }

.las-main-form-container h3, .las-main-form-container h3 {
  font-size: 18px;
  line-height: 21px;
  padding-bottom: 5px;
  margin: 10px 0 40px;
  position: relative;
  display: inline-block;
  width: 100%;
  color: #404040; }
  .las-main-form-container h3:after, .las-main-form-container h3:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 5px;
    background-color: #d71920;
    left: 0;
    bottom: -5px; }

.upload-box {
  border-radius: 8px;
  padding: 42px 50px; }

.offers .thanksFacInfo {
  margin-top: 30px;
  text-align: center; }
  .offers .thanksFacInfo p {
    margin-top: 10px; }

.secondFPL {
  text-align: center;
  font-size: 18px;
  margin-top: 50px; }

.checkList-wrap {
  margin-top: 20px; }
  .checkList-wrap ul {
    max-width: 90%;
    margin: 0; }
    .checkList-wrap ul li {
      margin: 10px 0; }
      .checkList-wrap ul li label {
        text-transform: initial;
        cursor: pointer; }
      .checkList-wrap ul li input {
        margin-right: 5px; }

.yes-no-wrap {
  border-top: 1px solid #ddd;
  margin: 20px 0 50px;
  padding: 25px 0; }
  .yes-no-wrap > div {
    margin: 15px 0 0;
    display: flex; }
    .yes-no-wrap > div md-radio-group {
      margin-left: 5px; }

.customize-offer-container {
  /* For Firefox */
  /* Webkit browsers like Safari and Chrome */ }
  .customize-offer-container input[type='number'] {
    -moz-appearance: textfield; }
  .customize-offer-container input[type=number]::-webkit-inner-spin-button,
  .customize-offer-container input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0; }

/*start blue header bg*/
.bank-lower-header-bg {
  background-color: #ffffff;
  height: auto !important; }
  .bank-lower-header-bg h1 {
    margin: 0;
    font-size: 31px;
    font-family: "Cairo-Bold";
    text-align: center;
    font-weight: normal;
    line-height: 25px;
    color: #FFFFFF;
    text-transform: uppercase; }
    .bank-lower-header-bg h1 small {
      color: #9b9b9b;
      display: block;
      font-size: 14px;
      font-family: "Cairo-Medium"; }

/*end blue header bg*/
.header-wrapper {
  height: 185px !important;
  padding-top: 50px !important; }

@media (min-width: 768px) {
  .tab-wrapper {
    width: 100%; } }

.tab-container-pass {
  background-color: #FFFFFF;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  margin-top: -80px;
  min-height: 500px; }
  .tab-container-pass .tab-wrapper {
    display: -ms-flexbox;
    display: flex;
    transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);
    transform: translate3d(0, 0, 0);
    height: 80px;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .tab-container-pass .tab-wrapper li {
      float: none;
      -ms-flex: 1;
      flex: 1;
      height: 80px;
      line-height: 80px;
      font-size: 16px;
      padding: 0;
      text-transform: uppercase;
      background-color: #f7f7f7;
      color: #9b9b9b;
      text-align: center;
      list-style: none;
      font-weight: normal;
      border-right: solid 1px rgba(224, 224, 224, 0.7);
      border-bottom: solid 1px rgba(224, 224, 224, 0.7);
      cursor: pointer; }
      .tab-container-pass .tab-wrapper li:last-of-type {
        border-right: 0; }
      .tab-container-pass .tab-wrapper li.active {
        background-color: #0062a8;
        color: #fff;
        border: solid 1px rgba(0, 0, 0, 0); }
      .tab-container-pass .tab-wrapper li .icon-icon-Check {
        position: absolute;
        right: -4px;
        color: #fff;
        font-size: 12px;
        font-weight: bold;
        z-index: 100; }
      .tab-container-pass .tab-wrapper li i {
        font-size: 28px; }
      .tab-container-pass .tab-wrapper li span {
        display: inline-block; }
        .tab-container-pass .tab-wrapper li span.tab-text {
          margin-left: 4px; }
      .tab-container-pass .tab-wrapper li:disabled, .tab-container-pass .tab-wrapper li[disabled] {
        cursor: not-allowed;
        pointer-events: none; }
      .tab-container-pass .tab-wrapper li.tab_disabled {
        cursor: not-allowed;
        pointer-events: none; }

/* Sub Box */
.sub-box {
  position: relative;
  width: 490px;
  padding: 40px;
  font-size: 20px;
  color: #9b9b9b;
  background-color: #FFFFFF;
  border: 1px solid rgba(224, 224, 224, 0.7);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  margin: 0 auto 0;
  text-align: center; }
  .sub-box.customer-login {
    margin: -160px auto 80px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    border: none;
    width: 570px; }
    .sub-box.customer-login .hr-divider {
      margin: 20px 0; }
    .sub-box.customer-login .singinWithPassword {
      margin-top: 0; }
  .sub-box .optional-block {
    padding: 20px 20px 0;
    border: 1px solid #cccccc; }
    .sub-box .optional-block .optional-label {
      margin-bottom: 20px;
      font-size: 18px; }
      .sub-box .optional-block .optional-label.error {
        color: #ff0000; }
  .sub-box.upload-fin {
    margin-top: 50px;
    color: #404040; }
    .sub-box.upload-fin md-radio-button {
      margin-top: 20px;
      margin-bottom: 0; }
    .sub-box.upload-fin p:first-of-type {
      margin-bottom: 20px; }
  .sub-box h2 {
    margin-top: 0; }
  .sub-box h4 {
    background: #f2f2f2;
    margin: -80px -80px 40px;
    height: 52px;
    line-height: 52px; }
  .sub-box p small {
    font-size: 13px;
    margin: 15px 0 8px;
    display: block; }
  .sub-box button + p small {
    margin-bottom: 0; }

.anchor-spacing-under-btns {
  margin-top: 30px;
  text-transform: uppercase;
  font-size: 16px; }

.singinWithPassword {
  text-align: center;
  padding: 20px;
  background: #f6f6f6;
  margin: 40px -40px -40px;
  text-transform: uppercase;
  font-size: 14px;
  border-top: 1px solid rgba(224, 224, 224, 0.7); }

.structure-main {
  min-height: calc(100% - 158px);
  height: auto !important;
  /* This line and the next line are not necessary unless you need IE6 support */
  width: 100%; }

.secondary-block {
  background: #0062a8;
  padding: 30px;
  color: #fff;
  margin-bottom: 20px;
  cursor: pointer; }

.file-hint-text {
  text-align: center;
  margin-top: 10px !important; }

.paddingtop {
  padding-top: 10px; }

.padd-bottom {
  padding-bottom: 20px !important; }

.link-wl {
  color: #0062a8; }

.form-block.sub-box {
  max-width: 410px;
  margin: 0 auto; }

/* Verification-Documents */
.list-for-verification {
  position: relative;
  padding-right: 285px;
  min-height: 210px; }
  .list-for-verification .table {
    margin-bottom: 0; }
  .list-for-verification .doc-for-upload {
    position: absolute;
    right: 0;
    top: 59px;
    width: 265px; }
    .list-for-verification .doc-for-upload .btn-border {
      width: 100%;
      margin-bottom: 10px;
      padding: 0 15px !important; }
      .list-for-verification .doc-for-upload .btn-border:before, .list-for-verification .doc-for-upload .btn-border:after {
        display: none; }

.application-bredcrumb {
  position: relative;
  text-align: center;
  margin-bottom: 30px; }
  .application-bredcrumb a {
    font-size: 14px;
    line-height: 15px;
    color: #404040;
    text-decoration: none; }
    .application-bredcrumb a.prev, .application-bredcrumb a.next {
      position: absolute; }
    .application-bredcrumb a.prev {
      left: 0; }
    .application-bredcrumb a.current {
      cursor: default; }
      .application-bredcrumb a.current h2 {
        display: inline-block;
        margin-bottom: 0; }
    .application-bredcrumb a.next {
      right: 0; }

.inline-div {
  display: inline-block; }
  .inline-div .btn {
    padding: 0 20px !important;
    margin-left: -6px;
    height: 34px;
    line-height: 34px;
    top: -3px; }
    .inline-div .btn:before {
      border-right: 0; }

.verification-table {
  padding-bottom: 20px;
  border: 1px solid #cccccc; }
  .verification-table .default-textfield {
    width: 150px;
    margin-left: 10px;
    height: 34px;
    line-height: 32px;
    display: inline-block;
    padding: 0 10px; }
    .verification-table .default-textfield + .default-textfield {
      position: relative;
      top: -1px;
      margin-left: 6px; }
  .verification-table .md-select-value .md-select-icon:after {
    top: 1px; }
  .verification-table table {
    width: 100%; }
    .verification-table table:last-of-type {
      margin-bottom: 20px; }
    .verification-table table thead {
      background: #fafafa;
      border-bottom: #eaeaea solid 1px;
      color: #404040;
      font-size: 15px;
      font-family: "Cairo-Bold";
      text-align: left;
      height: 52px;
      line-height: 52px; }
      .verification-table table thead th {
        padding: 0 20px;
        text-transform: capitalize;
        /* &:last-child{padding-left:0;}*/ }
        .verification-table table thead th span {
          text-align: right;
          font-family: "Cairo-Medium";
          font-size: 13px; }
    .verification-table table tbody tr {
      border-bottom: #eaeaea solid 1px;
      height: 50px;
      line-height: 50px; }
      .verification-table table tbody tr td {
        padding: 0 20px;
        font-size: 15px; }
        .verification-table table tbody tr td label {
          min-width: 240px;
          text-transform: none;
          margin-bottom: 0;
          max-width: 240px;
          line-height: 14px;
          vertical-align: middle; }
        .verification-table table tbody tr td md-radio-group {
          font-size: 14px; }
          .verification-table table tbody tr td md-radio-group md-radio-button + md-radio-button {
            margin-left: 28px; }
          .verification-table table tbody tr td md-radio-group .md-label {
            color: #9b9b9b; }
          .verification-table table tbody tr td md-radio-group .md-checked .md-label {
            color: #404040; }
        .verification-table table tbody tr td:last-child {
          padding-left: 0;
          text-align: right;
          font-size: 11px; }

.ec-personalDetail md-checkbox .md-label {
  font-weight: bold; }

/*loader css*/
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #d71920;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  color: transparent;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  position: fixed;
  z-index: 99; }

.getdataLoader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #d71920;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  color: transparent;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
  position: fixed;
  z-index: 99; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.red-loader {
  border-top: 5px solid #d71920; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.loader-sm {
  border: 3px solid #cccccc;
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 18px;
  height: 18px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle; }

.red-loader-sm {
  border-top: 3px solid #d71920; }

.form-block .red-loader-sm {
  top: 10px;
  position: relative; }

.dsa-loader {
  position: absolute !important;
  right: 0; }

.material-icons {
  color: #999;
  font-size: 21px !important;
  vertical-align: middle;
  text-decoration: none; }

.otp-password-type {
  -webkit-text-security: disc;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield; }

.otp-password-type::-webkit-outer-spin-button,
.otp-password-type::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
.otp-password-type {
  -moz-appearance: textfield; }

.las-basic-accordion-heading-parent {
  width: 100%;
  cursor: pointer; }

.las-basic-accordion-heading-child-1 {
  width: 80%;
  float: left; }

.las-basic-accordion-heading-child-2 {
  width: 20%;
  float: right;
  text-align: right; }

/* Firefox */
.otp-password-type {
  -moz-appearance: textfield; }

.right-diamond-active:before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid #d71920;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.las-vertical-line {
  border-left: 3px solid #d71920;
  height: 60px; }

.las-main-heading {
  padding-bottom: 50px;
  padding-top: 20px; }

.las-main-heading-left {
  width: 50%;
  float: left;
  border-right: 2.5px solid #d71920;
  text-align: right;
  padding-right: 2%; }

.las-main-heading-right {
  width: 50%;
  float: right;
  padding-left: 2%;
  padding-top: 14px; }

@media only screen and (max-width: 767px) {
  .responsive-las-main-heading {
    display: none; } }

@media only screen and (min-width: 768px) {
  .las-info-wrapper {
    display: none; } }

.labelWithParagraph {
  color: #0062a8 !important;
  margin-top: 8px !important; }

.personal-detail-confirmation-same-line {
  display: flex;
  width: 100% !important; }

.customer-id-number::-webkit-outer-spin-button,
.customer-id-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
.customer-id-number {
  -moz-appearance: textfield; }

.other-charges-tooltip {
  position: relative;
  display: inline-block; }

.other-charges-tooltip .other-charges-tooltip-text {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: -152%;
  margin-left: -195px;
  opacity: 0;
  transition: opacity 0.3s; }

.other-charges-tooltip .other-charges-tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: 90px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent; }

.other-charges-tooltip:hover .other-charges-tooltip-text {
  visibility: visible;
  opacity: 1; }

.etb-left-half-red-border {
  position: relative;
  padding-left: 13px; }

.etb-left-half-red-border:after {
  content: "";
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  margin-top: 8px;
  height: 70%;
  background: #d71920; }

.etb-accordion-heading-parent {
  width: 100%;
  cursor: pointer;
  padding-bottom: 60px; }

.etb-accordion-heading-child-1 {
  width: 90%;
  float: left; }

.etb-accordion-heading-child-2 {
  width: 10%;
  float: right;
  text-align: right; }

.etb-accordion-icon {
  cursor: pointer; }

.etb-accordion-content {
  margin-top: 19px; }

.etb-accordion-form-content {
  padding: 18px 113px 0 113px; }

@media only screen and (max-width: 711px) {
  .responsive-additional-address {
    height: 64px;
    margin-bottom: 14px; } }

@media only screen and (min-width: 309px) and (max-width: 389px) {
  .responsive-additional-address {
    height: 85px; } }

@media (max-width: 992px) {
  .head-offer-etb {
    width: auto;
    min-height: 0px !important; } }

@media (max-width: 768px) {
  .continue-loan-application-great-mobile {
    margin-top: 120px !important; } }

@media (min-width: 769px) and (max-width: 992px) {
  .continue-loan-application-great-mobile {
    margin-top: 20px !important; } }

@media (min-width: 769px) {
  .sub-box-home {
    padding-top: 53px !important; } }

@media (max-width: 992px) {
  .assisted-yes {
    margin-right: 0px !important; }
  .kyc-upload-mobile {
    margin-top: -280px !important; } }

@media (max-width: 768px) {
  .passcode-thank-you {
    margin-top: -60px !important; }
  .disbursement-initiation-heading {
    margin-top: -60px !important; }
  .no-offer-box-mobile {
    margin-top: -75px !important; }
  .great-non-preapproved {
    margin-top: 210px !important; } }

@media (max-width: 767px) {
  .bl-loan-detail-box {
    margin-top: 55px !important; } }

.passcode-suggesstion {
  left: 0px !important;
  right: 0px !important; }

.al-tnc-download-link {
  display: inline-block;
  width: 75%;
  text-align: left; }

@media (min-width: 1000px) and (max-width: 1199px) {
  .al-tnc-download-link {
    margin-left: -8px; } }

@media (min-width: 769px) and (max-width: 992px) {
  .great-non-preapproved {
    margin-top: 20px !important; } }

@media (min-width: 1200px) {
  .al-tnc-download-link {
    margin-left: 52px; } }

@media (min-width: 769px) {
  .complete-loan-application-heading {
    margin-top: 30px !important; } }

.lis-logo-background .lis-logo {
  width: 32rem !important;
  margin-top: 5px;
  height: 11rem !important;
  top: 40px;
  bottom: 105px;
  line-height: 1.5;
  display: inline-block;
  vertical-align: middle;
  margin: 11rem 0 0 0 !important; }

.lis-logo-background {
  line-height: 200px;
  height: 100%;
  /* border: 3px solid green; */
  text-align: center;
  width: 100% !important;
  position: absolute;
  z-index: 20;
  top: 0px;
  /* bottom: 105px; */
  margin: 0 0% 0 0%;
  background: white; }

.popupCloseButton {
  background-color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: arial;
  font-weight: bold;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 45px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center; }

.popupCloseButton:hover {
  background-color: #ccc; }

@media (max-width: 320px) {
  .lis-logo-background .lis-logo {
    width: 15rem !important;
    height: 5.5rem !important; } }

@media (min-width: 321px) and (max-width: 375px) {
  .lis-logo-background .lis-logo {
    width: 17rem !important;
    height: 5.75rem !important; } }

@media (min-width: 376px) and (max-width: 730px) {
  .lis-logo-background .lis-logo {
    width: 22.25rem !important;
    height: 7.75rem !important; } }

@media (min-width: 731px) and (max-width: 1160px) {
  .lis-logo-background .lis-logo {
    width: 23.25rem !important;
    height: 8.75rem !important; } }

.left-half-red-border {
  position: relative; }

.left-half-red-border:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  margin-top: 12px;
  height: 28%;
  /* or 100px */
  background: #d71920; }

.las-accordion-icon {
  cursor: pointer; }

.las-accordion-content {
  margin-top: 12px; }

.right-diamond-active {
  width: 100px;
  height: 40px;
  position: relative;
  background: #d71920;
  color: white; }

thead .table-portofolio-sticky {
  position: sticky;
  top: 0;
  background-color: #E6F0F5 !important; }

.table-height {
  max-height: 52vh; }

.table-responsive {
  overflow: auto; }

.portfolio .enablePort thead td:first-child, .portfolio .enablePort thead td:nth-of-type(2) {
  top: 0;
  z-index: 10; }

.right-diamond-active:before {
  content: "";
  position: absolute;
  right: -10px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 10px solid #d71920;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent; }

.right-diamond-active {
  width: 100px;
  height: 40px;
  position: relative;
  background: #d71920;
  color: white; }

.left-half-red-border {
  position: relative; }

.left-half-red-border:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  margin-top: 12px;
  height: 28%;
  /* or 100px */
  background: #d71920; }

.las-accordion-icon {
  cursor: pointer; }

.las-accordion-content {
  margin-top: 12px; }

.right-diamond-active {
  width: 100px;
  height: 40px;
  position: relative;
  background: #d71920;
  color: white; }

thead .table-portofolio-sticky {
  position: sticky;
  top: 0;
  background-color: #E6F0F5 !important; }

.table-height {
  max-height: 52vh; }

.table-responsive {
  overflow: auto; }

.portfolio .enablePort thead td:first-child, .portfolio .enablePort thead td:nth-of-type(2) {
  top: 0;
  z-index: 10; }

.amc-wrapper {
  position: relative;
  width: 300px !important;
  bottom: 46px; }

.amc-tooltip {
  transform: none; }

.amc-tooltip:hover > .amc-tooltip-text, .amc-tooltip:hover > .amc-wrapper {
  pointer-events: auto;
  opacity: 0.8; }

.amc-tooltip > .amc-tooltip-text, .amc-tooltip > .amc-wrapper {
  left: 0;
  display: block;
  position: absolute;
  z-index: 6000;
  overflow: visible;
  padding: 5px 8px;
  margin-top: 10px;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  background: #000;
  pointer-events: none;
  opacity: 0.0;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

span.amc-tooltip.amc-tooltip-scroll {
  display: inline-block; }

/* Arrow */
.amc-tooltip > .amc-tooltip-text:before, .amc-tooltip > .amc-wrapper:before {
  display: inline;
  bottom: -10px;
  content: "";
  position: absolute;
  border: solid;
  border-color: black transparent;
  border-width: 10px 0.5em 0em 0.5em;
  z-index: 6000;
  left: 221px; }

/* Invisible area so you can hover over tooltip */
.amc-tooltip > .amc-tooltip-text:after, .amc-tooltip > .amc-wrapper:after {
  bottom: -20px;
  content: " ";
  display: block;
  height: 20px;
  position: absolute;
  width: 60px;
  left: 20px; }

.amc-wrapper > .amc-tooltip-text {
  overflow-y: auto;
  max-height: 266px;
  max-width: 300px;
  display: block; }

@media (min-width: 768px) and (max-width: 1199px) {
  .amc-tooltip > .amc-tooltip-text, .amc-tooltip > .amc-wrapper {
    left: -136px !important; } }

.second-factor-main:after {
  content: "";
  display: table;
  clear: both; }

.second-factor-column {
  float: left;
  width: 50%;
  padding: 10px;
  padding: 90px 50px 0px 0px; }

.second-factor-column-right {
  float: left;
  width: 50%; }

.second-factor-heading {
  color: #2967a1;
  font-size: 23px; }

.second-factor-radio-btn {
  padding: 20px 10px 20px 10px;
  border: 2px solid lightgrey;
  border-radius: 10px;
  display: inline-block;
  width: 124px;
  margin-right: 22px;
  margin-top: 20px; }

.second-factor-input {
  border-left: 0px !important;
  border-right: 0px !important;
  border-top: 0px !important;
  width: 340px !important;
  display: initial !important;
  padding: 0 7px !important; }

.factor-form {
  width: 340px !important; }

.second-factor-mobile {
  display: none; }

.error-netbanking {
  left: -4px !important;
  width: 365px;
  top: 39px; }

@media (max-width: 1199px) {
  .second-factor-radio-btn {
    padding: 8px 8px 8px 10px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    width: 124px;
    margin-right: 16px;
    margin-top: 10px; } }

@media (max-width: 991px) {
  .second-factor-radio-btn {
    padding: 8px 8px 8px 10px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    width: 124px;
    margin-right: 16px;
    margin-top: 10px; }
  .second-factor-input {
    border-left: 0px !important;
    border-right: 0px !important;
    border-top: 0px !important;
    width: calc(100% - 20px) !important;
    display: initial !important;
    padding: 0 7px !important; }
  .second-factor-image {
    width: 129%; }
  .second-factor-column {
    float: left;
    width: 50%;
    padding: 10px;
    padding: 50px 50px 0px 0px; }
  .error-netbanking {
    width: calc(100% + 5px) !important; }
  .factor-form {
    width: calc(100% - 0px) !important; } }

@media (min-width: 769px) and (max-width: 829px) {
  .second-factor-radio-btn {
    padding: 8px 8px 8px 10px;
    border: 2px solid lightgrey;
    border-radius: 10px;
    display: inline-block;
    width: 124px;
    margin-right: 16px;
    margin-top: 10px; }
  .factor-form {
    width: calc(100% - 0px) !important; }
  .error-netbanking {
    width: calc(100% + 5px) !important; } }

@media (min-width: 0px) and (max-width: 769px) {
  .second-factor-column-right {
    display: none; }
  .second-factor-column {
    width: auto;
    padding: 0px; }
  .second-factor-mobile {
    display: block; }
  .bank-lower-header-bg {
    display: none; }
  .second-factor-radio-btn {
    padding: 2px 8px 12px 10px; }
  .second-factor-input {
    width: calc(100% - 20px) !important; }
  .factor-form {
    width: calc(100% - 20px) !important; }
  .error-netbanking {
    width: calc(100% + 5px) !important; } }

.noselect-secondfactor {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome and Opera */ }

.pass-like-text {
  -webkit-text-security: disc;
  -moz-text-security: disc; }

.las-upload-container {
  padding: 0px 220px 0px !important; }

.lasDotfUploadList {
  max-height: none !important; }

.las-dotf-added-doc {
  min-height: fit-content !important;
  max-height: 800px !important; }

.red-loader2 {
  border: 3px solid #cccccc;
  border-top: 3px solid #d71920;
  top: 0px; }

@media (min-width: 0px) and (max-width: 767px) {
  .las-upload-container {
    padding: 0px 0px 0px !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    min-height: 0px !important; }
  .las-dotf-added-doc {
    min-height: fit-content !important;
    max-height: 800px !important; } }

.two-block-las {
  margin: 0 0px !important; }

@media (min-width: 0px) and (max-width: 650px) {
  .track-status {
    width: 100% !important; }
  .track-status .status-wrap {
    width: 100% !important; } }

.pass-like-text {
  -webkit-text-security: disc;
  -moz-text-security: disc; }

.branch-las-dotf {
  background: #d71920 !important; }

@media (min-width: 1200px) and (max-width: 1289px) {
  .breadcrumb-title-appNo {
    margin-left: -38px !important; } }

@media (min-width: 992px) and (max-width: 1077px) {
  .breadcrumb-title-appNo {
    margin-left: -48px !important; } }

@media (min-width: 827) and (max-width: 991px) {
  .breadcrumb-title-appNo {
    margin-left: -82px !important; } }

@media (min-width: 768px) and (max-width: 826px) {
  .breadcrumb-title-appNo {
    margin-left: -48px !important; } }

@media (min-width: 0px) and (max-width: 767px) {
  .breadcrumb-title-appNo {
    margin-left: -20px !important; } }

.on-road-price-table {
  background: white; }
  .on-road-price-table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%; }
  .on-road-price-table td, .on-road-price-table th {
    border: 1px solid #e9ece7;
    text-align: left;
    padding: 8px; }
  .on-road-price-table .serial-no {
    width: 100px;
    text-align: center; }
  .on-road-price-table .component {
    width: 360px; }
  .on-road-price-table .value-rupee {
    width: 198px;
    text-align: center; }
  .on-road-price-table .rupee-input {
    border: 0px;
    text-align: center;
    width: 100%; }
  .on-road-price-table .discount-strip {
    background: #fef2f1; }
  .on-road-price-table .total-strip {
    background: #ccffc4; }

.onRoadPricePopUp {
  border-style: solid;
  border-width: 4px 0px 0px 0px;
  border-color: #ce3225;
  background: #fafafa;
  cursor: pointer; }

.on-road-price-input {
  width: 60%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #404040;
  padding: 0 20px;
  background-color: #fff;
  text-transform: capitalize;
  font-family: "Cairo-Bold";
  text-align: left;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #cccccc; }

.on-road-price-button {
  display: inline-block;
  float: left;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 40%;
  text-align: center;
  text-decoration: none;
  /* white-space: nowrap; */
  vertical-align: middle;
  font-family: "Cairo-Medium";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 11px;
  height: 40px;
  line-height: 40px;
  /* padding: 0 40px; */
  border-radius: 0 !important;
  background: #1962a8;
  color: white;
  font-family: "Cairo-Bold";
  font-size: 12px !important; }

@media (max-width: 992px) {
  .uanNumberBox .form-field-row .error, .uanNumberBox .form-field-row .suggestion {
    left: 0px !important;
    right: 20px !important;
    min-width: auto; } }

header {
  height: 70px;
  background-color: #FFFFFF;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
  display: block;
  position: relative;
  z-index: 1; }
  header .header-logo {
    width: 322px;
    float: left;
    padding: 6px 0; }
    header .header-logo .logo,
    header .header-logo .poweredby {
      height: 58px; }
    header .header-logo .logo {
      float: left;
      width: 161px;
      background-position: -9px -3px; }
    header .header-logo .poweredby {
      width: 89px;
      float: left;
      background-position: -201px -3px;
      position: relative;
      margin-left: 71px; }
      header .header-logo .poweredby:before {
        content: '';
        position: absolute;
        width: 1px;
        height: 36px;
        background: #9b9b9b;
        left: -36px;
        top: 50%;
        margin-top: -18px; }
  header .header-right {
    float: right;
    text-transform: uppercase;
    font-size: 13px;
    font-family: "Cairo-Bold"; }
    header .header-right a {
      text-decoration: none; }
      header .header-right a.menu-link {
        float: left;
        color: #9b9b9b;
        border-bottom: 2px solid transparent;
        padding-bottom: 3px;
        margin: 27px 15px 23px; }
        header .header-right a.menu-link:hover, header .header-right a.menu-link.active {
          color: #0062a8;
          border-bottom: 2px solid #d71920; }
    header .header-right .user-login {
      height: 70px;
      margin-left: 15px;
      float: left;
      background-color: #d71920;
      display: flex;
      padding: 0 15px;
      align-items: center;
      color: #fff;
      position: relative;
      cursor: pointer; }
      header .header-right .user-login span {
        margin-left: 6px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        height: 24px;
        line-height: 24px;
        padding-right: 20px;
        position: relative; }
        header .header-right .user-login span em {
          font-style: normal; }
        header .header-right .user-login span:after {
          position: absolute;
          right: 0;
          content: "\e902";
          font-family: "yesicon";
          font-size: 8px; }
      header .header-right .user-login .loggedIn-item {
        position: absolute;
        top: 70px;
        font-size: 14px;
        background-color: #d71920;
        width: 240px;
        right: 0px;
        border-radius: 20px 0 20px 20px;
        padding: 20px; }
        header .header-right .user-login .loggedIn-item a {
          display: block;
          padding: 10px 0px;
          color: #fff;
          font-weight: normal;
          font-family: "Cairo-Medium";
          text-transform: capitalize;
          border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
          header .header-right .user-login .loggedIn-item a:last-child {
            border: none; }

footer {
  background-color: #D8D7D7;
  padding: 20px 0; }
  footer .footer-links a {
    color: #484747;
    font-size: 15px;
    position: relative;
    font-family: "Cairo-Bold";
    cursor: pointer;
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
    text-decoration: none; }
    footer .footer-links a:last-child {
      margin-right: 0; }
    footer .footer-links a:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 30px;
      height: 2px;
      background-color: #d71920; }
  footer .copyright {
    font-size: 10px;
    color: #7b7b7b;
    float: left;
    padding: 10px 0 0;
    margin: 0;
    clear: both; }
  footer .footer-half {
    float: left;
    width: 50%; }
    footer .footer-half .poweredby {
      width: 89px;
      display: inline-block;
      height: 58px;
      background-position: -197px -3px; }

.fixed-footer {
  position: fixed;
  bottom: 0;
  width: 100%; }

/* here we are overwrite the material css elements*/
md-content.md-default-theme,
md-content {
  background: transparent;
  color: inherit; }

md-radio-button {
  display: inline-block; }

md-select {
  margin: 0; }
  md-select .md-select-value span {
    font-weight: normal;
    font-family: "Cairo-Bold"; }
  md-select .md-select-value.md-select-placeholder span {
    font-weight: normal; }

md-select.md-default-theme .md-select-value,
md-select .md-select-value {
  border: 0;
  padding: 0 !important; }
  md-select.md-default-theme .md-select-value .md-select-icon,
  md-select .md-select-value .md-select-icon {
    margin: 0;
    transform: translate3d(0, 0, 0);
    color: #cccccc; }

md-select-menu.md-default-theme md-content md-option[selected],
md-select-menu md-content md-option[selected] {
  font-weight: normal;
  font-family: "Cairo-Bold";
  color: #404040; }
  md-select-menu.md-default-theme md-content md-option[selected]:focus,
  md-select-menu md-content md-option[selected]:focus {
    color: #404040; }

md-select.md-default-theme:not([disabled]):focus .md-select-value,
md-select:not([disabled]):focus .md-select-value,
md-select.md-default-theme[disabled] .md-select-value,
md-select[disabled] .md-select-value {
  border: 0 !important;
  background-image: none;
  font-family: "Cairo-Bold"; }

md-select-placeholder {
  font-weight: normal;
  font-family: "Cairo-Medium"; }

md-select-menu.md-default-theme md-content md-option:not([disabled]):focus,
md-select-menu md-content md-option:not([disabled]):focus,
md-select-menu.md-default-theme md-content md-option:not([disabled]):hover,
md-select-menu md-content md-option:not([disabled]):hover {
  background: #f5f5f5; }

md-select .md-select-value.md-select-placeholder span {
  font-weight: normal;
  font-family: "Cairo-Medium"; }

md-autocomplete-wrap .md-bar {
  background-color: #0062a8 !important; }

md-autocomplete-wrap .md-container {
  background-color: #c7e2f2; }

/* Radio Button */
md-radio-button {
  margin-bottom: 10px; }
  md-radio-button .md-label {
    margin-left: 40px;
    display: block; }
  md-radio-button .md-container,
  md-radio-button .md-on,
  md-radio-button .md-off {
    width: 30px;
    height: 30px; }
  md-radio-button .md-off {
    border-color: #cccccc; }
  md-radio-button .md-on {
    background-color: #0062a8; }
  md-radio-button.md-checked .md-label {
    color: #404040; }
  md-radio-button.md-checked .md-off {
    border-color: #0062a8; }

md-radio-group.md-default-theme.md-focused:not(:empty) .md-checked .md-container:before,
md-radio-group.md-focused:not(:empty) .md-checked .md-container:before {
  background-color: #ffffff !important; }

/* CheckBox */
md-checkbox {
  margin-bottom: 0;
  margin-top: 10px;
  display: block;
  min-height: 30px;
  min-width: 30px;
  text-align: left; }
  md-checkbox + md-checkbox {
    margin-top: 15px; }
  md-checkbox .md-container {
    top: 0;
    transform: translateY(0); }
  md-checkbox .md-container, md-checkbox .md-icon {
    width: 30px;
    height: 30px;
    border-radius: 0; }
  md-checkbox .md-label {
    margin-left: 40px;
    font-size: 14px; }
  md-checkbox.md-default-theme:not(.md-checked) .md-icon,
  md-checkbox:not(.md-checked) .md-icon {
    border-color: #cccccc; }
  md-checkbox.md-default-theme.md-checked .md-icon,
  md-checkbox.md-checked .md-icon {
    background-color: #0062a8; }
    md-checkbox.md-default-theme.md-checked .md-icon:after,
    md-checkbox.md-checked .md-icon:after {
      left: 5.66667px;
      top: 1.22222px;
      width: 6.66667px;
      height: 13.33333px; }

/* Overlay */
.md-dialog.md-default-theme, md-dialog {
  border-radius: 0;
  box-shadow: none; }

.md-scroll-mask > .md-scroll-mask-bar {
  background: rgba(0, 0, 0, 0.9); }

/* Range Slider */
md-slider {
  display: inline-block;
  height: 30px;
  margin: 0; }
  md-slider .md-track-container {
    height: 20px;
    background-color: #c7e2f2;
    border-top: #0062a8 3px solid;
    border-radius: 0;
    top: 0; }
  md-slider .md-track {
    height: 20px;
    background: none; }
    md-slider .md-track.md-track-fill {
      background-color: #0062a8;
      border-radius: 0; }
  md-slider .md-thumb {
    left: -18px; }
  md-slider .md-sign {
    background-color: #3d93d1;
    width: 30px;
    border-radius: 0;
    height: 30px;
    left: -7px;
    top: -5px;
    transform: scale(1) translate3d(0, 0, 0); }
    md-slider .md-sign .md-thumb-text {
      display: none; }
    md-slider .md-sign:after {
      top: 10px;
      left: 10px;
      border-radius: 0;
      transform: scale(1) translate3d(0, 0, 0) !important;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 0 20px 20px;
      border-color: transparent transparent #0062a8 transparent;
      z-index: 9;
      opacity: 1; }
  md-slider .md-default-theme.md-min[md-discrete] .md-thumb:after, md-slider .md-min[md-discrete] .md-thumb:after, md-slider .md-thumb:after {
    background-color: #3d93d1 !important;
    border-color: #0062a8 !important;
    display: none; }
  md-slider .md-track-ticks canvas {
    height: 0 !important; }

/*material slider overwrite*/
md-slider.md-min .md-sign {
  opacity: 1;
  top: -5px;
  left: 0;
  background-color: #3d93d1 !important;
  cursor: pointer; }

md-toast {
  top: 0 !important;
  display: block !important;
  left: 50% !important;
  margin-left: -152px;
  bottom: auto !important; }

md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content {
  background-color: #0062a8;
  color: #fff;
  width: 304px; }

.md-toast-animating {
  overflow: auto !important;
  overflow-x: hidden !important; }

md-dialog {
  overflow: visible; }

html.md-default-theme, html, body.md-default-theme, body {
  background-color: transparent !important; }

md-select[required].ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after, md-select.ng-required.ng-invalid:not(.md-no-asterisk) .md-select-value span:first-child:after {
  display: none; }

md-select.md-default-theme.ng-invalid.ng-touched .md-select-value, md-select.ng-invalid.ng-touched .md-select-value {
  color: #9b9b9b !important; }

body > div {
  overflow: auto;
  max-height: 100%; }

body .md-select-menu-container {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }

.breadcrumb-title {
  margin-top: 40px;
  margin-bottom: 5px;
  margin-left: -86px; }

.breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #EFEFEF;
  color: #000000;
  margin: 0px -86px 40px; }
  .breadcrumb .breadcrumb-item {
    width: calc(100% / 6);
    padding: 10px;
    text-align: center; }
    .breadcrumb .breadcrumb-item a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 24px; }
    .breadcrumb .breadcrumb-item.active a {
      color: #0062a8;
      font-weight: bold; }
    .breadcrumb .breadcrumb-item .floatright {
      float: right;
      transform: translateX(50%);
      font-size: 20px; }
    .breadcrumb .breadcrumb-item:last-child .floatright {
      display: none; }

@media all and (max-width: 767px) {
  .breadcrumb {
    display: none; }
  .breadcrumb-title {
    margin-top: 40px;
    margin-bottom: 40px; } }

.gennext-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #EFEFEF;
  color: #000000;
  margin: 0px 0 40px; }
  .gennext-breadcrumb .breadcrumb-item {
    width: calc(100% / 6);
    padding: 10px;
    text-align: center; }
    .gennext-breadcrumb .breadcrumb-item a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 24px; }
    .gennext-breadcrumb .breadcrumb-item.active a {
      color: #0062a8;
      font-weight: bold; }
    .gennext-breadcrumb .breadcrumb-item .floatright {
      float: right;
      transform: translateX(50%);
      font-size: 20px; }
    .gennext-breadcrumb .breadcrumb-item:last-child .floatright {
      display: none; }

@media all and (max-width: 767px) {
  .gennext-breadcrumb {
    display: none; }
  .breadcrumb-title {
    margin-top: 40px;
    margin-bottom: 40px; } }

.gennext-npa-breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  background-color: #EFEFEF;
  color: #000000;
  margin: 0px 0 40px; }
  .gennext-npa-breadcrumb .breadcrumb-item {
    width: calc(100%/9);
    padding: 10px;
    text-align: center; }
    .gennext-npa-breadcrumb .breadcrumb-item a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 24px; }
      .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: stretch;
        background-color: #EFEFEF;
        color: #000000;
        margin: 0px 0 40px; }
        .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb .breadcrumb-item {
          width: calc(100%/8);
          padding: 10px;
          text-align: center; }
          .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb .breadcrumb-item a {
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px; }
          .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb .breadcrumb-item.active a {
            color: #0062a8;
            font-weight: bold; }
          .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb .breadcrumb-item .floatright {
            float: right;
            transform: translateX(50%);
            font-size: 20px; }
          .gennext-npa-breadcrumb .breadcrumb-item a .gennext-npa-breadcrumb .breadcrumb-item:last-child .floatright {
            display: none; }
    .gennext-npa-breadcrumb .breadcrumb-item.active a {
      color: #0062a8;
      font-weight: bold; }
    .gennext-npa-breadcrumb .breadcrumb-item .floatright {
      float: right;
      transform: translateX(50%);
      font-size: 20px; }
    .gennext-npa-breadcrumb .breadcrumb-item:last-child .floatright {
      display: none; }

@media all and (max-width: 767px) {
  .gennext-npa-breadcrumb {
    display: none; }
  .breadcrumb-title {
    margin-top: 40px;
    margin-bottom: 40px; } }

.breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  background-color: #EFEFEF;
  color: #000000;
  margin: 0px -86px 40px; }
  .breadcrumb .breadcrumb-dc-item {
    width: calc(100% / 5);
    padding: 10px;
    text-align: center; }
    .breadcrumb .breadcrumb-dc-item a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;
      line-height: 24px; }
    .breadcrumb .breadcrumb-dc-item.active a {
      color: #0062a8;
      font-weight: bold; }
    .breadcrumb .breadcrumb-dc-item .floatright {
      float: right;
      transform: translateX(50%);
      font-size: 20px; }
    .breadcrumb .breadcrumb-dc-item:last-child .floatright {
      display: none; }

@media (min-width: 0px) and (max-width: 810px) {
  .las-breadcrumb-web {
    display: none; }
  .las-breadcrumb-mobile {
    display: block; }
    .las-breadcrumb-mobile .breadcrumb-dc-item {
      width: calc(100% / 1); }
    .las-breadcrumb-mobile .breadcrumb-item {
      width: calc(100% / 1); } }

@media (min-width: 0px) and (max-width: 810px) {
  .las-breadcrumb-mobile {
    margin-right: -21px; } }

@media (min-width: 791px) and (max-width: 910px) {
  .las-breadcrumb-mobile {
    margin-right: -57px; } }

@media (min-width: 768px) and (max-width: 790px) {
  .las-breadcrumb-mobile {
    margin-right: -49px; } }

@media (min-width: 811px) {
  .las-breadcrumb-mobile {
    display: none; } }

/*for website compenents*/
.btn {
  display: inline-block;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;
  font-family: "Cairo-Medium";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 11px;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  border-radius: 0 !important; }
  .btn.btn-large {
    min-width: 450px; }
  .btn.btn-xlg {
    width: 300px; }
  .btn.btn-primary {
    background: #d71920;
    color: #fff; }
  .btn.btn-block {
    width: 100%; }
  .btn.btn-border {
    border: solid 1px #0062a8;
    color: #0062a8;
    background: #ffffff; }
  .btn.small-btn {
    height: auto;
    line-height: normal;
    padding: 9px 10px;
    font-size: 14px; }
  .btn.mid-btn {
    line-height: 50px;
    height: 50px;
    padding: 0px 20px;
    font-size: 14px; }
  .btn.without-bg {
    color: #9b9b9b;
    background: none; }
    .btn.without-bg:hover {
      background: #d71920;
      color: #fff; }
  .btn.link {
    border: 0;
    text-decoration: underline;
    padding: 0 20px; }
    .btn.link:before, .btn.link:after {
      display: none;
      padding: 0;
      text-align: center; }

button.link {
  border: 0;
  background: none; }

.btn-social {
  width: 150px;
  height: 50px;
  line-height: 50px;
  border-radius: 0;
  color: #fff;
  font-size: 24px;
  font-weight: normal;
  text-transform: none;
  text-decoration: none;
  margin-top: 15px;
  padding: 0;
  min-width: auto;
  background-image: url(../assets/images/yes-icon.png);
  background-size: 489px auto; }
  .btn-social.fb {
    background-position: -358px -80px; }
  .btn-social.linkedIn {
    background-position: -358px -128px; }
  .btn-social + .btn {
    margin-left: 6px; }

.anchor-primary {
  color: #0062a8;
  text-decoration: underline;
  font-family: "Cairo-Bold"; }

.button-conditional {
  margin-top: 40px; }
  .button-conditional .btn + .btn {
    margin-left: 35px; }

.button {
  background-color: #4CAF50;
  /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; }

.button3 {
  background-color: #f44336; }

/* Red */
.customer-overlay {
  position: relative;
  width: 530px;
  padding: 40px;
  font-size: 20px;
  color: #9b9b9b; }
  .customer-overlay h2 {
    margin-top: 0; }

.confirmation-layer {
  padding: 60px;
  text-align: center; }
  .confirmation-layer.main-form-container {
    width: auto;
    padding: 60px;
    min-height: auto; }
    .confirmation-layer.main-form-container h2 {
      margin-top: 0; }
  .confirmation-layer.main-form-kfs-container {
    width: auto;
    padding: 60px;
    min-height: auto; }
    .confirmation-layer.main-form-kfs-container h2 {
      margin-top: 0; }
  .confirmation-layer h3 {
    line-height: 16px;
    color: #404040;
    margin: 0 0 15px; }
  .confirmation-layer p {
    font-size: 14px;
    line-height: 15px;
    color: #9b9b9b; }
  .confirmation-layer .confirm-btns {
    margin-top: 30px; }
    .confirmation-layer .confirm-btns .btn {
      width: 105px; }
      .confirmation-layer .confirm-btns .btn + .btn {
        margin-left: 7px; }

.close-overlay {
  position: absolute;
  width: 50px;
  height: 50px;
  background: #d71920;
  color: #fff;
  text-align: center;
  line-height: 49px;
  right: -50px;
  top: 0;
  cursor: pointer; }
  .close-overlay i {
    vertical-align: middle; }

.md-dialog-container .main-form-container {
  overflow-y: auto;
  text-align: left;
  padding: 40px;
  min-height: auto;
  max-width: 780px; }
  .md-dialog-container .main-form-container h2 {
    margin-top: 0; }
    .md-dialog-container .main-form-container h2 + p {
      text-align: left;
      font-size: 14px !important; }

.md-dialog-container .main-form-kfs-container {
  overflow-y: auto;
  text-align: left;
  padding: 40px;
  min-height: auto;
  max-width: 1060px; }
  .md-dialog-container .main-form-kfs-container h2 {
    margin-top: 0; }
    .md-dialog-container .main-form-kfs-container h2 + p {
      text-align: left;
      font-size: 14px !important; }

.icon {
  font-family: "yesicon";
  display: inline-block;
  font-style: normal;
  text-decoration: none;
  font-weight: normal; }

.icon-Delete:before {
  content: "\e900"; }

.icon-icon-Check:before {
  content: "\e901"; }

.icon-IconArrow:before {
  content: "\e902"; }

.icon-IconClose:before {
  content: "\e903"; }

.icon-IconEmployer:before {
  content: "\e904"; }

.icon-iconInformation:before {
  content: "\e905"; }

.icon-IconKyc:before {
  content: "\e906"; }

.icon-IconLoanDetail:before {
  content: "\e907"; }

.icon-IconReferences:before {
  content: "\e908"; }

.icon-IconUpload:before {
  content: "\e909"; }

.icon-IconUser:before {
  content: "\e90a"; }

.icon-img {
  background-image: url(../assets/images/yes-icon.png);
  background-size: 617px auto; }
  .icon-img.icon-sm {
    background-size: 322px auto; }

.icon-user {
  background-position: -318px -18px;
  height: 27px;
  width: 27px; }

.icon-thanks, .icon-offer, .icon-great {
  width: 87px;
  height: 87px;
  margin: 0 auto; }

.icon-thanks {
  background-position: -329px -79px; }

.icon-offer {
  background-position: -548px -78px; }

.icon-great {
  background-position: -438px -78px; }

.up-arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #9b9b9b;
  display: inline-block;
  vertical-align: middle; }

.down-arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  display: inline-block;
  border-top: 4px solid #9b9b9b;
  vertical-align: middle; }

.left-arrow, .right-arrow {
  width: 15px;
  height: 15px;
  background-image: url(../assets/images/doc-icon.png);
  background-repeat: no-repeat;
  background-size: 267px auto;
  position: relative;
  top: 3px; }

.left-arrow {
  background-position: -208px -17px;
  margin-right: 7px; }

.right-arrow {
  background-position: -242px -17px;
  margin-left: 7px; }

/* 
Bootstrap stand alone css
https://cdnjs.com/libraries/bootstrap-datetimepicker
*/
.glyphicon {
  position: relative;
  top: 2px;
  display: inline-block;
  background-image: url(../assets/images/yes-icon.png);
  background-size: 617px auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 7px;
  height: 10px; }

.glyphicon-chevron-left {
  background-position: -371px -28px; }

.glyphicon-chevron-right {
  background-position: -389px -28px; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

/*Custom date picker css*/
.dropdown-menu {
  padding: 5px;
  background-color: #fff;
  border: 1px solid rgba(224, 224, 224, 0.7);
  border-radius: 0;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  /*Hiding bottom bar's clear and today buttons*/ }
  .dropdown-menu .btn {
    padding: 6px 12px;
    min-width: auto;
    height: auto;
    line-height: 1.5;
    border: 1px solid transparent; }
    .dropdown-menu .btn:before, .dropdown-menu .btn:after {
      display: none; }
  .dropdown-menu .btn-sm {
    padding: 5px 10px; }
  .dropdown-menu .btn-default {
    color: #404040;
    background-color: #fff;
    border-color: rgba(224, 224, 224, 0.7); }
    .dropdown-menu .btn-default.active:hover {
      color: #404040;
      background-color: #f2f2f2;
      border-color: #cccccc; }
    .dropdown-menu .btn-default:hover {
      color: #404040;
      background-color: #f2f2f2;
      border-color: #cccccc; }
  .dropdown-menu .btn-info {
    color: #fff;
    background-color: #0062a8;
    border-color: #0062a8; }
    .dropdown-menu .btn-info:hover {
      color: #fff;
      background-color: #0062a8;
      border-color: #0062a8; }
  .dropdown-menu .btn-danger, .dropdown-menu .btn-success {
    background-color: #d71920;
    color: #fff; }
    .dropdown-menu .btn-danger:hover, .dropdown-menu .btn-success:hover {
      background-color: #d71920;
      color: #fff; }
  .dropdown-menu .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0; }
  .dropdown-menu em {
    font-style: normal; }
  .dropdown-menu button {
    background-color: transparent; }
  .dropdown-menu input[type="reset"][disabled], .dropdown-menu input[type="submit"][disabled], .dropdown-menu html input[type="button"][disabled], .dropdown-menu button[disabled] {
    cursor: default;
    background-color: rgba(220, 220, 220, 0.15);
    color: #9b9b9b; }
    .dropdown-menu input[type="reset"][disabled]:hover, .dropdown-menu input[type="submit"][disabled]:hover, .dropdown-menu html input[type="button"][disabled]:hover, .dropdown-menu button[disabled]:hover {
      background-color: rgba(220, 220, 220, 0.15);
      border-color: rgba(224, 224, 224, 0.7);
      color: #9b9b9b; }
  .dropdown-menu .uib-month .btn {
    min-width: 100%; }
  .dropdown-menu .btn-group {
    display: none; }

/*for website forms structure*/
textarea {
  resize: vertical; }

a {
  cursor: pointer; }

input[type="search"] {
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box; }

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  cursor: not-allowed; }

button,
input,
select,
textarea {
  font-family: "Cairo-Medium";
  font-size: 14px;
  color: #404040;
  margin: 0; }

label {
  display: inline-block;
  margin-bottom: 5px;
  text-transform: uppercase; }

.range-container {
  position: relative;
  display: inline-block;
  margin-bottom: 40px; }
  .range-container .range-prefix {
    position: absolute;
    left: -35px; }

.form-block:before, .form-block:after {
  content: '';
  display: table;
  clear: both; }

.form-block:last-of-type .btn {
  margin-top: 20px; }

.form-block + h2 {
  margin-top: 15px; }

.form-block.last-block .form-field-row {
  margin-bottom: 0 !important; }

.form-field-row {
  margin-bottom: 20px;
  color: #9b9b9b;
  font-size: 14px;
  position: relative; }
  .form-field-row.row-full-expand {
    width: 100%;
    float: left; }
  .form-field-row md-radio-button {
    margin-right: 65px; }
    .form-field-row md-radio-button:last-of-type {
      margin-right: 0; }
  .form-field-row label {
    display: block;
    font-size: 12px;
    font-family: "Cairo-Bold";
    color: #9b9b9b; }
    .form-field-row label.label-lg {
      font-size: 14px;
      font-weight: normal;
      font-family: "Cairo-Medium";
      margin-bottom: 10px;
      text-transform: none; }
  .form-field-row .upload-box label {
    color: #404040; }
  .form-field-row.with-prefix.i-number .default-textfield {
    padding-left: 50px; }
  .form-field-row.with-prefix .field-prefix {
    position: absolute;
    color: #404040;
    left: 60px;
    height: 40px;
    font-family: "Cairo-Bold";
    line-height: 40px; }
  .form-field-row.with-prefix .default-textfield {
    padding-left: 40px; }
  .form-field-row .error, .form-field-row .suggestion {
    position: absolute;
    font-size: 11px;
    line-height: 13px;
    color: #fff;
    text-transform: none;
    text-align: left;
    padding: 3px 20px;
    min-width: 330px;
    z-index: 2; }
  .form-field-row.fullWidth .error, .form-field-row.fullWidth .suggestion {
    left: 0;
    right: 0; }
  .form-field-row .suggestion {
    background: #0062a8;
    display: none; }
  .form-field-row .error {
    background: #ff0000; }
  .form-field-row .error-las {
    position: absolute;
    font-size: 11px;
    line-height: 13px;
    color: #fff;
    text-transform: none;
    text-align: left;
    padding: 3px 20px;
    min-width: 146px;
    z-index: 2; }
  .form-field-row.fullWidth .error-las {
    left: 0;
    right: 0; }
  .form-field-row .error-las {
    background: #ff0000; }
  @media only screen and (min-width: 1199px) {
    .form-field-row .error-las {
      position: absolute;
      font-size: 11px;
      line-height: 13px;
      color: #fff;
      text-transform: none;
      text-align: left;
      padding: 3px 20px;
      min-width: 248px;
      z-index: 2; } }
  @media only screen and (min-width: 993px) and (max-width: 1198px) {
    .form-field-row .error-las {
      position: absolute;
      font-size: 11px;
      line-height: 13px;
      color: #fff;
      text-transform: none;
      text-align: left;
      padding: 3px 20px;
      min-width: 198px;
      z-index: 2; } }
  .form-field-row:focus .suggestion {
    display: block; }
  .form-field-row .otp-timer-table {
    right: 20px;
    top: 50%;
    text-align: right;
    width: 80px;
    display: table;
    position: absolute;
    height: 26px;
    margin-top: -13px;
    font-size: 12px; }
    .form-field-row .otp-timer-table .timer-cell {
      display: table-cell;
      vertical-align: middle; }
      .form-field-row .otp-timer-table .timer-cell .timer-circle {
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 24px;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        border: 1px solid #cccccc;
        color: #9b9b9b; }
      .form-field-row .otp-timer-table .timer-cell a {
        display: inline-block;
        font-family: "Cairo-Bold";
        vertical-align: middle;
        margin-left: 3px; }

.ec-personalDetail .formly-field-checkbox .form-field-row {
  width: auto !important; }

.two-block {
  margin: 0 -40px; }
  .two-block:before, .two-block:after {
    clear: both;
    content: '';
    display: table; }
  .two-block .form-field-row {
    width: 50%;
    padding: 0 40px;
    float: left; }
    .two-block .form-field-row .form-field-row {
      padding: 0;
      width: 100%; }
  .two-block .two-block {
    margin: 0; }
    .two-block .two-block .form-field-row {
      padding: 0;
      width: 50%; }

.default-textfield {
  display: block;
  width: 100%;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  color: #404040;
  padding: 0 20px;
  background-color: #fff;
  text-transform: capitalize;
  font-family: "Cairo-Bold";
  text-align: left;
  background-image: none;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s; }
  .default-textfield::-ms-expand {
    background-color: transparent;
    border: 0; }
  .default-textfield:focus {
    background-color: #ffffff;
    border-color: #0062a8;
    outline: none; }
  .default-textfield.input-error {
    border-color: #ff0000; }
  .default-textfield:focus + .suggestion {
    display: block; }
  .default-textfield::-webkit-input-placeholder {
    color: #b1b1b1;
    opacity: 1;
    font-weight: normal;
    font-family: "Cairo-Medium";
    text-transform: capitalize; }
  .default-textfield::-moz-placeholder {
    color: #b1b1b1;
    opacity: 1;
    font-weight: normal;
    font-family: "Cairo-Medium";
    text-transform: capitalize; }
  .default-textfield:-ms-input-placeholder {
    color: #b1b1b1;
    opacity: 1;
    font-weight: normal;
    font-family: "Cairo-Medium";
    text-transform: capitalize; }
  .default-textfield::placeholder {
    color: #b1b1b1;
    opacity: 1;
    font-weight: normal;
    font-family: "Cairo-Medium";
    text-transform: capitalize; }
  .default-textfield[disabled] {
    background-color: rgba(220, 220, 220, 0.15);
    font-family: "Cairo-Bold"; }
    .default-textfield[disabled]::-moz-placeholder {
      font-family: "Cairo-Bold"; }
    .default-textfield[disabled]::-webkit-input-placeholder {
      font-family: "Cairo-Bold"; }
    .default-textfield[disabled]:-ms-input-placeholder {
      font-family: "Cairo-Bold"; }
  .default-textfield:disabled {
    background-color: rgba(220, 220, 220, 0.15);
    opacity: 1;
    cursor: not-allowed; }
  .default-textfield[readonly] {
    opacity: 1; }
  .default-textfield.error-box {
    border: 1px solid #ff0000; }

md-autocomplete md-autocomplete-wrap {
  height: 40px;
  box-shadow: none !important;
  position: relative; }
  md-autocomplete md-autocomplete-wrap input[type=search] {
    display: block;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: #404040;
    padding: 0 20px;
    background-color: #fff;
    text-transform: capitalize;
    font-family: "Cairo-Bold";
    text-align: left;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #cccccc;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s; }
    md-autocomplete md-autocomplete-wrap input[type=search]::-ms-expand {
      background-color: transparent;
      border: 0; }
    md-autocomplete md-autocomplete-wrap input[type=search]:focus {
      background-color: #ffffff;
      border-color: #0062a8;
      outline: none; }
    md-autocomplete md-autocomplete-wrap input[type=search]::-webkit-input-placeholder {
      color: #b1b1b1;
      opacity: 1;
      font-weight: normal;
      font-family: "Cairo-Medium";
      text-transform: capitalize; }
    md-autocomplete md-autocomplete-wrap input[type=search]::-moz-placeholder {
      color: #b1b1b1;
      opacity: 1;
      font-weight: normal;
      font-family: "Cairo-Medium";
      text-transform: capitalize; }
    md-autocomplete md-autocomplete-wrap input[type=search]:-ms-input-placeholder {
      color: #b1b1b1;
      opacity: 1;
      font-weight: normal;
      font-family: "Cairo-Medium";
      text-transform: capitalize; }
    md-autocomplete md-autocomplete-wrap input[type=search]::placeholder {
      color: #b1b1b1;
      opacity: 1;
      font-weight: normal;
      font-family: "Cairo-Medium";
      text-transform: capitalize; }

md-autocomplete .md-show-clear-button button {
  position: absolute;
  right: 0;
  height: 40px;
  color: #cccccc; }
  md-autocomplete .md-show-clear-button button md-icon {
    height: 14px;
    width: 14px;
    min-height: 18px;
    min-width: 18px; }
    md-autocomplete .md-show-clear-button button md-icon path {
      fill: #cccccc; }

md-autocomplete.md-default-theme button md-icon path.md-select-placeholder, md-autocomplete button md-icon path md-select-value.md-select-placeholder {
  color: #b1b1b1 !important;
  opacity: 1;
  font-weight: normal;
  text-transform: capitalize; }

.upload-box {
  margin-bottom: 40px;
  font-size: 20px;
  color: #404040;
  margin: 0 0 40px;
  position: relative; }
  .upload-box label {
    color: #404040;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 20px;
    text-transform: none; }
  .upload-box .sep {
    position: absolute;
    left: 50%;
    margin-left: -10.5px;
    color: #9b9b9b;
    font-size: 22px;
    text-transform: lowercase;
    top: 50%;
    margin-top: -12.5px; }
  .upload-box .form-field-row {
    margin-bottom: 0;
    padding: 0; }
  .upload-box .myLabel {
    border: 1px dashed #9b9b9b;
    border-radius: 8px;
    display: inline-block;
    width: 374px;
    height: 120px;
    position: relative;
    padding: 35px 40px;
    padding-left: 112px;
    cursor: pointer; }
    .upload-box .myLabel span:before {
      content: '\e909';
      font-family: "yesicon";
      position: absolute;
      top: 50%;
      text-align: center;
      margin-top: -21.5px;
      left: 40px;
      font-size: 40px;
      color: #0062a8;
      /*background:$form-field-border-on-hover;*/ }
    .upload-box .myLabel span small {
      font-family: "Cairo-Medium"; }
    .upload-box .myLabel input[type="file"] {
      position: fixed;
      top: -1000px; }
  .upload-box .connect-social {
    width: 374px;
    display: inline-block;
    text-align: left;
    padding-top: 15px; }

/* Upload button */
.fileUpload {
  position: relative;
  overflow: hidden;
  color: #0062a8;
  text-decoration: underline;
  cursor: pointer; }
  .fileUpload .without-btn {
    margin-bottom: 20px;
    display: inline-block;
    text-decoration: underline; }
  .fileUpload span {
    text-transform: uppercase;
    font-size: 16px; }
  .fileUpload.btn {
    text-decoration: none; }
    .fileUpload.btn span {
      font-size: 20px; }

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0); }

.file-name {
  padding-right: 25px;
  position: relative;
  padding-left: 30px;
  margin-top: 15px; }
  .file-name:first-of-type {
    margin-top: 30px; }
  .file-name.uploaded {
    padding-left: 50px; }
  .file-name strong {
    color: #404040;
    font-size: 14px; }
  .file-name .action-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    font-size: 18px;
    margin-top: -10.5px;
    z-index: 9; }
    .file-name .action-btn.left-icon {
      left: 0;
      right: auto;
      margin-top: -18px; }
    .file-name .action-btn .icon-icon-Check {
      width: 36px;
      height: 36px;
      line-height: 34px;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      text-align: center;
      font-size: 14px;
      background: #0062a8;
      left: 0; }
    .file-name .action-btn small {
      font-size: 13px; }

.form-error {
  color: #ff0000;
  text-align: center;
  font-size: 14px; }

.uploaded-image {
  width: 345px;
  margin: 0 auto;
  padding: 40px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  background: #fafafa;
  margin-bottom: 30px;
  padding-left: 120px;
  position: relative; }
  .uploaded-image .user-image {
    margin: 0 auto;
    border-radius: 50%;
    border: 1px solid #cccccc;
    background: #ffffff;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 20px;
    top: 50%;
    margin-top: -40px;
    overflow: hidden; }
    .uploaded-image .user-image img {
      max-width: 100%; }
  .uploaded-image .action-btn {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -7px; }

@media (max-width: 768px) {
  .file-name br {
    display: block !important; }
  .fileUpload.btn span {
    font-size: 14px; } }

.anchor-spacing-under-btns span {
  color: #0062a8;
  cursor: pointer;
  text-decoration: underline; }

.uploaded-file-container {
  margin-top: 30px;
  max-height: 80px;
  overflow: auto; }
  .uploaded-file-container .file-name:first-of-type {
    margin-top: 0; }
  .uploaded-file-container .upload-more {
    margin-top: 30px; }

.no-top {
  top: 0 !important;
  margin-bottom: 20px !important;
  vertical-align: middle; }

.ver-mid {
  vertical-align: middle;
  top: 0; }

/*.uploaded-file-container{
  .uploaded{
    .action-btn{right:0;}
  }
}*/
.las-form .form-field-row {
  position: relative; }
  .las-form .form-field-row input[type="text"], .las-form .form-field-row select {
    position: relative;
    z-index: 2; }
  .las-form .form-field-row .label {
    position: absolute;
    transition: all 0.5s ease-in-out;
    z-index: 0; }
    .las-form .form-field-row .label .fas {
      margin-right: 5px; }
  .las-form .form-field-row .ng-empty ~ .label {
    top: 5px;
    left: 30px;
    color: #8C8C8C;
    font-size: 0.95em;
    transition: .5s;
    pointer-events: none; }
  .las-form .form-field-row input:focus ~ .label,
  .las-form .form-field-row select:focus ~ .label,
  .las-form .form-field-row .ng-not-empty ~ .label,
  .las-form .form-field-row md-select[aria-invalid="false"] ~ .label {
    top: -28px;
    left: 17px;
    color: #0062a8 !important;
    z-index: 1;
    font-size: 1.1em; }

.las-form .formly-field::after {
  content: "";
  display: block;
  clear: both; }

.las-form .formly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch; }
  .las-form .formly .form-field-row {
    width: 100%;
    margin-top: 10px; }
    .las-form .formly .form-field-row .default-textfield {
      color: #495057;
      background-color: transparent !important;
      border: 0px solid #FFFFFF !important;
      border-bottom: 1px solid #CCCCCC !important; }

.las-form .formly-field:not(.form-section-title-wrap) {
  width: 50%;
  margin-bottom: 40px; }

.las-form .form-section-title-wrap {
  clear: both;
  border-bottom: 2px solid #1aa7e3 !important;
  margin: 0 0 40px;
  padding: 0 15px;
  width: 100%; }
  .las-form .form-section-title-wrap .form-section-title {
    margin: 10px 0 0;
    color: #0062a8;
    font-size: 17px;
    font-weight: bold;
    text-shadow: none;
    line-height: 26px; }

.las-form .button-radio-wrap {
  display: block;
  margin-top: 20px; }
  .las-form .button-radio-wrap .radio-label {
    margin-bottom: 20px; }
  .las-form .button-radio-wrap md-radio-button {
    margin-right: 4px;
    padding: 4px 8px;
    line-height: 21px;
    border-radius: 4px;
    display: inline-block;
    color: #000;
    transition: all 0.15s ease-in-out; }
    .las-form .button-radio-wrap md-radio-button[aria-checked="true"] {
      background: #1aa7e3; }
      .las-form .button-radio-wrap md-radio-button[aria-checked="true"] .md-label {
        color: #fff; }
      .las-form .button-radio-wrap md-radio-button[aria-checked="true"]:focus, .las-form .button-radio-wrap md-radio-button[aria-checked="true"]:active {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .las-form .button-radio-wrap md-radio-button .md-container {
      opacity: 0; }
    .las-form .button-radio-wrap md-radio-button .md-label {
      margin-left: 0; }

.las-form.las-otp-form .formly-field {
  margin: 0 auto 40px; }

.form-field-row md-radio-group[disabled="true"],
.form-field-row md-radio-group[disabled="true"] md-radio-button {
  cursor: not-allowed; }

@media all and (max-width: 767px) {
  .las-form .formly-field:not(.form-section-title-wrap) {
    width: 100%; } }

.las-form-main .form-field-row {
  position: relative; }
  .las-form-main .form-field-row input[type="text"], .las-form-main .form-field-row select {
    position: relative;
    z-index: 2; }
  .las-form-main .form-field-row .label {
    position: absolute;
    transition: all 0.5s ease-in-out;
    z-index: 0; }
    .las-form-main .form-field-row .label .fas {
      margin-right: 5px; }
  .las-form-main .form-field-row .ng-empty ~ .label {
    top: 5px;
    left: 30px;
    color: #8C8C8C;
    font-size: 0.95em;
    transition: .5s;
    pointer-events: none; }
  .las-form-main .form-field-row input:focus ~ .label,
  .las-form-main .form-field-row select:focus ~ .label,
  .las-form-main .form-field-row .ng-not-empty ~ .label,
  .las-form-main .form-field-row md-select[aria-invalid="false"] ~ .label {
    top: -28px;
    left: 17px;
    color: #0062a8 !important;
    z-index: 1;
    font-size: 1.1em; }

.las-form-main .formly-field::after {
  content: "";
  display: block;
  clear: both; }

.las-form-main .formly {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch; }
  .las-form-main .formly .form-field-row {
    width: 100%;
    margin-top: 10px; }
    .las-form-main .formly .form-field-row .default-textfield {
      color: #495057;
      background-color: transparent !important;
      border: 0px solid #FFFFFF !important;
      border-bottom: 1px solid #CCCCCC !important; }

.las-form-main .formly-field:not(.form-section-title-wrap) {
  width: 25%;
  margin-bottom: 40px; }

.las-form-main .form-section-title-wrap {
  clear: both;
  border-bottom: 2px solid #1aa7e3 !important;
  margin: 0 0 40px;
  padding: 0 15px;
  width: 100%; }
  .las-form-main .form-section-title-wrap .form-section-title {
    margin: 10px 0 0;
    color: #0062a8;
    font-size: 17px;
    font-weight: bold;
    text-shadow: none;
    line-height: 26px; }

.las-form-main .button-radio-wrap {
  display: block;
  margin-top: 20px; }
  .las-form-main .button-radio-wrap .radio-label {
    margin-bottom: 20px; }
  .las-form-main .button-radio-wrap md-radio-button {
    margin-right: 4px;
    padding: 4px 8px;
    line-height: 21px;
    border-radius: 4px;
    display: inline-block;
    color: #000;
    transition: all 0.15s ease-in-out; }
    .las-form-main .button-radio-wrap md-radio-button[aria-checked="true"] {
      background: #1aa7e3; }
      .las-form-main .button-radio-wrap md-radio-button[aria-checked="true"] .md-label {
        color: #fff; }
      .las-form-main .button-radio-wrap md-radio-button[aria-checked="true"]:focus, .las-form-main .button-radio-wrap md-radio-button[aria-checked="true"]:active {
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .las-form-main .button-radio-wrap md-radio-button .md-container {
      opacity: 0; }
    .las-form-main .button-radio-wrap md-radio-button .md-label {
      margin-left: 0; }

.las-form-main.las-otp-form .formly-field {
  margin: 0 auto 40px; }

@media all and (max-width: 767px) {
  .las-form-main .formly-field:not(.form-section-title-wrap) {
    width: 100%; } }

/*for website pages structure*/
.calculateLoading {
  height: 300px; }
  .calculateLoading .loader1.red-loader1 {
    position: fixed;
    margin: 15% 48%;
    width: 50px;
    height: 50px;
    border-top: 5px solid #d71920 !important;
    border: 5px solid #cccccc; }

.offers {
  padding-top: 80px;
  margin-top: -140px; }
  .offers .tenure-amount {
    max-width: 430px;
    margin: 50px auto 0;
    display: flex;
    justify-content: space-between;
    text-align: left; }
    .offers .tenure-amount > * {
      width: 200px; }
  .offers label {
    color: #404040; }
  .offers .offer-container {
    background: #fafafa;
    margin: -80px -80px 0;
    padding: 80px 40px 0;
    border-bottom: 1px solid rgba(224, 224, 224, 0.7); }
    .offers .offer-container .div-table {
      padding: 40px 0; }
      .offers .offer-container .div-table label {
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        margin-bottom: 3px;
        line-height: 16px;
        font-weight: normal; }
      .offers .offer-container .div-table .loan-amount {
        font-size: 23px;
        line-height: 28px;
        font-family: "Cairo-Bold";
        color: #0062a8; }
        .offers .offer-container .div-table .loan-amount span {
          text-decoration: line-through;
          color: #9b9b9b;
          font-weight: normal;
          font-family: "Cairo-Medium"; }
  .offers .customize-offer-container {
    padding: 40px 0 80px;
    color: #9b9b9b; }
    .offers .customize-offer-container h6 {
      font-size: 18px;
      margin: 0;
      font-weight: normal; }
    .offers .customize-offer-container .customize-box {
      margin-top: 40px; }
      .offers .customize-offer-container .customize-box .box-label {
        width: 430px;
        margin: 0 auto; }
        .offers .customize-offer-container .customize-box .box-label label {
          display: block;
          text-align: left; }
      .offers .customize-offer-container .customize-box label {
        font-size: 14px;
        font-family: "Cairo-Bold";
        margin-bottom: 15px;
        position: relative; }
        .offers .customize-offer-container .customize-box label .selected-value {
          position: absolute;
          padding-left: 10px;
          font-family: "Cairo-Medium";
          color: #0062a8; }
      .offers .customize-offer-container .customize-box .range-slider {
        width: 430px;
        margin: 0 auto;
        height: 30px;
        position: relative; }
        .offers .customize-offer-container .customize-box .range-slider label {
          font-size: 16px;
          margin-bottom: 0;
          font-weight: normal;
          position: absolute;
          bottom: -17px;
          left: 0;
          color: #0062a8;
          font-family: "Cairo-Medium"; }
          .offers .customize-offer-container .customize-box .range-slider label:last-of-type {
            right: 0;
            left: auto; }
        .offers .customize-offer-container .customize-box .range-slider md-slider {
          display: inline-block;
          width: 100%; }
      .offers .customize-offer-container .customize-box + .customize-box {
        margin-top: 60px; }
  .offers h2 {
    font-size: 31px;
    line-height: 36px;
    margin-top: 0;
    font-family: "Cairo-Bold"; }
    .offers h2 + p {
      line-height: 30px; }
  .offers .icon-img {
    margin-bottom: 20px; }
  .offers h3 {
    font-size: 21px;
    line-height: 24px;
    text-transform: uppercase; }
  .offers p + p {
    margin-top: 30px; }
  .offers .declaration {
    color: #9b9b9b;
    padding: 35px 0 0; }
    .offers .declaration p {
      font-size: 16px;
      margin-bottom: 25px; }
    .offers .declaration .form-field-row {
      margin-bottom: 10px; }
  .offers .loan-tenure span {
    width: 63px;
    border: 1px solid #cccccc;
    color: #9b9b9b;
    font-size: 18px;
    margin: 0 5px;
    border-radius: 20px;
    display: inline-block;
    height: 40px;
    line-height: 38px;
    cursor: pointer; }
    .offers .loan-tenure span:hover {
      background: #0062a8;
      border-color: #0062a8;
      color: #fff; }
    .offers .loan-tenure span.active {
      background: #0062a8;
      border-color: #0062a8;
      color: #fff; }
  .offers .cv-offer-box {
    border: 1px solid rgba(224, 224, 224, 0.7);
    margin-bottom: 10px;
    width: 854px;
    margin: 30px auto 10px;
    font-family: "Cairo-Bold";
    text-align: left; }
    .offers .cv-offer-box ul {
      margin-bottom: 0; }
      .offers .cv-offer-box ul li {
        list-style: none;
        background: #fafafa;
        border-bottom: 1px solid #ccc; }
        .offers .cv-offer-box ul li label {
          background: #ffffff;
          width: 410px;
          display: inline-block;
          margin-bottom: 0;
          color: #9b9b9b;
          font-weight: normal;
          padding: 20px 20px 20px;
          margin-right: 30px;
          border-right: 1px solid rgba(224, 224, 224, 0.7); }
        .offers .cv-offer-box ul li div {
          width: 330px;
          display: inline-block; }
        .offers .cv-offer-box ul li:first-child label {
          padding-top: 20px; }
        .offers .cv-offer-box ul li:last-child label {
          padding-bottom: 20px; }
  .offers .no-offer-box {
    border: 1px solid rgba(224, 224, 224, 0.7);
    margin-bottom: 10px;
    width: 500px;
    margin: 30px auto 10px;
    font-family: "Cairo-Bold";
    text-align: left; }
    .offers .no-offer-box ul {
      margin-bottom: 0; }
      .offers .no-offer-box ul li {
        list-style: none;
        background: #fafafa;
        display: table;
        width: 100%; }
        .offers .no-offer-box ul li label {
          background: #ffffff;
          width: 175px;
          display: inline-block;
          margin-bottom: 0;
          color: #9b9b9b;
          font-weight: normal;
          padding: 0 30px 10px;
          margin-right: 30px;
          border-right: 1px solid rgba(224, 224, 224, 0.7);
          display: table-cell; }
        .offers .no-offer-box ul li div {
          display: table-cell;
          padding: 0 30px 10px; }
        .offers .no-offer-box ul li:first-child label {
          padding-top: 20px; }
        .offers .no-offer-box ul li:last-child label {
          padding-bottom: 20px; }

.reference-box {
  padding: 40px 80px 40px 40px;
  margin-top: 80px;
  margin-bottom: 20px;
  border: 1px solid rgba(224, 224, 224, 0.7);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  color: #9b9b9b; }
  .reference-box + .reference-box {
    margin-top: 0; }
  .reference-box .edit {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer; }
  .reference-box h6 {
    font-size: 18px;
    font-family: "Cairo-Bold";
    margin: 0 0 4px 0;
    color: #404040; }
  .reference-box p {
    margin-bottom: 4px; }
    .reference-box p:last-child {
      margin-bottom: 0; }
  .reference-box:last-of-type {
    margin-top: 40px; }

.upload-section {
  text-align: center; }
  .upload-section .upload-icon {
    background-color: rgba(51, 51, 51, 0.1);
    border: 1px solid #999;
    border-radius: 50%;
    color: #333;
    display: inline-block;
    font-size: 40px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    width: 90px; }
  .upload-section h4 {
    font-size: 28px;
    font-family: "Cairo-Bold";
    margin: 20px 0 5px 0; }
  .upload-section p {
    margin: 0 0 50px; }

.upload-detail-box {
  border: 1px solid rgba(224, 224, 224, 0.7);
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  margin: 15px 0 !important;
  text-align: left; }
  .upload-detail-box h6 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    margin-bottom: 0 !important; }

.information-container .added-applicant.uploadedFiles .addedList {
  margin-right: -20px; }

.required-doc {
  max-width: 330px;
  margin: 0 auto; }
  .required-doc ul {
    list-style: none; }
    .required-doc ul li {
      background-color: rgba(235, 139, 136, 0.1);
      color: #ff0000;
      padding: 0 20px; }
      .required-doc ul li:first-child {
        font-family: "Cairo-Bold";
        padding: 20px 20px 5px;
        color: #404040; }
      .required-doc ul li:last-child {
        padding: 0 20px 20px; }

.loan-application-list .application-list .list-body {
  display: flex;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  text-align: left; }
  .loan-application-list .application-list .list-body .list-item {
    flex: 1;
    padding: 10px 18px; }
    .loan-application-list .application-list .list-body .list-item i {
      margin-left: 10px;
      vertical-align: middle; }

.loan-application-list .application-list .app-progress-bar {
  background: #fafafa;
  border-top: 0;
  padding: 20px 0;
  position: relative;
  display: flex; }
  .loan-application-list .application-list .app-progress-bar:after {
    position: absolute;
    width: 100%;
    border-bottom: solid 1px rgba(224, 224, 224, 0.7);
    content: '';
    top: 51px;
    left: 0; }
  .loan-application-list .application-list .app-progress-bar span {
    flex: 1;
    text-align: center; }
    .loan-application-list .application-list .app-progress-bar span i {
      border-radius: 50%;
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: #fff;
      border: 1px solid rgba(224, 224, 224, 0.7);
      position: relative;
      z-index: 2; }
    .loan-application-list .application-list .app-progress-bar span label {
      display: block;
      font-size: 12px;
      color: #9b9b9b; }
    .loan-application-list .application-list .app-progress-bar span.active label {
      color: #404040;
      font-family: "Cairo-Bold"; }
    .loan-application-list .application-list .app-progress-bar span.active i {
      background-color: #0062a8;
      border: 1px solid #0062a8;
      color: #fff;
      text-align: center;
      font-weight: bold;
      font-size: 9px;
      font-family: "yesicon"; }

.hr-divider {
  text-align: center;
  position: relative;
  margin: 40px 0;
  color: #9b9b9b; }
  .hr-divider span {
    padding: 10px;
    background: #fff;
    position: relative;
    z-index: 1; }
  .hr-divider:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #f4f4f4;
    left: 0;
    top: 50%;
    margin-top: -.5px; }

/* My Application */
table {
  border-collapse: collapse;
  background-color: transparent; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 30px; }

.table th,
.table td {
  padding: 18px 10px;
  vertical-align: top; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid rgba(224, 224, 224, 0.7);
  text-transform: uppercase;
  font-weight: normal; }

.application-box {
  width: 100% !important;
  padding-bottom: 25px !important; }
  .application-box h2 {
    font-size: 31px !important; }
  .application-box .dashboard-wrapp {
    padding: 45px 0 0;
    text-align: center; }
    .application-box .dashboard-wrapp a {
      display: inline-block;
      font-size: 23px;
      line-height: 26px;
      text-decoration: none;
      text-align: center;
      color: #0062a8;
      vertical-align: top;
      padding: 0 33px; }
      .application-box .dashboard-wrapp a:first-child {
        padding-left: 0; }
      .application-box .dashboard-wrapp a:last-child {
        padding-right: 0; }
      .application-box .dashboard-wrapp a small {
        display: block;
        font-size: 14px;
        line-height: 16px;
        color: #404040;
        text-transform: uppercase;
        text-align: center; }
      .application-box .dashboard-wrapp a.rejected {
        color: #d71920; }

.loan-application-list {
  padding: 40px 0 50px;
  background: #fff; }
  .loan-application-list .application-list {
    text-align: left; }
    .loan-application-list .application-list .table thead {
      background: #fafafa; }
      .loan-application-list .application-list .table thead th {
        color: #9b9b9b;
        font-size: 12px;
        text-align: left; }
    .loan-application-list .application-list .table tbody tr {
      border-bottom: 1px solid rgba(224, 224, 224, 0.7);
      height: 101px; }
      .loan-application-list .application-list .table tbody tr td {
        font-size: 16px;
        padding: 30px 10px; }
    .loan-application-list .application-list .acceptance {
      color: #0062a8;
      font-size: 12px !important;
      font-family: "Cairo-Bold";
      white-space: nowrap; }
      .loan-application-list .application-list .acceptance .acceptance-box {
        display: inline-block;
        position: relative; }
        .loan-application-list .application-list .acceptance .acceptance-box:hover .status-bar {
          display: inline-block; }
        .loan-application-list .application-list .acceptance .acceptance-box span {
          position: relative;
          padding-left: 15px;
          cursor: pointer; }
          .loan-application-list .application-list .acceptance .acceptance-box span:after {
            content: '';
            position: absolute;
            width: 11px;
            height: 11px;
            border: 1px solid rgba(224, 224, 224, 0.7);
            border-radius: 50%;
            left: 0;
            top: 50%;
            margin-top: -5.5px; }
          .loan-application-list .application-list .acceptance .acceptance-box span.completed:after {
            border-color: #0062a8;
            background-color: #0062a8; }
          .loan-application-list .application-list .acceptance .acceptance-box span.inprocess:after {
            border-color: #0062a8; }
        .loan-application-list .application-list .acceptance .acceptance-box .status-bar {
          position: absolute;
          z-index: 9;
          width: 268px;
          top: 20px;
          display: block;
          left: -82px; }
        .loan-application-list .application-list .acceptance .acceptance-box .status-box {
          border: 1px solid #0062a8;
          background: #fff;
          height: 34px;
          line-height: 32px;
          padding: 0 10px;
          position: relative;
          display: inline-block; }
          .loan-application-list .application-list .acceptance .acceptance-box .status-box:after, .loan-application-list .application-list .acceptance .acceptance-box .status-box:before {
            bottom: 100%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            left: 50%;
            margin-left: -7px; }
          .loan-application-list .application-list .acceptance .acceptance-box .status-box:after {
            border-color: rgba(213, 30, 79, 0);
            border-bottom-color: #fff;
            border-width: 7px;
            margin-right: 1px; }
          .loan-application-list .application-list .acceptance .acceptance-box .status-box:before {
            border-color: rgba(194, 225, 245, 0);
            border-bottom-color: #0062a8;
            border-width: 8px;
            margin-left: -8px; }
          .loan-application-list .application-list .acceptance .acceptance-box .status-box ul {
            position: relative;
            list-style: none;
            margin-bottom: 0;
            display: inline-block; }
            .loan-application-list .application-list .acceptance .acceptance-box .status-box ul:before {
              content: '';
              width: 100%;
              position: absolute;
              height: 1px;
              background: rgba(224, 224, 224, 0.7);
              top: 50%;
              margin-top: -.5px; }
            .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li {
              display: inline-block;
              margin-left: 6px;
              position: relative;
              font-size: 12px;
              color: #9b9b9b;
              text-transform: capitalize;
              font-family: "Cairo-Medium";
              cursor: pointer; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:before {
                content: '';
                width: 11px;
                height: 11px;
                border-radius: 50%;
                border: 1px solid rgba(224, 224, 224, 0.7);
                background-color: #fafafa;
                display: inline-block;
                vertical-align: middle;
                margin-top: -2px; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li.inprogress:before {
                border-color: #0062a8;
                background-color: #fff; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li.completed:before {
                border-color: #0062a8;
                background-color: #0062a8; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:first-child, .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:last-child {
                background: #fff; }
                .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:first-child:before, .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:last-child:before {
                  display: none; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:first-child {
                margin-left: 0;
                margin-right: -10px;
                padding-right: 10px; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:last-child {
                margin-left: -3px;
                padding-left: 10px; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li label {
                position: absolute;
                font-size: 12px;
                text-transform: capitalize;
                color: #404040;
                line-height: 12px;
                white-space: nowrap;
                text-align: center;
                display: none;
                padding: 2px 3px;
                background: #f7f7f7;
                border: 1px solid rgba(224, 224, 224, 0.7);
                border-radius: 2px;
                margin-bottom: 0;
                margin-top: -3px;
                z-index: 99; }
              .loan-application-list .application-list .acceptance .acceptance-box .status-box ul li:hover label {
                display: block; }
    .loan-application-list .application-list .user-name {
      font-family: "Cairo-Bold"; }
    .loan-application-list .application-list .icon-Delete {
      font-size: 20px;
      color: #404040; }
    .loan-application-list .application-list .continue-btn {
      padding: 0 20px; }
      .loan-application-list .application-list .continue-btn:before {
        border-right: 0; }
    .loan-application-list .application-list p {
      color: #9b9b9b;
      text-align: center; }

.hr-divider {
  text-align: center;
  position: relative;
  margin: 40px 0;
  color: #9b9b9b; }
  .hr-divider span {
    padding: 10px;
    background: #fff;
    position: relative;
    z-index: 1; }
  .hr-divider:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #f4f4f4;
    left: 0;
    top: 50%;
    margin-top: -.5px; }

.search-filter {
  position: relative;
  margin-bottom: 20px;
  clear: both; }
  .search-filter .createBtn {
    position: absolute;
    right: 0; }
  .search-filter .filter, .search-filter .search-box {
    float: left; }
  .search-filter:before, .search-filter:after {
    content: '';
    clear: both;
    display: table; }

/* -- Search Box --*/
.search-box {
  position: relative;
  min-height: 40px;
  width: 300px; }
  .search-box .default-textfield, .search-box .search-btn {
    position: absolute; }
  .search-box .default-textfield {
    padding: 0 30px 0 10px;
    font-size: 13px;
    width: 100%; }
  .search-box .search-btn {
    right: 1px;
    width: 30px;
    background-color: #f7f7f7;
    height: 38px;
    border: 0;
    top: 1px;
    color: transparent;
    font-size: 0; }

/* -- Filter --*/
.filter {
  margin-left: 10px; }
  .filter .default-textfield {
    padding: 0 10px;
    font-size: 13px;
    color: #9b9b9b;
    font-family: "Cairo-Medium"; }
  .filter .icon {
    font-size: 7px;
    margin-left: 50px; }

.agreemet-preview {
  border: solid 1px #dcdcdc;
  margin: 80px 0  10px; }
  .agreemet-preview iframe {
    display: block; }

.welcome-text .div-table .loan-amount {
  font-size: 18px !important;
  line-height: 22px; }

.welcome-upload .detail-box {
  height: auto;
  max-height: none;
  min-height: 317px;
  padding: 80px;
  margin-bottom: 0; }
  .welcome-upload .detail-box .btn {
    margin-top: 40px; }

.welcome-upload .form-field-row {
  color: #404040;
  margin: 0 auto 0;
  float: none; }
  .welcome-upload .form-field-row .md-label {
    color: #9b9b9b; }

.welcome-upload .welcome-uploadBox .btn {
  margin-top: 0; }

.welcome-upload .welcome-uploadBox md-radio-button {
  margin-top: 30px; }

.welcome-upload .welcome-uploadBox .form-field-row {
  margin-bottom: 20px; }

.dt-tbl {
  display: table;
  width: 100%;
  margin-top: 40px;
  padding: 20px;
  border: solid 1px rgba(224, 224, 224, 0.7); }
  .dt-tbl .dt-tbl-cl {
    display: table-cell;
    font-size: 18px;
    text-align: left;
    width: 25%; }
    .dt-tbl .dt-tbl-cl label {
      color: #9b9b9b; }

.detail-box-wl {
  display: inline-block;
  width: 47%;
  margin: 40px auto 0px;
  vertical-align: top;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(224, 224, 224, 0.7); }
  .detail-box-wl i {
    font-size: 50px;
    margin-bottom: 30px;
    position: relative;
    color: #0062a8; }
  .detail-box-wl h2 {
    margin: 0 0 30px;
    font-size: 21px;
    line-height: 24px; }
  .detail-box-wl p {
    font-size: 14px;
    margin-bottom: 40px; }
  .detail-box-wl:nth-child(odd) {
    margin-right: 25px; }
  .detail-box-wl:nth-child(even) {
    margin-left: 25px; }
  .detail-box-wl .stps1 {
    padding: 40px; }

.sub-box.welcome_upload {
  box-shadow: none;
  border: none;
  width: 100%; }
  .sub-box.welcome_upload h4 {
    margin: -40px -40px 40px; }

/* Detail List */
.detail-box {
  padding: 35px 40px;
  margin-top: 40px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(224, 224, 224, 0.7);
  max-height: 312px;
  height: 312px; }
  .detail-box ol {
    margin: 18px 20px 0; }
    .detail-box ol li {
      margin-bottom: 25px; }
      .detail-box ol li:last-child {
        margin-bottom: 0; }
  .detail-box h2 {
    margin-top: 0;
    font-size: 21px;
    line-height: 24px;
    margin-bottom: 30px; }
  .detail-box h6 {
    margin-bottom: 30px;
    margin-top: 0; }
  .detail-box .box-detail {
    color: #0062a8;
    font-family: "Cairo-Bold"; }
    .detail-box .box-detail label {
      display: inline-block;
      font-weight: normal;
      font-size: 14px;
      width: 350px;
      margin-bottom: 15px; }
    .detail-box .box-detail:last-of-type label {
      margin-bottom: 0; }

.detail-container {
  display: table;
  width: 100%;
  margin-top: 40px; }
  .detail-container .form-field-row {
    display: table-cell;
    width: 50%;
    margin: 0 auto;
    float: none;
    height: 100%; }
    .detail-container .form-field-row .detail-box {
      height: 100%;
      max-height: none;
      min-height: auto;
      margin-top: 0;
      color: #404040; }
    .detail-container .form-field-row:last-of-type .detail-box {
      max-width: 550px;
      margin: 0 auto; }

.for-one .form-field-row {
  width: 49.7%;
  margin-bottom: 20px; }

@-moz-document url-prefix() {
  .detail-container .form-field-row .detail-box {
    height: none; } }

.thankyou-box {
  margin-bottom: 20px; }
  .thankyou-box .form-field-row {
    display: table-cell !important; }
  .thankyou-box .detail-box {
    /*margin-bottom:0;*/ }
    .thankyou-box .detail-box h2 {
      text-align: left;
      margin-bottom: 0; }
      .thankyou-box .detail-box h2:after {
        display: none; }
      .thankyou-box .detail-box h2 + p {
        text-align: left; }
    .thankyou-box .detail-box .box-detail:first-of-type {
      margin-top: 18px; }
    .thankyou-box .detail-box .personal-detail {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 10px;
      margin-top: 3px;
      font-family: "Cairo-Bold"; }

@media (min-width: 1200px) {
  .detail-box {
    padding: 35px 80px; } }

@media (max-width: 768px) {
  .detail-container.thankyou-box {
    height: auto; } }

@media (min-width: 769px) {
  .detail-container.thankyou-box {
    height: 0; } }

.detailContainer2 .form-field-row {
  margin: 0 auto 0 -4px; }

.thankyou-container {
  display: table;
  height: 500px;
  width: 100%; }
  .thankyou-container h2 {
    font-size: 31px !important;
    margin-top: 0; }
  .thankyou-container .div-cell {
    display: table-cell;
    vertical-align: middle; }

.information-container {
  margin-top: 30px; }
  .information-container .added-applicant {
    padding: 30px;
    color: #404040;
    margin: 0;
    min-height: 180px;
    max-height: 180px;
    overflow: hidden;
    background: #fafafa; }
    .information-container .added-applicant .btn {
      margin-top: 38px; }
    .information-container .added-applicant .addedList {
      max-height: 73px;
      overflow: hidden;
      overflow-y: auto;
      padding-top: 10px; }
      .information-container .added-applicant .addedList .file-name:first-of-type {
        margin-top: 0; }
      .information-container .added-applicant .addedList .file-name.uploaded {
        min-height: 27px; }
      .information-container .added-applicant .addedList .file-name strong {
        vertical-align: middle; }
      .information-container .added-applicant .addedList .action-btn.left-icon {
        margin-top: -15px; }
    .information-container .added-applicant.new-applicant {
      background: #ffffff;
      cursor: pointer; }
      .information-container .added-applicant.new-applicant h6 {
        padding-right: 0; }
    .information-container .added-applicant h6 {
      padding-right: 30px; }
    .information-container .added-applicant ul {
      margin-top: 10px;
      list-style: none; }
      .information-container .added-applicant ul li {
        margin-bottom: 5px; }
        .information-container .added-applicant ul li:last-child {
          margin-bottom: 0; }
  .information-container .upload-more {
    text-align: center;
    margin-top: 20px; }
    .information-container .upload-more .without-btn {
      margin-bottom: 0; }
  .information-container .detail-container {
    margin-top: 0;
    margin-bottom: 20px; }
    .information-container .detail-container .form-field-row:last-of-type .reference-box {
      max-width: 330px;
      margin: 0 auto; }

.upload-finacials .information-container {
  margin-top: 30px; }
  .upload-finacials .information-container .reference-box {
    min-height: 232px;
    height: 100%;
    max-height: 260px;
    overflow: hidden;
    margin-bottom: 20px; }

.add-personal-co-applicant .information-container .reference-box {
  min-height: auto; }

.detail-container {
  text-align: center; }
  .detail-container .form-field-row {
    display: inline-block;
    text-align: left; }
  .detail-container.for-one {
    text-align: left; }

.upload-finacials .information-container .reference-box {
  min-height: 232px;
  height: 100%;
  max-height: 232px;
  margin-bottom: 20px !important;
  text-align: left; }

.refBox {
  min-height: 160px !important; }

.new-doc-white-container {
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 0 4px 0 #DBD9E7;
  padding: 30px 15px;
  margin: 0 auto 20px; }
  .new-doc-white-container:after {
    clear: both;
    content: '';
    display: table; }
  .new-doc-white-container md-checkbox {
    display: inline-block; }
  .new-doc-white-container h4 {
    font-size: 17px;
    margin: 0;
    margin-bottom: 30px;
    padding: 0 15px;
    font-family: "Cairo-Bold"; }
    .new-doc-white-container h4 span {
      display: block;
      font-size: 13px;
      font-weight: normal;
      font-family: "Cairo-Medium";
      color: #9b9b9b; }
    .new-doc-white-container h4.comment-space {
      margin-bottom: 5px; }
  .new-doc-white-container .document-table {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .new-doc-white-container .document-table .document-list {
      display: table-cell;
      padding: 0 15px;
      position: relative;
      padding-bottom: 85px; }
      .new-doc-white-container .document-table .document-list label {
        margin-bottom: 25px;
        display: block;
        width: 100%;
        font-weight: normal;
        color: #93929D;
        padding-bottom: 10px;
        border-bottom: #eaeaea solid 1px;
        font-size: 12px;
        text-transform: uppercase; }
      .new-doc-white-container .document-table .document-list ul {
        list-style: none; }
        .new-doc-white-container .document-table .document-list ul li {
          padding-right: 0;
          margin-bottom: 5px;
          /* md-checkbox .md-label {
                    margin-top: 5px;
                }*/ }
          .new-doc-white-container .document-table .document-list ul li .small-checkbox md-checkbox .md-label {
            margin-top: -5px !important;
            margin-left: 18px; }
          .new-doc-white-container .document-table .document-list ul li md-checkbox + .default-textfield {
            margin-top: 15px; }
      .new-doc-white-container .document-table .document-list.download-list {
        border-right: solid 1px #eaeaea;
        padding: 0 30px 15px;
        margin-top: -30px;
        padding-top: 30px; }
        .new-doc-white-container .document-table .document-list.download-list ul li {
          padding-right: 0;
          position: relative; }
        .new-doc-white-container .document-table .document-list.download-list:first-child {
          padding-left: 0; }
        .new-doc-white-container .document-table .document-list.download-list.noborder {
          border-right: none;
          padding-right: 0;
          margin-right: 0; }

.after-upload-doc {
  display: table;
  width: calc(100% + 60px);
  padding: 0 30px;
  border-bottom: 1px solid #EAEAEF;
  margin: 0 -30px 30px; }
  .after-upload-doc .doc-row {
    display: table-row; }
    .after-upload-doc .doc-row:last-child .doc-cell {
      padding-bottom: 30px; }
    .after-upload-doc .doc-row:first-child .doc-cell {
      padding-bottom: 20px; }
    .after-upload-doc .doc-row .doc-cell {
      display: table-cell;
      padding: 5px 10px;
      color: #666; }
      .after-upload-doc .doc-row .doc-cell:nth-child(1) {
        width: 60px;
        padding-left: 30px; }
      .after-upload-doc .doc-row .doc-cell:nth-child(2).doc-title {
        color: #333;
        font-size: 18px;
        font-family: "Cairo-Bold"; }
      .after-upload-doc .doc-row .doc-cell:nth-child(3) {
        text-align: right;
        font-size: 12px;
        color: #9b9b9b; }
      .after-upload-doc .doc-row .doc-cell:nth-child(4) {
        width: 195px;
        text-align: right;
        padding-right: 30px; }

.all-doc {
  padding: 10px !important;
  background-color: #f2f2f2;
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px; }

.list-container {
  /* border-top: solid 1px #EBEAEF;*/
  /* padding:30px 0;*/
  padding-bottom: 30px; }
  .list-container.no-style {
    padding-top: 0;
    border: none; }
  .list-container.no-padding-bottom {
    padding-bottom: 0; }
  .list-container:after {
    content: '';
    display: table;
    clear: both; }

.padding-bottom0 {
  padding-bottom: 0 !important; }

.multi-list {
  margin-bottom: 10px; }
  .multi-list:last-child {
    margin-bottom: 0; }

.default-textfield.textarea {
  height: 90px;
  margin-bottom: 20px; }

.textarea-box {
  padding: 0 15px; }
  .textarea-box h4 {
    padding: 0; }

.small-checkbox {
  float: right;
  position: relative;
  margin-top: 0; }
  .small-checkbox md-checkbox .md-icon {
    width: 14px;
    height: 14px; }
  .small-checkbox md-checkbox {
    min-width: 14px;
    min-height: 14px;
    margin-bottom: 0;
    margin-right: 10px;
    display: inline-block; }
    .small-checkbox md-checkbox .md-label {
      font-size: 12px;
      margin-left: 23px; }
    .small-checkbox md-checkbox .md-container {
      width: 14px;
      height: 14px; }
  .small-checkbox md-checkbox.md-checked .md-icon:after {
    width: 5px;
    height: 8px;
    left: 4px; }
  .small-checkbox md-checkbox.md-default-theme.md-checked .md-icon:after, .small-checkbox md-checkbox.md-checked .md-icon:after {
    border-color: #fff; }

header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 75; }

.structure-main {
  margin-top: 120px; }

.portfolio .enablePort thead td:first-child, .portfolio .enablePort thead td:nth-of-type(2) {
  position: sticky;
  left: 0px;
  background: #E6F0F5; }

.portfolio .enablePort tbody td:first-child, .portfolio .enablePort tbody td:nth-of-type(2) {
  position: sticky;
  left: 0px;
  background: #fff; }

.portfolio .enablePort tbody tr:last-child td:first-child, .portfolio .enablePort tbody tr:last-child td:nth-of-type(2) {
  position: sticky;
  left: 0px;
  background: transparent; }

.portfolio .disablePort thead td:first-child {
  position: sticky;
  left: 0px;
  background: #E6F0F5; }

.portfolio .disablePort tbody td:first-child {
  position: sticky;
  left: 0px;
  background: #eee; }

.las-info-wrapper {
  text-align: center;
  text-align: center;
  margin: 20px 0 55px; }
  .las-info-wrapper .las-info-title {
    line-height: 1.5;
    color: #212529;
    font-size: 26px;
    font-weight: bold;
    padding-bottom: 20px;
    margin-bottom: 20px;
    position: relative; }
    .las-info-wrapper .las-info-title:after {
      position: absolute;
      bottom: 15px;
      display: block;
      content: "";
      height: 4px;
      width: 75px;
      background: #d71920;
      left: 50%;
      transform: translateX(-50%); }
  .las-info-wrapper p {
    line-height: 1.5em;
    color: #212529;
    font-size: 16px; }
  .las-info-wrapper .smallfont {
    font-size: 13px !important; }
  .las-info-wrapper .bigfont {
    font-size: 15px !important; }
  .las-info-wrapper .help-text-wrapper {
    position: relative; }

.portfolio {
  margin-bottom: 50px;
  margin-top: -20px; }

.portfolio .customer-name {
  text-transform: capitalize; }

.portfolio table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  table-layout: fixed; }

.portfolio thead {
  background-color: #E6F0F5 !important;
  color: #000000 !important; }

.portfolio .table th,
.portfolio .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.portfolio .table tr:last-child td {
  border-top: none; }

.portfolio .form-control,
.las-form .form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

.portfolio .form-control:focus,
.las-form .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.portfolio input.ng-invalid:not(.ng-untouched) {
  border-color: #a94442;
  box-shadow: 0 0 0 0.2rem rgba(168, 69, 66, 0.075); }

.portfolio tbody .total-amount-row {
  background-color: #E6F0F5 !important;
  color: #000000 !important; }

.portfolio tbody tr td:nth-child(11),
.portfolio tbody tr td:nth-child(12) {
  min-width: 150px; }

.portfolio .form-control {
  max-width: 150px;
  min-width: 100px; }

.portfolio tr.disabled {
  pointer-events: none;
  background: #eee;
  opacity: 0.9; }

.portfolio tr.disabled input,
.portfolio tr.disabled input.amountInvalid,
.portfolio tr.disabled input.unitsInvalid {
  background: #eaeaea;
  border: 1px solid #ced4da; }

.portfolio input.invalid {
  border: 1px solid #a94442; }

.portfolio .disclaimer {
  margin-top: 50px; }

.portfolio .txstrong {
  font-weight: bold; }

.portfolio .form-error,
.las-soc-form .form-error {
  margin-top: 10px; }

.las-soc-form .form-success {
  margin-top: 10px;
  color: #155724; }

.las-soc-form form {
  padding: 0 15px 50px; }
  .las-soc-form form table {
    text-align: left !important;
    font-size: 13px !important;
    width: 100%;
    color: #000;
    background-color: transparent;
    border-collapse: collapse; }
    .las-soc-form form table td {
      padding: 5px;
      line-height: 20px;
      border-top: 1px solid #dee2e6; }
  .las-soc-form form .terms {
    margin: 40px 0 20px;
    font-size: 16px;
    color: #000; }

button.disabled {
  opacity: .3; }

.mt-50 {
  margin-top: 5px; }

button:focus {
  outline: #eb8b8f auto 2px; }

.btn-primary:hover {
  background: #ab1419 !important; }

.con-block {
  padding: 0px 0 40px; }
  .con-block h2 {
    font-size: 34px;
    margin-bottom: 15px;
    margin-top: 15px; }
  .con-block p {
    font-size: 20px;
    color: #777; }

.cust-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px; }
  .cust-heading .assetClass {
    display: inline-block;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #999; }

.td-50 {
  width: 50px; }

.td-100 {
  width: 100px; }

.td-80 {
  width: 80px; }

.td-150 {
  width: 150px; }

.td-200 {
  width: 200px; }

.blinking {
  animation: blinkingText 1s infinite; }

@keyframes blinkingText {
  0% {
    color: green; }
  50% {
    color: transparent; }
  100% {
    color: green; } }

.help-text-wrapper {
  position: relative;
  z-index: 999999; }
  .help-text-wrapper .fund-listing {
    margin-top: 10px;
    list-style-type: disc; }
    .help-text-wrapper .fund-listing li {
      padding: 5px; }
  .help-text-wrapper span {
    display: none;
    position: absolute;
    top: -3px;
    padding: 10px;
    background: #f1f1f1;
    border-radius: 2px;
    left: 26px;
    font-size: 12px;
    color: #777;
    width: 300px;
    height: 200px;
    overflow: auto; }
  .help-text-wrapper:before {
    display: none;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 12px solid #f1f1f1;
    content: '';
    position: absolute;
    left: 17px;
    top: 2px; }
  .help-text-wrapper:hover:before {
    display: inline-block; }
  .help-text-wrapper:hover .fa-info-circle + span {
    display: inline-block; }

.track-status {
  width: 66.6667%;
  margin: 0 auto 100px; }
  .track-status .application-number {
    border-bottom: 2px solid #1aa7e3;
    text-align: center;
    margin-bottom: 40px; }
  .track-status .status-wrap {
    margin: 0 auto;
    width: 70%; }
    .track-status .status-wrap .status {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center;
      margin-bottom: 20px;
      padding-bottom: 20px; }
      .track-status .status-wrap .status:not(:last-child) {
        border-bottom: 1px solid #ccc; }
      .track-status .status-wrap .status .status-indicator {
        text-align: center;
        width: 80px; }
        .track-status .status-wrap .status .status-indicator img {
          width: 35px;
          height: auto; }
        .track-status .status-wrap .status .status-indicator .indicator {
          font-size: 20px; }
      .track-status .status-wrap .status .status-title {
        flex: 1; }
      .track-status .status-wrap .status.processing .indicator {
        color: #d71920; }
      .track-status .status-wrap .status.pending .indicator {
        color: #646464; }
  .track-status .title {
    font-size: 16.3px;
    line-height: 1.5;
    font-weight: bold;
    color: #212529; }
  .track-status .status-date {
    font-size: 12.8px;
    color: #212529; }

.soc-terms .ft0 {
  font: bold 13px 'Book Antiqua'; }

.soc-terms .ft2 {
  margin-left: 3px; }

.soc-terms .ft4 {
  margin-left: 32px; }

.soc-terms .ft5 {
  margin-left: 32px; }

.soc-terms .ft6 {
  margin-left: 33px; }

.soc-terms .ft7 {
  margin-left: 31px; }

.soc-terms .ft8 {
  margin-left: 35px; }

.soc-terms .ft10 {
  margin-left: 38px; }

.soc-terms .ft11 {
  margin-left: 3px; }

.soc-terms .ft12 {
  margin-left: 38px; }

.soc-terms .ft14 {
  margin-left: 5px; }

.soc-terms .ft15 {
  margin-left: 13px; }

.soc-terms .ft16 {
  margin-left: 12px; }

.soc-terms .ft18 {
  margin-left: 14px; }

.soc-terms .ft19 {
  margin-left: 11px; }

.soc-terms .ft20 {
  margin-left: 15px; }

.soc-terms .ft21 {
  margin-left: 12px; }

.soc-terms .ft22 {
  margin-left: 4px; }

.soc-terms .ft23 {
  margin-left: 4px; }

.soc-terms .ft24 {
  margin-left: 6px; }

.soc-terms .ft25 {
  margin-left: 41px; }

.soc-terms .ft26 {
  margin-left: 4px; }

.soc-terms .ft27 {
  margin-left: 31px; }

.soc-terms .ft28 {
  margin-left: 13px; }

.soc-terms .ft29 {
  margin-left: 12px; }

.soc-terms .ft30 {
  margin-left: 14px; }

.soc-terms .ft31 {
  margin-left: 33px; }

.soc-terms .ft32 {
  margin-left: 35px; }

.soc-terms .ft33 {
  font: 12px 'Book Antiqua'; }

.soc-terms .ft34 {
  margin-left: 5px; }

.soc-terms .ft35 {
  margin-left: 4px; }

.soc-terms .ft37 {
  margin-left: 3px; }

.soc-terms .ft38 {
  margin-left: 5px; }

.soc-terms .ft39 {
  margin-left: 31px; }

.soc-terms .p1 {
  text-align: justify;
  margin-top: 34px;
  margin-bottom: 0px; }

.soc-terms .p2 {
  text-align: justify;
  margin-top: 32px;
  margin-bottom: 0px; }

.soc-terms .p3 {
  text-align: justify;
  margin-top: 14px;
  margin-bottom: 0px; }

.soc-terms .p4 {
  text-align: justify;
  margin-top: 15px;
  margin-bottom: 0px; }

.soc-terms .p5 {
  text-align: justify;
  margin-top: 16px;
  margin-bottom: 0px; }

.soc-terms .p6 {
  margin-top: 16px;
  margin-bottom: 0px; }

.soc-terms .p7 {
  margin-top: 8px;
  margin-bottom: 0px; }

.soc-terms .p8 {
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p9 {
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p10 {
  margin-top: 3px;
  margin-bottom: 0px; }

.soc-terms .p11 {
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p12 {
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p13 {
  padding-left: 48px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -48px; }

.soc-terms .p14 {
  text-align: justify;
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p15 {
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p16 {
  padding-right: 33px;
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p17 {
  margin-top: 3px;
  margin-bottom: 0px; }

.soc-terms .p18 {
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p19 {
  text-align: justify;
  margin-top: 2px;
  margin-bottom: 0px;
  text-indent: 3px; }

.soc-terms .p20 {
  text-align: justify;
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p21 {
  text-align: justify;
  margin-top: 10px;
  margin-bottom: 0px; }

.soc-terms .p22 {
  text-align: justify;
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p23 {
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p24 {
  padding-left: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-indent: -24px; }

.soc-terms .p25 {
  padding-right: 33px;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p26 {
  padding-right: 249px;
  margin-top: 5px;
  margin-bottom: 0px; }

.soc-terms .p27 {
  text-align: justify;
  margin-top: 8px;
  margin-bottom: 0px; }

.soc-terms .p28 {
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 0px; }

.soc-terms .p29 {
  text-align: justify;
  margin-top: 24px;
  margin-bottom: 0px; }

.soc-terms .p30 {
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p31 {
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p32 {
  padding-right: 1px;
  margin-top: 7px;
  margin-bottom: 0px; }

.soc-terms .p33 {
  text-align: justify;
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p34 {
  text-align: justify;
  margin-top: 7px;
  margin-bottom: 0px; }

.soc-terms .p35 {
  text-align: justify;
  margin-top: 3px;
  margin-bottom: 0px; }

.soc-terms .p36 {
  text-align: justify;
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p37 {
  text-align: justify;
  margin-top: 6px;
  margin-bottom: 0px; }

.soc-terms .p38 {
  text-align: justify;
  margin-top: 5px;
  margin-bottom: 0px; }

.soc-terms .p39 {
  text-align: justify;
  margin-top: 9px;
  margin-bottom: 0px; }

.soc-terms .p40 {
  margin-top: 10px;
  margin-bottom: 0px; }

.soc-terms .p41 {
  margin-top: 15px;
  margin-bottom: 0px; }

.soc-terms .p42 {
  text-align: justify;
  margin-top: 9px;
  margin-bottom: 0px; }

.soc-terms .p43 {
  margin-top: 7px;
  margin-bottom: 0px; }

.soc-terms .p44 {
  text-align: justify;
  margin-top: 7px;
  margin-bottom: 0px; }

.soc-terms .p45 {
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p46 {
  padding-left: 24px;
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p47 {
  padding-left: 24px;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p48 {
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p49 {
  padding-left: 708px;
  margin-top: 17px;
  margin-bottom: 0px; }

.soc-terms .p50 {
  padding-right: 33px;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p51 {
  text-align: justify;
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p52 {
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p53 {
  padding-left: 48px;
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p54 {
  margin-top: 0px;
  margin-bottom: 0px; }

.soc-terms .p55 {
  text-align: justify;
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p56 {
  text-align: justify;
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p57 {
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p58 {
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p59 {
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p60 {
  text-align: right;
  margin-top: 17px;
  margin-bottom: 0px; }

.soc-terms .p61 {
  margin-top: 5px;
  margin-bottom: 0px; }

.soc-terms .p62 {
  text-align: justify;
  margin-top: 6px;
  margin-bottom: 0px; }

.soc-terms .p63 {
  text-align: justify;
  margin-top: 3px;
  margin-bottom: 0px; }

.soc-terms .p64 {
  text-align: justify;
  padding-right: 33px;
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p65 {
  text-align: justify;
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p66 {
  text-align: justify;
  margin-top: 7px;
  margin-bottom: 0px; }

.soc-terms .p67 {
  margin-top: 3px;
  margin-bottom: 0px; }

.soc-terms .p68 {
  text-align: justify;
  padding-right: 33px;
  margin-top: 2px;
  margin-bottom: 0px; }

.soc-terms .p69 {
  text-align: justify;
  margin-top: 15px;
  margin-bottom: 0px; }

.soc-terms .p70 {
  text-align: justify;
  margin-top: 21px;
  margin-bottom: 0px; }

.soc-terms .p71 {
  margin-top: 19px;
  margin-bottom: 0px; }

.soc-terms .p72 {
  margin-top: 21px;
  margin-bottom: 0px; }

.soc-terms .p73 {
  text-align: justify;
  margin-top: 20px;
  margin-bottom: 0px; }

.soc-terms .p74 {
  margin-top: 4px;
  margin-bottom: 0px; }

.soc-terms .p75 {
  margin-top: 1px;
  margin-bottom: 0px; }

.soc-terms .p76 {
  text-align: justify;
  margin-top: 12px;
  margin-bottom: 0px; }

.soc-terms .p77 {
  margin-top: 12px;
  margin-bottom: 0px; }

.soc-terms .p78 {
  margin-top: 13px;
  margin-bottom: 0px; }

.tc-hedline {
  text-align: center;
  text-decoration: underline; }

.tc-table-border td, th {
  border: 1px solid black;
  padding: 3px 5px 3px 7px; }

.in-tc-table-border td, th {
  border: 1px solid black;
  width: auto;
  padding-left: 7px; }

.sn-center {
  text-align: center; }

td:empty {
  padding: 10px; }

/*for website 2x devices */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .glyphicon, .btn-social, .icon-img {
    background-image: url(../assets/images/yes-icon2x.png) !important; }
  .right-arrow, .left-arrow {
    background-image: url(../assets/images/doc-icon2x.png); } }

/*for website repsonsive*/
@media (max-width: 1200px) {
  .detail-box-wl {
    width: 46%; } }

@media (max-width: 992px) {
  .form-field-row .error, .form-field-row .suggestion {
    left: 20px;
    right: 20px;
    min-width: auto; }
  .only-for-lg {
    display: block; }
  .none-for-md {
    display: none !important; }
  .md-dialog-container .main-form-container {
    margin: 0;
    padding: 60px; }
    .md-dialog-container .main-form-container h2 {
      margin-top: 0; }
  .md-dialog-container .main-form-kfs-container {
    margin: 0;
    padding: 60px; }
    .md-dialog-container .main-form-kfs-container h2 {
      margin-top: 0; }
  .form-field-row {
    margin-bottom: 20px; }
  .tab-container-pass {
    min-height: 350px; }
    .tab-container-pass .tab-wrapper {
      height: 60px; }
      .tab-container-pass .tab-wrapper li {
        height: 60px;
        line-height: 60px; }
      .tab-container-pass .tab-wrapper .tab-text {
        display: none; }
  .main-form-container {
    padding: 0 20px 40px; }
  .main-form-kfs-container {
    padding: 0 20px 40px; }
  .offers {
    padding: 30px 15px; }
  .two-block {
    margin: 0 -20px; }
    .two-block .form-field-row {
      padding: 0 20px; }
  .customer-login {
    padding: 40px; }
  .dt-tbl .dt-tbl-cl {
    font-size: 14px; }
  .detail-box-wl h2 {
    font-size: 16px; }
  .detail-box-wl p {
    margin-bottom: 20px; }
  .detail-box-wl:nth-child(odd) {
    margin-right: 10px; }
  .detail-box-wl:nth-child(even) {
    margin-left: 10px; }
  /* My Application */
  .loan-application-list .application-list .acceptance .acceptance-box .status-box:before {
    border-width: 8px;
    margin-left: -14px; }
  .loan-application-list .application-list .acceptance .acceptance-box .status-box:after {
    border-width: 6px;
    margin-left: -12px; }
  /* /My Application */ }

/* Only For Tablet */
@media only screen and (min-width: 767px) and (max-width: 992px) {
  .upload-box {
    margin: 0 0px 40px;
    padding: 20px; }
    .upload-box .form-field-row {
      padding: 0; }
    .upload-box .myLabel {
      width: auto;
      height: auto;
      padding: 20px;
      padding-left: 70px; }
      .upload-box .myLabel span:before {
        left: 20px; }
    .upload-box .connect-social {
      width: auto;
      padding-top: 0; }
      .upload-box .connect-social .btn-social {
        width: auto;
        padding: 0 15px; }
  .loan-application-list .application-list .app-progress-bar span label {
    font-size: 11px; }
  .detail-box {
    padding: 25px 15px; }
  .offers .offer-container {
    margin: -30px -15px;
    padding-top: 30px; }
    .offers .offer-container .div-table {
      padding: 20px 0 30px; }
      .offers .offer-container .div-table .table-cell {
        padding: 0 8px; }
      .offers .offer-container .div-table label {
        font-size: 12px;
        line-height: 15px; }
  .offers .customize-offer-container .customize-box .range-slider md-slider {
    width: 440px; }
  /* My Application */
  .application-box {
    min-height: auto !important; }
  .loan-application-list .application-list .acceptance .acceptance-box .status-bar {
    left: -122px; }
  .loan-application-list .application-list .table thead th {
    font-size: 10px;
    padding: 18px 5px; }
  .loan-application-list .application-list .table tbody tr td {
    font-size: 12px;
    padding: 30px 5px; }
  /* /My Application */ }

/* /Only For Tablet */
@media (max-width: 959px) and (min-width: 600px) {
  .layout-sm-column {
    -ms-flex-direction: row;
    flex-direction: row; } }

@media (max-width: 768px) {
  body,
  html {
    background: #ffffff;
    font-size: 16px; }
  br {
    display: none; }
  .only-for-mobile {
    display: block !important; }
  .not-for-mobile {
    display: none !important; }
  .inline {
    display: inline !important; }
  p big {
    font-size: 16px;
    line-height: 20px; }
  /* Header */
  header {
    height: 56px; }
    header .header-right a.menu-link {
      display: none; }
    header .header-right .user-login {
      width: 60px;
      height: 56px;
      /* margin-right: -15px;*/
      margin-left: 0; }
      header .header-right .user-login .loggedIn-item {
        top: 56px; }
      header .header-right .user-login span {
        width: auto;
        margin-left: 0; }
        header .header-right .user-login span em {
          display: none; }
        header .header-right .user-login span:after {
          right: 5px;
          font-size: 5px; }
    header .header-logo {
      width: 220px; }
      header .header-logo .logo,
      header .header-logo .poweredby {
        background-size: 458px auto;
        height: 44px; }
      header .header-logo .logo {
        width: 121px;
        background-position: -6px -1px; }
      header .header-logo .poweredby {
        margin-left: 20px;
        width: 66px;
        background-position: -149px -1px; }
        header .header-logo .poweredby:before {
          left: -10px; }
  /* /Header */
  /* Inner Container */
  .bank-lower-header-bg {
    padding: 40px 15px 20px;
    height: auto;
    min-height: 40px; }
    .bank-lower-header-bg h1 {
      font-size: 20px;
      line-height: 24px; }
  .tab-container-pass {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 50px;
    box-shadow: none; }
    .tab-container-pass .tab-wrapper {
      margin: 0 -15px; }
  .main-form-container {
    padding: 0; }
    .main-form-container h2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px; }
      .main-form-container h2 + p {
        font-size: 16px;
        line-height: 24px; }
    .main-form-container h3 {
      margin: 5px 0 25px; }
  .main-form-kfs-container {
    padding: 0; }
    .main-form-kfs-container h2 {
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 20px; }
      .main-form-kfs-container h2 + p {
        font-size: 16px;
        line-height: 24px; }
    .main-form-kfs-container h3 {
      margin: 5px 0 25px; }
  .sub-box {
    width: 100%;
    padding: 30px 15px; }
    .sub-box h4 {
      margin: -30px -15px 30px;
      font-size: 16px; }
    .sub-box.upload-fin {
      margin-top: 30px;
      border: 0;
      box-shadow: none;
      padding: 0; }
      .sub-box.upload-fin p:first-of-type {
        font-size: 16px; }
    .sub-box.customer-login {
      margin: -30px auto 30px;
      width: 100%; }
  md-radio-button {
    margin-bottom: 10px;
    margin-top: 10px; }
  md-checkbox {
    margin-top: 0; }
  /* /Inner Container */
  .form-block:last-of-type .btn {
    margin-top: 10px; }
  .two-block {
    margin: 0; }
    .two-block .form-field-row {
      padding: 0;
      width: 100%;
      float: none; }
      .two-block .form-field-row.with-prefix .field-prefix {
        left: 20px; }
      .two-block .form-field-row:before, .two-block .form-field-row:after {
        content: '';
        clear: both; }
  /* Upload Box */
  .upload-box {
    padding: 20px;
    margin-bottom: 40px; }
    .upload-box .sep {
      position: static;
      margin: 20px 0;
      text-align: center;
      display: block; }
    .upload-box .myLabel,
    .upload-box .connect-social {
      width: 100%; }
    .upload-box .myLabel {
      width: 288px;
      padding: 20px;
      height: 80px;
      padding-left: 65px;
      line-height: 19px;
      margin: 0 auto;
      display: block; }
      .upload-box .myLabel span:before {
        left: 20px;
        margin-top: -10.5px; }
      .upload-box .myLabel br {
        display: block; }
    .upload-box .connect-social {
      text-align: center;
      padding-top: 0; }
  .btn-social {
    width: 47%;
    max-width: 150px; }
  md-next-button,
  md-prev-button {
    display: none; }
  /* Offer */
  .offers {
    padding: 30px 15px;
    margin-top: -30px;
    background-color: #FFFFFF;
    border: 1px solid rgba(224, 224, 224, 0.7);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 30px; }
    .offers .icon {
      margin-bottom: -20px; }
    .offers h2 + p {
      margin-top: 0; }
    .offers .offer-container {
      margin: -30px -15px 0;
      padding: 40px 15px 0; }
      .offers .offer-container .div-table label {
        font-size: 12px; }
    .offers .customize-offer-container .customize-box .range-slider md-slider {
      width: 350px; }
    .offers .offer-input-amount {
      width: 100%;
      min-width: auto; }
    .offers .offer-text-indent {
      font-size: 30px;
      padding-right: 110px; }
      .offers .offer-text-indent::-webkit-input-placeholder {
        font-family: "Cairo-Bold"; }
      .offers .offer-text-indent::-moz-placeholder {
        font-family: "Cairo-Bold"; }
      .offers .offer-text-indent:-ms-input-placeholder {
        font-family: "Cairo-Bold"; }
      .offers .offer-text-indent ::placeholder {
        font-family: "Cairo-Bold"; }
    .offers .subject-msg {
      padding: 20px 0;
      font-size: 16px; }
  .range-slider-container {
    padding: 20px 0; }
    .range-slider-container.div-table .loan-amount {
      font-size: 24px;
      line-height: 30px; }
  .detail-box {
    max-width: 100%;
    margin: 30px auto;
    padding: 20px 15px;
    height: auto;
    max-height: none; }
    .detail-box ol li {
      margin-bottom: 15px; }
    .detail-box label {
      display: block; }
  /*welcome screen css*/
  .dt-tbl {
    display: block;
    padding: 0 0 10px;
    margin-top: 20px; }
    .dt-tbl .dt-tbl-cl {
      display: inline-block;
      width: 49%;
      font-size: 14px;
      padding: 10px 10px 0; }
  .display-none-for-mobile {
    display: none; }
  .detail-box-wl {
    width: 100%; }
    .detail-box-wl p {
      font-size: 14px !important; }
    .detail-box-wl i {
      margin: 20px 0; }
    .detail-box-wl:nth-child(odd) {
      margin-right: 0; }
    .detail-box-wl:nth-child(even) {
      margin-left: 0;
      margin-bottom: 40px; }
  /*end of welcome css*/
  /*my application*/
  .page-headings {
    padding: 30px 0; }
  .apply-loan-section {
    padding: 20px 0; }
  .loan-application-list {
    margin: 0 0 20px; }
    .loan-application-list .loan-list-top {
      padding: 15px 15px 0; }
      .loan-application-list .loan-list-top span {
        margin-left: 0; }
    .loan-application-list .list-content {
      width: calc(100% - 28px) !important; }
    .loan-application-list .loan-app-list .loan-app-cell {
      display: block;
      width: 100%; }
      .loan-application-list .loan-app-list .loan-app-cell label {
        display: inline-block; }
      .loan-application-list .loan-app-list .loan-app-cell p {
        display: inline-block;
        font-size: 14px; }
      .loan-application-list .loan-app-list .loan-app-cell:nth-child(5) {
        width: calc(100% + 60px);
        margin: 0 0 0 -43px;
        padding: 10px; }
    .loan-application-list .btn {
      width: auto !important; }
    .loan-application-list .app-progress-bar span {
      margin: 0 0 10px 20px;
      position: relative;
      display: block;
      height: 40px;
      line-height: 40px; }
      .loan-application-list .app-progress-bar span:last-of-type {
        margin-bottom: 0; }
      .loan-application-list .app-progress-bar span label {
        width: 100%;
        text-align: left;
        padding-left: 50px;
        font-size: 16px; }
      .loan-application-list .app-progress-bar span i {
        position: absolute;
        left: 0;
        margin-top: -15px;
        top: 50%; }
    .loan-application-list .app-progress-bar:after {
      width: 1px;
      height: 100%;
      border-left: solid 1px #C4CFD6;
      height: 100%;
      left: 34px;
      top: 0; }
  .layout-sm-column {
    display: block; }
  /*end of my application*/
  .structure-main {
    min-height: calc(100% - 144px); }
  .detail-container .form-field-row {
    display: block !important;
    margin-bottom: 20px;
    max-width: 100%; }
    .detail-container .form-field-row .detail-box {
      margin-bottom: 0; }
    .detail-container .form-field-row:last-of-type {
      margin-bottom: 0; }
      .detail-container .form-field-row:last-of-type .detail-box {
        max-width: 100%; }
  .information-container .detail-container .form-field-row {
    max-width: 100%; }
    .information-container .detail-container .form-field-row:last-of-type .reference-box {
      max-width: 100%; }
  /* My Application */
  .loan-application-list .application-list .table tbody tr {
    padding: 8px 10px 50px;
    height: auto; }
  .loan-application-list {
    padding: 30px 0; }
    .loan-application-list .application-list .user-name {
      display: inline-block; }
    .loan-application-list .application-list .table {
      /*display:block;*/ }
      .loan-application-list .application-list .table tbody tr {
        display: block;
        border: 1px solid rgba(224, 224, 224, 0.7);
        margin-bottom: 20px;
        padding: 8px 10px 40px; }
        .loan-application-list .application-list .table tbody tr:last-child {
          margin-bottom: 0; }
        .loan-application-list .application-list .table tbody tr td {
          float: left;
          width: 50%;
          font-size: 16px;
          padding: 10px; }
          .loan-application-list .application-list .table tbody tr td label {
            font-size: 12px;
            line-height: 14px;
            color: #9b9b9b;
            text-transform: uppercase; }
          .loan-application-list .application-list .table tbody tr td:before, .loan-application-list .application-list .table tbody tr td:after {
            content: '';
            display: table;
            clear: both; }
        .loan-application-list .application-list .table tbody tr:before, .loan-application-list .application-list .table tbody tr:after {
          content: '';
          display: table;
          clear: both; }
      .loan-application-list .application-list .table .icon-Delete {
        margin-left: 25px;
        vertical-align: middle;
        margin-bottom: 0 !important; }
      .loan-application-list .application-list .table .acceptance label {
        font-family: "Cairo-Medium"; }
      .loan-application-list .application-list .table .acceptance .acceptance-box .status-bar {
        left: 0;
        margin-left: 0;
        text-align: left;
        display: block;
        top: 27px; }
      .loan-application-list .application-list .table .acceptance .acceptance-box .status-box:before, .loan-application-list .application-list .table .acceptance .acceptance-box .status-box:after {
        left: 36px; }
  /* /My Application */
  .tab-vertical {
    margin-right: 0 !important; }
    .tab-vertical .right-tab-wrapper {
      width: 100% !important;
      padding-left: 0 !important; }
      .tab-vertical .right-tab-wrapper h2 {
        display: none; }
  .loan-application-list .application-list .table tbody tr td {
    padding: 10px 0; }
  .loan-application-list .application-list .acceptance {
    font-size: 10px !important; } }

@media (max-width: 576px) {
  .icon-img.icon-sm {
    background-size: 242px auto; }
  .icon-img.icon-thanks, .icon-img.icon-offer, .icon-img.icon-great {
    width: 67px;
    height: 67px; }
  .icon-img.icon-offer {
    background-position: -411px -58px; }
  .icon-img.icon-thanks {
    background-position: -489px -58px; }
  .icon-img.icon-great {
    background-position: -571px -58px; }
  md-checkbox .md-container, md-checkbox .md-icon {
    width: 20px;
    height: 20px; }
  md-checkbox .md-container {
    top: 4px; }
    md-checkbox .md-container .md-ripple-containe {
      left: -10px;
      top: -10px;
      right: -10px;
      bottom: -10px; }
  md-checkbox.md-checked .md-icon:after {
    left: 4.66667px;
    top: 0.22222px;
    width: 6.66667px;
    height: 13.33333px; }
  md-checkbox .md-label {
    margin-left: 30px; }
  .only-for-xs {
    display: block !important; }
  .btn {
    width: 100%;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    padding: 0 20px; }
    .btn.btn-large {
      min-width: 100%; }
    .btn.btn-social {
      width: 47%;
      max-width: 150px; }
  .upload-box .myLabel {
    width: 100%; }
  .anchor-spacing-under-btns {
    font-size: 16px; }
  .div-table .table-cell {
    display: block;
    border-bottom: 1px solid #f4f4f4;
    border-left: 0; }
    .div-table .table-cell:last-of-type {
      border-bottom: 0; }
  .offers .offer-container .offer-details {
    margin-top: 10px; }
    .offers .offer-container .offer-details .div-table {
      padding: 0 0 10px; }
      .offers .offer-container .offer-details .div-table .loan-amount {
        font-size: 18px;
        line-height: 22px; }
      .offers .offer-container .offer-details .div-table .table-cell {
        display: inline-block;
        float: left;
        width: 50%;
        padding: 15px 0 10px; }
        .offers .offer-container .offer-details .div-table .table-cell:nth-child(5) {
          min-height: auto; }
  .offers .customize-offer-container {
    padding: 20px 0;
    border-bottom: 1px solid rgba(224, 224, 224, 0.7); }
    .offers .customize-offer-container h6 {
      font-size: 12px; }
    .offers .customize-offer-container .customize-box {
      text-align: left;
      margin-top: 10px; }
      .offers .customize-offer-container .customize-box label {
        font-size: 12px;
        margin-bottom: 5px; }
      .offers .customize-offer-container .customize-box .box-label {
        width: 100%; }
      .offers .customize-offer-container .customize-box .range-slider {
        display: none; }
      .offers .customize-offer-container .customize-box + .customize-box {
        margin-top: 20px; }
      .offers .customize-offer-container .customize-box .loan-tenure span {
        width: 35px;
        font-size: 14px;
        margin: 5px;
        height: 28px;
        line-height: 26px;
        text-align: center; }
        .offers .customize-offer-container .customize-box .loan-tenure span:first-child {
          margin-left: 0; }
        .offers .customize-offer-container .customize-box .loan-tenure span:last-child {
          margin-right: 0; }
  .offers .declaration {
    padding-top: 15px; }
  .offers p + p {
    margin-top: 10px; }
  .offers .no-offer-box {
    width: 100%;
    margin: 20px auto;
    font-size: 12px; }
    .offers .no-offer-box ul li label {
      width: 85px;
      padding: 0 15px 15px;
      margin-right: 15px; }
    .offers .no-offer-box ul li:first-child label {
      padding-top: 15px; }
    .offers .no-offer-box ul li:last-child label {
      padding-bottom: 15px; }
  .default-container h2 {
    font-size: 15px !important; }
  .default-container .form-field-row {
    text-align: left; }
    .default-container .form-field-row md-radio-button {
      margin-right: 12px; }
  .uploaded-image {
    width: 100%; }
  .detail-container {
    margin-top: 20px; } }

@media (max-width: 500px) {
  .not-for-xs {
    display: none !important; }
  .close-overlay {
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 29px;
    font-size: 12px; }
  .confirmation-layer {
    padding: 30px 15px; }
  .detail-box .box-detail {
    margin-bottom: 15px; }
    .detail-box .box-detail label {
      display: block;
      margin-bottom: 0; }
  .application-box .dashboard-wrapp a {
    padding: 0 10px;
    font-size: 18px;
    line-height: 21px; } }

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .list-for-verification {
    padding-right: 235px; }
    .list-for-verification .doc-for-upload {
      width: 215px; }
      .list-for-verification .doc-for-upload .btn-border {
        font-size: 11px; }
  .verification-table .default-textfield {
    width: 140px; }
  .verification-table table tbody tr td {
    padding: 10px 15px; }
    .verification-table table tbody tr td label {
      line-height: normal;
      display: block;
      margin-bottom: 10px; } }

@media (max-width: 992px) {
  .verification-table md-radio-button {
    margin-top: 0; }
  .verification-table table thead th {
    overflow: hidden; }
    .verification-table table thead th:last-child {
      padding-left: 15px;
      padding-right: 15px; }
  .verification-table table tbody tr {
    height: auto;
    line-height: normal; }
    .verification-table table tbody tr td {
      padding: 10px 15px; }
      .verification-table table tbody tr td label {
        line-height: normal; }
      .verification-table table tbody tr td:last-child {
        width: 100px;
        padding-left: 10px; }
  .verification-table .inline-div {
    display: block;
    margin-top: 10px; }
  .verification-table .default-textfield {
    margin-left: 0;
    margin-right: 10px; } }

@media (max-width: 768px) {
  .application-bredcrumb {
    margin-top: 30px; }
    .application-bredcrumb a.current h2 {
      margin-top: 0; }
  .list-for-verification {
    padding-right: 0; }
    .list-for-verification .doc-for-upload {
      position: relative;
      top: auto;
      width: 100%;
      margin-top: 30px; }
  .new-doc-white-container {
    padding: 20px 5px; }
    .new-doc-white-container .document-table .document-list {
      display: block;
      margin-bottom: 30px;
      padding-bottom: 60px; }
      .new-doc-white-container .document-table .document-list md-checkbox .md-label {
        margin-top: 5px; }
    .new-doc-white-container .small-checkbox md-checkbox {
      margin-top: 0 !important; }
      .new-doc-white-container .small-checkbox md-checkbox .md-label {
        margin-top: 5px !important; }
    .new-doc-white-container .all-doc {
      margin-bottom: 0 !important; } }

@media (max-width: 576px) {
  .application-bredcrumb a {
    color: transparent;
    font-size: 0; }
  .list-for-verification table thead {
    height: auto;
    line-height: normal; }
    .list-for-verification table thead tr th {
      padding-top: 12px;
      padding-bottom: 12px; }
      .list-for-verification table thead tr th span {
        display: block;
        text-align: left; }
  .list-for-verification table tbody tr {
    display: block; }
    .list-for-verification table tbody tr td {
      display: block;
      width: 100% !important; }
      .list-for-verification table tbody tr td label {
        display: block;
        max-width: 100%;
        margin-bottom: 10px; }
      .list-for-verification table tbody tr td:last-child {
        padding: 0 15px 10px !important;
        text-align: left; }
  .verification-table .default-textfield {
    width: 100%;
    margin: 10px 0 0 !important; } }

@media (max-width: 500px) {
  .list-container {
    padding: 0 0 30px; }
  .new-doc-white-container {
    padding: 0;
    box-shadow: none; }
    .new-doc-white-container .document-table .document-list {
      padding: 0 0 60px 0; }
    .new-doc-white-container h4 {
      margin-bottom: 20px;
      padding: 0; }
    .new-doc-white-container .all-doc {
      left: 0;
      right: 0; } }

/* Yes Bank Modified CSS */
/* Modification */
.bg-grey {
  background: #f5f5f5; }

body,
html {
  background: #ffffff;
  margin: 0;
  padding: 0; }

.container .no-margin-top {
  margin-top: 0px; }

.note-inr {
  color: #000; }

.margin-top-20 {
  margin-top: 20px;
  display: block; }

.no-text-decoration {
  text-decoration: none; }

.no-offer-form {
  margin: 20px auto 0;
  width: 400px; }
  .no-offer-form .proceedHeading {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 20px;
    color: #0062a8; }
  .no-offer-form .form-field-row {
    width: 80%;
    margin: 0 auto 15px; }
    .no-offer-form .form-field-row .error {
      width: 100%;
      min-width: auto; }
    .no-offer-form .form-field-row .suggestion {
      min-width: auto;
      width: 100%; }

.normal {
  font-size: 15px; }

.padd-20 {
  padding: 0 20px; }

.name-capitalize {
  text-transform: capitalize; }

.add-bt-amt {
  margin-bottom: 10px; }

header {
  box-shadow: none;
  border-bottom: #cccccc solid 1px; }
  header .header-logo {
    width: 162px; }
  header .header-right {
    font-size: 11px; }
    header .header-right .user-login {
      padding: 0 10px; }
      header .header-right .user-login span {
        padding-right: 0; }
        header .header-right .user-login span:after {
          display: none; }
    header .header-right .header-logo {
      width: 91px; }
      header .header-right .header-logo .poweredby {
        margin-left: 0; }
        header .header-right .header-logo .poweredby:before {
          left: auto;
          right: -36px;
          display: none; }

md-radio-button {
  margin-bottom: 0; }
  md-radio-button.md-checked .md-off {
    border-color: #cccccc; }
  md-radio-button.md-checked .md-ink-ripple {
    color: transparent; }
  md-radio-button .md-on {
    background-color: #404040; }
  md-radio-button .md-off {
    border-width: 1px; }
  md-radio-button .md-label {
    margin-left: 26px; }
  md-radio-button .md-container,
  md-radio-button .md-on,
  md-radio-button .md-off {
    width: 16px;
    height: 16px; }

.form-error {
  margin-bottom: 10px; }

.bank-lower-header-bg {
  background-image: none;
  height: 185px; }
  .bank-lower-header-bg h1 {
    color: #404040;
    position: relative;
    padding-bottom: 5px; }
    .bank-lower-header-bg h1:after {
      content: '';
      position: absolute;
      width: 100px;
      height: 5px;
      background-color: #d71920;
      left: 50%;
      margin-left: -50px;
      bottom: -5px; }
  .bank-lower-header-bg p {
    text-align: center;
    margin-top: 8px;
    color: #404040;
    text-transform: uppercase; }
    .bank-lower-header-bg p small {
      font-size: 14px; }

.main-form-container {
  width: 860px;
  margin: 0 auto;
  padding: 0px 80px 50px; }
  .main-form-container h3 {
    margin: 10px 0;
    padding-bottom: 0; }
    .main-form-container h3:after {
      display: none; }
  .main-form-container h2 {
    font-size: 21px;
    margin-bottom: 30px; }
    .main-form-container h2 + p {
      font-size: 16px; }

.main-form-kfs-container {
  width: 1060px;
  margin: 0 auto;
  padding: 0px 80px 50px; }
  .main-form-kfs-container h3 {
    margin: 10px 0;
    padding-bottom: 0; }
    .main-form-kfs-container h3:after {
      display: none; }
  .main-form-kfs-container h2 {
    margin: 0 auto !important;
    font-size: 21px;
    text-align: center;
    margin-bottom: 30px; }
    .main-form-kfs-container h2 + p {
      font-size: 16px; }

.etb-main-form-container {
  margin: 0 auto; }
  .etb-main-form-container h3 {
    margin: 10px 0;
    padding-bottom: 0; }
    .etb-main-form-container h3:after {
      display: none; }
  .etb-main-form-container h2 {
    font-size: 21px;
    margin-bottom: 30px; }
    .etb-main-form-container h2 + p {
      font-size: 16px; }

.two-block {
  margin: 0 -20px; }
  .two-block .form-field-row {
    padding: 0 20px; }

.form-field-row.with-prefix .field-prefix {
  left: 40px; }

md-checkbox {
  min-height: 22px;
  color: #404040; }
  md-checkbox .md-icon {
    border-width: 1px; }
    md-checkbox .md-icon:after {
      color: #d71920; }
  md-checkbox .md-container, md-checkbox .md-icon {
    width: 22px;
    height: 22px; }
  md-checkbox .md-label {
    margin-left: 32px; }
  md-checkbox.md-checked .md-icon {
    background-color: transparent;
    border-color: #ccc; }
    md-checkbox.md-checked .md-icon:after {
      border-color: #d71920;
      left: 6.66667px;
      top: 2.22222px; }
  md-checkbox.md-checked .md-ink-ripple {
    color: transparent; }

.fileUpload.btn span {
  font-size: 11px; }

.btn {
  position: relative;
  padding: 0 20px 0 60px;
  font-family: "Cairo-Bold"; }
  .btn.btn-social {
    padding: 0; }
    .btn.btn-social:before, .btn.btn-social:after {
      display: none; }
  .btn:before {
    content: '';
    position: absolute;
    width: 40px;
    border-right: 1px solid #eb8b8f;
    height: 100%;
    left: 0;
    top: 0; }
  .btn:after {
    content: '';
    position: absolute;
    left: 12px;
    top: 50%;
    width: 10px;
    height: 10px;
    margin-top: -6px;
    border-right: 2.5px solid #eb8b8f;
    border-top: 2.5px solid #eb8b8f;
    transform: rotate(45deg); }
  .btn.btn-large {
    min-width: auto;
    min-width: initial !important; }
  .btn.btn-block {
    width: auto; }
  .btn.small-btn {
    padding: 9px 10px 9px 50px;
    font-size: 11px; }
  .btn.btn.mid-btn {
    height: 40px;
    line-height: 40px;
    padding: 0px 20px 0 60px;
    font-size: 11px; }
  .btn.without-bg {
    color: #9b9b9b;
    background: none;
    border: solid 1px #cccccc; }
    .btn.without-bg:before {
      background: #d71920; }
    .btn.without-bg:hover {
      background: #d71920;
      color: #fff; }

.btn-social {
  width: 120px;
  height: 40px;
  line-height: 40px;
  font-size: 18px; }

.tab-container-pass {
  padding: 0 18px 0 0;
  box-shadow: none;
  margin-bottom: 0;
  margin-top: 0; }
  .tab-container-pass .tab-wrapper {
    height: 36px; }
    .tab-container-pass .tab-wrapper li {
      height: 36px;
      line-height: 36px;
      font-size: 15px;
      font-family: "Cairo-Bold";
      color: #fff;
      text-transform: capitalize;
      border-bottom: 0;
      background-color: #cccccc;
      position: relative;
      border-right: 0;
      margin-left: 18px; }
      .tab-container-pass .tab-wrapper li i {
        font-size: 18px; }
      .tab-container-pass .tab-wrapper li:before {
        content: '';
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #fff;
        left: -18px;
        top: 0; }
      .tab-container-pass .tab-wrapper li:after {
        content: '';
        position: absolute;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        background: #cccccc;
        right: -18px;
        top: 0;
        z-index: 99; }
      .tab-container-pass .tab-wrapper li:first-child {
        margin-left: 0; }
        .tab-container-pass .tab-wrapper li:first-child:before {
          display: none; }
      .tab-container-pass .tab-wrapper li.active {
        border: 0; }
        .tab-container-pass .tab-wrapper li.active:after {
          background: #0062a8; }
      .tab-container-pass .tab-wrapper li span.tab-text {
        position: relative;
        top: -3px; }

.sub-box {
  font-size: 14px;
  box-shadow: none;
  border: 0;
  padding: 0 30px 0; }
  .sub-box.customer-login {
    width: 450px;
    box-shadow: none;
    margin: 0 auto 0;
    padding-bottom: 50px; }
    .sub-box.customer-login .error, .sub-box.customer-login .suggestion {
      width: 100%;
      left: auto;
      right: auto; }
    .sub-box.customer-login h2 {
      font-size: 25px; }
      .sub-box.customer-login h2 + p {
        margin-top: 0; }
    .sub-box.customer-login .form-field-row.with-prefix .field-prefix {
      left: 20px; }
    .sub-box.customer-login .form-field-row.with-prefix .default-textfield {
      padding-left: 50px; }
  .sub-box.upload-fin {
    margin-top: 30px; }
    .sub-box.upload-fin p:first-of-type {
      margin-top: 0; }
  .sub-box.form-block .form-field-row.with-prefix .field-prefix {
    left: 20px; }

.singinWithPassword {
  margin: 0;
  margin-top: 40px;
  background: #fafafa;
  border-top: 0; }
  .singinWithPassword a {
    text-decoration: none; }

.offers {
  margin-top: -36px;
  width: 100%;
  padding: 50px 0;
  margin-right: 0 !important; }
  .offers .offer-container {
    margin: -50px 0 0;
    padding: 50px 0 0; }
  .offers h2 {
    font-size: 31px;
    margin-top: 0; }
    .offers h2 + p {
      margin-top: 0;
      line-height: 20px; }
      .offers h2 + p br {
        display: block; }
  .offers .icon-img {
    display: none; }
  .offers .declaration {
    padding: 0; }
    .offers .declaration p {
      margin-bottom: 0; }
    .offers .declaration md-checkbox {
      margin-top: 40px;
      margin-bottom: 25px; }

.anchor-spacing-under-btns {
  font-size: 14px; }

p big {
  margin-bottom: 40px; }

.upload-box {
  margin: 0 0 40px;
  padding: 20px 0; }
  .upload-box label {
    font-size: 18px; }
  .upload-box .connect-social {
    width: 250px; }
  .upload-box .myLabel {
    width: 100%;
    padding: 38px 40px;
    padding-left: 72px; }
    .upload-box .myLabel span:before {
      left: 20px; }
  .upload-box .sep {
    left: 54%; }

.file-name .action-btn .icon-icon-Check {
  height: 26px;
  width: 26px;
  line-height: 26px;
  font-size: 9px; }

.file-name .action-btn .icon-Delete {
  color: #9b9b9b;
  font-size: 15px; }

.file-name .action-btn.left-icon {
  margin-top: -13px; }

.file-name.uploaded {
  padding-left: 35px; }

.fileUpload span {
  font-size: 14px; }

.agreement {
  margin-top: -166px;
  width: 100%;
  padding-bottom: 50px;
  padding-right: 0; }
  .agreement h2 {
    font-size: 31px; }
  .agreement .agreemet-preview {
    margin-top: 0; }

.detail-box {
  margin-bottom: 20px;
  max-height: 290px;
  min-height: 290px;
  padding: 40px; }

.detail-box-wl h2, .detail-box h2 {
  font-size: 21px; }

.detail-box-wl .icon-IconUpload, .detail-box-wl .icon-IconKyc, .detail-box .icon-IconUpload, .detail-box .icon-IconKyc {
  display: none; }

.dt-tbl .dt-tbl-cl {
  font-size: 14px; }
  .dt-tbl .dt-tbl-cl p {
    font-family: "Cairo-Bold"; }

.welcome-upload .fileUpload {
  margin-bottom: 20px; }

.reference-box {
  margin-top: 40px; }

.default-container {
  padding: 50px 0;
  width: 100%;
  margin: 0 !important; }
  .default-container h2 {
    margin-top: 0; }
    .default-container h2 + p {
      text-align: left;
      font-size: 14px; }
  .default-container p {
    padding-bottom: 15px;
    line-height: 18px; }
    .default-container p:last-of-type {
      padding-bottom: 0; }
  .default-container ol li {
    margin-bottom: 30px; }
    .default-container ol li:last-child {
      margin-bottom: 0; }
    .default-container ol li p {
      padding-bottom: 0;
      margin-bottom: 10px; }
  .default-container h6 {
    font-size: 16px; }

i {
  font-style: normal; }

@media (max-width: 992px) {
  .tab-container-pass .tab-wrapper li {
    height: 36px;
    line-height: 36px; }
    .tab-container-pass .tab-wrapper li:after, .tab-container-pass .tab-wrapper li:before {
      width: 36px;
      height: 36px; }
    .tab-container-pass .tab-wrapper li span.tab-text {
      display: block;
      margin-left: 0;
      position: relative;
      z-index: 100;
      padding-left: 25px; }
  .main-form-container {
    width: auto;
    min-height: 300px;
    padding: 0;
    margin-right: -18px; }
  .main-form-kfs-container {
    width: auto;
    min-height: 300px;
    padding: 0;
    margin-right: -18px; }
  body,
  html {
    font-size: 14px !important; } }

@media only screen and (min-width: 767px) and (max-width: 992px) {
  .main-form-container {
    padding: 30px 0; }
  .main-form-kfs-container {
    padding: 30px 0; }
  .sub-box.customer-login {
    padding: 0 0 30px; }
  .tab-container-pass .main-form-container h2 {
    margin-top: 0; }
  .tab-container-pass .main-form-kfs-container h2 {
    margin-top: 0; }
  .welcome-upload .detail-box {
    padding: 60px 20px;
    min-height: 277px; }
  .upload-box .connect-social .btn-social {
    width: 120px; }
  .loan-application-list .list-header .head {
    font-size: 11px;
    padding: 10px 4px; }
  .loan-application-list .application-list .list-body .list-item {
    padding: 10px 4px;
    font-size: 13px; }
  .loan-application-list .application-list .list-body .btn.small-btn {
    padding: 9px 10px 9px 34px; }
    .loan-application-list .application-list .list-body .btn.small-btn:before {
      width: 24px; }
    .loan-application-list .application-list .list-body .btn.small-btn:after {
      left: 4px; }
  .reference-box {
    margin-top: 0; }
  .agreement {
    margin-top: -184px !important; } }

@media (max-width: 768px) {
  header .header-logo {
    width: 121px; }
  header .header-right .user-login {
    width: auto; }
  header .header-right .header-logo {
    width: 83px; }
    header .header-right .header-logo .poweredby:before {
      right: -16px; }
  .upload-box .myLabel {
    width: 288px; }
  .bank-lower-header-bg {
    height: 170px;
    padding-top: 30px; }
  .singinWithPassword {
    margin-top: 30px; }
  .tab-container-pass {
    margin-top: -55px;
    min-height: auto; }
    .tab-container-pass .tab-wrapper {
      margin: -55px 0 0 0; }
      .tab-container-pass .tab-wrapper li {
        padding-left: 27px; }
        .tab-container-pass .tab-wrapper li:first-child {
          padding-left: 15px; }
        .tab-container-pass .tab-wrapper li span.tab-text {
          display: none;
          font-size: 11px;
          white-space: nowrap;
          padding-left: 11px; }
        .tab-container-pass .tab-wrapper li i {
          position: relative;
          z-index: 999; }
  .upload-box .connect-social {
    width: 100%; }
  .main-form-container {
    padding-bottom: 30px; }
    .main-form-container h2 {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 25px; }
  .main-form-kfs-container {
    padding-bottom: 30px; }
    .main-form-kfs-container h2 {
      font-size: 18px;
      margin-top: 20px;
      margin-bottom: 25px; }
  .sub-box {
    padding-bottom: 30px; }
    .sub-box.customer-login {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 30px; }
      .sub-box.customer-login h2 {
        font-size: 20px;
        margin-top: 0; }
  .two-block .form-field-row.with-prefix .field-prefix {
    left: 40px; }
  .offers {
    border: 0;
    box-shadow: none;
    padding: 30px 0;
    margin-top: -185px;
    margin-right: 0; }
    .offers .declaration {
      padding: 15px 0 0; }
      .offers .declaration md-checkbox {
        margin: 30px 0; }
  .detail-box {
    max-height: none;
    min-height: auto;
    padding: 20px 15px; }
    .detail-box h2 {
      margin-top: 0;
      font-size: 16px; }
    .detail-box .box-detail label {
      font-size: 12px; }
  .welcome-upload .detail-box {
    min-height: auto;
    height: auto;
    max-height: none;
    padding: 20px 15px; }
    .welcome-upload .detail-box .btn {
      margin-top: 20px; }
    .welcome-upload .detail-box .welcome-uploadBox .form-field-row {
      margin-bottom: 20px; }
    .welcome-upload .detail-box .welcome-uploadBox btn {
      margin-top: 0; }
  .reference-box {
    padding: 15px 60px 15px 15px; }
    .reference-box h6 {
      font-size: 16px; }
    .reference-box p {
      font-size: 14px; }
  .agreement {
    margin-top: -150px;
    margin-right: 0; }
  p big {
    margin-bottom: 20px; }
  .hr-divider {
    margin: 20px 0; }
  .offers .offer-container .div-table .loan-amount {
    font-size: 18px; }
  .detailContainer2 .form-field-row {
    margin: 0 auto; }
  .tab-container-pass .tab-wrapper li {
    font-size: 15px !important; }
  .icon-icon-Check {
    right: -8px !important;
    font-size: 10px !important; } }

@media (max-width: 576px) {
  .secondary-block {
    padding: 20px; }
    .secondary-block h6 {
      font-size: 18px;
      margin: 0 0 10px 0;
      line-height: 20px; }
  .display-inline {
    display: inline-block !important; }
  .detail-box .box-detail label {
    display: inline-block;
    width: 150px; }
  .btn {
    height: 40px;
    line-height: 40px;
    font-size: 11px;
    width: auto; }
  .btn.btn-social {
    width: 120px; }
  .offers .offer-container .offer-details .div-table .table-cell {
    border-bottom: 0; }
  .upload-box label {
    font-size: 18px; }
  .upload-box .myLabel {
    width: 100%;
    font-size: 14px;
    padding-top: 23px;
    padding-bottom: 23px; }
  .offers .customize-offer-container .customize-box {
    text-align: center; }
    .offers .customize-offer-container .customize-box label {
      display: block;
      text-align: center; }
  .offers .customize-offer-container .only-for-xs p {
    text-align: left; }
  .offers br {
    display: block; }
  .declaration br {
    display: none; }
  .confirmation-layer .confirm-btns .btn + .btn {
    margin-left: 0; }
  .md-dialog-container .main-form-container {
    margin: 0;
    padding: 30px 20px 20px 20px; }
  .md-dialog-container .main-form-kfs-container {
    margin: 0;
    padding: 30px 20px 20px 20px; }
  .close-overlay {
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 29px;
    font-size: 12px;
    z-index: 99; }
  /*.bank-lower-header-bg h1 {
        font-size: 17px;
        line-height: 21px;
    }*/
  .sub-heading p {
    margin-left: -15px; } }

.reference-container {
  margin-top: 0 !important; }
  .reference-container .detail-container {
    margin-bottom: 0 !important; }
    .reference-container .detail-container .reference-box {
      margin-top: 30px !important; }

.sub-heading p {
  text-align: left !important;
  margin-top: 18px; }

.fix-height {
  min-height: calc(100vh - 171px); }

.cv-no-offer {
  min-height: 400px;
  padding: 100px 0; }

.cv-offer {
  min-height: 400px;
  padding: 50px 0; }
  .cv-offer ul li {
    list-style: none; }

.popup-box p.text-center {
  text-align: center !important; }

.popup-box .terms-list {
  margin-top: 30px; }
  .popup-box .terms-list p {
    margin-bottom: 5px; }
  .popup-box .terms-list li {
    margin-bottom: 10px; }
    .popup-box .terms-list li:last-child {
      margin-bottom: 0; }
    .popup-box .terms-list li ul {
      margin-left: 20px;
      list-style-type: upper-alpha; }
      .popup-box .terms-list li ul li ul {
        list-style-type: lower-alpha; }

.line-checkbox {
  margin-right: 15%; }

.single-line-checkbox {
  display: inline-block;
  width: 50%; }

.half-width-field .form-field-row {
  float: none; }

.md-select-menu-container {
  width: 320px; }

.on-road-price-icon {
  left: 308px;
  top: -142px; }

@media (min-width: 0px) and (max-width: 769px) {
  .on-road-price-icon {
    left: 94%;
    top: -262px; } }

.downloadbtn:after {
  content: '';
  position: absolute;
  left: 12px;
  top: 40%;
  width: 23px;
  height: 23px;
  margin-top: -6px;
  background: url(/assets/images/download.svg) no-repeat top left;
  transform: none;
  border: none; }

.password-block {
  position: relative; }

.eye-icon {
  position: absolute;
  top: 15px;
  right: 178px; }
  @media screen and (max-width: 768px) {
    .eye-icon {
      position: absolute;
      top: 15px;
      right: 3px; } }
  @media screen and (min-width: 768px) and (max-width: 980px) {
    .eye-icon {
      position: absolute;
      top: 15px;
      right: 128px; } }

.tab-vertical {
  width: 100%;
  margin-top: 0;
  padding: 50px 0; }
  .tab-vertical h2 {
    margin-top: 0;
    font-size: 31px;
    line-height: 34px; }
  .tab-vertical .tab-wrapper {
    width: 30%;
    display: block;
    float: left;
    height: auto; }
    .tab-vertical .tab-wrapper li {
      margin-left: 0;
      text-align: left;
      background: #dff2fd;
      height: 44px;
      line-height: 44px;
      margin: 2px 0;
      padding: 0 30px;
      position: relative;
      color: #0062a8; }
      .tab-vertical .tab-wrapper li:first-child {
        margin-top: 0; }
      .tab-vertical .tab-wrapper li:last-child {
        margin-bottom: 0; }
      .tab-vertical .tab-wrapper li:before, .tab-vertical .tab-wrapper li:after {
        display: none; }
      .tab-vertical .tab-wrapper li.active {
        background: #0062a8;
        color: #fff; }
        .tab-vertical .tab-wrapper li.active:after {
          content: '';
          border-top: 10px solid transparent;
          border-left: 10px solid #0062a8;
          border-bottom: 10px solid transparent;
          position: absolute;
          width: 0;
          height: 0;
          right: -10px;
          top: 11px;
          display: block;
          border-radius: 0;
          background: none; }
  .tab-vertical .right-tab-wrapper {
    width: 70%;
    float: left;
    padding-left: 30px; }
    .tab-vertical .right-tab-wrapper h2 {
      font-size: 18px;
      text-align: left;
      margin-bottom: 0; }
      .tab-vertical .right-tab-wrapper h2 + p {
        text-align: left;
        margin-bottom: 20px; }
      .tab-vertical .right-tab-wrapper h2:after {
        display: none; }
    .tab-vertical .right-tab-wrapper h5 {
      font-size: 15px;
      font-family: "Cairo-Bold"; }

.accordian-tab {
  padding: 0px 30px 10px 30px;
  position: relative;
  margin-bottom: 20px; }
  .accordian-tab:before {
    content: '+';
    position: absolute;
    width: 20px;
    height: 20px;
    left: 0;
    background: #0062a8;
    top: 0;
    color: #fff;
    text-align: center;
    font-size: 20px;
    line-height: 20px; }
  .accordian-tab h6 {
    font-size: 16px; }
  .accordian-tab .tab-content {
    display: none;
    padding: 10px 0; }
  .accordian-tab.active {
    background: #dff2fd;
    padding: 10px 30px 15px 30px; }
    .accordian-tab.active .tab-content {
      display: block; }
    .accordian-tab.active:before {
      content: '-';
      line-height: 16px; }

.acc-title {
  background: #dff2fd;
  color: #fff;
  margin-left: 0;
  text-decoration: none;
  font-family: "Cairo-Bold";
  height: 44px;
  line-height: 44px;
  margin: 2px 0;
  padding: 0 30px;
  position: relative;
  color: #0062a8;
  font-size: 15px;
  margin-bottom: 20px; }

.tab-content ol, .tab-content ul {
  margin-left: 15px;
  margin-top: 10px; }

.tab-content .table {
  background: #fff;
  margin-top: 20px;
  margin-bottom: 0; }
  .tab-content .table th, .tab-content .table td {
    padding: 10px; }
  .tab-content .table thead {
    font-family: "Cairo-Bold"; }
    .tab-content .table thead th {
      text-align: left; }
  .tab-content .table tbody tr {
    border-bottom: 1px solid rgba(224, 224, 224, 0.7); }
  .tab-content .table.sbl tbody tr td:first-child {
    font-family: "Cairo-Bold"; }
