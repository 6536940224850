.breadcrumb-title{
    margin-top: 40px;
    margin-bottom: 5px;
    margin-left: -86px;
}
.breadcrumb{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
    align-content: stretch;
    background-color: #EFEFEF;
    color: #000000;
    margin: 0px -86px 40px;
    .breadcrumb-item{
        width: calc(100% / 6);
        padding: 10px;
        text-align: center;
        a{
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
        }
        &.active a{
            color: #0062a8;
            font-weight: bold;
        }
        .floatright{
            float: right;
            transform: translateX(50%);
            font-size: 20px;
        }
        &:last-child{
            .floatright{
                display: none;
            }
        }
    }
}

@media all and (max-width: 767px) {
    .breadcrumb{
        display: none;
    }
    .breadcrumb-title{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}


.gennext-breadcrumb{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: center;
    align-content: stretch;
    background-color: #EFEFEF;
    color: #000000;
    margin: 0px 0 40px;
    .breadcrumb-item{
        width: calc(100% / 6);
        padding: 10px;
        text-align: center;
        a{
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
        }
        &.active a{
            color: #0062a8;
            font-weight: bold;
        }
        .floatright{
            float: right;
            transform: translateX(50%);
            font-size: 20px;
        }
        &:last-child{
            .floatright{
                display: none;
            }
        }
    }
}

@media all and (max-width: 767px) {
    .gennext-breadcrumb{
        display: none;
    }
    .breadcrumb-title{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.gennext-npa-breadcrumb{
    display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
    align-content: stretch;
    background-color: #EFEFEF;
    color: #000000;
    margin: 0px 0 40px;
    .breadcrumb-item{
        width: calc(100%/9);
        padding: 10px;
        text-align: center;
        a{.gennext-npa-breadcrumb{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            align-content: stretch;
            background-color: #EFEFEF;
            color: #000000;
            margin: 0px 0 40px;
            .breadcrumb-item{
                width: calc(100%/8);
                padding: 10px;
                text-align: center;
                a{
                    color: inherit;
                    text-decoration: none;
                    font-size: 14px;
                    line-height: 24px;
                }
                &.active a{
                    color: #0062a8;
                    font-weight: bold;
                }
                .floatright{
                    float: right;
                    transform: translateX(50%);
                    font-size: 20px;
                }
                &:last-child{
                    .floatright{
                        display: none;
                    }
                }
            }
        }
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
        }
        &.active a{
            color: #0062a8;
            font-weight: bold;
        }
        .floatright{
            float: right;
            transform: translateX(50%);
            font-size: 20px;
        }
        &:last-child{
            .floatright{
                display: none;
            }
        }
    }
}


@media all and (max-width: 767px) {
    .gennext-npa-breadcrumb{
        display: none;
    }
    .breadcrumb-title{
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.breadcrumb{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: stretch;
    background-color: #EFEFEF;
    color: #000000;
    margin: 0px -86px 40px;
    .breadcrumb-dc-item{
        width: calc(100% / 5);
        padding: 10px;
        text-align: center;
        a{
            color: inherit;
            text-decoration: none;
            font-size: 14px;
            line-height: 24px;
        }
        &.active a{
            color: #0062a8;
            font-weight: bold;
        }
        .floatright{
            float: right;
            transform: translateX(50%);
            font-size: 20px;
        }
        &:last-child{
            .floatright{
                display: none;
            }
        }
    }
}
@media (min-width: 0px) and (max-width: 810px) {
    .las-breadcrumb-web{
        display: none;
    }
    .las-breadcrumb-mobile{
        display: block;
        .breadcrumb-dc-item{
            width: calc(100% / 1);
        }
        .breadcrumb-item{
            width: calc(100% / 1);
        }
    }
}
@media (min-width: 0px) and (max-width: 810px) {
    .las-breadcrumb-mobile{
        margin-right: -21px;
    }
}
@media (min-width: 791px) and (max-width: 910px) {

    .las-breadcrumb-mobile{
        margin-right: -57px;
    }
}
@media (min-width: 768px) and (max-width: 790px) {

    .las-breadcrumb-mobile{
        margin-right: -49px;
    }
}
@media(min-width: 811px) {
    .las-breadcrumb-mobile{
        display: none;
    }
}