@media (max-width: 768px) {
    body,
    html {
        background: $light-background;
        font-size: 16px;
    }
    br {
        display: none;
    }
    .only-for-mobile { display: block!important;}
    .not-for-mobile{ display:none!important;}
    .inline {display: inline !important;}
    p {
        big {
            font-size: 16px;
            line-height: 20px;
        }
    }
    /* Header */
    header {
        height: $header-mobile-height;
        .header-right {
            a {
                &.menu-link {
                    display: none;
                }
            }
            .user-login {
                width: 60px;
                height: $header-mobile-height;
               /* margin-right: -15px;*/
                margin-left: 0;
                .loggedIn-item {
                    top: $header-mobile-height;
                }
                span {
                    width: auto;
                    margin-left: 0;
                    em {
                        display: none;
                    }
                    &:after {
                        right: 5px;
                        font-size: 5px;
                    }
                }
            }
        }
        .header-logo {
            width: 220px;
            .logo,
            .poweredby {
                background-size: $logo-mobile-background-size;
                height: $logo-mobile-height;
            }
            .logo {
                width: $logo-mobile-width;
                background-position: $logo-mobile-background-position;
            }
            .poweredby {
                margin-left: 20px;
                width: 66px;
                background-position: -149px -1px;
                &:before {
                    left: -10px;
                }
            }
        }
    }
    /* /Header */
    /* Inner Container */
    .bank-lower-header-bg {
        padding: 40px 15px 20px;
        height: auto;
        min-height: 40px;
        h1 {
            font-size: 20px;
            line-height: 24px;
        }
    }
    .tab-container-pass {
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 50px;
        box-shadow: none;
        .tab-wrapper {
            margin: 0 -15px;
        }
    }
    .main-form-container {
        padding: 0;
        h2 {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
            +p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        h3 {
            margin: 5px 0 25px;
        }
    }
    .main-form-kfs-container {
        padding: 0;
        h2 {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 20px;
            +p {
                font-size: 16px;
                line-height: 24px;
            }
        }
        h3 {
            margin: 5px 0 25px;
        }
    }
    .sub-box {
        width: 100%;
        padding: 30px 15px;
        h4 {
            margin: -30px -15px 30px;
            font-size: 16px;
        }
        &.upload-fin {
            margin-top: 30px;
            border: 0;
            box-shadow: none;
            padding: 0;
            p {
                &:first-of-type {
                    font-size: 16px;
                }
            }
        }
        &.customer-login {
            margin: -30px auto 30px;
            width: 100%;
        }
    }
    md-radio-button {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    md-checkbox {
        margin-top: 0;
    }
    /* /Inner Container */
    .form-block {
        &:last-of-type {
            .btn {
                margin-top: 10px;
            }
        }
    }
    .two-block {
        margin: 0;
        .form-field-row {
            padding: 0;
            width: 100%;
            float: none;
            &.with-prefix .field-prefix {
                left: 20px;
            }
            &:before,
            &:after {
                content: '';
                clear: both;
            }
        }
    }
    /* Upload Box */
    .upload-box {
        padding: 20px;
        margin-bottom: 40px;
        .sep {
            position: static;
            margin: 20px 0;
            text-align: center;
            display: block;
        }
        .myLabel,
        .connect-social {
            width: 100%;
        }
        .myLabel {
            width: 288px;
            padding: 20px;
            height: 80px;
            padding-left: 65px;
            line-height: 19px;
            margin: 0 auto;
            display: block;
            span {
                &:before {
                    left: 20px;
                    margin-top: -10.5px;
                }
            }
            br {
                display: block;
            }
        }
        .connect-social {
            text-align: center;
            padding-top: 0;
        }
    }
    .btn-social {
        width: 47%;
        max-width: 150px;
    }
    md-next-button,
    md-prev-button {
        display: none;
    }
    /* Offer */
    .offers {
        padding: 30px 15px;
        margin-top: -30px;
        background-color: #FFFFFF;
        border: 1px solid $border-default;
        box-shadow: 0 0 6px 0 $shadow-default;
        margin-bottom: 30px;
        .icon {
            margin-bottom: -20px;
        }
        h2 {
            &+p {
                margin-top: 0;
            }
        }
        .offer-container {
            margin: -30px -15px 0;
            padding: 40px 15px 0;
            .div-table {
                label {
                    font-size: 12px;
                }
            }
        }
        .customize-offer-container {
            .customize-box {
                .range-slider {
                    md-slider {
                        width: 350px;
                    }
                }
            }
        }
        .offer-input-amount {
            width: 100%;
            min-width: auto;
        }
        .offer-text-indent {
            font-size: 30px;
            padding-right: 110px;
            &::-webkit-input-placeholder {
                font-family:$font-family-bold;
            }
            &::-moz-placeholder {
                font-family:$font-family-bold;
            }
            &:-ms-input-placeholder {
                font-family:$font-family-bold;
            }
            ::placeholder {
                font-family:$font-family-bold;
            }
        }
        .subject-msg {
            padding: 20px 0;
            font-size: 16px;
        }
    }
    .range-slider-container {
        padding: 20px 0;
        &.div-table {
            .loan-amount {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }
    .detail-box {
        max-width: 100%;
        margin: 30px auto;
        padding: 20px 15px;
        height: auto;
        max-height: none;
        ol {
            li {
                margin-bottom: 15px;
            }
        }
        label {
            display: block;
        }
    }
    /*welcome screen css*/
    .dt-tbl {
        display: block;
        padding: 0 0 10px;
        margin-top: 20px;
        .dt-tbl-cl {
            display: inline-block;
            width: 49%;
            font-size: 14px;
            padding: 10px 10px 0;
        }
    }
    .display-none-for-mobile {
        display: none;
    }
    .detail-box-wl {
        width: 100%;
        p {
            font-size: 14px !important;
        }
        i {
            margin: 20px 0;
        }
        &:nth-child(odd) {
            margin-right: 0;
        }
        &:nth-child(even) {
            margin-left: 0;
            margin-bottom: 40px;
        }
    }
    /*end of welcome css*/
    /*my application*/
    .page-headings {
        padding: 30px 0;
    }
    .apply-loan-section {
        padding: 20px 0;
    }
    .loan-application-list {
        margin: 0 0 20px;
        .loan-list-top {
            padding: 15px 15px 0;
            span {
                margin-left: 0;
            }
        }
        .list-content {
            width: calc(100% - 28px) !important;
        }
        .loan-app-list {
            .loan-app-cell {
                display: block;
                width: 100%;
                label {
                    display: inline-block;
                }
                p {
                    display: inline-block;
                    font-size: 14px;
                }
                &:nth-child(5) {
                    width: calc(100% + 60px);
                    margin: 0 0 0 -43px;
                    padding: 10px;
                }
            }
        }
        .btn {
            width: auto !important;
        }
        .app-progress-bar {
            span {
                margin: 0 0 10px 20px;
                position: relative;
                display: block;
                height: 40px;
                line-height: 40px;
                &:last-of-type {
                    margin-bottom: 0;
                }
                label {
                    width: 100%;
                    text-align: left;
                    padding-left: 50px;
                    font-size: 16px;
                }
                i {
                    position: absolute;
                    left: 0;
                    margin-top: -15px;
                    top: 50%;
                }
            }
            &:after {
                width: 1px;
                height: 100%;
                border-left: solid 1px #C4CFD6;
                height: 100%;
                left: 34px;
                top: 0;
            }
        }
    }
    .layout-sm-column {
        display: block;
    }
    /*end of my application*/
    .structure-main {
       min-height: calc(100% - 144px);
    }
    .detail-container{
        .form-field-row{
            display:block!important;
            margin-bottom:20px;
            max-width:100%;
            .detail-box{margin-bottom:0;}
            &:last-of-type{
                margin-bottom:0;
                .detail-box{
                    max-width:100%;
                }
            }
        }
    }
    .information-container{
        .detail-container{
            .form-field-row{
                max-width:100%;
                &:last-of-type{
                  .reference-box{
                    max-width:100%;
                  }  
                }
            }
        }
    }
    /* My Application */
    .loan-application-list .application-list .table tbody tr{
        padding:8px 10px 50px;
        height:auto;
    }
    .loan-application-list {
         padding: 30px 0;
        .application-list{
            .user-name{
                display:inline-block;
            }
            .table{
                /*display:block;*/
                tbody{

                    tr{
                        display:block;
                        border:1px solid $border-default;
                        margin-bottom:20px;
                        padding: 8px 10px 40px;
                        &:last-child{
                            margin-bottom:0;
                        }
                         td{
                            float:left;
                            width:50%;
                            font-size:16px;
                            padding:10px;
                            
                            label{
                                font-size:12px;
                                line-height:14px;
                                color:$text-color;
                                text-transform:uppercase;
                            }
                            &:before, &:after{
                                content:'';
                                display:table;
                                clear:both;
                            }
                        }
                        &:before, &:after{
                            content:'';
                            display:table;
                            clear:both;
                        }
                    }
                   
                }
                .icon-Delete{
                    margin-left:25px;
                    vertical-align:middle;
                    margin-bottom:0!important;
                }

                .acceptance{
                    label{
                        font-family:$font-family;
                    }
                     .acceptance-box{
                        .status-bar{
                            left:0;
                            margin-left:0;
                            text-align:left;
                            
                            display:block;
                            top:27px;
                        }
                        .status-box{
                            &:before, &:after{
                                left:36px;
                            }
                        }
                    } 
                }
            } 
        }
    } 
    /* /My Application */ 
    .tab-vertical{
        margin-right:0 !important ;
        .right-tab-wrapper{
            width:100%!important;
            padding-left:0!important;
            h2{
                display:none;
            }
        }
    }

    .loan-application-list .application-list .table tbody tr td{
        padding:10px 0;
    }

    .loan-application-list .application-list .acceptance{
        font-size: 10px !important;
    }
}









@media (max-width: 576px) {
    .icon-img {
        &.icon-sm {
            background-size: 242px auto;
        }
        &.icon-thanks,
        &.icon-offer,
        &.icon-great {
            width: 67px;
            height: 67px;
        }
        &.icon-offer {
            background-position: -411px -58px;
        }
        &.icon-thanks {
            background-position: -489px -58px;
        }
        &.icon-great {
            background-position: -571px -58px;
        }
    }
    md-checkbox {
        .md-container,
        .md-icon {
            width: 20px;
            height: 20px;
        }
        .md-container {
            top: 4px;
            .md-ripple-containe {
                left: -10px;
                top: -10px;
                right: -10px;
                bottom: -10px;
            }
        }
        &.md-checked {
            .md-icon {
                &:after {
                    left: 4.66667px;
                    top: 0.22222px;
                    width: 6.66667px;
                    height: 13.33333px;
                }
            }
        }
        .md-label {
            margin-left: 30px;
        }
    }
    .only-for-xs {
        display: block!important;
    }
    .btn {
        width: 100%;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        padding: 0 20px;
        &.btn-large {
            min-width: 100%;
        }
        &.btn-social {
            width: 47%;
            max-width: 150px;
        }
    }
    .upload-box {
        .myLabel {
            width: 100%;
        }
    }
    .anchor-spacing-under-btns {
        font-size: 16px;
    }
    .div-table {
        .table-cell {
            display: block;
            border-bottom: 1px solid #f4f4f4;
            border-left: 0;
            &:last-of-type {
                border-bottom: 0;
            }
        }
    }
    .offers {
        .offer-container {
            .offer-details {
                margin-top: 10px;
                .div-table {
                    padding: 0 0 10px;
                    .loan-amount {
                        font-size: 18px;
                        line-height: 22px;
                    }
                    .table-cell {
                        display: inline-block;
                        float: left;
                        width: 50%;
                        padding: 15px 0 10px;
                        &:nth-child(5) {
                            // width: 100%;
                            min-height: auto;
                        }
                    }
                }
            }
        }
        .customize-offer-container {
            padding: 20px 0;
            border-bottom: 1px solid rgba(224, 224, 224, 0.7);
            h6 {
                font-size: 12px;
            }
            .customize-box {
                text-align: left;
                margin-top: 10px;
                label {
                    font-size: 12px;
                    margin-bottom: 5px;
                }
                .box-label{
                    width:100%;
                }
                .range-slider {
                    display: none;
                }
                +.customize-box {
                    margin-top: 20px;
                }
                .loan-tenure {
                    span {
                        width: 35px;
                        font-size: 14px;
                        margin: 5px;
                        height: 28px;
                        line-height: 26px;
                        text-align: center;
                        &:first-child {
                            margin-left: 0;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        .declaration {
            padding-top: 15px;
        }
        p {
            &+p {
                margin-top: 10px;
            }
        }
        .no-offer-box {
            width: 100%;
            margin: 20px auto;
            font-size: 12px;
            ul {
                li {
                    label {
                        width: 85px;
                        padding: 0 15px 15px;
                        margin-right: 15px;
                    }
                    &:first-child {
                        label {
                            padding-top: 15px;
                        }
                    }
                    &:last-child {
                        label {
                            padding-bottom: 15px;
                        }
                    }
                }
            }
        }
    }

    .default-container{
        h2{
            font-size:15px!important;
        }
       .form-field-row{
            text-align:left;
            md-radio-button{
                margin-right:12px;
            } 
        }
    }
    .uploaded-image{
        width:100%;
    }
    .detail-container{
        margin-top:20px;
    }

}

@media (max-width: 500px) {
    .not-for-xs { display: none !important;}
    .close-overlay {
        top: 0;
        right: 0;
        width: 30px;
         height: 30px;
         line-height:29px;
         font-size:12px;
    }
    .confirmation-layer {
        padding: 30px 15px;
    }
    .detail-box {
        .box-detail {
            margin-bottom: 15px;
            label {
                display: block;
                margin-bottom: 0;
            }
        }
    }
    .application-box .dashboard-wrapp a {
        padding: 0 10px;
        font-size: 18px;
        line-height: 21px;
    }
}



