@import 'bourbon';
@import 'neat';

@import "modules/_animation.scss";
@import "modules/_incompatible-browser.scss";
@import "modules/_loader.scss";
@import "modules/_version.scss";

@import 'node_modules/angular-material/angular-material.scss';

/*for website helpers structure*/
/*@import 'helpers/variable.scss';*/
@import 'helpers/yesbank-variable.scss';

@import 'mixin/mixin.scss';

/*for website layout structure*/
@import 'layout/common-elements.scss';
@import 'layout/header.scss';
@import 'layout/footer.scss';
@import 'layout/material-overwrite.scss';
@import 'layout/breadcurmb';


/*for website compenents*/
@import 'component/buttons.scss';
@import 'component/checkbox.scss';
@import 'component/loaders.scss';
@import 'component/overlay.scss';
@import 'component/radio.scss';
@import 'component/select-box.scss';
@import 'component/tabing.scss';
@import 'component/validation.scss';
@import 'component/iconFont.scss';
@import 'component/datepicker.scss';


/*for website forms structure*/
@import 'forms/form.scss';



/*for website pages structure*/
@import 'pages/offer.scss';
@import 'pages/reference.scss';
@import 'pages/upload.scss';
@import 'pages/myapplication.scss';
@import 'pages/agreement.scss';
@import 'pages/welcome.scss';
@import 'pages/thankyou.scss';
@import 'pages/information.scss';
@import 'pages/request-new-document.scss';
@import 'pages/las';

/*for website 2x devices */
@import 'retina/retina.scss';

/*for website repsonsive*/
@import 'device/tablet.scss';
@import 'device/mobile.scss';
@import 'device/verfy-documents-mobile.scss';

/* Yes Bank Modified CSS */


@import 'layout/yesbank-common-elements.scss';
@import 'pages/tab-vertical.scss';



