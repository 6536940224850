@mixin default-text-field {
    display: block;
    width: 100%;
    height: $form-default-height;
    font-size: $form-field-size;
    line-height: $form-default-lineheight;
    color: $form-field-color;
    padding: 0 20px;
    background-color: #fff;
    text-transform:capitalize;
    font-family:$font-family-bold;
    text-align:left;
    background-image: none;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid $form-field-border;
    -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }
    &:focus {
        background-color: $form-field-background-on-hover;
        border-color: $form-field-border-on-hover;
        outline: none;

    }
}