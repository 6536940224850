/* 
Bootstrap stand alone css
https://cdnjs.com/libraries/bootstrap-datetimepicker
*/
.glyphicon {
    position: relative;
    top: 2px;
    display: inline-block;
    background-image: $icon-background;
    background-size: $icon-background-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 7px;
    height: 10px;
}


.glyphicon-chevron-left{
    background-position:-371px -28px;
}

.glyphicon-chevron-right {
   background-position:-389px -28px;
}


.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

.dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

/*Custom date picker css*/
.dropdown-menu {
    padding:5px;
    background-color:#fff;
    border: 1px solid $border-default;
    border-radius:0;
    -webkit-box-shadow: 0 0 6px 0 $shadow-default;
    box-shadow: 0 0 6px 0  $shadow-default;
    .btn {
        padding: 6px 12px;
        min-width: auto;
        height: auto;
        line-height: 1.5;
        border: 1px solid transparent;
        &:before, &:after{
            display:none;
        }
    }
    .btn-sm {
        padding: 5px 10px;
    }
    .btn-default {
        color:$text-color2;
        background-color: #fff;
        border-color:$border-default;
        &.active{
            &:hover{
                color: $text-color2;
                background-color:$gray-secondary;
                border-color: $form-field-border;
            }
        }
        &:hover {
            color:$text-color2;
            background-color:$gray-secondary;
            border-color: $form-field-border;
        }

    }
    .btn-info {
        color: #fff;
        background-color:$secondary-btn-background-color;
        border-color:$secondary-btn-background-color;
        &:hover{
            color: #fff;
            background-color:$secondary-btn-background-color;
             border-color:$secondary-btn-background-color;
        }

    }
    .btn-danger, .btn-success{
        background-color:$primary-btn-background-color;
        color:$primary-btn-color;
        &:hover{
            background-color:$primary-btn-background-color;
            color:$primary-btn-color;
        }
    }
    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0,0,0,0);
        border: 0;
    }


    em{
        font-style:normal;
    }
    button{
        background-color:transparent;
    }


    input[type="reset"][disabled], input[type="submit"][disabled], html input[type="button"][disabled], button[disabled] {
    cursor: default;
     background-color:$background-disable;
     color:$text-color;
     &:hover{
        background-color:$background-disable;
        border-color:$border-default;
        color:$text-color;
        }
    }
    .uib-month{
        .btn{
            min-width:100%;
        }
    }

    /*Hiding bottom bar's clear and today buttons*/
    .btn-group{
        display: none;
    }
}
