.welcome-text{
	.div-table .loan-amount{
		font-size:18px !important;
		line-height:22px;
	}
}
.welcome-upload{
	
	.detail-box{
		height:auto;
		max-height:none;
		min-height:317px;
		padding:80px;
		margin-bottom:0;
		.btn{
			margin-top:40px;
		}
	}
	.form-field-row{
		color:$text-color2;
		margin:0 auto 0;
		float: none;
		.md-label{
			color:$text-color;
		}
	}
	.welcome-uploadBox{
		.btn{
			margin-top:0;
		}
		md-radio-button{
			margin-top:30px;
		}
		.form-field-row{
                margin-bottom:20px;
        }
	}
}


.dt-tbl{
	display: table;
	width: 100%;
	margin-top: 40px;
	padding:20px;
	border:solid 1px $border-default;
	.dt-tbl-cl{
		display: table-cell;
		font-size: 18px;
		text-align: left;
		width: 25%;
		label{
			color: $text-color;
		}
	}
}
.detail-box-wl{
	display: inline-block;
	width:47%;
	margin:40px auto 0px;
	vertical-align: top;
    box-shadow: 0 0 4px 0 $shadow-default;
    border:1px solid $border-default;

	i{
		font-size:50px;
		margin-bottom: 30px;
		position:relative;
		color: $secondary-color;
	}
	h2{
		margin:0 0 30px;
		font-size: 21px;
    	line-height: 24px;
	}
	p{
		font-size: 14px;
		margin-bottom: 40px;
	}
	&:nth-child(odd){
		margin-right:25px;
	}
	&:nth-child(even){
		margin-left:25px;
	}
	.stps1{
		padding: 40px;
	}
}
.sub-box {
	&.welcome_upload{
		box-shadow: none;
		border:none;
		width: 100%;
		h4{
			margin:-40px -40px 40px;
		}
		
	}
}