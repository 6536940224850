.icon {
    font-family: $font-family-icon;
    display: inline-block;
    font-style: normal;
    text-decoration: none;
    font-weight:normal;
}

.icon-Delete:before {
    content: "\e900";
}

.icon-icon-Check:before {
    content: "\e901";
}

.icon-IconArrow:before {
    content: "\e902";
}

.icon-IconClose:before {
    content: "\e903";
}

.icon-IconEmployer:before {
    content: "\e904";
}

.icon-iconInformation:before {
    content: "\e905";
}

.icon-IconKyc:before {
    content: "\e906";
}

.icon-IconLoanDetail:before {
    content: "\e907";
}

.icon-IconReferences:before {
    content: "\e908";
}

.icon-IconUpload:before {
    content: "\e909";
}

.icon-IconUser:before {
    content: "\e90a";
}

.icon-img {
    background-image: $icon-background;
    background-size: $icon-background-size;
    &.icon-sm{
    	background-size:322px auto;
    }
}
.icon-user{
    background-position: -318px -18px;
    height: 27px;
    width: 27px;
}
.icon-thanks, .icon-offer, .icon-great{
	width:87px;
	height:87px;
	margin:0 auto;
}
.icon-thanks{
	background-position:-329px -79px;
}
.icon-offer{
	background-position:-548px -78px;
}
.icon-great{
	background-position:-438px -78px;
}
.up-arrow{
    width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid $text-color;
  display:inline-block;
  vertical-align:middle;
}
.down-arrow{
    width: 0; 
  height: 0; 
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  display:inline-block;
  border-top: 4px solid $text-color;
  vertical-align:middle;
}
.left-arrow, .right-arrow{
  width:15px;
  height:15px;
  background-image:url(../assets/images/doc-icon.png);
  background-repeat:no-repeat;
  background-size:267px auto;
  position: relative;
  top: 3px;
}
.left-arrow{
  background-position:-208px -17px;
  margin-right:7px;
}
.right-arrow{
  background-position:-242px -17px;
  margin-left:7px;
}


