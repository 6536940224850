.reference-box {
    padding: 40px 80px 40px 40px;
    margin-top: 80px;
    margin-bottom: $form-fields-gap;
    border: 1px solid $border-default;
    box-shadow: 0 0 6px 0 $shadow-default;
    position: relative;
    color:$text-color;

    +.reference-box {
        margin-top: 0;
    }
    .edit {
        position: absolute;
        right: 40px;
        top: 40px;
        cursor:pointer;
    }
    h6 {
        font-size: 18px;
        font-family:$font-family-bold;
        margin: 0 0 4px 0;
        color:$text-color2;
    }
    p {
        margin-bottom: 4px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &:last-of-type {
        margin-top: 40px;
    }
}
