header{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 75;
}
.structure-main{
    margin-top: 120px;
}

.portfolio{
    .enablePort{
        thead td:first-child, thead td:nth-of-type(2){
            position:sticky;
            left:0px;
            background: #E6F0F5
        }
        tbody td:first-child, tbody td:nth-of-type(2)
        {
          position:sticky;
          left:0px;
          background: #fff;
        }
        tbody tr:last-child td:first-child, tbody tr:last-child td:nth-of-type(2)
        {
          position:sticky;
          left:0px;
          background: transparent;
        }
    }
    .disablePort{
        thead td:first-child{
            position:sticky;
            left:0px;
            background: #E6F0F5
        }
        tbody td:first-child{
          position:sticky;
          left:0px;
          background: #eee;
        }
    }
}


.las-info-wrapper{
    text-align: center;
    text-align: center;
    margin: 20px 0 55px;
    .las-info-title{
        line-height: 1.5;
        color: #212529;
        font-size: 26px;
        font-weight: bold;
        padding-bottom: 20px;
        margin-bottom: 20px;
        position: relative;
        &:after{
            position: absolute;
            bottom: 15px;
            display: block;
            content: "";
            height: 4px;
            width: 75px;
            background: #d71920;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    p{
        line-height: 1.5em;
        color: #212529;
        font-size: 16px;
    }
    .smallfont{
        font-size: 13px !important;
    }
    .bigfont{
        font-size: 15px !important;
    }
    .help-text-wrapper{
        position: relative;
    }
}
.portfolio{
    margin-bottom: 50px;
    margin-top: -20px;
}
.portfolio .customer-name{
    text-transform: capitalize;
}
.portfolio table{
    width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    border-collapse: collapse;
    table-layout: fixed;
}
.portfolio thead{
    background-color: #E6F0F5 !important;
    color: #000000 !important;
}
.portfolio .table th,
.portfolio  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}
.portfolio  .table tr:last-child td{
    border-top: none;
}
.portfolio .form-control,
.las-form .form-control{
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.portfolio .form-control:focus,
.las-form .form-control:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.portfolio input.ng-invalid:not(.ng-untouched){
    border-color: #a94442;
    box-shadow: 0 0 0 0.2rem rgba(168, 69, 66, .075);
}

.portfolio tbody .total-amount-row{
    background-color: #E6F0F5 !important;
    color: #000000 !important;
}
.portfolio tbody tr td:nth-child(11),
.portfolio tbody tr td:nth-child(12){
    min-width: 150px;
}
.portfolio .form-control{
    max-width: 150px;
    min-width: 100px;
}
.portfolio tr.disabled {
    pointer-events: none;
    background: #eee;
    opacity: 0.9;
}
.portfolio tr.disabled input,
.portfolio tr.disabled input.amountInvalid,
.portfolio tr.disabled input.unitsInvalid{
    background: #eaeaea;
    border: 1px solid #ced4da;
}
.portfolio input.invalid{
    border: 1px solid #a94442;
}
.portfolio .disclaimer{
    margin-top: 50px;
}
.portfolio .txstrong{
    font-weight: bold;
}
.portfolio  .form-error,
.las-soc-form .form-error{
    margin-top: 10px;
}
.las-soc-form .form-success{
    margin-top: 10px;
    color: #155724;
}
.las-soc-form{
    form{
        padding: 0 15px 50px;

        table{
            text-align: left !important;
            font-size: 13px !important;
            width: 100%;
            color: #000;
            background-color: transparent;
            border-collapse: collapse;
            td{
                padding: 5px;
                line-height: 20px;
                border-top: 1px solid #dee2e6; 
            }
        }
        .terms{
            margin: 40px 0 20px;
            font-size: 16px;
            color: #000; 
        }
    }
}
button.disabled{
    opacity: .3;
}

.mt-50{
    margin-top: 5px;
}

button:focus{
    outline: #eb8b8f auto 2px;
}

.btn-primary:hover{
    background: #ab1419 !important;
}


.con-block{
    padding: 0px 0 40px;
    h2{
        font-size: 34px;
        margin-bottom: 15px;
        margin-top: 15px;
    }
    p{
        font-size: 20px;
        color: #777;
    }
}

.cust-heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 5px;
    .assetClass{
        display: inline-block;
        margin-left: 10px;
        padding-left: 10px;
        border-left: 1px solid #999;
    }
}

.td-50{
    width: 50px;
}
.td-100{
    width: 100px;
}
.td-80 {
    width: 80px;
}
.td-150{
    width: 150px;
}
.td-200{
    width: 200px;
}


.blinking{
    animation:blinkingText 1s infinite;
}
@keyframes blinkingText{
    0%{     color: green;    }
    50%{    color: transparent; }
    100%{   color: green;    }
}

.help-text-wrapper{
    position: relative;
    z-index: 999999;
    .fund-listing{
        margin-top:10px;
        list-style-type: disc;
        li{
            padding: 5px;
        }
    }
    span{
        display: none;
        position: absolute;
        top: -3px;
        padding: 10px;
        background: #f1f1f1;
        border-radius: 2px;
        left: 26px;
        font-size: 12px;
        color: #777;
        width:300px;
        height:200px;
        overflow: auto;
    }
    &:before{
        display: none;
        border-top: 7px solid transparent;
        border-bottom: 7px solid transparent;
        border-right: 12px solid #f1f1f1;
        content: '';
        position: absolute;
        left: 17px;
        top: 2px;
    }
    &:hover{
        &:before{
            display: inline-block;
        }
        .fa-info-circle+span{
            display: inline-block;
        }
    }
}
// .fa-info-circle:hover,.fa-info-circle:active, .fa-info-circle:focus{
    
// }

.track-status{
    width: 66.6667%;
    margin: 0 auto 100px;
    .application-number{
        border-bottom: 2px solid #1aa7e3;
        text-align: center;
        margin-bottom: 40px;
    }
    .status-wrap{
        margin: 0 auto;
        width: 70%;
        .status{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            align-content: center;
            margin-bottom: 20px;
            padding-bottom: 20px;
            &:not(:last-child){
                border-bottom: 1px solid #ccc;
            }
            .status-indicator{
                text-align: center;
                width: 80px;
                img{
                    width: 35px;
                    height: auto;
                }
                .indicator{
                    font-size: 20px;
                }
            }
            .status-title{
                flex: 1;
            }
            &.processing{
                .indicator{
                    color: #d71920;
                }
            }
            &.pending{
                .indicator{
                    color:#646464;
                }
            }
        }
    }
    .title{
        font-size: 16.3px;
        line-height: 1.5;
        font-weight: bold;
        color: #212529;
    }
    .status-date{
        font-size: 12.8px;
        color: #212529;
    }
    
}

.soc-terms{
    
    .ft0{font: bold 13px 'Book Antiqua';}
    .ft2{margin-left: 3px;}
    .ft4{margin-left: 32px;}
    .ft5{margin-left: 32px;}
    .ft6{margin-left: 33px;}
    .ft7{margin-left: 31px;}
    .ft8{margin-left: 35px;}
    .ft10{margin-left: 38px;}
    .ft11{margin-left: 3px;}
    .ft12{margin-left: 38px;}
    .ft14{margin-left: 5px;}
    .ft15{margin-left: 13px;}
    .ft16{margin-left: 12px;}
    .ft18{margin-left: 14px;}
    .ft19{margin-left: 11px;}
    .ft20{margin-left: 15px;}
    .ft21{margin-left: 12px;}
    .ft22{margin-left: 4px;}
    .ft23{margin-left: 4px;}
    .ft24{margin-left: 6px;}
    .ft25{margin-left: 41px;}
    .ft26{margin-left: 4px;}
    .ft27{margin-left: 31px;}
    .ft28{margin-left: 13px;}
    .ft29{margin-left: 12px;}
    .ft30{margin-left: 14px;}
    .ft31{margin-left: 33px;}
    .ft32{margin-left: 35px;}
    .ft33{font: 12px 'Book Antiqua';}
    .ft34{margin-left: 5px;}
    .ft35{margin-left: 4px;}
    .ft37{margin-left: 3px;}
    .ft38{margin-left: 5px;}
    .ft39{margin-left: 31px;}
    
    .p1{text-align: justify;margin-top: 34px;margin-bottom: 0px;}
    .p2{text-align: justify;margin-top: 32px;margin-bottom: 0px;}
    .p3{text-align: justify;margin-top: 14px;margin-bottom: 0px;}
    .p4{text-align: justify;margin-top: 15px;margin-bottom: 0px;}
    .p5{text-align: justify;margin-top: 16px;margin-bottom: 0px;}
    .p6{margin-top: 16px;margin-bottom: 0px;}
    .p7{margin-top: 8px;margin-bottom: 0px;}
    .p8{margin-top: 1px;margin-bottom: 0px;}
    .p9{text-align: justify;margin-top: 0px;margin-bottom: 0px;}
    .p10{margin-top: 3px;margin-bottom: 0px;}
    .p11{margin-top: 1px;margin-bottom: 0px;}
    .p12{margin-top: 0px;margin-bottom: 0px;}
    .p13{padding-left: 48px;margin-top: 0px;margin-bottom: 0px;text-indent: -48px;}
    .p14{text-align: justify;margin-top: 1px;margin-bottom: 0px;}
    .p15{margin-top: 0px;margin-bottom: 0px;}
    .p16{padding-right: 33px;margin-top: 2px;margin-bottom: 0px;}
    .p17{margin-top: 3px;margin-bottom: 0px;}
    .p18{margin-top: 2px;margin-bottom: 0px;}
    .p19{text-align: justify;margin-top: 2px;margin-bottom: 0px;text-indent: 3px;}
    .p20{text-align: justify;margin-top: 4px;margin-bottom: 0px;}
    .p21{text-align: justify;margin-top: 10px;margin-bottom: 0px;}
    .p22{text-align: justify;margin-top: 2px;margin-bottom: 0px;}
    .p23{margin-top: 2px;margin-bottom: 0px;}
    .p24{padding-left: 24px;margin-top: 0px;margin-bottom: 0px;text-indent: -24px;}
    .p25{padding-right: 33px;margin-top: 0px;margin-bottom: 0px;}
    .p26{padding-right: 249px;margin-top: 5px;margin-bottom: 0px;}
    .p27{text-align: justify;margin-top: 8px;margin-bottom: 0px;}
    .p28{text-align: justify;margin-top: 5px;margin-bottom: 0px;}
    .p29{text-align: justify;margin-top: 24px;margin-bottom: 0px;}
    .p30{text-align: justify;margin-top: 0px;margin-bottom: 0px;}
    .p31{margin-top: 4px;margin-bottom: 0px;}
    .p32{padding-right: 1px;margin-top: 7px;margin-bottom: 0px;}
    .p33{text-align: justify;margin-top: 2px;margin-bottom: 0px;}
    .p34{text-align: justify;margin-top: 7px;margin-bottom: 0px;}
    .p35{text-align: justify;margin-top: 3px;margin-bottom: 0px;}
    .p36{text-align: justify;margin-top: 4px;margin-bottom: 0px;}
    .p37{text-align: justify;margin-top: 6px;margin-bottom: 0px;}
    .p38{text-align: justify;margin-top: 5px;margin-bottom: 0px;}
    .p39{text-align: justify;margin-top: 9px;margin-bottom: 0px;}
    .p40{margin-top: 10px;margin-bottom: 0px;}
    .p41{margin-top: 15px;margin-bottom: 0px;}
    .p42{text-align: justify;margin-top: 9px;margin-bottom: 0px;}
    .p43{margin-top: 7px;margin-bottom: 0px;}
    .p44{text-align: justify;margin-top: 7px;margin-bottom: 0px;}
    .p45{margin-top: 4px;margin-bottom: 0px;}
    .p46{padding-left: 24px;margin-top: 4px;margin-bottom: 0px;}
    .p47{padding-left: 24px;margin-top: 0px;margin-bottom: 0px;}
    .p48{margin-top: 0px;margin-bottom: 0px;}
    .p49{padding-left: 708px;margin-top: 17px;margin-bottom: 0px;}
    .p50{padding-right: 33px;margin-top: 0px;margin-bottom: 0px;}
    .p51{text-align: justify;margin-top: 0px;margin-bottom: 0px;}
    .p52{margin-top: 4px;margin-bottom: 0px;}
    .p53{padding-left: 48px;margin-top: 1px;margin-bottom: 0px;}
    .p54{margin-top: 0px;margin-bottom: 0px;}
    .p55{text-align: justify;margin-top: 4px;margin-bottom: 0px;}
    .p56{text-align: justify;margin-top: 2px;margin-bottom: 0px;}
    .p57{margin-top: 2px;margin-bottom: 0px;}
    .p58{margin-top: 1px;margin-bottom: 0px;}
    .p59{margin-top: 2px;margin-bottom: 0px;}
    .p60{text-align: right;margin-top: 17px;margin-bottom: 0px;}
    .p61{margin-top: 5px;margin-bottom: 0px;}
    .p62{text-align: justify;margin-top: 6px;margin-bottom: 0px;}
    .p63{text-align: justify;margin-top: 3px;margin-bottom: 0px;}
    .p64{text-align: justify;padding-right: 33px;margin-top: 1px;margin-bottom: 0px;}
    .p65{text-align: justify;margin-top: 1px;margin-bottom: 0px;}
    .p66{text-align: justify;margin-top: 7px;margin-bottom: 0px;}
    .p67{margin-top: 3px;margin-bottom: 0px;}
    .p68{text-align: justify;padding-right: 33px;margin-top: 2px;margin-bottom: 0px;}
    .p69{text-align: justify;margin-top: 15px;margin-bottom: 0px;}
    .p70{text-align: justify;margin-top: 21px;margin-bottom: 0px;}
    .p71{margin-top: 19px;margin-bottom: 0px;}
    .p72{margin-top: 21px;margin-bottom: 0px;}
    .p73{text-align: justify;margin-top: 20px;margin-bottom: 0px;}
    .p74{margin-top: 4px;margin-bottom: 0px;}
    .p75{margin-top: 1px;margin-bottom: 0px;}
    .p76{text-align: justify;margin-top: 12px;margin-bottom: 0px;}
    .p77{margin-top: 12px;margin-bottom: 0px;}
    .p78{margin-top: 13px;margin-bottom: 0px;}
    
}
.tc-hedline{
    text-align: center;
    text-decoration: underline;
}
.tc-table-border td, th{
    border: 1px solid black;
    // width: 588px;
    padding: 3px 5px 3px 7px;
}

.in-tc-table-border td, th{
    border: 1px solid black;
    width: auto;
    padding-left: 7px;
}
.sn-center{
    text-align: center;
}
td:empty{
    padding: 10px;
}