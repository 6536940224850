/*font family used as pr bank guideline*/
$font-url-ttf:url('../assets/fonts/cairo/Cairo-Medium.ttf') format('ttf');
// $font-url-eot:url('../assets/fonts/cairo/Cairo-Medium.eot') format('eot');
// $font-url-woff:url('../assets/fonts/cairo/Cairo-Medium.woff') format('woff');
$font-url-bold-ttf:url('../assets/fonts/cairo/Cairo-Bold.ttf') format('ttf');
// $font-url-bold-eot:url('../assets/fonts/cairo/Cairo-Bold.eot') format('eot');
// $font-url-bold-woff:url('../assets/fonts/cairo/Cairo-Bold.woff') format('woff');
$font-family: "Cairo-Medium"; 
$font-family-bold: "Cairo-Bold"; 
$text-size:14px;


/*yes bank font icons*/
$font-url-ttf-icon:url('../assets/fonts/yesicon.ttf') format('ttf');
$font-url-svg-icon:url('../assets/fonts/yesicon.eot') format('svg');
$font-url-eot-icon:url('../assets/fonts/yesicon.eot') format('eot');
$font-url-woff-icon:url('../assets/fonts/yesicon.woff') format('woff');
$font-family-icon: "yesicon"; 

/* button styles */
$primary-btn-background-color: #d71920;
$primary-btn-color: #fff;
$secondary-btn-background-color: #0062a8;
$secondary-btn-color: #fff;
$secondary-bg: #0062a8;

/* anchor styles */
$primary-anchor-color: #0062a8;
$secondary-anchor-color: #9b9b9b;
$default-anchor-color: #404040;
$hover-anchor-color: #0062a8;
$anchor-text-decoration: underline;

/* dropdown styles */
$blue-selectbox-background-color: #0062a8;
$blue-selectbox-color: #fff;
$gray-selectbox-background-color: #e2e2e2;
$gray-selectbox-color: #9b9b9b;
$select-box-border-radius:20px;

$yes-bank-gray:#cccccc;
$primary-color: #d71920;
$secondary-color: #0062a8;
$gray-secondary:#f2f2f2;
$gray-primary:#f5f5f5;
$text-color:#9b9b9b;
$text-color2:#404040;
$text-color3:#CECECE;
$error-color:#ff0000;
$body-background:#ffffff;
$lower-bg:#ffffff;
$light-background:#ffffff;
$footer-background:#D8D7D7;
$grey-background:#f7f7f7;
$grey-secondary:#fafafa;
$primary-blue-bg:#0062a8;
$bg-grey:#f5f5f5;

/* form feild variables */
$default-box-padding:40px;
$default-box-Toppadding:80px;
$form-field-border:#cccccc;
$form-field-border-on-hover:#0062a8;
$form-placeholder:#9b9b9b;
$placaholder:#b1b1b1;
$form-field-color:#404040;
$form-field-background-on-hover:#ffffff;
$form-field-size:14px;
$form-fields-gap:20px;
$form-seprator-gap:40px;
$form-default-height:40px;
$form-default-lineheight:40px;
$form-button-height:40px;
$form-button-lineheight:40px;
$form-button-fontsize:11px;
$field-font-size:20px;
$border-default:rgba(224,224,224,0.7);
$shadow-default:rgba(0,0,0,0.05);
$default-divider:#f4f4f4;
$background-disable: rgba(220,220,220,0.15);

/* gradient lower header bg */


 /* Logo Style */
 $logo-width:161px;
 $logo-height:58px;
 $icon-background:url(../assets/images/yes-icon.png);
 $icon-background-size:617px auto;
 $logo-background-position:-9px -3px;
 $header-mobile-height:56px;
 $logo-mobile-width:121px;
 $logo-mobile-height:44px;
 $logo-mobile-background-size:458px auto;
 $logo-mobile-background-position: -6px -1px;


 $footer-text-color:#7b7b7b;
 $footer-link-color:#484747;

 /* Slider */
$slider-color:#3d93d1;
$slider-bar-color:#c7e2f2;

/* Tab */
$tab-bg:#dff2fd;
$tab-color:#0062a8;

