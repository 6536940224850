.upload-section {
	text-align: center;
    .upload-icon {
        background-color: rgba(51, 51, 51, 0.1);
        border: 1px solid #999;
        border-radius: 50%;
        color: #333;
        display: inline-block;
        font-size: 40px;
        height: 90px;
        line-height: 90px;
        text-align: center;
        width: 90px;
    }
    h4 {
    	font-size: 28px;
   font-family:$font-family-bold;
    margin: 20px 0 5px 0;
    }
    p {
    	margin: 0 0 50px;
    }
}


.upload-detail-box{
    border: 1px solid rgba(224, 224, 224, 0.7);
    border-radius: 4px;
    width: 100%;
    padding: 20px;
    margin: 15px 0 !important;
    text-align: left;

    h6{
        font-size: 20px;
        font-weight: 500;
        color:#333;
        margin-bottom:0 !important;

    }
}

.information-container{
    .added-applicant{
        &.uploadedFiles{
            .addedList{margin-right:-20px;}
        }
    }
} 
.required-doc{
    max-width: 330px;
    margin: 0 auto;
    ul{
        list-style: none;
        li{
            &:first-child{
                font-family:$font-family-bold;
                padding:20px 20px 5px;
                color:$text-color2;
            }
            background-color: rgba(235,139,136,.1);
            color:#ff0000;
            padding:0 20px;
            &:last-child{
                padding:0 20px 20px;
            }
        }
    }
}






